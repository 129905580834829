<template>
  <div id="app">
     <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app',

}
</script>

<style>
#app {
  font-size: 12px;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body .el-table th.gutter{
  /* 控制表头和内容对齐 */
  display:table-cell !important
}
/* red{color: red} */
</style>
