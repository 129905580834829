import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    size: {
    //   width: 0,
    //   height: 0
    }
  },
  mutations: {
    // sizeChange: function(state, size){
    //   state.size = size;
    // }
  },
  actions: {
  },
  modules: {
  }
})
