import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import md5 from 'js-md5'
import './plugins/element.js'
import './plugins/config.js'
import './plugins/util.js'
import myCharts from './plugins/myCharts.js'
// import Echarts from 'vue-echarts'
// import 'echarts/lib/chart/line'
// import 'echarts/lib/chart/pie'
import AMap from 'vue-amap';
import API from "./plugins/config.js";
import Util from "./plugins/util.js";
import Cookies from "js-cookie";
import "./assets/css/reset.css";
import "./assets/css/main.css";
import "./assets/css/newAdd.css";
import "./assets/css/style.css";
Vue.config.productionTip = false;

Vue.prototype.$md5=md5;
Vue.prototype.API=API;
Vue.prototype.Util=Util;
Vue.prototype.Cookies=Cookies;
Vue.use(myCharts,AMap);

Vue.prototype.$setSalary = function (mins,maxs) {
  let vJson = {min:"",max:"",value:""};
  let min = mins == null || mins == "" ? -1 : mins.toString();
  let max = maxs == null || maxs == "" ? -1 : maxs.toString();
  let minleg = min.length;
  let maxleg = max.length;
  vJson.min = min == -1 ? -1 : minleg == 5 ?  min.slice(0,2) : min.slice(0,1)
  vJson.max = max == -1 ? -1 : maxleg == 5 ?  max.slice(0,2) : max.slice(0,1);
  vJson.value = vJson.min == -1 && vJson.max == -1
      ? "面议"
      : vJson.min == -1
          ? vJson.max + "K以下"
          : vJson.max == -1
              ? vJson.min + "K以上"
              : vJson.min +"-"+ vJson.max + "K"
  return vJson;
}

Vue.prototype.$setEdu = function(edu){
  let xueLi = ["小学","中学","初中","高中","专科","中专","大专","本科","硕士","博士","博士后"];
  let leng = edu.toString().length;
  let val = leng == 3 ? edu.toString().slice(0,2) : edu.toString().slice(0,1);
  let val1 = leng == 3 ? edu.toString().slice(2,3) : edu.toString().slice(1,2);
  let edus = xueLi[val - 1]+(val1 == 0 ? '' : val1 == 1 ? '以下' :val1 == 2 ? '以上' :'')
  return edus == 'undefined' ? "不限" : edus;
}


//高德地图导入
AMap.initAMapApiLoader({
  key: 'a2f0a183973e73cafc018e43fd85be40',//刚刚开发者申请哪里的key
  plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType','AMap.Autocomplete']
});
window.vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
