<template>
    <div class="login">
        <div class="loginBox">
            <h1>龙里人力资源网管理后台</h1>
            <el-form class="m_top40" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0">
                <el-form-item  prop="account">
                    <el-input placeholder="请输入账号" autocomplete="off"  v-model="ruleForm.account"></el-input>
                </el-form-item>
                <el-form-item  prop="pwd">
                    <el-input maxlength="20" placeholder="请输入密码" type="password" v-model="ruleForm.pwd" show-password></el-input>
                </el-form-item>
            </el-form>
            <div class="m_top40">
                <el-button style="width:100%" @click="getUserLogin('ruleForm')"  type="primary">登录</el-button>
            </div>
            <div class="m_top20">
                <el-checkbox v-model="checked">已阅读</el-checkbox>
                <span class="btncol" @click="clickgetbook">隐私声明</span>
            </div>
        </div>
        <el-dialog
            title="隐私声明" width="38%"
            :visible.sync="opengetbook">
                <div class="el_content" v-html="content">
                </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="opengetbook = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "Login.vue",
        data(){
            var valaccount = (rule, value, callback) => {
                if (value) {
                    callback();
                } else {
                    callback(new Error('请输入账号'));
                }
            };
            var valpwd=(rule, value, callback)=>{
                if (value) {
                    callback();
                } else {
                    callback(new Error('请输入密码'));
                }
            };
            return{
                ruleForm:{
                    account:'',
                    pwd:''
                },
                content:'',
                checked:true,//隐私声明
                opengetbook:false,
                rules: {
                    account: [
                        { validator: valaccount, trigger: 'blur' }
                    ],
                    pwd:[
                        {validator: valpwd, trigger: 'blur'}
                    ]
                }
            }
        },
        mounted() {
            var  _self = this;
            document.onkeydown = function(e) {
                let ev = document.all ? window.event : e;
                if (ev.keyCode === 13) {
                    _self.getUserLogin('ruleForm');
                }
            }
        },
        computed: {
            token: function () {
                return "token=" + this.$store.state.token;
            }
        },
        created() {
            this.Cookies.set("Tabtitle",[]);
            this.Cookies.set("comParamMap",{});
        },
        methods:{
            //隐私声明
            clickgetbook:function(){
                var _this=this;
                axios.get(_this.API.news.sma_detail,{
                        headers:{
                            token: _this.Util.getjwtToken()
                        },
                        params:{
                            iclass:6,
                        }
                    }).then(function (res) {
                        if(res.data.success){
                            _this.content=res.data.data.content;
                            _this.opengetbook=true;
                        }else{
                            _this.$message.error(res.data.msg)
                        }
                        }).catch(function (error) {
                        _this.$message.error('登录出错，稍后重试！')   
                    });    
            },
            getUserLogin:function (formName) {
                var _this=this;
                // this.$refs[formName].resetFields();表单重置
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(this.checked){
                            axios.get(_this.API.login.accountPwdLogin,{
                                params:{
                                    account:this.ruleForm.account,
                                    pwd:this.$md5(this.ruleForm.pwd)
                                }
                            }).then(function (res) {
                                if(res.data.success){
                                    var logininfo=res.data.data;
                                    if(_this.Util.setLogininfo(logininfo)){
                                        _this.$router.push("Home")
                                    }
                                    document.onkeydown = function(){}
                                }else{
                                    _this.$message.error(res.data.msg)
                                }
                                }).catch(function (error) {
                                _this.$message.error('登录出错，稍后重试！')   
                            });
                        }else{
                            _this.$message.error('请阅读并勾选隐私声明！')
                        }
                    }else{
                        return false;
                    }
                });

            }
        }
    }
</script>

<style scoped lang="scss">
.login{
    width: 100%;
	// min-width: 1500px;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-image: url('~@/assets/img/login-bg.png');
    background-size: 100% 100%;
    .loginBox{
      width: 340px;
      height: 340px;
      margin-right: 200px;
      padding: 40px;
      border-radius: 10px;
      background-color: #ffffff;
    }
    .btncol{
        font-size:14px;
        color: #409eff;
        margin-left: 10px;
        cursor: pointer;
    }
    .el_content{
       width: 100%;
       height: 600px;
       overflow-y: scroll;
    }
}

</style>