<template>
  <div class="home">
    <div class="tilte_btn">
      <span class="lineY"></span>
      <span>
        <h2>添加新闻</h2>
      </span>
    </div>
    <div style="padding:10px">
      <span class="ent_del"><span style="color:red">*</span> 新闻标题：</span>
      <el-input v-model="webNewInfo.title" size="medium" style="width:80%" placeholder="请输入标题..."></el-input>
    </div>
    <div style="padding:10px">
      <span class="ent_del"><span style="color:red">*</span> 新闻摘要：</span>
      <el-input size="medium" style="width:80%" maxlength="200" placeholder="请输入新闻摘要(不超过200字)" v-model="webNewInfo.summary">
      </el-input>
    </div>
    <div style="padding:10px">
      <span class="ent_del"><span style="color:red">*</span> 新闻分类：</span>
      <el-checkbox-group v-model="typeArr">
        <el-checkbox v-for="(item, index) in newTitleType" :key="item.id" :label="item.id">{{item.title}}</el-checkbox>
      </el-checkbox-group>
    </div>
    <div style="padding:10px">
      <span class="ent_del">重要性：</span>
      <el-input v-model="webNewInfo.asort" @input="frmChange($event)" size="medium" style="width:20%" placeholder="请输入大于0的数字(小到大排序)"></el-input>
      <span class="word-aux">数字越小越靠前（系统默认按照发布时间倒序排序，无特殊情况无需修改此值）</span>
    </div>
    <div style="padding:10px">
      <el-row>
        <el-col :span="8">
          <span class="ent_del">新闻作者：</span>
          <el-input v-model="webNewInfo.author" size="medium" style="width:60%" placeholder="请输入作者"></el-input>
        </el-col>
        <el-col :span="8">
          <span class="ent_del">来源：</span>
          <el-input v-model="webNewInfo.source" size="medium" style="width:60%" placeholder="请输入来源地址"></el-input>
        </el-col>
        <el-col :span="8">
          <span class="ent_del">发布时间：</span>
          <el-date-picker @change="changePublishTime" style="width:60%" v-model="webNewInfo.publishTime" type="date" placeholder="选择发布时间" value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-col>
      </el-row>
    </div>
    <div style="padding:10px">
      <span class="ent_del">新闻缩略图：</span>
      <div style="padding-top:10px">
        <el-upload class="avatar-uploader" :action="uploadImgUrl" :show-file-list="false" :on-success="handleAvatarSuccess">
          <img v-if="webNewInfo.iconurl" :src="webNewInfo.iconurl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </div>
    </div>
    <div style="padding:10px">
      <span class="ent_del"><span style="color:red">*</span> 新闻内容：</span>
      <!-- <span>
              <el-button size="small" type="primary" @click="clearClick">清空内容</el-button>
          </span>
          <span class="m_fl15">
              <el-button size="small" :type="!isEditorDisabled ?'info':'success'" @click="disableClick">{{ !isEditorDisabled ? '禁用' : '启用' }}</el-button>
          </span> -->
    </div>
    <div style="padding:10px">
      <tinymce :id="editorId" ref="editor" v-model="webNewInfo.content" :disabled="isEditorDisabled" @handleImgUpload="imgUpload" @handfileUpload="fileUpload" />
    </div>
    <div style="padding:10px 10px 30px">
      <el-button @click="getNewaddData" style="width:150px" type="danger">发布</el-button>
    </div>
  </div>
</template>
<script>
import Tinymce from '@/components/Tinymce';
export default {
  name: 'Web_newAdd',
  components: { Tinymce },
  /*props: {
         comParam: Object
      },*/
  data () {
    return {
      uploadImgUrl: this.API.file.uploadSingle,
      newTitleType: [],
      webNewInfo: {},
      typeArr: [],
      //新闻编辑
      editorId: 'editor-demo',
      isEditorDisabled: false
    };
  },
  created () {
    this.comParam = this.$router.currentRoute.query;
    this.webNewInfo.adduid = this.Util.getLogininfo().data.id;
    this.webNewInfo.addusername = this.Util.getLogininfo().data.username;
    this.webNewInfo.publishTime = this.Util.setFormatDate(
      new Date(),
      'yyyy-MM-dd'
    );
    this.webNewInfo.asort = 100;
    this.getNewType(this.comParam.type);
  },
  methods: {
    ////更新视图
    frmChange (e) {
      this.$forceUpdate();
    },
    //图片上传部分
    handleAvatarSuccess (res, file) {
      if (res.success) {
        this.$set(this.webNewInfo, 'iconurl', res.data);
      } else {
        this.$message.error(res.msg);
      }
    },
    getNewType: function (type) {
      var _this = this;
      axios
        .get(this.API.news.menus, {
          headers: {
            token: this.Util.getjwtToken(),
          },
          params: {
            iclass: type,
          },
        })
        .then(function (res) {
          if (res.data.success && res.data.data.length > 0) {
            _this.newTitleType = res.data.data;
            console.log('分类', res.data.data);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //发布时间
    changePublishTime: function (e) {
      this.webNewInfo.publishTime = e;
    },
    //新闻内容编辑部分
    fileUpload (callback, value, meta) {
      console.log(callback, value, meta);
    },
    // 上传图片
    imgUpload (blobInfo, succFun, failFun) {
      var _this = this;
      var xhr, formData;
      var file = blobInfo.blob(); //转化为易于理解的file对象
      xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open('POST', _this.API.file.uploadSingle);
      xhr.onload = function () {
        var json;
        if (xhr.status != 200) {
          failFun('HTTP Error: ' + xhr.status);
          return;
        }
        json = JSON.parse(xhr.responseText);
        console.log('upload', json);
        if (!json || !json.data || json.data.length < 0) {
          failFun('上传失败，请重试');
          return;
        }
        succFun(json.data);
      };
      formData = new FormData();
      formData.append('file', file, file.name); //此处与源文档不一样
      xhr.send(formData);
    },
    // 清空事件
    clearClick () {
      this.$refs.editor.clear();
    },
    // 禁用事件
    disableClick () {
      this.isEditorDisabled = !this.isEditorDisabled;
    },
    //提交
    getNewaddData: function () {
      if (this.Util.isBlank(this.webNewInfo.title)) {
        this.$message.error('新闻标题不能为空！');
        return;
      }
      if (this.Util.isBlank(this.webNewInfo.summary)) {
        this.$message.error('新闻摘要不能为空！');
        return;
      }
      //  if(this.Util.isBlank(this.webNewInfo.author)){
      //     this.$message.error('新闻作者不能为空！');
      //     return;
      //  }
      //  if(this.Util.isBlank(this.webNewInfo.source)){
      //     this.$message.error('新闻来源不能为空！');
      //     return;
      //  }
      if (this.Util.isBlank(this.webNewInfo.publishTime)) {
        this.$message.error('新闻发布时间不能为空！');
        return;
      }
      // if (this.Util.isBlank(this.webNewInfo.iconurl)) {
      //   this.$message.error('请上传新闻封面图！');
      //   return;
      // }
      if (this.Util.isBlank(this.webNewInfo.content)) {
        this.$message.error('请编辑新闻内容！');
        return;
      }
      if(this.typeArr.length < 1){
        this.$message.error('请选择新闻分类！');
        return;
      }
      var _this = this;
      _this.webNewInfo.menid = _this.typeArr.join(",");
      axios({
        url: _this.API.news.editor,
        method: 'post',
        headers: {
          token: this.Util.getjwtToken(),
        },
        data: _this.webNewInfo,
      })
        .then(function (e) {
          var resp = e.data;
          if (resp.success) {
            _this.$message({
              message: resp.msg,
              type: 'success',
              duration: 2000,
              onClose: function () {
                _this.$parent.removeTab(_this.$parent.editableTabsValue);
              },
            });
          } else {
            _this.$message.error(resp.msg);
          }
        })
        .catch(function (error) {
          console.log('添加', error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.tilte_btn {
  background-color: #ffffff;
}
.word-aux {
  color: #999999 !important;
  margin-left: 10px;
}
</style>
