<template>
  <div class="main">
    <div class="Index_top">
      <div class="logo_fl">
        <div class="logoImg">
          <img src="../assets/img/new-logo.png" alt="" />
        </div>
      </div>
      <div class="logo_fr" :style="'width:'+(screenWidth - 239)+'px'">
        <div style="margin-left: 20px;font-size: 22px;">
          <span>龙里人力资源网管理后台</span>
        </div>
        <div class="logo_user">
          <div class="user_img">
            <img src="../assets/img/u.png" alt="" />
          </div>
          <div>
            <el-menu class="hortal fr" mode="horizontal" @select="handleSelect">
              <el-submenu index="1">
                <template slot="title">{{userInfo.username}}</template>
                <el-menu-item index="1">个人信息修改</el-menu-item>
                <el-menu-item index="2">修改密码</el-menu-item>
                <el-menu-item index="3">退出登录</el-menu-item>
              </el-submenu>
            </el-menu>
          </div>
        </div>
      </div>
    </div>
    <main class="mainBox">
      <div class="tac" :style="'height:'+(screenHeight-80)+'px'">
        <el-menu :default-active="defaultMenuActive" unique-opened class="el-menu-vertical-demo" @select="handtabchange">
          <div v-for="(item, index) in menuList" :key="index">
            <el-menu-item :index="index + ''" v-if="!item.childs||item.childs.length==0">
              <template slot="title">
                <i :class="item.icon"></i>
                <span>{{item.name}}</span>
              </template>
            </el-menu-item>
            <el-submenu :index="index + ''" v-else>
              <template slot="title">
                <i :class="item.icon"></i>
                <span>{{item.name}}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item :index="index + '-' + cindex" v-for="(child, cindex) in item.childs" :key="cindex">{{child.name}}</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </div>
        </el-menu>
      </div>
      <div class="compon" :style="'width:'+(screenWidth - 239)+'px'">
        <template>
          <div>
            <el-tabs class="jdctabs" type="border-card" v-model="editableTabsValue">
              <el-tab-pane v-for="(item,index) in Tabtitle" :key="index">
                <div slot="label" @click="clickTab(item.url)">
                  <span>{{item.name}}</span>
                  <i @click="removeTab(index)" class="el-icon-circle-close"></i>
                </div>
              </el-tab-pane>
            </el-tabs>
            <el-dropdown class="jdctabs-close" @command="TabCloseCommand">
              <el-button class="el-dropdown-link jdctabs-close-btn">
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="closeleft" icon="el-icon-caret-left">关闭左边</el-dropdown-item>
                <el-dropdown-item command="closeright" icon="el-icon-caret-right">关闭右边</el-dropdown-item>
                <el-dropdown-item command="closeouther" icon="el-icon-close">关闭其他</el-dropdown-item>
                <el-dropdown-item command="closeall" icon="el-icon-error">关闭全部</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
        <div class="tab_page" :style="'height:'+(screenHeight-120)+'px'">
          <!--<component :is="comName" :comParam="comParam"></component>-->
          <router-view />
        </div>
      </div>
    </main>
    <el-dialog title="个人信息修改" :visible.sync="editUserDialog" width="30%">
      <el-form ref="editUserData" size="medium" :model="editUserData" label-width="80px">
        <el-form-item label="姓名">
          <el-input style="width:80%;margin-right:10px" v-model="editUserData.username" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="电子邮件">
          <el-input style="width:80%;margin-right:10px" v-model="editUserData.email" placeholder="请输入电子邮件"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input style="width:80%;margin-right:10px" v-model="editUserData.remark" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="editUserDialog = false">取 消</el-button>
        <el-button size="medium" type="primary" @click="editUserDialogConfirm">提 交</el-button>
      </span>
    </el-dialog>

    <el-dialog title="通过手机验证修改" :visible.sync="changePwdDialog" width="30%">
      <el-form ref="changePwdData" size="medium" :model="changePwdData" label-width="80px">
        <el-form-item label="旧密码">
          <el-input style="width:80%;margin-right:10px" v-model="changePwdData.oldPwd" placeholder="请输入旧密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input style="width:80%;margin-right:10px" v-model="changePwdData.newPwd1" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码">
          <el-input style="width:80%;margin-right:10px" v-model="changePwdData.newPwd2" placeholder="请确认新密码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="changePwdDialog = false">取 消</el-button>
        <el-button size="medium" type="primary" @click="getChengePwd">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
/*import Home from "../pages/home/Home";
import Jobs from "../pages/jobs/Jobs";
import JobAdd from "../pages/jobs/JobAdd";
import JobDetail from "../pages/jobs/JobDetail";
 
import Ent_goodspot from "../pages/BasicData/Ent_goodspot";
import Ent_nature from "../pages/BasicData/Ent_nature";
import Ent_especial from "../pages/BasicData/Ent_especial";
import Ent_scale from "../pages/BasicData/Ent_scale";
import Ent_size from "../pages/BasicData/Ent_size";
import Ent_welfare from "../pages/BasicData/Ent_welfare";
import Ent_lndustry from "../pages/BasicData/Ent_lndustry";
import Ent_major from "../pages/BasicData/Ent_major";
import Ent_meminfo from "../pages/BasicData/Ent_meminfo";

import Recruit from "../pages/recruits/Recruit";
import Recruit_add from "../pages/recruits/Recruit_add";
import Recruit_edit from "../pages/recruits/Recruit_edit";
import Recruit_BoothEdit from "../pages/recruits/Recruit_BoothEdit";
import Recruit_detail from "../pages/recruits/Recruit_detail";

import EnterpriseList from "../pages/organs/EnterpriseList";
import EnterpriseReg from "../pages/organs/EnterpriseReg";
import Enterpriseadd from "../pages/organs/Enterpriseadd";
import EnterpriseDetail from "../pages/organs/EnterpriseDetail";

import Numder from "../pages/numder/Numder";
import Desire from "../pages/numder/Desire";
import Numder_add from "../pages/numder/Numder_add";

import Resume from "../pages/resume/Resume";
import ResumeDetail from "../pages/resume/ResumeDetail";
 
import Web_bander from "../pages/webruns/Web_bander";
import Web_recom from "../pages/webruns/Web_recom";
import Web_newZW from "../pages/webruns/Web_newZW";
import Web_newZX from "../pages/webruns/Web_newZX";
import Web_newAdd from "../pages/webruns/Web_newAdd";
import Web_newDetail from "../pages/webruns/Web_newDetail";
import Web_newEdit from "../pages/webruns/Web_newEdit";
import Web_info from "../pages/webruns/Web_info";
import Web_topNav from "../pages/webruns/Web_topNav";
import Web_SingleMenuArt from "../pages/webruns/Web_SingleMenuArt";
import Web_smaEditor from "../pages/webruns/Web_smaEditor";
 
import Powers from "../pages/systems/Powers";
import Role from "../pages/systems/Role";
import Admin from "../pages/systems/Admin";
import Person from "../pages/systems/Person";
*/

export default {
  name: "index.vue",
  /*components:{
      Home,Jobs,JobDetail,JobAdd,
      Powers,Role,Admin,Person
      Recruit,Recruit_add,Recruit_detail,Recruit_edit,Recruit_BoothEdit,
      EnterpriseList,EnterpriseReg,Enterpriseadd,EnterpriseDetail,
      Ent_goodspot,Ent_nature,Ent_especial,Ent_scale,Ent_size,Ent_welfare,Ent_lndustry,Ent_major,Ent_meminfo,

      Numder,Desire,Numder_add,
      Resume,ResumeDetail,

      Web_bander,Web_newZW,Web_newZX,Web_newAdd,Web_newDetail,Web_recom,Web_newEdit,
      Web_info,Web_topNav,Web_SingleMenuArt,Web_smaEditor
  },*/
  data () {
    return {
      screenWidth: document.body.clientWidth,
      screenHeight: document.body.clientHeight,

      editableTabsValue: '0',//控制标签颜色
      defaultMenuActive: "0",
      comName: 'Home',
      userInfo: {
        username: '',
        email: '',
        remark: ''
      },
      comParam: {},
      comParamMap: {},

      menuList: [],
      Tabtitle: [],
      //修改个人信息部分
      editUserDialog: false,
      editUserData: {
        username: '',
        email: '',
        remark: ''
      },
      //修改密码部分
      changePwdDialog: false,
      changePwdData: {
        oldPwd: '',
        newPwd1: '',
        newPwd2: ''
      },
      count: '获取',
      timer: null,
      translate: 60,
      disabledBtn: false,
      tabUrls: '',
      currRoute: {}
    }
  },
  created () {
    this.getloadLeftMenu();
    var loginInfo = this.Util.getLogininfo();
    this.userInfo = loginInfo.data;
    this.currRoute = this.$router.currentRoute;
    this.comName = this.$router.currentRoute.name;
    this.Tabtitle = JSON.parse(this.Cookies.get("Tabtitle"));
    this.comParamMap = JSON.parse(this.Cookies.get("comParamMap"));
    var _self = this;
    _self.Tabtitle.forEach(function (item, index) {
      if (_self.$router.currentRoute.name === item.url) {
        _self.editableTabsValue = index + "";
      }
    });

  },
  mounted () {
    this.screenWidth = document.body.clientWidth;
    this.screenHeight = document.body.clientHeight;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
        this.screenHeight = document.body.clientHeight;
      })();
    };
  },
  updated () {
    var _self = this;
    _self.Tabtitle.forEach(function (item, index) {
      if (_self.$router.currentRoute.name === item.url) {
        _self.editableTabsValue = index + "";
      }
    });
    if (this.menuList.length > 0) {
      try {
        _self.menuList.forEach(function (item, index) {
          if (item instanceof Object) {
            if (_self.$router.currentRoute.name === item.url) {
              _self.defaultMenuActive = index + "";
            }
          } else if (item instanceof Array) {
            item.forEach(function (subitem, idx) {
              if (_self.$router.currentRoute.name === subitem.url) {
                _self.defaultMenuActive = idx + '';
              }
            })
          }
        })
      } catch (e) {
      }
    }
  },
  methods: {
    //获取左侧菜单栏
    getloadLeftMenu: function () {
      var _this = this;
      if (!this.Util.getUid()) {
        _this.$router.push({ path: '/' })
      }
      axios.get(this.API.permission.loadLeftMenu, {
        headers: {
          token: this.Util.getjwtToken(),
        },
        params: {
          id: this.Util.getUid()
        }
      }).then(function (res) {
        if (res.data.success) {
          console.log('res', res);
          _this.menuList = res.data.data;
          if (_this.Tabtitle.length <= 0) {
            _this.Tabtitle[0] = res.data.data[0];
          }
          if (_this.menuList.length > 0) {
            try {
              _this.menuList.forEach(function (item, index) {
                if (item instanceof Object) {
                  if (_this.$router.currentRoute.name === item.url) {
                    _this.defaultMenuActive = index + "";
                    throw new Error("break");
                  }
                } else if (item instanceof Array) {
                  item.forEach(function (subitem, idx) {
                    if (_this.$router.currentRoute.name === subitem.url) {
                      _this.defaultMenuActive = idx + '';
                      throw new Error("break");
                    }
                  })
                }
              })
            } catch (e) {
            }
          }
        } else {
          _this.menuList = [];
        }
      }).catch(function (error) {
        console.error("加载左侧菜单", error);
        _this.$router.push({ path: '/' })
      });
    },
    //左上角下拉框选择
    handleSelect: function (e) {
      var _this = this;
      //修改密码
      if (e == 1) {
        this.editUserData.username = this.userInfo.username;
        this.editUserData.email = this.userInfo.email;
        this.editUserData.remark = this.userInfo.remark;
        this.editUserDialog = true
      } else if (e == 2) {
        this.changePwdDialog = true
      } else if (e == 3) {
        this.$confirm('确定退出系统吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _this.$router.push({ path: '/' });
          var storage = window.localStorage;//清除信息
          storage.clear();
          _this.Cookies.set("Tabtitle", []);
          _this.Cookies.set("tabUrls", "");
        })
      }
    },
    //修改密码部分，发送验证码
    getphoneCode: function () {
      var _this = this;
      if (!this.Util.phoneVail(_this.changePwdData.phone)) {
        _this.$message.warning('手机号输入不合法');
        return
      }
      this.timer = setInterval(() => {
        if (this.translate > 0 && this.translate <= 60) {
          this.translate--;
          this.disabledBtn = true;
          this.count = this.translate + '秒'
        } else {
          this.translate = 60;
          this.count = '重新获取';
          this.disabledBtn = false;
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 1000)
    },
    editUserDialogConfirm: function () {
      var _this = this;
      if (!_this.editUserData.username) {
        _this.$message.warning('请输入管理员姓名');
        return;
      }
      if (!_this.Util.emailVail(_this.editUserData.email)) {
        _this.$message.warning('电子邮件输入不合法');
        return;
      }
      if (!_this.editUserData.remark) {
        _this.$message.warning('请输入备注');
        return;
      }
      _this.editUserData.id = _this.Util.getUid();
      axios({
        url: _this.API.admin.update,
        method: 'post',
        headers: {
          token: this.Util.getjwtToken(),
        },
        data: _this.editUserData
      }).then(function (e) {
        var resp = e.data;
        if (resp.success) {
          var loginInfo = _this.Util.getLogininfo();
          loginInfo.data = resp.data;
          _this.userInfo = loginInfo.data;
          _this.Util.setLogininfo(loginInfo);
          _this.editUserDialog = false;
          _this.$message(resp.msg);
        } else {
          _this.$message.warning(resp.msg);
        }
      }).catch(function (error) {
      });
    },
    //提交修改密码数据
    getChengePwd: function () {
      var _this = this;
      var submitData = JSON.parse(JSON.stringify(_this.changePwdData));
      if (this.Util.isBlank(submitData.oldPwd)) {
        _this.$message.warning('请输入旧密码');
        return
      }
      if (this.Util.isBlank(submitData.newPwd1)) {
        _this.$message.warning('请输入新密码');
        return
      }
      if (submitData.newPwd1 != submitData.newPwd2) {
        _this.$message.warning('密码输入不一致');
        return;
      }
      submitData.id = _this.Util.getUid();

      submitData.oldPwd = _this.$md5(submitData.oldPwd);
      submitData.newPwd = _this.$md5(submitData.newPwd1);
      axios({
        url: _this.API.admin.setPwd,
        method: 'get',
        headers: {
          token: _this.Util.getjwtToken(),
        },
        params: submitData
      }).then(function (e) {
        var resp = e.data;
        if (resp.success) {
          _this.changePwdDialog = false;
          _this.$message(resp.msg);
        } else {
          _this.$message.warning(resp.msg);
        }
      }).catch(function (error) {
        _this.$message.warning(error);
      });
    },

    //右侧菜单栏
    handtabchange (index, indexPath) {
      //console.log(index,indexPath);
      this.ispushTitle(index);
    },

    //将右侧菜单添加到标题
    ispushTitle: function (index) {
      var indexs = index.split('-');
      var clickItem;
      if (indexs.length <= 1) {
        clickItem = this.menuList[indexs[0]];
        this.comName = clickItem.url//切换页面
      } else {
        clickItem = this.menuList[indexs[0]].childs[indexs[1]];
        this.comName = clickItem.url//切换页面
      }
      var ispush = true;
      for (var i = 0; i < this.Tabtitle.length; i++) {
        if (this.Tabtitle[i].id === clickItem.id) {
          this.editableTabsValue = i + '';
          ispush = false;
        }
      }
      if (ispush) {
        this.Tabtitle.push({
          id: clickItem.id,
          name: clickItem.name,
          url: clickItem.url
        });
        this.tabUrls = this.tabUrls + clickItem.url;
        this.editableTabsValue = (this.Tabtitle.length - 1) + '';
        this.$router.push(clickItem.url);
        this.Cookies.set("Tabtitle", this.Tabtitle, { SameSite: "None", Secure: false });
      } else {
        this.$router.replace(clickItem.url);
      }
    },
    pullItem (name, url, params) {
      //console.log(name,url)
      /*if(this.tabUrls.indexOf(url) == -1){
          this.Tabtitle.push({
              id: url, //这里用url做id吧
              name: name,
              url: url
          });
          this.tabUrls = this.tabUrls +  url;
      }*/
      if (params) {
        this.comParamMap[url] = params;
      }
      this.comParam = this.comParamMap[url];
      this.comName = url;
      var pullispush = true;
      for (var i = 0; i < this.Tabtitle.length; i++) {
        if (this.Tabtitle[i].url == url) {
          this.editableTabsValue = i + '';
          pullispush = false;
        }
      }
      if (pullispush) {
        this.Tabtitle.push({
          id: url, //这里用url做id吧
          name: name,
          url: url
        });
        this.$router.push({ name: url, query: params });
      } else {
        this.$router.replace({ name: url, query: params });
      }
      this.Cookies.set("Tabtitle", this.Tabtitle, { SameSite: "None", Secure: false });
      this.Cookies.set("comParamMap", this.comParamMap, { SameSite: "None", Secure: false });
    },
    //点击标签切换
    clickTab (e) {
      this.comParam = this.comParamMap[e];
      this.comName = e;
      this.$router.replace({ name: e, query: this.comParam });
    },
    //删除标签
    removeTab (index) {
      var item = this.Tabtitle[index];
      if (this.Tabtitle.length > 1) {
        if ((this.Tabtitle.length - 1) == index) {
          setTimeout(() => {
            this.editableTabsValue = (index - 1) + '';
            this.comName = this.Tabtitle[index - 1].url;
            this.comParam = this.comParamMap[this.comName];
            this.$router.push({ name: this.comName, query: this.comParam });
          }, 1);
          this.Tabtitle.splice(index, 1);
        } else {
          this.Tabtitle.splice(index, 1);
        }
      } else {
        setTimeout(() => {
          this.comName = '';
        }, 1);
        this.Tabtitle.splice(index, 1);
      }
      this.tabUrls = this.tabUrls.replace(item.url, '');
      this.Cookies.set("Tabtitle", this.Tabtitle);
      this.Cookies.set("comParamMap", this.comParamMap, { SameSite: "None", Secure: false });
    },
    /**
     * 标签关闭组操作
     * @param cmd
     * @constructor
     */
    TabCloseCommand: function (cmd) {
      var idx = parseInt(this.editableTabsValue);
      var tablength = this.Tabtitle.length;
      if (cmd === "closeleft") {
        this.Tabtitle.splice(0, idx);
      }
      if (cmd === "closeright") {
        var delc = tablength - (idx + 1);
        this.Tabtitle.splice(idx + 1, delc);
      }
      if (cmd === "closeouther") {
        this.Tabtitle.splice(idx + 1, tablength - (idx + 1));
        this.Tabtitle.splice(0, this.Tabtitle.length - 1);
        this.editableTabsValue = 0 + "";
        this.comName = this.Tabtitle[0].url;
      }
      if (cmd === "closeall") {
        this.Tabtitle = [];
        this.tabUrls = "";
        this.Tabtitle[0] = this.menuList[0];
        this.editableTabsValue = 0 + "";
        this.defaultMenuActive = 0 + "";
        this.comName = this.menuList[0].url;
        this.$router.replace(this.comName);
      }
      this.Cookies.set("Tabtitle", this.Tabtitle, { SameSite: "None", Secure: false });
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/css/index.css";
@import "../assets/css/home.css";
.jdctabs {
  width: calc(100% - 40px);
  display: inline-block;
}
.jdctabs-close {
  top: -16px;
  border-bottom: 1px solid #dcdfe6;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
}
.jdctabs-close-btn {
  padding: 13px 8px;
}
</style>