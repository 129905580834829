<template>
    <div v-loading="loading"
        element-loading-text="拼命加载中">
        <div class="tilte_btn">
            <span class="lineY"></span>
            <span>
                <h2>展位一览</h2>
            </span>
            <!-- <span>
               <el-button @click="getRecruBoohEdit" type="warning" class="el_btn" size="medium">编辑</el-button>
           </span> -->
		</div>
        <div class="rmba">
            展位预定状态说明: 
            <el-button class="c1"></el-button>可预订 
            <el-button class="c2" ></el-button>已预订 
            <el-button class="c3"></el-button>暂停预定
        </div>
        <div style="padding-bottom: 20px;">
            <div class="booth-box-view" :style="{width:winWidth}">
                <div class="box02 linshi">
                    <el-popover v-for="(item,index) in BootList.slice(53,68).reverse()"
                                placement="top"
                                trigger="hover">
                        <div class="ac_dialog">
                            <div class="acd_header" v-if="item.status==1">状态：可预订</div>
                            <div class="acd_content m_top10" v-if="item.status == 1">
                                <el-button type="danger" size="mini" @click="stopYuding(item,3)">暂停预定</el-button>
                            </div>
                            <div class="acd_header" v-if="item.status==2">状态：已预订</div>
                            <div class="acd_content" v-if="item.status==2">
                                <span>{{item.entname}}</span>
                            </div>
                            <div v-if="item.status==2" class="booh_job">
                                <p v-for="(list,key) in entjobinfo" :key="key" @click="OpenViewjob(list)">
                                    <span>{{list.jobname}}</span>
                                    <span style="color: red;" v-if="list.minSalary==-1&&list.maxSalary==-1">面议</span>
                                    <span style="color: red;" v-else-if="list.minSalary==-1">{{list.maxSalary}}元以下</span>
                                    <span style="color: red;" v-else-if="list.maxSalary==-1">{{list.minSalary}}元以上</span>
                                    <span style="color: red;" v-else>{{list.minSalary}}-{{list.maxSalary}}元</span>
                                </p>
                                <el-button type="info" size="mini" class="btn" @click="goEntdetail(item)">查看详情</el-button>
                            </div>
                            <div class="acd_header" v-if="item.status==3">状态：暂停预定</div>
                            <div class="acd_content m_top10" v-if="item.status == 3">
                                <el-button type="danger" size="mini" @click="stopYuding(item,1)">开放预定</el-button>
                            </div>
                        </div>
                        <li class="li"
                            slot="reference"
                            @mouseenter="getEntInfo(item.id)"
                            :class="item.status==1?'ok':item.status==2?'good':'off'"
                            :style="index===0?'border-left: 1px solid #333':''"><span>临</span>{{(index + 1)}}</li>
                    </el-popover>
                </div>

                <!-- 左上 -->
                <div class="box01">
                    <el-popover
                        v-for="(item,index) in BootList.slice(46,52).reverse()"
                        placement="top"
                        trigger="hover">
                        <div class="ac_dialog">
                            <div class="acd_header" v-if="item.status==1">状态：可预订</div>
                            <div class="acd_content m_top10" v-if="item.status == 1">
                                <el-button type="danger" size="mini" @click="stopYuding(item,3)">暂停预定</el-button>
                            </div>
                            <div class="acd_header" v-if="item.status==2">状态：已预订</div>
                            <div class="acd_content m_top10" v-if="item.status==2">
                                <span>{{item.entname}}</span>
                            </div>
                            <div v-if="item.status==2" class="booh_job">
                                <p v-for="(list,key) in entjobinfo" :key="key" @click="OpenViewjob(list)">
                                    <span>{{list.jobname}}</span>
                                    <span style="color: red;" v-if="list.minSalary==-1&&list.maxSalary==-1">面议</span>
                                    <span style="color: red;" v-else-if="list.minSalary==-1">{{list.maxSalary}}元以下</span>    
                                    <span style="color: red;" v-else-if="list.maxSalary==-1">{{list.minSalary}}元以上</span>
                                    <span style="color: red;" v-else>{{list.minSalary}}-{{list.maxSalary}}元</span>
                                </p>
                                <el-button type="info" size="mini" class="btn" @click="goEntdetail(item)">查看详情</el-button>
                            </div>
                            <div class="acd_header" v-if="item.status==3">状态：暂停预定</div>
                            <div class="acd_content m_top10" v-if="item.status == 3">
                                <el-button type="danger" size="mini" @click="stopYuding(item,1)">开放预定</el-button>     
                            </div>
                        </div>
                        <li  class="li"
                        slot="reference"
                        @mouseenter="getEntInfo(item.id)"
                        :class="item.status==1?'ok':item.status==2?'good':'off'"
                        :style="index==0?'border-left: 1px solid #333':''">{{item.bno}}</li>
                    </el-popover>
                </div>
                <!-- 中上 -->
                <div class="box02">
                    <el-popover v-for="(item,index) in BootList.slice(34,46).reverse()"
                    placement="top"
                    trigger="hover">
                        <div class="ac_dialog">
                            <div class="acd_header" v-if="item.status==1">状态：可预订</div>
                            <div class="acd_content m_top10" v-if="item.status == 1">
                                <el-button type="danger" size="mini" @click="stopYuding(item,3)">暂停预定</el-button>
                            </div>
                            <div class="acd_header" v-if="item.status==2">状态：已预订</div>
                            <div class="acd_content booh_job" v-if="item.status==2">
                                <span>{{item.entname}}</span>
                            </div>
                            <div v-if="item.status==2" class="booh_job">
                                <p v-for="(list,key) in entjobinfo" :key="key" @click="OpenViewjob(list)">
                                    <span>{{list.jobname}}</span>
                                    <span style="color: red;" v-if="list.minSalary==-1&&list.maxSalary==-1">面议</span>
                                    <span style="color: red;" v-else-if="list.minSalary==-1">{{list.maxSalary}}元以下</span>    
                                    <span style="color: red;" v-else-if="list.maxSalary==-1">{{list.minSalary}}元以上</span>
                                    <span style="color: red;" v-else>{{list.minSalary}}-{{list.maxSalary}}元</span>
                                </p>
                                <el-button type="info" size="mini" class="btn" @click="goEntdetail(item)">查看详情</el-button>
                            </div>
                            <div class="acd_header" v-if="item.status==3">状态：暂停预定</div>
                            <div class="acd_content m_top10" v-if="item.status == 3">
                                <el-button type="danger" size="mini" @click="stopYuding(item,1)">开放预定</el-button>     
                            </div>
                        </div>
                        <li  class="li"
                        slot="reference"
                        @mouseenter="getEntInfo(item.id)"
                        :class="item.status==1?'ok':item.status==2?'good':'off'"
                        :style="index===0?'border-left: 1px solid #333':''">{{item.bno}}</li>
                    </el-popover>
                    <el-popover v-for="(item,index) in BootList.slice(22,34)"
                    placement="top"
                    trigger="hover">
                        <div class="ac_dialog">
                            <div class="acd_header" v-if="item.status==1">状态：可预订</div>
                            <div class="acd_content m_top10" v-if="item.status == 1">
                                <el-button type="danger" size="mini" @click="stopYuding(item,3)">暂停预定</el-button>
                            </div>
                            <div class="acd_header" v-if="item.status==2">状态：已预订</div>
                            <div class="acd_content booh_job" v-if="item.status==2">
                                <span>{{item.entname}}</span>
                            </div>
                            <div v-if="item.status==2" class="booh_job">
                                <p v-for="(list,key) in entjobinfo" :key="key" @click="OpenViewjob(list)">
                                    <span>{{list.jobname}}</span>
                                    <span style="color: red;" v-if="list.minSalary==-1&&list.maxSalary==-1">面议</span>
                                    <span style="color: red;" v-else-if="list.minSalary==-1">{{list.maxSalary}}元以下</span>    
                                    <span style="color: red;" v-else-if="list.maxSalary==-1">{{list.minSalary}}元以上</span>
                                    <span style="color: red;" v-else>{{list.minSalary}}-{{list.maxSalary}}元</span>
                                </p>
                                <el-button type="info" size="mini" class="btn" @click="goEntdetail(item)">查看详情</el-button>
                            </div>
                            <div class="acd_header" v-if="item.status==3">状态：暂停预定</div>
                            <div class="acd_content m_top10" v-if="item.status == 3">
                                <el-button type="danger" size="mini" @click="stopYuding(item,1)">开放预定</el-button>     
                            </div>
                        </div>
                        <li  class="li m_top"
                            slot="reference"
                            @mouseenter="getEntInfo(item.id)"
                            :class="item.status==1?'ok':item.status==2?'good':'off'"
                            :style="index===0?'border-left: 1px solid #333':''">{{item.bno}}</li>
                    </el-popover>
                </div>
                <!-- 中下 -->
                <div class="box03">
                    <el-popover v-for="(item,index) in BootList.slice(11,22).reverse()"
                    placement="top"
                    trigger="hover">
                        <div class="ac_dialog">
                            <div class="acd_header" v-if="item.status==1">状态：可预订</div>
                            <div class="acd_content m_top10" v-if="item.status == 1">
                                <el-button type="danger" size="mini" @click="stopYuding(item,3)">暂停预定</el-button>
                            </div>
                            <div class="acd_header" v-if="item.status==2">状态：已预订</div>
                            <div class="acd_content" v-if="item.status==2">
                                <span>{{item.entname}}</span>
                            </div>
                            <div v-if="item.status==2" class="booh_job">
                                <p v-for="(list,key) in entjobinfo" :key="key" @click="OpenViewjob(list)">
                                    <span>{{list.jobname}}</span>
                                    <span style="color: red;" v-if="list.minSalary==-1&&list.maxSalary==-1">面议</span>
                                    <span style="color: red;" v-else-if="list.minSalary==-1">{{list.maxSalary}}元以下</span>    
                                    <span style="color: red;" v-else-if="list.maxSalary==-1">{{list.minSalary}}元以上</span>
                                    <span style="color: red;" v-else>{{list.minSalary}}-{{list.maxSalary}}元</span>
                                </p>
                                <el-button type="info" size="mini" class="btn" @click="goEntdetail(item)">查看详情</el-button>
                            </div>
                            <div class="acd_header" v-if="item.status==3">状态：暂停预定</div>
                            <div class="acd_content m_top10" v-if="item.status == 3">
                                <el-button type="danger" size="mini" @click="stopYuding(item,1)">开放预定</el-button>     
                            </div>
                        </div>
                        <li class="li"
                        slot="reference"
                        @mouseenter="getEntInfo(item.id)"
                        :class="item.status==1?'ok':item.status==2?'good':'off'"
                        :style="index===0?'border-left: 1px solid #333':''">{{item.bno}}</li>
                    </el-popover>
                    <el-popover v-for="(item,index) in BootList.slice(0,11)"
                    placement="top"
                    trigger="hover">
                        <div class="ac_dialog">
                            <div class="acd_header" v-if="item.status==1">状态：可预订</div>
                            <div class="acd_content m_top10" v-if="item.status == 1">
                                <el-button type="danger" size="mini" @click="stopYuding(item,3)">暂停预定</el-button>
                            </div>
                            <div class="acd_header" v-if="item.status==2">状态：已预订</div>
                            <div class="acd_content" v-if="item.status==2">
                                <span>{{item.entname}}</span>
                            </div>
                            <div v-if="item.status==2" class="booh_job">
                                <p v-for="(list,key) in entjobinfo" :key="key" @click="OpenViewjob(list)">
                                    <span>{{list.jobname}}</span>
                                    <span style="color: red;" v-if="list.minSalary==-1&&list.maxSalary==-1">面议</span>
                                    <span style="color: red;" v-else-if="list.minSalary==-1">{{list.maxSalary}}元以下</span>    
                                    <span style="color: red;" v-else-if="list.maxSalary==-1">{{list.minSalary}}元以上</span>
                                    <span style="color: red;" v-else>{{list.minSalary}}-{{list.maxSalary}}元</span>
                                </p>
                                <el-button type="info" size="mini" class="btn" @click="goEntdetail(item)">查看详情</el-button>
                            </div>
                            <div class="acd_header" v-if="item.status==3">状态：暂停预定</div>
                            <div class="acd_content m_top10" v-if="item.status == 3">
                                <el-button type="danger" size="mini" @click="stopYuding(item,1)">开放预定</el-button>     
                            </div>
                        </div>
                        <li class="li m_top"
                        slot="reference"
                        @mouseenter="getEntInfo(item.id)"
                        :class="item.status==1?'ok':item.status==2?'good':'off'"
                        :style="index===0?'border-left: 1px solid #333':''">{{item.bno}}</li>
                    </el-popover>
                </div>
                <!-- 右 -->
                <div class="box04 u-hide">
                    <el-popover v-for="(item,index) in BootList.slice(52,54)" 
                    placement="top"
                    trigger="hover">
                        <div class="ac_dialog">
                            <div class="acd_header" v-if="item.status==1">状态：可预订</div>
                            <div class="acd_content m_top10" v-if="item.status == 1">
                                <el-button type="danger" size="mini" @click="stopYuding(item,3)">暂停预定</el-button>
                            </div>
                            <div class="acd_header" v-if="item.status==2">状态：已预订</div>
                            <div class="acd_content" v-if="item.status==2">
                                <span>{{item.entname}}</span>
                            </div>
                            <div v-if="item.status==2" class="booh_job">
                                <p v-for="(list,key) in entjobinfo" :key="key" @click="OpenViewjob(list)">
                                    <span>{{list.jobname}}</span>
                                    <span style="color: red;" v-if="list.minSalary==-1&&list.maxSalary==-1">面议</span>
                                    <span style="color: red;" v-else-if="list.minSalary==-1">{{list.maxSalary}}元以下</span>    
                                    <span style="color: red;" v-else-if="list.maxSalary==-1">{{list.minSalary}}元以上</span>
                                    <span style="color: red;" v-else>{{list.minSalary}}-{{list.maxSalary}}元</span>
                                </p>
                                <el-button type="info" size="mini" class="btn" @click="goEntdetail(item)">查看详情</el-button>
                            </div>
                            <div class="acd_header" v-if="item.status==3">状态：暂停预定</div>
                            <div class="acd_content m_top10" v-if="item.status == 3">
                                <el-button type="danger" size="mini" @click="stopYuding(item,1)">开放预定</el-button>     
                            </div>
                        </div>
                        <li class="li"
                        slot="reference"
                        @mouseenter="getEntInfo(item.id)"
                        :class="item.status==1?'ok':item.status==2?'good':'off'">
                        {{item.bno}}</li>
                    </el-popover>
                </div>
                <!-- 底部 -->
                <div class="box05 u-hide">
                    <el-popover v-for="(item,index) in BootList.slice(54,75).reverse()"
                    placement="top"
                    trigger="hover">
                        <div class="ac_dialog">
                            <div class="acd_header" v-if="item.status==1">状态：可预订</div>
                            <div class="acd_content m_top10" v-if="item.status == 1">
                                <el-button type="danger" size="mini" @click="stopYuding(item,3)">暂停预定</el-button>
                            </div>
                            <div class="acd_header" v-if="item.status==2">状态：已预订</div>
                            <div class="acd_content" v-if="item.status==2">
                                <span>{{item.entname}}</span>
                            </div>
                            <div v-if="item.status==2" class="booh_job">
                                <p v-for="(list,key) in entjobinfo" :key="key" @click="OpenViewjob(list)">
                                    <span>{{list.jobname}}</span>
                                    <span style="color: red;" v-if="list.minSalary==-1&&list.maxSalary==-1">面议</span>
                                    <span style="color: red;" v-else-if="list.minSalary==-1">{{list.maxSalary}}元以下</span>    
                                    <span style="color: red;" v-else-if="list.maxSalary==-1">{{list.minSalary}}元以上</span>
                                    <span style="color: red;" v-else>{{list.minSalary}}-{{list.maxSalary}}元</span>
                                </p>
                                <el-button type="info" size="mini" class="btn" @click="goEntdetail(item)">查看详情</el-button>
                            </div>
                            <div class="acd_header" v-if="item.status==3">状态：暂停预定</div>
                            <div class="acd_content m_top10" v-if="item.status == 3">
                                <el-button type="danger" size="mini" @click="stopYuding(item,1)">开放预定</el-button>     
                            </div>
                        </div>
                        <li class="li"
                        slot="reference"
                        @mouseenter="getEntInfo(item.id)"
                        :class="item.status==1?'ok':item.status==2?'good':'off'"
                        :style="index===0?'border-left: 1px solid #333':''">{{item.bno}}</li>
                    </el-popover>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import qs from 'qs';
export default {
    name:'Recruit_Booth',
    data(){
        return{
           selectRmareaIndex: 0,
           winWidth:"1440px",
           tabRmlistArry:[],
           rmData: {}, //招聘会信息
           BootList:[], //展区的展位

           entjobinfo:[],
           loading: true
        }
    },
    created(){
        var _self=this;
        this.comRecruit = this.$router.currentRoute.query;
        this.rmData=this.comRecruit;
        this.findRmba();
    },
    methods:{
        goEntdetail: function(item){
            this.$parent.$parent.pullItem("企业详情","EnterpriseDetail",{
                id: item.entid
            })
        },
        OpenViewjob: function(row) {
            this.$parent.$parent.pullItem("职位信息", "JobDetail", {id: row.id});
        },
        getRecruBoohEdit:function(){
           this.$parent.$parent.pullItem("招聘会展位设置","Recruit_BoothEdit", this.comRecruit);
        },
        selectRmlist: function(index){
            this.selectRmareaIndex = index;
            this.findRmbootch(this.tabRmlistArry[index].rmArea);
        },
        //获取以约定的职位
        getEntInfo(id){
            var _this = this;
            axios({
                url: _this.API.job_fairs.rm_bootch_Detail,
                method: 'get',
                headers:{
                    token: _this.Util.getjwtToken(),
                },
                params:{
                    id: id
                }
            }).then(function(e){
                var resp = e.data;
                console.log('职位', resp)
                if(resp && resp.success){
                    if(resp.data.jobinfo && resp.data.jobinfo.length>0){
                        _this.entjobinfo = resp.data.jobinfo
                    }
                }
            })
        },
        /**
         * 获取展区分布
         */
        findRmba: function(){
            var _this = this;
            axios({
                url: this.API.job_fairs.rmba_list,
                method: 'get',
                headers:{
                    token:this.Util.getjwtToken(),
                },
                params:{
                    rmid: this.rmData.id
                }
            }).then(function(e){
                var resp = e.data;
                if(resp && resp.success){
                    _this.tabRmlistArry = resp.data;
                    if(_this.tabRmlistArry && _this.tabRmlistArry.length > 0){
                        _this.findRmbootch(_this.tabRmlistArry[0].rmArea);
                    }
                }
            })
        },
        /**
         * 获取招聘会展区的展位
         */
        findRmbootch: function(barea){
            var _this = this;
            axios({
                url: this.API.job_fairs.rm_bootch_list,
                method: 'get',
                headers:{
                    token:this.Util.getjwtToken(),
                },
                params:{
                    rmid: this.rmData.id,
                    barea: barea
                }
            }).then(function(e){
                var resp = e.data;
                console.log('展位',resp)
                if(resp && resp.success){
                    _this.loading = false;
                    _this.BootList = resp.data;
                } else{
                    _this.$message.error('加载失败');
                }
            })
        },
        /**暂停预定 */
        stopYuding: function(row, status){
            // if(index < 0 || index >= this.tabRmBootchlistArry.length){
            //     return;
            // }
            var _this = this;
            axios({
                url: _this.API.job_fairs.rm_bootch_status,
                method: 'post',
                data: qs.stringify({
                    id: row.id,
                    status: status
                }),
                headers: {
                    token:_this.Util.getjwtToken(),
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(function(e){
                var resp = e.data;
                if(resp && resp.success){
                    _this.$message(resp.msg);
                    _this.findRmbootch(_this.tabRmlistArry[0].rmArea);
                }else{
                    _this.$message.error(resp.msg);
                }
            })
        }, 
        /**删除预定 */
        deleteBootch: function(index){
            if(index < 0 || index >= this.tabRmBootchlistArry.length){
                return;
            }
            var _this = this;
            axios({
                url: _this.API.job_fairs.rm_bootch_delete,
                method: 'delete',
                params: {
                    id:  _this.tabRmBootchlistArry[index].id
                },
                headers: {
                    token:_this.Util.getjwtToken(),
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(function(e){
                var resp = e.data;
                if(resp && resp.success){
                    _this.$message(resp.msg);
                    _this.tabRmBootchlistArry.splice(index, 1);
                }else{
                    _this.$message.error(resp.msg);
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.u-hide{
    display: none !important;
}
.rmba {
    display: flex;
    padding: 10px;
    align-items: center;
}
.rmba .c1{
    margin: 0 5px;
    border: 0;
    background: #9addf7 !important;
}
.rmba .c2{
    margin: 0 5px;
    border: 0;
    background: #FF9C20 !important;
}
.rmba .c3{
    margin: 0 5px;
    border: 0;
    background: #DEDFE2 !important;
}
.tilte_btn{
    background-color: #ffffff;
}
.li{
    width: 55px;
    font-size: 15px;
    text-align: center;
    line-height:72px;
    height: 72px;
    cursor: pointer;
    border-right: 1px solid #333;
    border-bottom:1px solid #333;
    &:hover{
        color: #fff;
        background:blue;
    }
}
.booth-box-view{
    width: 100%;
    margin:20px auto;
    min-height: 750px;
    position: relative;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 48px 17px;
    overflow: hidden;
    background-image: url("../../assets/img/booth1.jpg");
    .box01{
        width: 336px;
        height: 72px;
        top:188px;
        left:129px;
        display:flex;
        position: absolute;
    }
    .box02{
        width: 673px;
        height: 148px;
        display: flex;
        flex-wrap: wrap;
        top:188px;
        right:130px;
        position: absolute;
        .m_top{
            margin-top:4px;
            border-bottom:none;
            border-top:1px solid #333;
        }
    }
    .box03{
        width: 620px;
        height: 146px;
        bottom: 188px;
        right:184px;
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        .m_top{
            margin-top:4px;
            border-top:1px solid #333;
            border-bottom:none;
        }
    }
    .box04{
        width: 56px;
        height: 146px;
        bottom: 188px;
        right: 15px;
        position: absolute;
        .m_top{
            margin-top:4px;
            border-top:1px solid #333;
        }
    }
    .box05{
        width: 1182px;
        height: 72px;
        bottom: 48px;
        left:129px;
        display: flex;
        position: absolute;
    }
}
.ac_dialog .acd_header{
    background: #fc4c4e;
    padding: 0 5px;
    height: 38px;
    display: flex;
    align-items: center;
    color: #fff;
}
.ok{
    background: #9addf7 ; 
}
.good{
    background: #FF9C20;
}
.off{
    background:#DEDFE2
}
.booh_job{
    text-align: center;
    margin-top: 8px;
    cursor: pointer;
    p:hover{color:blue}
    .btn{
        margin-top: 10px;
    }
}

.booth-box-view .box02.linshi{
    top: 118px;
    width: 825px;
    .li{
        height: 55px;
        line-height: 55px;
        box-sizing: border-box;
        border-top: 1px solid #555;
        border-bottom: 0;
    }
}
</style>