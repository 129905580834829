<template>
    <div>
        <div class="web_navSort">
                <span :class="navIndex==index?'IsTab':''" 
                   @click="WebNavtab(index)" v-for="(list,index) in navIndexarry" :key="'list'+index">
                   {{list.title}}
                </span>
        </div>
        <el-card>
            <el-radio v-model="radio" color:="#4184fd" label="1" style="font-size:14px">使用系统自动排序</el-radio>
                <draggable class="home_card" v-model="sortInfo" @update="datadragEnd" :options = "{animation:500}">
                    <transition-group>
                        <el-col :span="6" v-for="(item, index) in sortInfo" :key="index">
                            <el-card>
                                <el-row class="home_card_item">
                                        <el-col :span="24">
                                            <div class="home_card_item1">{{item.jobname}}</div>
                                            <div class="home_card_item2">{{item.entname}}</div>
                                        </el-col>
                                </el-row>
                                <div class="home_card_item3">
                                    <div class="home_card_item4">
                                        <i class="el-icon-star-on"></i>
                                        职位招聘人数
                                        <span v-if="item.recruitnummin==0&&item.recruitnummax==0">
                                            0
                                        </span>
                                        <span v-else-if="item.recruitnummin!=-1&&item.recruitnummax==-1">
                                        {{item.recruitnummin}}人以上
                                        </span>
                                        <span v-else>
                                        {{item.recruitnummin}}-{{item.recruitnummax}}人
                                        </span>
                                    </div>
                                    <el-button type="danger" size="mini" @click="deitjobinfo(item)">删除</el-button>
                                </div>
                            </el-card>
                        </el-col>
                     </transition-group> 
            </draggable>
            <div class="home_card">
                <el-col :span="6">
                     <el-card class="card_button">
                        <span @click="addJobSort()">新增职位</span>
                    </el-card>
                </el-col>  
            </div>
        </el-card>
        <!-- dialog 对话框 -->
        <el-dialog :visible.sync="dialogVisible" :show-close="false">
               <div>
                   <div class="dialogstyle_top">请选择职位</div>
                   <div class="m_top15">
                        <el-input  v-model="addjob.jobname" placeholder="职位名称" size="medium" style="width:25%"></el-input>
                        <el-input  v-model="addjob.entname" placeholder="公司名称" style="width:25%" size="medium" class="ent_delFr"></el-input>
                        <el-date-picker class="ent_delFr"
                            v-model="addjob.publishTime"
                            @change="changepublishTime"
                            type="date"
                            size="medium"
                            clearable
                            value-format="yyyy-MM-dd"
                            placeholder="发布时间">
                        </el-date-picker>
                        <el-button @click="seachAddJoblist" type="danger" size="medium" style="margin-left:15px">筛选</el-button>
                   </div>
                    <el-table :data="addjobInfo" border ref="addTable"
                    style="width: 100%"
                    class="m_top15" @selection-change="addTableChange">
                       <el-table-column type="selection" align="center">
                       </el-table-column>
                        <el-table-column
                            prop="jobname"
                            label="职位名称"
                            width="280"
                            align="center">
                        </el-table-column>
                        <el-table-column
                             prop="entname"  
                             label="公司名称" 
                             width="280" 
                             align="center">
                        </el-table-column>
                        <el-table-column
                        prop="publishtime"
                            label="发布时间"
                            align="center">
                        </el-table-column>
                    </el-table> 
                    <div  style="margin-top: 10px;text-align: center;">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :page-sizes="[10,20, 30, 40, 50]"
                            :page-size="10"
                            :current-page="addjob.pageIndex"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="currenttotal">
                        </el-pagination>
                     </div>
                     <el-button @click="getAddjobInfo" type="primary">提交</el-button>
               </div>
       </el-dialog>
    </div>
</template>
<script>
import  Qs from 'qs';
import draggable from 'vuedraggable';
export default {
    name:'Ent_sort',
    components:{
        draggable
    },
    data(){
        return{
            navIndex:0,
            radio:'',
            sortInfo:[],
            navIndexarry:[{
                id:3,
                title:'热门职位排序'
            },{
                id:2,
                title:'高端职位排序' 
            },{
                id:1,
                title:'急聘职位排序'
            }],
            Type:3,
            //新增岗位
            addjobInfo:[],
            addjob:{
                jobname:'',//职位
                entname:'',//企业
                publishTime:'',
                queryType:1,
                pageIndex:1,
                pageSize:10
            },
            currenttotal:null,
            dialogVisible:false,
            
        }
    },
    created(){
        this.jobSortinfo(this.Type)
    },
    mounted(){
        //为了防止火狐浏览器拖拽的时候以新标签打开，此代码真实有效
        document.body.ondrop = function (event) {
            event.preventDefault();
            event.stopPropagation();
        } 
    },
    methods:{
        datadragEnd (evt) {
            var _self = this;
            var temp=[];
            for(var i=0;i< _self.sortInfo.length;i++){
                temp.push({
                    jobid:_self.sortInfo[i].id,
                    sortType:_self.Type,
                    sortNo: i
                })
            }
            axios({
                    url: _self.API.jobinfo.updJobinfoSort,
                    method: 'post',
                    headers: {
                        token: _self.Util.getjwtToken()
                    },
                    data:Qs.stringify({
                        jSortparamVos:JSON.stringify(temp)
                    }) 
                }).then(function(e) {
                    var resp = e.data;
                    if (resp.success) {
                        _self.$message.success(resp.msg);    
                    }else{
                       
                    }
                })
         },
        WebNavtab:function(index){
           this.navIndex=index;
           this.Type=this.navIndexarry[index].id;
           this.addjob.queryType=this.navIndexarry[index].id;
           this.jobSortinfo(this.Type);
        },
        jobSortinfo:function(Type){
            var _this=this;
           axios({
                url: _this.API.jobinfo.jobinfoSort,
                method: 'get',
                headers: {
                    token: _this.Util.getjwtToken()
                },  
                params:{
                    queryType:Type
                }
            }).then(function(res) {
                var temp=res.data;
                if(temp.success){
                    _this.sortInfo=temp.data
                }else{
                   _this.sortInfo=[] 
                }
                
            })
        },
        //新增岗位
        addJobSort:function(){
            this.dialogVisible=true;
            this.getAddjoblist();
        },
        changepublishTime:function(e){
            if(e){
               this.addjob.publishTime=e+' 00:00:00';
            }else{
               this.addjob.publishTime='';
            }
        },
        //筛选
        seachAddJoblist:function(){
           this.addjob.pageIndex=1;
           this.getAddjoblist()
        },
        getAddjoblist:function(){
            var _self=this;
           axios({
                url: _self.API.jobinfo.queryJobinfo,
                method: 'POST',
                headers: {
                    token: _self.Util.getjwtToken()
                },
                data:this.addjob
            }).then(function(e) {
                var resp = e.data;
                if (resp.success) {
                   _self.addjobInfo=resp.data.list;
                   _self.currenttotal=resp.data.pager.recordCount;
                }else{
                    _self.addjobInfo = [];
                    _self.currenttotal = 0;
                }
            })
        },
        //编辑
        deitjobinfo:function(e){
            var _self = this;
            var dd = {
                "jobid": e.id,
                "sortNo": 0,
                "sortType": _self.Type
            }
            axios({
                url: _self.API.jobinfo.delJobinfoSort,
                method: 'POST',
                headers: {
                    token: _self.Util.getjwtToken()
                },
                data:dd
            }).then(function(e) {
                var resp = e.data;
                if (resp.success) {
                    _self.$message.success('删除成功！');
                    _self.jobSortinfo(_self.Type);
                }else{
                    _self.$message.error('删除失败！');
                }
            })
        },
        //选择提交
        addTableChange:function(val){
           this.jsonData=val;
        },
        //提交
        getAddjobInfo:function(){
            var _self=this;
            if(_self.jsonData&&_self.jsonData.length>0){
               var temp=[];
                for(var i=0;i<_self.jsonData.length;i++){
                    temp.push({
                        jobid:_self.jsonData[i].id,
                        sortType:_self.Type,
                        sortNo:0
                    })
                }
                axios({
                        url: _self.API.jobinfo.updJobinfoSort,
                        method: 'post',
                        headers: {
                            token: _self.Util.getjwtToken()
                        },
                        data:Qs.stringify({
                            jSortparamVos:JSON.stringify(temp)
                        }) 
                    }).then(function(e) {
                        var resp = e.data;
                        if (resp.success) {
                             _self.dialogVisible=false;
                             _self.jobSortinfo(_self.Type);
                        }else{
                             _self.$message.error('新增失败');
                        }
                    })
            }else{
                _self.$message.warning('请勾选要新增的职位！');
            }
        },
        handleSizeChange:function(e){
            this.addjob.pageSize=e;
            this.getAddjoblist();
        },
        handleCurrentChange:function(e){
           this.addjob.pageIndex=e;
           this.getAddjoblist();
        }
    }
}
</script>
<style scoped lang="scss">
.IsTab{
    color: #000000;
}
.home_card_item{
    height: 80px ;
}
.home_card_item1,.home_card_item2{
    text-align: left;
    margin-left: 0;;
}
.card_button{
     /* height: 160px; */
    line-height: 118px;
}
.dialogstyle_top {
    margin-top: -20px;
    font-family: '微软雅黑';
    font-style: normal;
    font-size: 15px;
    color: #000000;
}
</style>