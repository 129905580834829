import { render, staticRenderFns } from "./Recruit_info.vue?vue&type=template&id=b0eb9dfa&scoped=true&"
import script from "./Recruit_info.vue?vue&type=script&lang=js&"
export * from "./Recruit_info.vue?vue&type=script&lang=js&"
import style0 from "./Recruit_info.vue?vue&type=style&index=0&id=b0eb9dfa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0eb9dfa",
  null
  
)

export default component.exports