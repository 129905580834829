<template>
    <div class="home">
        <div class="tilte_btn">
            <span class="lineY"></span>
            <span><h1>企业投递简历情况统计</h1></span>
        </div>
        <nav class="nav_sech">
            <main>
                <div style="padding:0 15px">
                    <el-input
                        class="searchInput"
                        placeholder="请输入姓名 / 职位关键字"
                        v-model="queryform.keywords">
                    </el-input>
                </div>
                <el-button class="searchBtn" @click="searchName">搜索</el-button>
                <el-button type="warning" @click="werResume = true">筛选字段</el-button>
                <el-button type="warning" @click="outData">导出</el-button>
            </main>
        </nav>
        <main class="people_main">
            <el-table
                style="width: 100%; margin-bottom: 20px"
                :data="listData"
                border>
                    <el-table-column 
                        :key="index"
                        v-for="(item,index) in showFileld"
                        :width="item.width"
                        :label="item.label" 
                        align="center">
                        <template slot-scope="scope">
                            <div v-if="(item.type == 'text' || item.type == 'text_mu') ">
                                {{scope.row.member[item.name]}}
                            </div>
                            <div v-if="item.type == 'select'">
                                <div v-for="v in item.values" :key="v.label">
                                    <span v-if="scope.row.member[item.name] == v.value">{{v.label}}</span>
                                </div>
                            </div>
                            <div v-if="item.type == 'mufield'">
                                <span v-if="item.showType=='adds'">
                                    {{scope.row.member[item.name.split('-')[0]]}}-{{scope.row.member[item.name.split('-')[1]]}}
                                </span>
                                <span v-else>
                                    {{scope.row[item.name.split('-')[0]]}}-{{scope.row[item.name.split('-')[1]]}}元
                                </span>
                            </div>
                        </template>
                    </el-table-column>
            </el-table>
            <!-- <div class="bom_connt m_top10">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10,20, 30, 40, 50]"
                    :page-size="10"
                    :current-page="queryform.page"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="connt">
                </el-pagination>
            </div> -->
        </main>
        <el-drawer
            :with-header="false"
            :visible.sync="werResume"
            :direction="direction">
            <main class="finds">
                <h2>导出字段自定义筛选</h2>
                <el-checkbox-group v-model="checkList">
                    <el-checkbox :label="item.label" style="margin-top: 15px;"
                    v-for="(item,index) in fields" :key="index">
                    </el-checkbox>
                </el-checkbox-group>
            </main>
        </el-drawer>
    </div>
</template>
<script>
export default {
    name:'EnterpResume',
    data() {
        return {
            listData:[],
            fields:[],
            checkList: [],
            showFileld:[],

            werResume: false,
            direction: 'rtl',

            queryform:{
                page:1,
                limit:10,
                type:2,//1按照职位统计，2按照人员统计
                entid:'',
                keywords:''
            },
            connt:0
        }
    },
    watch: {
        checkList:{
            handler:function() {
                this.setShowFiled()
            },
            deep:true
        }
    },
    created() {
        
        this.fields = JSON.parse(JSON.stringify(this.Util.fields));
        for(var i=0;i<this.fields.length;i++){
            this.checkList.push(this.fields[i].label)
        }
        var experWorkAskArry=JSON.parse(JSON.stringify(this.Util.experWorkAsk));
        var recordAskArry=JSON.parse(JSON.stringify(this.Util.recordAsk));
        experWorkAskArry.splice(0,1);
        recordAskArry.splice(0,1);
        this.experWorkAskArry=experWorkAskArry;
        this.recordAskArry=recordAskArry;

        this.comParam = this.$router.currentRoute.query;
        this.queryform.entid = this.comParam.entid;
        this.getResumeData();
        this.setShowFiled();
    },
    methods: {
        setShowFiled() {
            let showFileld = [];
            
            for(let i =0 ;i<this.fields.length;i++){
                let item = this.fields[i];
                if(this.arrHave(this.checkList,item.label)){
                    showFileld.push(item);
                }
            }
            this.showFileld = showFileld;
        },
        //导出
        outData() {
            var outData = "";
            for(var i =0;i<this.fields.length;i++){
                if(this.arrHave(this.checkList, this.fields[i].label)){
                    if(this.fields[i].alias){
                        outData+=(outData?',':'')+this.fields[i].alias
                    }else {
                        outData+=(outData?',':'')+this.fields[i].name
                    }
                }
            }
            const loading = this.$loading({
                lock: true,
                text: '正在导出中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            axios({
                url: this.API.file.export,
                method: 'get',
                headers:{
                    token:this.Util.getjwtToken(),
                },
                params: {
                    entid: this.comParam.entid,
                    allowField: outData
                }
            }).then((e) => {
                var resp = e.data;
                loading.close();
                if(resp.success){
                    this.$message.success('导出成功')
                    window.open(resp.data); 
                }else{
                    this.$message.error('导出失败')
                }
            }).catch((err)=>{
                loading.close();
                this.$message.error('导出失败')
            })
        },
        arrHave: function(arr, v){
            return arr.indexOf(v) > -1;
        },
        getResumeData(){
            var _this = this;
            axios({
                url: this.API.statistics.jobStatis,
                method: 'get',
                headers:{
                    token:_this.Util.getjwtToken(),
                },
                params: _this.queryform
            }).then((e) => {
                var resp = e.data;
                console.log("简历情况", resp);
                if(resp.success){
                    var temp =_this.Util.recordAsk; 
                    for(var i=0;i<resp.data.list.length;i++){
                        for(var j=0;j<temp.length;j++){
                            if(resp.data.list[i].education == temp[j].value){
                                resp.data.list[i].Showeducation=temp[j].label
                            }  
                        }
                    }
                    _this.listData=resp.data.list;
                    _this.connt=resp.data.pager.recordCount;
                }else{
                    _this.listData=[];
                    _this.connt=0
                }
            })
        },
        searchName(){
            this.queryform.page = 1;
            this.getResumeData(); 
        },
        handleSizeChange(e){
            this.queryform.page = e;
            this.getResumeData();
        },
        handleCurrentChange(e){
            this.queryform.limit = e;
            this.getResumeData();
        }
    }
}
</script>
<style lang="scss">
.searchInput{
    width: 200px;
}
.people_main{
   height:100%;
   padding:0 15px 15px;
   background:#fff;
}
.finds{
    padding: 15px;
}
</style>