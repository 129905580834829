<template>
    <div class="home">
        <nav class="navTab">
            <div v-for="(listBox,index) in zxTitleNav" :key="index"  :class="tabIndex==index?'isDivcom':''" @click="tabNavtilte(index)">
                <h2>{{listBox.name}}</h2>
            </div>
        </nav>
        <div class="tab_Res">
            <component :is="comName"></component>
        </div>
    </div>
</template>

<script>
import Ent_sort from "./recom/Ent_sort";
import Job_sort from "./recom/Job_sort";
import People_sort from "./recom/People_sort";
    export default {
        name: "Web_recom",
        components:{
            Ent_sort,Job_sort,People_sort
        },
        data(){
            return{
               tabIndex:0,
               comName:'Job_sort',
            //    comParam:{},//装参数，可以用不上

               zxTitleNav:[{
                   name:'职位排序',
                   Url:'Job_sort'
               },{
                   name:'企业排序',
                   Url:'Ent_sort'
               },{
                   name:'人才推荐',
                   Url:'People_sort'
               }]
            }
        },
        created(){

        },
        methods:{
            tabNavtilte:function(index){
                this.tabIndex=index;
                this.comName=this.zxTitleNav[index].Url;
            }
        }
    }
</script>

<style scoped lang="scss">
.isDivcom{
    color:#000;
    border-bottom: 2px solid #000000;
}
</style>