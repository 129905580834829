<template>
    <div class="home">
        <div class="tilte_btn">
            <span class="lineY"></span>
            <span><h1>人力资源服务人员基础数据</h1></span>
            <span>
                <el-button @click="getMeminfoAdd" type="danger" class="el_btn" size="medium">新 增</el-button>
            </span>
        </div>
        <nav class="nav_sech">
            <el-input class="el_input" size="medium" v-model="queryform.hribName" placeholder="请输入姓名"></el-input>
            <el-input class="el_input" size="medium" v-model="queryform.hribTel" placeholder="请输入联系方式"></el-input>
            <el-select class="el_input" size="medium" v-model="queryform.hribtype" placeholder="意向类型">
                <el-option label="全部" value="0"></el-option>
                <el-option label="人事代理" value="1"></el-option>
                <el-option label="劳务派遣" value="2"></el-option>
            </el-select>
            <el-button size="medium" @click="sechMenlist" type="primary" class="el_btn">查 询</el-button>
        </nav>
        <div class="inte"></div>
        <main class="el_table" style="margin: 15px 15px 0">
            <el-table
                    :data="tableData"
                    style="width: 100%;margin-bottom: 20px"
                    border>
                <el-table-column
                        prop="hribName"
                        label="用户名称"
                        align="center">
                </el-table-column>
                <el-table-column
                        label="意向类型"
                        align="center"
                        width="200">
                    <template slot-scope="scope">
                        <span v-if="scope.row.hribtype==1">人事代理</span>
                        <span v-if="scope.row.hribtype==2">劳务派遣</span>
                    </template>
                </el-table-column>
                <el-table-column 
                        prop="hribTel" 
                        label="联系方式"
                        align="center" 
                        width="200">
                </el-table-column>
                <el-table-column 
                        prop="hribCity" 
                        label="所在城市"
                        align="center" 
                        width="250">
                </el-table-column>
                <el-table-column 
                        prop="hribLiveness" 
                        label="活跃天数"
                        align="center" 
                        width="200">
                </el-table-column>
                <el-table-column 
                        prop="createtime" 
                        label="意向时间" 
                        align="center" 
                        width="300">
                </el-table-column>
                <el-table-column label="操作" align="center" width="300">
                    <template slot-scope="scope">
                        <el-button
                                @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        <el-button
                                size="medium "
                                type="danger"
                                @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height:60px;text-align: center;">
                <el-pagination
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="10"
                        :current-page="queryform.page"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="connt">
                </el-pagination>
            </div>
        </main>
        <el-dialog
                :title="diaTitle"
                :visible.sync="dialogVisible"
                width="38%">
                <template>
                    <el-form ref="addEditinfo" size="medium" :model="addEditinfo" label-width="100px">
                        <el-form-item label="头像地址"> 
                            <el-upload
                            class="avatar-uploader"
                            :action="uploadImgUrl"
                            :show-file-list="false"
                            :on-success="handleIdcardnSuccess">
                            <img v-if="addEditinfo.hribHeadImg" :src="addEditinfo.hribHeadImg" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        </el-form-item>
                        <el-form-item label="所在城市">
                            <el-input class="el_input" v-model="addEditinfo.hribCity" placeholder="请输入所在城市"></el-input>
                        </el-form-item>
                        <el-form-item label="经验时长">
                            <el-input class="el_input" v-model="addEditinfo.hribExperience" type="number" min="1" placeholder="请输入经验时长/年"></el-input>
                        </el-form-item>
                        <el-form-item label="职务">
                            <el-input class="el_input" v-model="addEditinfo.hribJobName" placeholder="请输入职务"></el-input>
                        </el-form-item>
                        <el-form-item label="活跃天数">
                            <el-input class="el_input" v-model="addEditinfo.hribLiveness" type="number" min="1" placeholder="请输入活跃天数"></el-input>
                        </el-form-item>
                        <el-form-item label="主推">
                            <el-input class="el_input" v-model="addEditinfo.hribMainPush"  placeholder="请输入主推">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="姓名">
                            <el-input class="el_input" v-model="addEditinfo.hribName"  placeholder="请输入姓名">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="联系方式">
                            <el-input class="el_input" v-model="addEditinfo.hribTel"  placeholder="请输入联系方式">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="意向类型">
                            <el-radio-group v-model="addEditinfo.hribtype">
                                <!-- <el-radio class="m_fl15" label="1">全职</el-radio> -->
                                <el-radio label="1">人事代理</el-radio>
                                <el-radio label="2">劳务派遣</el-radio>
                           </el-radio-group>
                        </el-form-item>
                    </el-form>
                </template>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="postAddData()">确 定</el-button>
                </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name:'Ent_meminfo',
    data(){
        return{
            uploadImgUrl:this.API.file.uploadSingle,//图片地址

            diaTitle:'',
            dialogVisible:false,
            addEditinfo:{
                hribtype:'1'
            },

            tableData:[],
            queryform:{
                hribtype:'0',
                createtime:'',
                page:1,
                limit:10
            },
            connt:null
        }
    },
    created() {
        this.getDesireList()  
    },
    methods: {
        //上传图片
        handleIdcardnSuccess:function(res,file){
            if(res.success){
                this.$set(this.addEditinfo,'hribHeadImg',res.data);
            }else{
                this.$message.error(res.msg);
            }
        },
        //新增
        getMeminfoAdd:function(){
            this.diaTitle='新增';
            this.dialogVisible=true;
        },
        handleEdit:function(index,row){
            this.diaTitle='编辑';
            row.hribtype=row.hribtype+'';
            this.addEditinfo=JSON.parse(JSON.stringify(row));
            this.dialogVisible=true;
        },
        handleDelete:function(index,row){
            var _self=this;
            this.$confirm('此操作将永久删除该人员, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    _self.handleDeleteData(index,row);
            })
        },
        handleDeleteData:function(index,row){
            var _this=this;
            axios({
                    url:this.API.hrib_user.delete,
                    method: 'delete',
                    params:{
                        id:row.id,
                    },
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                }).then((res)=>{
                    if(res.data.success){
                        _this.$message.success('删除成功!');
                        _this.tableData.splice(index,1);
                    }else{

                }
            })
        } ,
        //提交
        postAddData:function(){
            if(this.Util.isBlank(this.addEditinfo.hribHeadImg)){
                this.$message.warning('请上传头像！');
                return
            }
            if(this.Util.isBlank(this.addEditinfo.hribCity)){
                this.$message.warning('请输入所在城市！');
                return
            }
            if(this.Util.isBlank(this.addEditinfo.hribExperience)){
                this.$message.warning('请输入经验时长！');
                return
            }
            if(this.Util.isBlank(this.addEditinfo.hribJobName)){
                this.$message.warning('请输入职务！');
                return
            }
            if(this.Util.isBlank(this.addEditinfo.hribLiveness)){
                this.$message.warning('请输入活跃度！');
                return
            }
            if(this.Util.isBlank(this.addEditinfo.hribMainPush)){
                this.$message.warning('请输入主推！');
                return
            }
            if(this.Util.isBlank(this.addEditinfo.hribName)){
                this.$message.warning('请输入姓名！');
                return
            }
            if(this.Util.isBlank(this.addEditinfo.hribTel)){
                this.$message.warning('请输入联系方式！');
                return
            }
            if(!this.Util.phoneVail(this.addEditinfo.hribTel)){
                this.$message.error('联系电话不合理！');
                return; 
            }
            this.isSuccessgetData(this.addEditinfo)
        },
        isSuccessgetData:function(DataInfo){
            var _this=this;
            axios({
                url:this.API.hrib_user.editor,
                method: 'post',
                data:DataInfo,
                headers:{
                    token:this.Util.getjwtToken(),
                },
            }).then(function (res) {
                console.log(res)
                if(res.data.success){
                    _this.dialogVisible=false;
                    _this.addEditinfo={
                        hribtype:'0',
                        createtime:'',
                        page:1,
                        limit:10
                    };
                    _this.getDesireList();
                }else{

                }
            })
        },
        //查寻
        sechMenlist:function(){
            this.queryform.page=1;
            this.getDesireList()
        },
        getDesireList:function(){
            var _this=this;
            axios.get(_this.API.hrib_user.list,{
                headers:{
                    token:_this.Util.getjwtToken(),
                },
                    params:_this.queryform
                }).then(function (pores) {
                    console.log('意向',pores.data);
                    if(pores.data.success){
                       _this.tableData=pores.data.data.list;
                       _this.connt=pores.data.data.pager.recordCount;
                    }else{
                       _this.tableData=[]; 
                    }
                }).catch(function (error) {
                    console.log('error', error);
            });
        },
        //分页操作
        handleSizeChange:function(e){

        },
        handleCurrentChange:function(e){

        }

    },
}
</script>
<style lang="scss" scoped>

</style>