//封装公用方法
import './config.js';
const Util = {
  evt: {

  },
  /*=======自定义字段筛选配置======== */
  fields: [
    {
      label: '姓名',
      type: 'text',
      name: 'username',
      width: '100px'
    },
    {
      label: '学历',
      name: 'education',
      type: 'select',
      width: '80px',
      bindVal: [],
      values: [
        { label: '小学', value: 10 },
        { label: '中学', value: 20 },
        { label: '初中', value: 30 },
        { label: '高中', value: 40 },
        { label: '专科', value: 50 },
        { label: '中专', value: 60 },
        { label: '大专', value: 70 },
        { label: '本科', value: 80 },
        { label: '硕士', value: 90 },
        { label: '博士', value: 100 },
        { label: '博士后', value: 110 }
      ]
    },
    {
      label: '性别',
      name: 'sex',
      width: '80px',
      type: 'select',
      bindVal: [],
      ischange: true,
      values: [
        { label: '男', value: 1 },
        { label: '女', value: 2 }
      ]
    },
    {
      label: '年龄',
      type: 'text',
      width: '80px',
      name: 'age'
    },
    {
      label: '居住地址',
      type: 'mufield',
      width: '200px',
      showType: 'adds',
      alias: 'liveAddress',
      name: 'hkprovinceName-hkcityName'
    },
    {
      label: '工作经验',
      type: 'text',
      width: '180px',
      name: 'workExperience'
    },
    {
      label: '电话',
      type: 'text',
      width: '120px',
      name: 'phone'
    },
    {
      label: '邮箱',
      type: 'text',
      width: '120px',
      name: 'email'
    },
    {
      label: '求职状态',
      type: 'select',
      width: '220px',
      name: 'ittJobstate',
      bindVal: [],
      values: [
        { label: '离职', value: 1 },
        { label: '在职,可一个月到岗', value: 2 },
        { label: '在职,考虑换工作', value: 3 },
        { label: '在职,应届生毕业', value: 4 }
      ]
    },
    {
      label: '期望地址',
      type: 'mufield',
      width: '200px',
      showType: 'adds',
      ischange: true,
      alias: 'hkAddress',
      name: 'ittprovinceName-ittcityName'
    },
    {
      label: '期望行业',
      type: 'text',
      width: '150px',
      name: 'ittindtypeNames'
    },
    {
      label: '期望职位',
      type: 'text',
      width: '150px',
      name: 'ittcartypeNames'
    },
    {
      label: '期望新资',
      type: 'mufield',
      width: '150px',
      alias: 'ittSalary',
      showType: 'ittminSalary',
      name: 'ittminSalary-ittmaxSalary'
    },
    {
      label: '工作性质',
      type: 'select',
      width: '150px',
      name: 'ittType',
      bindVal: [],
      values: [
        { label: '全职', value: 1 },
        { label: '普工/兼职', value: 2 }
      ]
    }
  ],
  //掌握成都
  masterLevels: [
    { label: '一般', value: 1 },
    { label: '良好', value: 2 },
    { label: '熟练', value: 3 },
    { label: '精通', value: 4 }
  ],
  companyscale: [
    { label: '1-49人', min: 1, max: 49 },
    { label: '50-99人', min: 50, max: 99 },
    { label: '100-499人', min: 100, max: 499 },
    { label: '500-999人', min: 500, max: 499 },
    { label: '1000-2000人', min: 1000, max: 2000 },
    { label: '2000-5000人', min: 2000, max: 5000 },
    { label: '5000-10000人', min: 5000, max: 10000 }
  ],
  /*@
  学历要求*/
  recordAsk: [
    { label: '不限', value: '' },
    // {label:'小学',value:10},
    { label: '初中', value: 20 },
    { label: '初中及以下', value: 31 },
    { label: '高中', value: 40 },
    { label: '专科', value: 50 },
    { label: '中专/中技', value: 60 },
    { label: '大专', value: 70 },
    { label: '本科', value: 80 },
    { label: '硕士及以上', value: 92 }
    // {label:'博士',value:100},
    // {label:'博士后',value:110}
  ],
  /*@
  经验要求*/
  experWorkAsk: [
    { label: '不限', min: -1, max: -1 },
    { label: '1年以下', min: -1, max: 1 },
    { label: '1-2年', min: 1, max: 2 },
    { label: '3-5年', min: 3, max: 5 },
    { label: '6-8年', min: 6, max: 8 },
    { label: '8-10年', min: 8, max: 10 },
    { label: '10年以上', min: 10, max: -1 }
  ],
  /**
   * 招聘人数
   */
  recruitArr: [
    { label: '0-10人', min: 0, max: 10 },
    { label: '10-20人', min: 10, max: 20 },
    { label: '20-30人', min: 20, max: 30 },
    { label: '30-40人', min: 30, max: 40 },
    { label: '40-50人', min: 40, max: 50 },
    { label: '50人以上', min: 50, max: -1 }
  ],
  /**
   * 薪资待遇
   */
  salaryArr: [
    { label: '不限', min: -1, max: -1 },
    { label: '3000元以下', min: -1, max: 3000 },
    { label: '3000～5000元', min: 3000, max: 5000 },
    { label: '5000～8000元', min: 5000, max: 8000 },
    { label: '8000～10000元', min: 8000, max: 10000 },
    { label: '10000元以上', min: 10000, max: -1 },
  ],
  /*@企业认证 */
  auditStateAdd: [
    { value: -1, label: '全部' }, { value: 0, label: '待审核' },
    { value: 1, label: '审核失败' }, { value: 2, label: '审核成功' }],
  /*@职位类型 */
  jobtypeAdd: [
    { value: -1, label: '全部' },
    { value: 1, label: '全职' },
    { value: 2, label: '普工/兼职' }
  ],
  /*@招聘类型*/
  recruitTypeAdd: [
    { value: -1, label: '全部' },
    { value: 1, label: '招聘中' }
  ],
  // 权限类型 0 左侧菜单(url)权限 1普通页面链接(url)权限 2 页面按钮权限 3 api接口
  powerStype: [
    { value: 0, label: '左侧菜单(url)权限' },
    { value: 1, label: '普通页面链接(url)权限' },
    { value: 2, label: '页面按钮权限' }, { value: 3, label: 'api接口' }
  ],
};
/*@窗口大小的改变事件 */
/*@
* 存入登录信息
* @*/
Util.setLogininfo = function (data) {
  var res = false;
  try {
    data = JSON.stringify(data);
    localStorage.setItem('login', data);
    res = true;
  } catch (e) {
    console.log("====== 保存登录信息出错 ======");
    console.log(e);
    console.log("====== end ======");
  }
  return res;
};
Util.getLogininfo = function () {
  var value = false;
  try {
    value = JSON.parse(localStorage.getItem('login'));
  } catch (e) {
    console.log("====== 获取登录信息出错 ======");
    console.log(e);
    console.log("====== end ======");
  }
  return value;
};
/*取出登入的oken*/
Util.getjwtToken = function () {
  if (Util.getLogininfo()) {
    return Util.getLogininfo().jwtToken;
  } else {
    return "";
  }
};
/*取出登入的id*/
Util.getUid = function () {
  if (Util.getLogininfo()) {
    return Util.getLogininfo().data.id;
  } else {
    return "";
  }

};

/*取出登入的用户级别userType*/
Util.getUserType = function () {
  if (Util.getLogininfo()) {
    return Util.getLogininfo().data.userType;
  } else {
    return 0;
  }

};

/**
 * 判断是否为空 包括null, undefined字符串是也判断为空
 * @param {String} s
 */
Util.isBlank = function (s) {
  if (!s || s == 'null' || s == 'undefined') {
    return true;
  } else {
    return false;
  }
};
Util.isnull = function (s) {
  if (s == 'null' || s == 'undefined' || typeof (s) == "undefined" || typeof (s) == undefined || s === '') {
    return true;
  } else {
    return false;
  }
};
/**
 * 校验手机号
 * @param {Object} phone
 */
Util.phoneVail = function (phone) {
  let reg = /^((0\d{2,3}-\d{7,8})|(1[3584679]\d{9}))$/;
  console.log(reg.test(phone))
  if (reg.test(phone)) {
    return true;
  } else {
    return false;
  }
};
/**
 * 校验邮箱
 * @param {Object} email
 */
Util.emailVail = function (email) {
  let reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
  console.log(reg.test(email))
  if (reg.test(email)) {
    return true;
  } else {
    return false;
  }
};
/**
 * 通过学历数值得到显示的学历
 * 学历要求：第一位代表学历 第二位为0表示该学历 为1表示学历以下 为2表示学历以上
 * [1-11]->[小学，中学，初中，高中，专科，中专，大专,本科，硕士，博士，博士后]
 * 比如 [30:初中，31：初中以下，32：初中以上]
 */
Util.eduShow = function (eduValue) {
  var eduArr = ['小学', '中学', '初中', '高中', '专科', '中专', '大专', '本科', '硕士', '博士', '博士后'];
  try {
    var edu0 = parseInt((eduValue + '')[0]);
    var edu1 = parseInt((eduValue + '')[1]);
    var show = eduArr[edu0 - 1];
    if (edu1 == 1) {
      show += "以下";
    } else if (edu1 == 2) {
      show += "以上";
    }
    return show;
  } catch (e) {
    console.log('学历转换错误', e)
    return "学历转换错误";
  }
}
/**
 *格式化时间日期
 * @param date
 * @param fmt
 * @returns {string}
 */
Util.setFormatDate = function (date, fmt) {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(),        // 年
    "y+": date.getFullYear().toString(),        // 年
    "M+": (date.getMonth() + 1).toString(),     // 月
    "d+": date.getDate().toString(),            // 日
    "H+": date.getHours().toString(),           // 时
    "h+": date.getHours().toString(),           // 时
    "m+": date.getMinutes().toString(),         // 分
    "S+": date.getSeconds().toString(),         // 秒
    "s+": date.getSeconds().toString()          // 秒
  };
  if (fmt == null || fmt == "") { fmt = "yyyy-MM-dd hh:mm:ss"; }
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
    };
  };
  return fmt;
}



export default Util