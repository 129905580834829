<template>
    <div class="home">
        <nav class="navTab">
            <div :class="tabIndex==0?'isDivcom':''" @click="tabNavtilte(0,'Res_Detail')"><h2>简历详情</h2></div>
            <div :class="tabIndex==1?'isDivcom':''" @click="tabNavtilte(1,'Res_Deliver')"><h2>简历投递情况</h2></div>
        </nav>
        <div class="inte"></div>
        <component :is="comName" :comParam="comParam"></component>
    </div>
</template>

<script>
import Res_Detail from "./Res_Detail";
import Res_Deliver from "./Res_Deliver";
    export default {
        name: "ResumeDetail",
        components:{
           Res_Detail,Res_Deliver
        },
        data(){
            return{
               tabIndex:0,
               comName:'Res_Detail',
            }
        },
        created() {
            this.comParam = this.$router.currentRoute.query;
            console.log('简历详情',this.comParam.tabIndex)
            if(this.comParam.tabIndex){
                this.tabIndex = this.comParam.tabIndex;
                this.comName = 'Res_Deliver';
            }
        },
        watch: {
            // sizeChange: function(old, newd){
            //     console.log("sizeChange", old, newd.screenWidth);
            // }
        },

        methods:{
           tabNavtilte:function(index,name){
              this.tabIndex=index;
              this.comName=name;
           }
        }
        
    }
</script>

<style scoped lang="scss">
   .home{
       background: #fff;
   }
   .isDivcom{
     color:#000;
     border-bottom: 2px solid #000000;
 }
</style>