<template>
    <div class="home">
       <nav class="navTab">
            <div :class="tabIndex==0?'isDivcom':''" @click="tabNavtilte(0,'Recruit_info')"><h2>招聘会信息</h2></div>
            <div :class="tabIndex==1?'isDivcom':''" @click="tabNavtilte(1,'Recruit_Booth')"><h2>展位预定情况</h2></div>
        </nav>
        <div class="inte"></div>
        <component :is="comName" :comRecruit="comRecruit"></component>
    </div>
</template>

<script>
import Recruit_info from './Recruit_info';
import Recruit_Booth from './Recruit_Booth';
    export default {
        name: "Recruit_detail",
        components:{
           Recruit_info,Recruit_Booth
        },
        data(){
            return{
                comRecruit:{},
                tabIndex:0,
                comName:'Recruit_info',
            }
        },
         props: {
               comParam: Object
         },
        created(){
            this.comRecruit=this.comParam;
        },
        methods:{
            tabNavtilte:function(index,name){
                this.tabIndex=index;
                this.comName=name;
            }
        }
    }
</script>

<style scoped lang="scss">
.isDivcom{
    color:#000;
    border-bottom: 2px solid #000000;
}
</style>