<template>
  <div class="home" style="background: #f6f7f6;">
     <main class="ent_main clearfix">
       <div class="fl ent_fl">
          <div class="tilte_btn">
                <span class="lineY"></span>
                <span><h2>企业基础数据</h2></span>
              <span>
               <el-button v-if="userType!=1" @click="editorEnt" type="primary" class="el_btn" size="medium">修改企业信息</el-button>
               <el-button @click="getJobAdd" type="warning" class="el_btn" size="medium">发布职位</el-button>
               <el-button v-if="userType!=1" @click="recruitPreordain" type="primary" class="el_btn" size="medium">招聘会展位预定</el-button>
               <el-button v-if="userType!=1" @click="getResume" type="primary" class="el_btn" size="medium">简历投递情况</el-button>
            </span>
          </div>
          <div style="padding:10px;">
                <span class="ent_del">企业名称：</span><span class="ent_detail">{{EntDetail.name?EntDetail.name:'无'}}</span>
          </div>
          <div style="padding:10px">
              <el-row>
                <el-col :span='10'>
                  <span class="ent_del">行业分类：</span>
                  <span class="ent_detail">{{EntDetail.indtypeNames?EntDetail.indtypeNames:'无'}}</span>
                </el-col>
                <el-col :span='10'>
                    <span class="ent_del ent_delFr">企业性质：</span>
                    <span class="ent_detail">{{EntDetail.natures?EntDetail.natures:'无'}}</span>
                </el-col>
              </el-row>
          </div>
          <div style="padding:10px">
              <el-row>
                <el-col :span='10'>
                    <span class="ent_del">企业规模：</span>
                    <span class="ent_detail">{{EntDetail.scaleEnt?EntDetail.scaleEnt:'无'}}</span>
                </el-col>
                <el-col :span='10'>
                    <span class="ent_del ent_delFr">员工规模：</span>
                    <span class="ent_detail" v-if="EntDetail.scalePersonMin==-1">{{EntDetail.scalePersonMax}}人以下</span>
                    <span class="ent_detail" v-else-if="EntDetail.scalePersonMax==-1">{{EntDetail.scalePersonMin}}人以上</span>
                    <span class="ent_detail" v-else>{{EntDetail.scalePersonMin}}~{{EntDetail.scalePersonMax}}人</span>
                </el-col>
              </el-row>
          </div>
          <div style="padding:10px">
              <span class="ent_del">公司福利：</span>
              <el-button type="danger" size="medium" v-for="(item,index) in EntDetail.welfareArry" :key="index" v-if="item">{{item}}</el-button>
              <span class="ent_detail" v-if="!(EntDetail.welfareArry)||EntDetail.welfareArry.length<=0">无</span>
          </div>
          <div style="padding:10px">
              <span class="ent_del">公司特色：</span>
              <el-button type="danger" size="medium" v-for="(item,index) in EntDetail.characteristicArry" :key="index" v-if="item">{{item}}</el-button>
              <span class="ent_detail" v-if="!(EntDetail.characteristicArry)||EntDetail.characteristicArry.length<=0">无</span>
          </div>
          <div style="padding:10px">
              <span class="ent_del">公司地址：</span>
              <span class="ent_detail" v-if="EntDetail.eprovinceName">{{EntDetail.eprovinceName}}-
                  {{EntDetail.ecityName}}-{{EntDetail.ecountyName}}
                  {{EntDetail.eaddrDetail}}
              </span>
              <span class="ent_detail" v-else>暂无地址</span>
          </div>
          <div class="mapBox" id="mapBox" v-if="EntDetail.eprovinceName">
              <!-- 地图 -->
          </div>
          <div class="tilte_btn">
                <span class="lineY"></span>
                <span><h2>联系方式</h2></span>
          </div>
          <div style="padding:10px">
               <el-row>
                <el-col :span='8'>
                    <span class="ent_del">联系人：</span>
                    <span class="ent_detail">{{EntDetail.contactsPerson?EntDetail.contactsPerson:'无'}}</span>
                </el-col>
                <el-col :span='8'>
                    <span class="ent_del ent_delFr">联系方式：</span>
                    <span class="ent_detail">{{EntDetail.contactsNumber?EntDetail.contactsNumber:'无'}}</span>
                </el-col>
                <el-col :span='8'>
                    <span class="ent_del ent_delFr">邮箱：</span>
                    <span class="ent_detail">{{EntDetail.contactsEmail?EntDetail.contactsEmail:'无'}}</span>
                </el-col>
              </el-row>
          </div>
          <div class="tilte_btn">
                <span class="lineY"></span>
                <span><h2>公司简介</h2></span>
          </div>
          <div style="padding:10px">
               <el-input
                    type="textarea"
                    style="width:90%"
                    placeholder="暂无简介"
                    :autosize="{ minRows: 7, maxRows: 10}"
                    readonly
                    v-model="EntDetail.descript" >
                </el-input>
          </div>
          <div class="tilte_btn">
                <span class="lineY"></span>
                <span>
                  <h2>企业logo</h2>
                </span>
          </div>
          <div style="padding:10px">
                <div class="ent_img">
                 <div class="ent_list">
                    <img :src="EntDetail.logoPurl"/>
                 </div>
              </div>
           </div>
          <div class="tilte_btn">
                <span class="lineY"></span>
                <span><h2>公司相册</h2></span>
          </div>
          <div style="padding:0 10px 10px">
              <div class="ent_img">
                 <div class="ent_list" v-for="(item,index) in EntalbumArry" :key="index">
                    <img :src="item.phoneUrl"/>
                 </div>
              </div>
          </div>
          <div style="padding:10px 10px 30px" v-if="isBtnShow==0||isBtnShow==3">
                 <el-button @click="examineaudit" type="danger" style="width:120px">通过审核</el-button>
                 <el-button @click="outexamineaudit" type="info" style="width:120px">驳回</el-button>
          </div>
       </div>
       <div class="fr ent_fr">
           <div class="tilte_btn">
                <span class="lineY"></span>
                <span><h2>企业资质审核</h2></span>
            </div>
            <div style="padding:10px">
               <span class="ent_del">注册号：</span>
               <span class="ent_detail">{{EntDetail.entRegno?EntDetail.entRegno:'无'}}</span>
            </div>
            <div style="padding:10px">
               <span class="ent_del">统一社会信用代码：</span>
               <span class="ent_detail">{{EntDetail.creditCode?EntDetail.creditCode:'无'}}</span>
            </div>
            <div style="padding:10px">
               <span class="ent_del">法人代表：</span>
               <span class="ent_detail">{{EntDetail.legalPerson?EntDetail.legalPerson:'无'}}</span>
            </div>
            <div style="padding:10px">
               <span class="ent_del">营业期限：</span>
               <span class="ent_detail">{{EntDetail.dbtime?EntDetail.dbtime:'无'}}</span>
            </div>
            <div style="padding:10px">
               <span class="ent_del">企业营业执照：</span>
               <div class="ent_License">
                  <img :src="EntDetail.licensePhotoUrl"/>
               </div>
            </div>
            <div style="padding:10px">
               <span class="ent_del">法人实名认证：</span>
               <div class="ent_License_img">
                  <img :src="EntDetail.legalPersonCardNurl"/>
                  <img class="m_fl15" :src="EntDetail.legalPersonCardPurl"/>
               </div>
            </div>
       </div>
     </main>
     <main class="el_table">
        <div class="org_box">
             <el-row>
                 <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6" class="org_list" v-for="(item,index) in jobDataList" :key="index">
                       <div class="job-delete" title="删除职位" @click="deleteJobInfo(item)"><i class="el-icon-delete-solid"></i></div>
                       <div class="org_main" @click="getJobDetail(item)">
                           <div class="clearfix">
                               <div class="fl job_list">
                                    <span style="font-size:18px; font-weight: 700;">{{item.jobname}}</span>
                                    <p>发布时间：{{item.publishTime}}</p>
                                    <p>
                                        <span style="margin-right: 15px;color: burlywood;">{{item.minSalary}}-{{item.maxSalary}}元</span>
                                        <span style="margin-right: 15px;" v-if="item.workExperienceMin==-1&&item.workExperienceMax==-1">
                                            经验不限
                                        </span>
										<span style="margin-right: 15px;" v-else-if="item.workExperienceMin==-1">
										    {{item.workExperienceMax}}年以下
										</span>
										<span style="margin-right: 15px;" v-else-if="item.workExperienceMax==-1">
										    {{item.workExperienceMin}}年以上
										</span>
										<span style="margin-right: 15px;" v-else>
										    {{item.workExperienceMin}}-{{item.workExperienceMax}}年
										</span>
										<span style="margin-right: 15px;" v-if="item.workExperienceMin==-1&&item.workExperienceMax==-1">
										    {{item.workExperienceMin}}-{{item.workExperienceMax}}年
										</span>
                                        <span>{{item.educationText?item.educationText:'学历不限'}}</span>
                                    </p>
                                    <p class="fuli">
                                        <span>{{item.jobLightPoint}}</span>
                                    </p>
                               </div>
                           </div>
                       </div>
                 </el-col>
             </el-row>
        </div>
        <div class="noList" v-if="jobDataList&&jobDataList.length==0">
               暂无岗位信息
        </div>
         <div style="height:60px;text-align: center; margin: 20px auto;">
           <el-pagination
                   background
                   @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
                   :page-sizes="[12,16, 20, 24, 28]"
                   :page-size="12"
                   :current-page="page"
                   layout="total, sizes, prev, pager, next, jumper"
                   :total="recordCount">
           </el-pagination>
         </div>
   </main>
      <el-dialog
            title="请输入驳回原因"
            :visible.sync="dialogOutexamine"
            width="30%">
            <div class="OutText">
               <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4}"
                    placeholder="请输入内容/100字内"
                    v-model="auditmsg">
                </el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogOutexamine = false">取 消</el-button>
                <el-button type="primary" @click="OutexamineData">确 定</el-button>
            </span>
       </el-dialog>
  </div>
</template>
<script>
 import { lazyAMapApiLoaderInstance } from 'vue-amap';
 export default {
     name:'EnterpriseDetail',
     data(){
         return{
             isBtnShow:'',

             EntDetail:{},
             EntalbumArry:[],

             auditmsg:'',
             dialogOutexamine:false,
             userType:0,
             page:1,
             limit:10,
             recordCount:0,
             jobDataList:[],
         }
     },
     created(){
         this.userType = this.Util.getUserType();
         //高德地图调用
       var _self=this;
       this.comParam = this.$router.currentRoute.query;
       this.recordAsk = this.Util.recordAsk;
       console.log('参数', this.comParam);
       this.getEntDetail(this.comParam.id);
       this.getEntsalbum(this.comParam.id);
       this.isBtnShow=this.comParam.auditState;
     },
     methods:{
         //添加职位
         getJobAdd:function(){
             this.$parent.pullItem("添加职位", "JobAdd", this.comParam);
         },
         recruitPreordain:function(){
             this.$parent.pullItem("招聘会展位预定", "RecruitPreordain",{
                 entid:this.EntDetail.id,
                 entName:this.EntDetail.name,
                 contactsPerson:this.EntDetail.contactsPerson,
                 contactsNumber:this.EntDetail.contactsNumber
             });
         },
         deleteJobInfo:function(item){
             var _self = this;
             _self.$confirm('确定要删除职位【<b><red>'+item.jobname+'</red></b>】吗, 是否继续?', '提示', {
                 confirmButtonText: '确定',
                 cancelButtonText: '取消',
                 type: 'warning',
                 dangerouslyUseHTMLString:true
             }).then(() => {
                 let param = new FormData(); // 创建form对象
                 param.append('jobId', item.id);
                 param.append('token', this.Util.getjwtToken());
                 let config = {
                     headers: {'Content-Type': 'application/x-www-form-urlencoded'}
                 }
                 _self.axios.post(_self.API.enterprise.deleteJobInfo,param,config).then((response) => { //请求成功返回
                     let res = response.data;
                     if(res.code == 200 && res.success){
                         _self.$message.success(res.msg);
                         _self.getJobList(item.entname);
                     }
                 })
             })
         },
        //去职位详情
        getJobDetail(item){
            this.$parent.pullItem("职位信息", "JobDetail", {id: item.id});
        },
        //去简历投递情况详情
        getResume(){
            this.$parent.pullItem("简历投递情况", "EnterpResume", {entid: this.comParam.id});
        },
        editorEnt:function(){
            this.$parent.pullItem("编辑企业", "Enterpriseadd",{id: this.EntDetail.id});
        },
        //获取该企业的职位
        getJobList(eName){
           var _self = this;
           axios.get(_self.API.ajob.list, {
                headers: {
                    token: _self.Util.getjwtToken()
                },
                params:{
                    entname:eName,
                    jobtype:-1,
                    recruitType:-1,
                    page:this.page,
                    limit:this.limit
                }
            }).then(function(pores) {
                if (pores.data.success) {
                    let resp = pores.data.data;
                    _self.recordCount = resp.pager.recordCount;
                    if(resp.list&&resp.list.length>0){
                        for(var j=0;j<_self.recordAsk.length;j++){
                            for(var i=0;i<resp.list.length;i++){
                                if(resp.list[i].educationRequire == _self.recordAsk[j].value){
                                    resp.list[i].educationText = _self.recordAsk[j].label
                                }
                            }
                        }
                        _self.jobDataList=resp.list;
                    }
                }
            })
        },
        //企业相册
       getEntsalbum:function(Eid){
          var _self = this;
           axios.get(_self.API.aent.album, {
                headers: {
                    token: _self.Util.getjwtToken()
                },
                params:{
                    id:Eid
                }
            }).then(function(pores) {
                if (pores.data.success) {
                    if(pores.data.data&&pores.data.data.length>0){
                        _self.EntalbumArry=pores.data.data
                    }
                }
            }).catch(function(error) {
                  console.log(error);
            });
       },
       getEntDetail:function(Eid){
           var _self = this;
           axios.get(_self.API.aent.detail, {
                headers: {
                    token: _self.Util.getjwtToken()
                },
                params:{
                    id:Eid
                }
            }).then(function(pores) {
                if (pores.data.success) {
                    if(pores.data.data){
                        if(pores.data.data.indtypeNames){
                            pores.data.data.indtypeNames.replace(/-/g, '/');
                        }
                        if(pores.data.data.natures){
                            pores.data.data.natures.replace(/-/g, '/');
                        }
                         _self.EntDetail=pores.data.data;
						 if(pores.data.data.characteristic) {
							  _self.EntDetail.characteristicArry=pores.data.data.characteristic.split("-");
						 }
						 if(pores.data.data.welfare) {
							  _self.EntDetail.welfareArry=pores.data.data.welfare.split("-");
						 }
                         // console.log("data", _self.EntDetail);
                         _self.entName = _self.EntDetail.name;
                         _self.getJobList(_self.entName);
                         lazyAMapApiLoaderInstance.load().then(() => {
                                //替换经纬度
                             if(_self.EntDetail.entLng&&_self.EntDetail.entLat){
                                 var point = new AMap.LngLat(_self.EntDetail.entLng, _self.EntDetail.entLat);
                                 _self.map = new AMap.Map('mapBox', {
                                     center: point,
                                     zoom: 13
                                 });
                                 var marker = new AMap.Marker({
                                     position: point,
                                     title: _self.EntDetail.name
                                 });
                                 _self.map.add(marker);
                             }
                        });
                    }
                }
            }).catch(function(error) {
                console.log(error);
            });
         },
         //通过审核
         examineaudit:function(){
               var _this=this;
               this.$confirm('该企业将通过审核, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                    _this.getexamineData('2')
                    }).catch(() => {
               });
         },
         outexamineaudit:function(){
            this.dialogOutexamine=true
         },
         OutexamineData:function(){
             this.getexamineData('1')
         },
         getexamineData:function(state){
             var _self=this;
              axios.get(_self.API.aent.audit,{
                    headers:{
                        token:_self.Util.getjwtToken(),
                    },
                    params:{
                        auditState:state,
                        id:_self.comParam.id,
                        auditmsg:_self.auditmsg
                    }
                }).then(function (pores) {
                    if(pores.data.success){
                        _self.$message.success(pores.data.msg)
                        _self.auditmsg='';
                        _self.dialogOutexamine=false;
                    }else{
                       _self.$message.error(pores.data.msg)
                    }
                }).catch(function (error) {
                    console.log(error);
                });
         },
         //分页
         handleSizeChange(e){
             this.limit = e;
             this.getJobList(_self.entName);
         },
         handleCurrentChange(e){
            this.page = e;
            this.getJobList(_self.entName);
         }
     }

 }

</script>
<style scoped lang="scss">
.tilte_btn{
    background-color: #ffffff;
}
.job_list{
    p:nth-child(2){
       font-size:16px;
    }
    p:nth-child(3){
       font-size:16px;
       margin: 10px 0;
    }
    .fuli{
       margin-bottom: 20px;
       span{
            color:blue;
       }
    }
}
.org_list{
    height:210px;
    position: relative;
}
.job-delete{
    position: absolute;
    top: 20px;
    right: 25px;
    cursor: pointer;
    z-index: 1000;
}
.job-delete i{font-size: 20px;color: #cc0000;}
</style>
