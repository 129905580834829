<template>
    <div class="home">
        <div class="tilte_btn">
           <span class="lineY"></span>
           <span><h1>人力资源服务意向管理</h1></span>
        </div>
        <nav class="nav_sech" style="justify-content: space-between;">
            <div>
                <el-input @change="searchHriName" class="el_input" placeholder="意向用户名称" v-model="queryform.hriName" clearable></el-input>
                <el-select @change="changeState" class="el_input" v-model="queryform.state" placeholder="意向类型" clearable>
                    <el-option label="全部" value="0"></el-option>
                    <el-option label="人事代理" value="1"></el-option>
                    <el-option label="劳务派遣" value="2"></el-option>
                </el-select>
                <el-input @change="searchhriUserName" class="el_input" placeholder="意向对象名称" v-model="queryform.hriUserName" clearable></el-input>

                <el-date-picker  class="el_input" clearable
                    v-model="queryform.createtime"
                    @change="changeScreatetime"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="意向时间">
                </el-date-picker>
                <!-- <el-button class="el_btn" type="danger">查询</el-button> -->
            </div>
            <!-- <div>
                  <el-input
                    style="width:250px;margin-right:15px"
                    placeholder="请输入姓名/关键字"
                    v-model="queryform.keywords">
                    <i slot="suffix"  class="el-input__icon el-icon-search" @click="searchList"></i>
                </el-input>
            </div> -->
        </nav>
        <div class="inte"></div>
        <main class="el_table" style="margin: 15px 15px 0">
            <el-table
                    :data="tableData"
                    style="width: 100%;margin-bottom: 20px"
                    border>
                <el-table-column
                        prop="hriName"
                        label="意向用户名称"
                        align="center"
                        width="328">
                </el-table-column>
                <el-table-column
                        label="意向类型"
                        align="center"
                        width="320">
                    <template slot-scope="scope">
                        <span v-if="scope.row.type==1">人事代理</span>
                        <span v-if="scope.row.type==2">劳务派遣</span>
                    </template>

                </el-table-column>
                <el-table-column 
                        prop="hriUserName" 
                        label="意向对象"
                        align="center" 
                        width="320">
                </el-table-column>
                <el-table-column 
                        prop="createtime" 
                        label="意向时间" 
                        align="center" 
                        width="320">
                </el-table-column>
                <el-table-column 
                        prop="hriUserTel" 
                        label="联系方式" 
                        align="center" 
                        width="328">
                </el-table-column>
            </el-table>
            <div style="height:60px;text-align: center;">
                <el-pagination
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="10"
                        :current-page="queryform.page"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="connt">
                </el-pagination>
            </div>
        </main>
    </div>
</template>

<script>
    export default {
        name: "Desire",
        data(){
            return{
               queryform:{
                   page:1,
                   limit:10,
                   type:0,
                   hriName:'',
                   hriUserName:'',//对象
                   hriUserTel:'',
                   createtime:''
               },
               tableData:[],
               input3:'',
               select:'',
               connt:null
            }
        },
        created(){
            this.getDesireList()
        },
        methods:{
            //查询意向用户名称
            searchHriName:function(){
               this.queryform.page=1;
               this.getDesireList() 
            },
            //意向类型
            changeState:function(e){
                this.queryform.page=1;
                if(e){
                    this.queryform.type=e;    
                }else{
                    this.queryform.type=0;
                }
               this.getDesireList();
            },
            //意向对象
            searchhriUserName:function(){
                this.queryform.page=1;
                this.getDesireList() 
            },
            //意向时间
            changeScreatetime:function(e){
                this.queryform.page=1;
                if(e){
                    this.queryform.createtime=e;    
                }else{
                    this.queryform.createtime=''; 
                }
                this.getDesireList();
            },
            getDesireList:function(){
              var _this=this;
              axios.get(_this.API.hrIntention.list,{
                    headers:{
                        token:_this.Util.getjwtToken(),
                    },
                    params:_this.queryform
                }).then(function (pores) {
                    if(pores.data.success){
                       _this.tableData=pores.data.data.list;
                       _this.connt=pores.data.data.pager.recordCount;
                    }else{
                       _this.tableData=[]; 
                    }
                }).catch(function (error) {
                    console.log('error', error);
                });
            },
          //分页操作
             handleSizeChange:function(e){
               this.queryform.limit=e;
               this.getDesireList()
             },
             handleCurrentChange:function(e){
               this.queryform.page=e;
               this.getDesireList();
             }
        }
       
    }
</script>

<style scoped lang="scss">

</style>