<template>
  <div class="home">
    <div class="tilte_btn">
      <span class="lineY"></span>
      <span>
        <h1>求职简历库</h1>
      </span>
    </div>
    <nav class="nav_sech" style="justify-content: space-between">
      <main>
        <div style="width:112px;padding-left:15px">
          <el-select size="medium" clearable style="width:100%" v-model="queryform.sex" placeholder="性别">
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
          </el-select>
        </div>
        <div style="width:112px;padding-left:15px">
          <el-input type="number" min="1" v-model="queryform.workExperience" size="medium" style="width:100%" placeholder="工作经验"></el-input>
        </div>
        <div style="width:112px;padding-left:15px">
          <el-input type="number" min="1" v-model="queryform.age" size="medium" style="width:100%" placeholder="年龄"></el-input>
        </div>
        <div style="width:112px;padding-left:15px">
          <el-select size="medium" style="width:100%" v-model="queryform.education" placeholder="学历" clearable>
            <el-option v-for="(item,index) in recordAskArry" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
        <div style="width:200px;padding-left:15px">
          <el-date-picker style="width:100%" @change="chnagetakeTime" v-model="queryform.takeTimeInput" type="date" size="medium" value-format="yyyy-MM-dd HH-mm-ss" placeholder="投递日期">
          </el-date-picker>
        </div>
        <el-button @click="seachResList" size="medium" type="warning" class="el_btn">查询</el-button>
        <el-button @click="exportExcel" size="medium" type="success" :disabled="isDisabled" class="el_btn">导出数据</el-button>
      </main>
      <main>
        <div style="padding-right:15px">
          <el-input size="medium" placeholder="请输入姓名 / 职位关键字" v-model="queryform.keywords">
            <el-button class="searchBtn" slot="append" @click="searchName">搜索</el-button>
          </el-input>
        </div>
      </main>
    </nav>
    <main class="people_main">
      <div style="width:100%;height: 100px;line-height:100px;text-align:center;font-size: 20px" v-if="!listData || listData.length <= 0">没有数据喽</div>
      <div class="people_list m_top10" v-for="(item,index) in listData" :key="index">
        <div style="text-align: right;">
          <el-row>
            <el-col :span="10">
              <img v-if="item.portraiturl" :src="item.portraiturl">
              <img v-else src="../../assets/img/noheadimg.png">
            </el-col>
            <el-col :span="14">
              <p class="po_title m_top10">{{item.username?item.username:'无姓名'}}</p>
              <p style="font-size: 15px" class="m_top10">{{item.sex==1?'男':'女'}}/{{item.age?item.age:'0'}}岁/{{item.Showeducation?item.Showeducation:'无学历'}}</p>
            </el-col>
          </el-row>
        </div>
        <div class="el-center" >
          <p><span style="color:#999">工作经验</span></p>
          <p style="font-size: 15px;" class="m_top10">{{item.workExperience?item.workExperience:'无经验'}}</p>
        </div>
        <div class="el-center">
          <p><span style="color:#999">曾任职岗位</span></p>
          <p style="font-size: 15px;" class="m_top10">{{item.oncejobname?item.oncejobname:'无任职岗位'}}</p>
        </div>
        <div class="el-center">
          <p style="color:#999">期望薪资</p>
          <p style="font-size: 15px;" class="m_top10" v-if="item.ittminsalary==-1&&item.ittmaxsalary==-1">
            面议</p>
          <p style="font-size: 15px;" class="m_top10" v-if="item.ittminsalary==-1">
            {{item.ittmaxsalary}}元以下</p>
          <p style="font-size: 15px;" class="m_top10" v-if="item.ittmaxsalary==-1">
            {{item.ittminsalary}}元以上</p>
          <p style="font-size: 15px;" class="m_top10" v-else>
            {{item.ittminsalary}}-{{item.ittmaxsalary}}元</p>
        </div>
        <div class="el-center">
          <p style="color:#999">投递岗位</p>
          <p style="font-size: 15px;color:red;" class="m_top10" v-if="item.jobApplyList.length>0">
            {{item.jobApplyList[0].jobname?item.jobApplyList[0].jobname:'无'}}
            <el-popover placement="bottom" trigger="click">
              <div class="rea_list">
                <p v-for="(jobList,n) in item.jobApplyList" :key="n" @click="getResume(item)">{{jobList.jobname}}</p>
              </div>
              <el-button slot="reference" type="text">展开</el-button>
            </el-popover>
          </p>
          <p style="font-size: 15px;color:red;" class="m_top10" v-else>
            暂无投递岗位
          </p>
        </div>
        <div class="el-center">
          <p style="color:#999">投递时间</p>
          <p style="font-size: 15px;" class="m_top10">{{item.createtime}}</p>
        </div>

        <div style="float: left;width: 350px;">
          <el-button @click="getDetails(item)" type="danger" class="el_btn" size="small">查看详情</el-button>
          <el-button @click="downloadJL(item)" type="info" class="el_btn" size="small">下载简历</el-button>
          <el-button @click="deleteJl(item)" type="danger" class="el_btn" size="small">删除简历</el-button>
        </div>
      </div>
      <div class="bom_connt m_top10">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[10,20, 30, 40, 50]" :page-size="10" :current-page="queryform.pageIndex"
          layout="total, sizes, prev, pager, next, jumper" :total="connt">
        </el-pagination>
      </div>
    </main>

  </div>
</template>

<script>
import Qs from 'qs'
export default {
  name: "Resume",
  data () {
    return {
      listData: [],
      queryform: {
        age: '',
        sex: '',
        education: '',
        keywords: '',
        takeTimeInput: '',
        workExperience: '',
        pageIndex: 1,
        pageSize: 10,
      },
      experWorkAskArry: [],//经验
      recordAskArry: [],//学历
      isDisabled: false,
      connt: null
    }
  },
  created () {
    var experWorkAskArry = JSON.parse(JSON.stringify(this.Util.experWorkAsk));
    var recordAskArry = JSON.parse(JSON.stringify(this.Util.recordAsk));
    experWorkAskArry.splice(0, 1);
    recordAskArry.splice(0, 1);
    this.experWorkAskArry = experWorkAskArry;
    this.recordAskArry = recordAskArry;
    this.getResumeData();
  },
  methods: {
    getResume: function (row) {
      row.tabIndex = 1;
      this.$parent.pullItem("简历详情", "ResumeDetail", row)
    },
    //投递时间
    chnagetakeTime: function (e) {
      if (e) {
        this.queryform.takeTimeInput = e
      } else {
        this.queryform.takeTimeInput = ''
      }
    },
    getResumeData: function () {
      var _this = this;
      try {
        _this.queryform.takeTime = _this.queryform.takeTimeInput.substring(0, 10);
      } catch (e) {
        _this.queryform.takeTime = '';
      }
      axios({
        url: _this.API.mem.jobResumes,
        method: 'post',
        headers: {
          token: _this.Util.getjwtToken(),
        },
        data: _this.queryform
      }).then(function (e) {
        var resp = e.data;
        console.log("简历", resp);
        if (resp.success) {
          var temp = _this.Util.recordAsk;
          for (var i = 0; i < resp.data.list.length; i++) {
            for (var j = 0; j < temp.length; j++) {
              if (resp.data.list[i].education == temp[j].value) {
                resp.data.list[i].Showeducation = temp[j].label
              }
            }
          }
          _this.listData = resp.data.list;
          _this.connt = resp.data.pager.recordCount;
        } else {
          _this.listData = [];
          _this.connt = 0
        }
      })
    },
    //筛选
    seachResList: function () {
      this.queryform.pageIndex = 1;
      this.getResumeData();
    },
    searchName: function () {
      this.queryform.pageIndex = 1;
      this.getResumeData();
    },
    getDetails: function (row) {
      this.$parent.pullItem("简历详情", "ResumeDetail", row)
    },
    //下载简历
    // downloadJL: function (Eid) {
    //   var _this = this;
    //   this.$confirm('是否下载该人员的简历?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     window.location.href = _this.API.resume.download + Eid;
    //   }).catch(() => {
    //     this.$message({
    //       type: 'info',
    //       message: '已取消下载'
    //     });
    //   });
    // },
    downloadJL (item) {
      const _this = this
      axios.get(_this.API.resume.download + item.id, { responseType: 'blob' }).then(res => {
        let data = res.data;
        let fileReader = new FileReader();
        fileReader.onload = (event) => {
          try {
            console.log(event, 'event')
            if (event.target.result.indexOf('success') >= 0) {
              let jsonData = JSON.parse(event.target.result);
              if (jsonData.code) {
                // 说明是普通对象数据，后台转换失败
                console.log(jsonData)
                _this.$message({
                  type: "error",
                  message: jsonData.msg
                });
              }
            } else {
              _this.popDownLoadBox(res.data, `${item.username}个人简历.PDF`)
            }
          } catch (err) {
            console.log(err, "流处理")
            _this.$message({
              type: "error",
              message: "简历下载失败！"
            });
          }
        };
        fileReader.readAsText(data);
      }).catch(err => {
        console.log(err, 'errerrerr ---------------')
      })
    },
    popDownLoadBox (data, filename) {
      if (data) {
        let blob = new Blob([data])
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, filename)
        } else {
          let a = document.createElement('a')
          a.download = filename
          a.href = window.URL.createObjectURL(blob)
          a.click()
        }
      }
    },
    handleSizeChange: function (e) {
      this.queryform.pageSize = e;
      this.getResumeData();
    },
    handleCurrentChange: function (e) {
      this.queryform.pageIndex = e;
      this.getResumeData();
    },
    exportExcel : function(){
      this.isDisabled=true;
      const _this = this
      axios.get(_this.API.mem.exportExcel, { responseType: 'blob' }).then(res => {
        let data = res.data;
        let fileReader = new FileReader();
        fileReader.onload = (event) => {
          try {
            console.log(event, 'event')
            if (event.target.result.indexOf('success') >= 0) {
              let jsonData = JSON.parse(event.target.result);
              if (jsonData.code) {
                // 说明是普通对象数据，后台转换失败
                console.log(jsonData)
                _this.$message({
                  type: "error",
                  message: jsonData.msg
                });
              }
            } else {
              _this.popDownLoadBox(res.data, `简历数据.xls`)
            }
          } catch (err) {
            console.log(err, "流处理")
            _this.$message({
              type: "error",
              message: "导出人才数据失败！"
            });
          }
        };
        fileReader.readAsText(data);
        this.isDisabled=false;
      }).catch(err => {
        console.log(err, 'errerrerr ---------------')
      })
    },
    deleteJl: function(item){
      let _this = this;
        this.$confirm('确认删除该简历?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.delete(_this.API.mem.deleteJl,{
            headers:{
              token:_this.Util.getjwtToken(),
            },
            params:{
              id:item.id
            }
          }).then(function (res) {
            if(res.data.success){
              _this.$message({
                type: "success",
                message: "删除成功！"
              });
              _this.getResumeData();
            }

          }).catch(function (error) {
            console.log(error);
          });
        });
    }
  }
}
</script>

<style scoped lang="scss">
.people_main {
  height: 100%;
  padding-top: 5px;
  background: #f6f7f6;
}
.people_list > div {
  width: 240px;
  text-align: center;
}
.people_list > div .po_title {
  font-weight: bold;
  font-size: 18px;
  color: #505050;
}
.el-col-12 {
  width: 44%;
}
.el-center {
  text-align: center;
}
.rea_list {
  color: red;
  text-align: center;
  line-height: 25px;
  max-height: 300px;
  cursor: pointer;
  overflow: auto;
}
</style>