import { render, staticRenderFns } from "./People_sort.vue?vue&type=template&id=4a8df724&scoped=true&"
import script from "./People_sort.vue?vue&type=script&lang=js&"
export * from "./People_sort.vue?vue&type=script&lang=js&"
import style0 from "./People_sort.vue?vue&type=style&index=0&id=4a8df724&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a8df724",
  null
  
)

export default component.exports