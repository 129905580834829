<template>
    <div class="home">
        <nav class="navTab">
            <div :class="tabIndex==0?'isDivcom':''" @click="tabnavIndex(0)"><h2>友情链接</h2></div>
            <div :class="tabIndex==1?'isDivcom':''" @click="tabnavIndex(1)"><h2>联系信息</h2></div>
            <div :class="tabIndex==2?'isDivcom':''" @click="tabnavIndex(2)"><h2>关于我们</h2></div>
        </nav>
        <main class="el_table" style="margin: 15px 15px 0" v-if="tabIndex==0">
            <el-button type="danger" @click="openDialog" size="medium">新增</el-button>
            <el-table
                :data="tableData"
                style="width: 100%;margin: 20px 0"
                border>
                <el-table-column
                    prop="name"
                    label="链接名称"
                    align="center"
                    width="500">
                </el-table-column>
                <el-table-column
                    prop="linksUrl"
                    label="链接地址"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="sort"
                    label="显示排序"
                    align="center" width="100">
                </el-table-column>
                <el-table-column label="操作" align="center" width="450">
                    <template slot-scope="scope">
                        <el-button
                            size="medium"
                            @click="handleEditrow(scope.$index, scope.row)"
                            type="primary">
                            编辑
                        </el-button>
                        <el-button 
                            type="danger" 
                            size="medium"
                            @click="handleDel(scope.$index, scope.row)">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </main>
        <main class="el_table" style="margin: 15px 15px 0" v-if="tabIndex==1">
            <el-button type="primary" @click="editWebinfo" size="medium">保存</el-button>
            <el-row>
                <el-col :span="8">
                    <div class="m_top30">
                        <span class="ent_del">联系电话：</span> 
                        <el-input v-model="webInfo.contactTel" size="medium" style="width:70%" placeholder="请输入联系电话"></el-input>        
                    </div>
                    <div class="m_top10">
                        <span class="ent_del">联系邮箱：</span> 
                        <el-input v-model="webInfo.email" size="medium" style="width:70%" placeholder="请输入联系邮箱"></el-input>    
                    </div>
                    <div class="m_top10">
                        <span class="ent_del">邮箱编码：</span> 
                        <el-input v-model="webInfo.postalCode" size="medium" style="width:70%" placeholder="请输入联系邮箱"></el-input>    
                    </div>
                    <div class="m_top10">
                        <span class="ent_del">联系地址：</span> 
                        <el-input v-model="webInfo.contactAddr" size="medium" style="width:70%" placeholder="请输入联系地址"></el-input>    
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="m_top30">
                        <span class="ent_del">版权所有名称：</span> 
                        <el-input v-model="webInfo.copyName" size="medium" style="width:70%" placeholder="请输入版权所有名称"></el-input>    
                    </div>
                    <div class="m_top10">
                        <span class="ent_del">版权所有地址：</span> 
                        <el-input v-model="webInfo.copyUrl"  size="medium" style="width:70%" placeholder="请输入版权所有地址"></el-input>    
                    </div>
                   
                    <div class="m_top10">
                        <span class="ent_del">技术支持网址：</span> 
                        <el-input v-model="webInfo.technicalSupportUrl" size="medium" style="width:70%" placeholder="请输入技术支持网址"></el-input>    
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="m_top30">
                        <span class="ent_del">传 真：</span> 
                        <el-input v-model="webInfo.fax" size="medium" style="width:70%" placeholder="请输入传真"></el-input>    
                    </div>
                    <div class="m_top10">
                        <span class="ent_del">ICP备案号：</span> 
                        <el-input v-model="webInfo.copyIcpRecord" size="medium" style="width:70%" placeholder="请输入ICP备案号"></el-input>    
                    </div>
                    <div class="m_top10">
                        <span class="ent_del">技术支持：</span> 
                        <el-input v-model="webInfo.technicalSupport" size="medium" style="width:70%" placeholder="请输入技术支持"></el-input>    
                    </div>
                </el-col>
            </el-row>
            <div class="m_top30">
                <el-row>
                    <el-col :span="6">
                    <p class="ent_del">微信小程序二维码地址：</p> 
                    <el-upload
                        class="avatar-uploader m_top30"
                        :action="uploadImgUrl"
                        :show-file-list="false"
                        :on-success="wxImgMinSuccess">
                        <img v-if="webInfo.wxMiniQrCodeUrl" :src="webInfo.wxMiniQrCodeUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    </el-col>
                    <el-col :span="6">
                        <p class="ent_del">微信公众号二维码地址：</p> 
                        <el-upload
                            class="avatar-uploader m_top30"
                            :action="uploadImgUrl"
                            :show-file-list="false"
                            :on-success="wxImgMaxSuccess">
                            <img v-if="webInfo.wxSubQrCodeUrl" :src="webInfo.wxSubQrCodeUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-col>
                </el-row>
            </div>    
        </main>

      <main class="el_table" style="margin: 15px 15px 0" v-if="tabIndex==2">
        <el-button type="primary" @click="editWebinfo" size="medium">保存</el-button>
        <div class="m_top30">
          <el-row>
            <el-col :span="6">
              <p class="ent_del">关于我们图片上传：</p>
              <el-upload
                  class="avatar-uploader m_top30"
                  :action="uploadImgUrl"
                  :show-file-list="false"
                  :on-success="aboutUsSuccess">
                <img v-if="webInfo.aboutUsUrl" :src="webInfo.aboutUsUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-col>
          </el-row>
        </div>
      </main>


        <!-- 新增 -->
        <el-dialog
                :title="diaTitle"
                :visible.sync="dialogAdd"
                width="38%">
                <template>
                   <div class="m_top10">
                        <span class="ent_del">链接名称：</span> 
                        <el-input v-model="linksData.name" size="medium" style="width:70%" placeholder="请输入链接名称"></el-input>    
                   </div>
                   <div class="m_top10">
                        <span class="ent_del">链接地址：</span> 
                        <el-input v-model="linksData.linksUrl" size="medium" style="width:70%" placeholder="请输入链接地址"></el-input>    
                    </div>
                    <div class="m_top10">
                        <span class="ent_del">显示排序：</span> 
                        <el-input v-model="linksData.sort" size="medium" style="width:70%" 
                        placeholder="请输入排序" type="number" min="1"></el-input>    
                    </div>
                </template>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogAdd = false">取 消</el-button>
                    <el-button type="primary" @click="postAddData">提 交</el-button>
                </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name:'Web_info',
    data(){
        return{
            linksData:{},
            tabIndex:0,
            tableData:[],
            diaTitle:'',
            dialogAdd:false,

            webInfo:{},//基础信息部分
            uploadImgUrl:this.API.file.uploadSingle
        }
    },
    created() {
        this.loadtitleData();
        this.getwebdataInfo();
    },
    methods: {
        tabnavIndex:function(index){
            this.tabIndex=index;    
        },
        loadtitleData:function(){
            var _this=this;
            axios.get(this.API.friends_links.list,{
                    headers:{
                        token:this.Util.getjwtToken(),
                    }
                }).then(function (res) {
                    // console.log('链接',res)
                    if(res.data.success){
                        _this.tableData=res.data.data;
                    }
                }).catch(function (error) {
                    console.log(error);
            });
        },
        //弹出框
        openDialog:function(){
            this.linksData={};
            this.diaTitle="新增链接";
            this.dialogAdd=true;
        },
        //编辑
        handleEditrow:function(index,row){
            this.diaTitle="编辑链接";
            this.linksData=JSON.parse(JSON.stringify(row));
            this.dialogAdd=true;
        },
        //删除
        handleDel:function(index,row){
            var _self=this;
                this.$confirm('此操作将永久删除该链接, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    _self.handleDelData(index,row);
                })
        },
        handleDelData:function(index,row){
            var _this=this;
                axios({
                    url:this.API.friends_links.delete,
                    method: 'delete',
                    params:{
                        id:row.id,
                    },
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                }).then((res)=>{
                    if(res.data.success){
                        _this.$message.success('删除成功!');
                        _this.tableData.splice(index, 1);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
        },
        //提交
        postAddData:function(){
            var _this=this;
            if(this.Util.isBlank(this.linksData.name)){
                this.$message.warning('请输入链接名称！');
                return   
            }
            if(this.Util.isBlank(this.linksData.name)){
                this.$message.warning('请输入链接地址！');
                return
            }
            if(this.Util.isBlank(this.linksData.sort)){
                this.$message.warning('请输入显示排序！');
                return
            }
            axios({
                    url:this.API.friends_links.editor,
                    method:'post',
                    data:this.linksData,
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                }).then((res)=> {
                    if(res.data.success){
                        console.log('成功',res.data.data);
                        _this.dialogAdd=false;
                        _this.linksData={};
                        _this.$message.success(res.data.msg);
                        _this.loadtitleData();
                    }
                }).catch(function (error) {
                    console.log(error);
            });
        },
        //获取基础信息
        getwebdataInfo:function(){
            var _this=this;
            axios.get(this.API.copyright.info,{
                headers:{
                    token:this.Util.getjwtToken(),
                }
                }).then(function (res) {
                    console.log('info',res)
                    if(res.data.success){
                        _this.webInfo=res.data.data;
                    }
                }).catch(function (error) {
                    console.log(error);
            });
        },
        //微信上传
        wxImgMinSuccess:function(res, file){
            if(res.success){
                this.$set(this.webInfo, 'wxMiniQrCodeUrl', res.data);
            }else{
                this.$message.error(res.msg);
            }
        },
        wxImgMaxSuccess:function(res,file){
            if(res.success){
                this.$set(this.webInfo, 'wxSubQrCodeUrl', res.data);
            }else{
                this.$message.error(res.msg);
            }
        },
        aboutUsSuccess:function(res,file){
            if(res.success){
              this.$set(this.webInfo, 'aboutUsUrl', res.data);
          }else{
              this.$message.error(res.msg);
          }
      },
        //编辑基本数据
        editWebinfo:function(){
            var temp=(this.webInfo.contactAddr&&
                this.webInfo.contactTel&&this.webInfo.copyIcpRecord&&
                this.webInfo.copyName&&this.webInfo.copyUrl&&
                this.webInfo.email&&this.webInfo.fax&&
                this.webInfo.postalCode&&this.webInfo.technicalSupport&&
                this.webInfo.technicalSupportUrl);
                console.log(temp,this.webInfo);
                if(!temp){
                    this.$message.error('输入不能为空哦！');
                    return;
                }
            axios({
                    url:this.API.copyright.editor,
                    method:'post',
                    data:this.webInfo,
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                }).then((res)=> {
                    if(res.data.success){
                        this.$message.success(res.data.msg);
                    }
                }).catch(function (error) {
                    console.log(error);
            });
        }


    },
}
</script>
<style lang="scss" scoped>
.isDivcom{
     color:#000;
     border-bottom: 2px solid #000000;
 }

</style>