<template>
  <div class="home">
    <div class="tilte_btn">
      <span class="lineY"></span>
      <span><h1>注册企业审核</h1></span>
    </div>
    <nav class="nav_sech" style="justify-content: space-between;">
      <div>
        <el-date-picker
          class="el_input"
          size="medium"
          @change="changesItem"
          v-model="queryform.time"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="注册时间"
        >
        </el-date-picker>
        <el-select
          class="el_input"
          @change="chnangeNature"
          clearable
          size="medium"
          v-model="queryform.natures"
          placeholder="企业性质"
        >
          <el-option
            v-for="item in entNatureArry"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
        <el-select
          class="el_input"
          @change="chnangeScaleEnt"
          clearable
          size="medium"
          v-model="queryform.scaleEnt"
          placeholder="企业规模"
        >
          <el-option
            v-for="item in entScaleEntArry"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
        <el-select
          class="el_input"
          @change="chnangeAuditState"
          clearable
          size="medium"
          v-model="queryform.auditStateShow"
          placeholder="企业认证"
        >
          <el-option
            v-for="item in auditStateArry"
            :label="item.label"
            :value="item.value"
            :key="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-right:15px">
        <el-input
          size="medium"
          placeholder="请输入企业名称"
          v-model="queryform.keywords"
          clearable
        >
          <el-button class="searchBtn" slot="append" @click="searchName"
            >搜索</el-button
          >
        </el-input>
      </div>
    </nav>
    <div class="inte"></div>
    <main class="el_table" style="padding:15px">
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%;margin-bottom: 20px"
        border
      >
        <el-table-column prop="name" label="公司名称" align="center" min-width="200">
        </el-table-column>
        <el-table-column
          prop="natures"
          label="企业性质"
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="scaleEnt"
          label="企业规模"
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="createtime"
          label="注册时间"
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column label="企业认证" align="center" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.auditState == -1">全部</span>
            <span v-if="scope.row.auditState == 0">待审核</span>
            <span style="color:red" v-if="scope.row.auditState == 1"
              >审核失败</span
            >
            <span style="color:blue" v-if="scope.row.auditState == 2"
              >审核成功</span
            >
            <span v-if="scope.row.auditState == 3">再次提交</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="updatetime"
          label="认证时间"
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <span
              v-if="
                scope.row.auditState == -1 ||
                  scope.row.auditState == 0 ||
                  scope.row.auditState == 3
              "
            >
              <el-button
                type="danger"
                size="medium"
                @click="handleEdit(scope.$index, scope.row)"
                >查看详情</el-button
              >
              <el-button
                v-if="userType != 1"
                type="warning"
                size="medium"
                @click="getexamine(scope.$index, scope.row)"
                >通过审核</el-button
              >
              <el-button
                v-if="userType != 1"
                type="info"
                size="medium"
                @click="getReject(scope.$index, scope.row)"
                >驳回企业</el-button
              >
            </span>
            <span v-if="scope.row.auditState == 1 || scope.row.auditState == 2">
              <el-button
                type="danger"
                size="medium"
                @click="handleEdit(scope.$index, scope.row)"
                >查看详情</el-button
              >
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div style="height:60px;text-align: center;">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="10"
          :current-page="queryform.page"
          layout="total, sizes, prev, pager, next, jumper"
          :total="connt"
        >
        </el-pagination>
      </div>
    </main>
    <el-dialog
      title="请输入驳回原因"
      :visible.sync="dialogOutexamine"
      width="30%"
    >
      <div class="OutText">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          placeholder="请输入内容/100字内"
          v-model="auditmsg"
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogOutexamine = false">取 消</el-button>
        <el-button type="primary" @click="OutexamineData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Reg_organs',
  data() {
    return {
      queryform: {
        createtimeStart: '',
        createtimeEnd: '',
        natures: '',
        auditState: '-1',
        scaleEnt: '',
        keywords: '',
        page: 1,
        limit: 10,
      }, //搜索数据

      dialogOutexamine: false, //驳回
      auditmsg: '',
      loading: true,
      entNatureArry: [], //企业性质
      entScaleEntArry: [], //企业规模
      auditStateArry: [], //企业认证
      tableData: [],
      userType: 0,
      connt: null, //分页操作
    };
  },
  created() {
    this.userType = this.Util.getUserType();
    this.getEntNature();
    this.getEntScaleEnt();
    this.getTableData();
    this.auditStateArry = this.Util.auditStateAdd;
  },
  mounted() {
    //  this.$refs.box.scrollTop=0;
    //  console.log('this',this.$refs.box,this.$refs.box.scrollTop)
  },
  methods: {
    getTableData: function() {
      var _self = this;
      const loading = this.$loading({
        lock: true,
        text: 'loading...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)',
      });
      axios
        .get(_self.API.aent.regEntAuditList, {
          headers: {
            token: _self.Util.getjwtToken(),
          },
          params: _self.queryform,
        })
        .then(function(pores) {
          if (pores.data.success) {
            // console.log('列表',pores);
            if (pores.data.data) {
              _self.tableData = pores.data.data.list;
              _self.connt = pores.data.data.pager.recordCount;
            }
          }
          _self.loading = false;
          loading.close();
        })
        .catch(function(error) {
          console.log(error);
          loading.close();
        });
    },
    //搜注册时间
    changesItem: function(e) {
      this.queryform.page = 1;
      if (e) {
        this.queryform.createtimeStart = e + ' 00:00:00';
        this.queryform.createtimeEnd = e + ' 23:59:59';
      } else {
        this.queryform.createtimeStart = '';
        this.queryform.createtimeEnd = '';
      }
      this.getTableData();
    },
    //搜企业性质
    chnangeNature: function(e) {
      this.queryform.page = 1;
      this.getTableData();
    },
    //搜企业规模
    chnangeScaleEnt: function(e) {
      this.queryform.page = 1;
      this.getTableData();
    },
    chnangeAuditState: function(e) {
      var _self = this;
      if (this.Util.isBlank(e) && e !== 0) {
        _self.queryform.auditState = '-1';
      } else {
        _self.queryform.auditState = e;
      }
      this.queryform.page = 1;
      _self.getTableData();
    },
    //收关键字
    searchName: function() {
      if (!this.queryform.keywords) {
        this.$message.error('请输入关键字');
        return;
      }
      this.queryform.page = 1;
      this.getTableData();
    },
    //获取企业性质
    getEntNature: function() {
      var _self = this;
      axios
        .get(_self.API.baseEn.list, {
          headers: {
            token: _self.Util.getjwtToken(),
          },
        })
        .then(function(pores) {
          if (pores.data.success) {
            if (pores.data.data) {
              _self.entNatureArry = pores.data.data;
            }
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    //获取企业规模
    getEntScaleEnt: function() {
      var _self = this;
      axios
        .get(_self.API.baseEcs.list, {
          headers: {
            token: _self.Util.getjwtToken(),
          },
        })
        .then(function(pores) {
          if (pores.data.success) {
            if (pores.data.data) {
              _self.entScaleEntArry = pores.data.data;
            }
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    //去详情
    handleEdit: function(index, row) {
      this.$parent.pullItem('企业详情', 'EnterpriseDetail', row);
    },
    //通过审核
    getexamine: function(index, row) {
      var _this = this;
      this.$confirm('该企业将通过审核, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          _this.getexamineData(row.id, '2');
        })
        .catch(() => {});
    },
    getexamineData: function(eid, state) {
      var _self = this;
      axios
        .get(_self.API.aent.audit, {
          headers: {
            token: _self.Util.getjwtToken(),
          },
          params: {
            auditState: state,
            id: eid,
            auditmsg: _self.auditmsg,
          },
        })
        .then(function(pores) {
          if (pores.data.success) {
            _self.$message.success(pores.data.msg);
            _self.auditmsg = '';
            _self.getTableData();
            _self.dialogOutexamine = false;
          } else {
            _self.$message.error(pores.data.msg);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    //驳回
    getReject: function(index, row) {
      this.dialogOutexamine = true;
      this.outId = row.id;
    },
    OutexamineData: function() {
      //  if(!this.auditmsg){
      //     this.$message.error('请输入驳回原因');
      //     return ;
      //  }else{
      //   this.getexamineData(this.outId,'1')
      //  }
      this.getexamineData(this.outId, '1');
    },
    //分页操作
    handleSizeChange: function(e) {
      this.queryform.limit = e;
      this.getTableData();
    },
    handleCurrentChange: function(e) {
      this.queryform.page = e;
      this.getTableData();
    },
  },
};
</script>

<style scoped lang="scss">
.tilte_btn {
  background-color: #ffffff;
}
.Table {
  background-color: #999;
}
.el-input__icon {
  cursor: pointer;
}
</style>
