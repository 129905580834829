<template>
	<div class="home">
		<el-table
			:data="tableData"
			style="width: 100%"
			row-key="id"
			lazy
			:load="load"
      :span-method="arraySpanMethod"
			:tree-props="{children: 'children', hasChildren: 'hasChildren'}">
			<el-table-column label="分类名称">
				<template slot-scope="scope">
          <span @click="addjtAllView(scope)" v-if="scope.row.isshowAddBtn" style="color:#ff6600;font-size:18px;cursor:pointer;">
            <i class="el-icon-circle-plus"></i>
            添加分类
          </span>
          <span v-else>
            <el-input v-if="!scope.row.isedit && !scope.row.isadd" :readonly="true" style="width:320px" v-model="scope.row.name" placeholder="请输入内容"></el-input>
            <el-input v-else style="width:320px" v-model="scope.row.name" placeholder="请输入内容"></el-input>
            
            <!-- <span> 
              (id:{{scope.row.id?scope.row.id:'无'}},别名: 
              <span v-if="!scope.row.isedit && !scope.row.isadd">{{scope.row.alias}}</span>
              <el-input  v-else style="width:220px" v-model="scope.row.alias" placeholder="请输入别名"></el-input>
              )
            </span> -->
          </span>
        </template>
			</el-table-column>

			<el-table-column label="排序" width="120px">
        <template slot-scope="scope">
          <el-input v-if="!scope.row.isedit && !scope.row.isadd" :readonly="true" style="width:80px" v-model="scope.row.asort" placeholder="排序"></el-input>
          <el-input v-else  style="width:80px" v-model="scope.row.asort" placeholder="排序"></el-input>
        </template>
      </el-table-column>
			<el-table-column label="操作" width="360px">
        <template slot-scope="scope">
          <el-button @click="okEvt(scope.row)" v-if="scope.row.isedit || scope.row.isadd" type="success" size="medium">确定</el-button>
          <el-button @click="delRowEvt(scope.row)" type="warning" size="medium" v-if="scope.row.isedit || scope.row.isadd">取消</el-button>  
          <el-button v-if="!scope.row.isedit && !scope.row.isadd" @click="editEvt(scope.row)" type="primary" size="medium">修改</el-button>
          <el-button v-if="!scope.row.isedit && !scope.row.isadd" @click="delEvt(scope.row)" type="danger" size="medium">删除</el-button>
        </template>
      </el-table-column>
		</el-table>
	</div>
</template>

<script>
export default {
	name: "Ent_major",
	data() {
		return {
			tableData:[]
		};
	},
	created() {
    var _self = this;
    this.findData('0', function(data){
      for(var i = 0; i < data.length; i ++){
        data[i].hasChildren = true;
        data[i].isedit = false;
        data[i].isadd = false;
      }
      data.push({
        id: "test" + Math.random(),
        pid: '0',
        isshowAddBtn: true,
        hasChildren: false
      });
      _self.tableData = data;
    })
  },
	methods: {
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (row.isshowAddBtn) {
        if (columnIndex === 0) {
          return {
            rowspan: 1,
            colspan: 3
          };
        }else{
           return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    //当点击添加分类的时候在相应的行中添加一行填写记录
    addjtAllView: function(scope){
      var row = scope.row;
      if(row.pid == "0"){
        var item = this.tableData.splice(this.tableData.length - 1, 1);
        this.tableData.push({
          id: "test" + Math.random(),
          alias: "",
          asort: 0,
          name: "",
          pid: row.pid,
          searchShow: 0,
          isadd: true,
          isedit: false,
          hasChildren: false
        });
        this.tableData.push(item[0]);
      }else{
        this.addOneRow(this.tableData, row.pid);
      }
      
    },
    //当点击添加分类的时候在相应的行中添加一行填写记录
    addOneRow: function(tables, pid){
      if(tables && tables.length > 0){
        for(var i = 0; i < tables.length; i ++){
          if(tables[i].id == pid){
            var item = tables[i].children.splice(tables[i].children.length - 1, 1);
            tables[i].children.push({
              id: "test" + Math.random(),
              alias: "",
              asort: 0,
              name: "",
              pid: pid,
              searchShow: 0,
              isadd: true,
              isedit: false,
              hasChildren: false
            });
            tables[i].children.push(item[0]);
            return;
          }else{
            this.addOneRow(tables[i].children, pid);
          }
        }
      }
    },
    //取消
    delRowEvt:function(data){
		this.deleteTable(this.tableData, data.id);
    },
    okEvt: function(data){
      var _this=this;
      if(!data.name){
         _this.$message.error('分类名称不能为空');
        return;
      }
    //   if(!data.alias){
    //      _this.$message.error('分类别名不能为空');
    //     return;
    //   }
      if(_this.isSubmit){
        return;
      }
      _this.isSubmit = true;
      axios({
          url: data.isadd ? this.API.jtAll.add:this.API.jtAll.update,
          method: 'post',
          data: data,
          headers:{
              token:this.Util.getjwtToken(),
          },
      }).then((res)=>{
          if(res.data.success){
            _this.$message(res.data.msg);
            //不能直接等于
            for(let index in res.data.data) {  
                data[index] = res.data.data[index];
            };
            data.isadd = false;
            data.isedit = false;
            data.hasChildren = true;
          }else{
            _this.$message.error(res.data.msg);
          }
          _this.isSubmit = false;
      }).catch(function (error) {
          _this.isSubmit = false;
      });
    },
    editEvt: function(data){
      data.isadd = false;
      data.isedit = true;
      data.hasChildren = false;
    },
    delEvt: function(data){
      var _this = this;
      axios({
          url: this.API.jtAll.delete,
          method: 'delete',
          params: {
            id: data.id
          },
          headers:{
              token:this.Util.getjwtToken(),
          },
      }).then((res)=>{
          if(res.data.success){
            _this.$message(res.data.msg);
            _this.deleteTable(_this.tableData, data.id);
          }else{
            _this.$message.error(res.data.msg);
          }
      })
    },
    deleteTable: function(table, id){
      if(table && table.length > 0){
        for(var i = 0 ; i < table.length; i ++){
          if(table[i].id == id){
            table.splice(i, 1);
          }else{
            this.deleteTable(table[i].children, id);
          }
        }
      }
    },
    findData: function(id, callback){
      axios.get(this.API.jtAll.findByPid,{
          headers:{
              token:this.Util.getjwtToken(),
          },
          params:{
            id: id,
          }
      }).then(function (res) {
          if(res.data.success){
              callback(res.data.data);

          }else{
            callback([]);
            console.log("jtAll.findData", res);
          }
      }).catch(function (error) {
          console.log(error);
      });
    },
		load(tree, treeNode, resolve) {
      var _self = this;
      this.findData(tree.id, function(data){
        for(var i = 0; i < data.length; i ++){
          data[i].hasChildren = true;
          data[i].isedit = false;
          data[i].isadd = false;
        }
        data.push({
          id: "test" + Math.random(),
          pid: tree.id,
          isshowAddBtn: true,
          hasChildren: false
        });
        tree.children = data;
        resolve(data);
      })
		}
	}
};
</script>
<style lang="scss" scoped>

</style>