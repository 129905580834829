import { render, staticRenderFns } from "./Recruit.vue?vue&type=template&id=a66566d6&scoped=true&"
import script from "./Recruit.vue?vue&type=script&lang=js&"
export * from "./Recruit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a66566d6",
  null
  
)

export default component.exports