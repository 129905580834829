/**
 * 各种画echarts图表的方法都封装在这里
 * 注意：这里echarts没有采用按需引入的方式，只是为了方便学习
 */
import echarts from 'echarts';


const install = function(Vue) {
    Object.defineProperties(Vue.prototype, {
        $chart: {
            get() {
                return {
                    //区域内
                    regional: function (id,data) {
                    this.chart = echarts.init(document.getElementById(id));
                    this.chart.clear();
                    const optionData = {
                        color:['#6b73cf','#e2e4ef'],
                        series: [
                            {
                                name: '访问来源',
                                type: 'pie',
                                radius: ['60%', '70%'],
                                center: ['50%', '50%'],
                                avoidLabelOverlap: false,
                                hoverAnimation:false,
                                label: {
                                    normal: {
                                        show: true,
                                        position: 'center',
                                        textStyle: {
                                            fontSize: '18',
                                            fontWeight: 'bold'
                                        },
                                        formatter:'{d}%'
                                    },
                                },
                                labelLine: {
                                    normal: {
                                        show: false
                                    }
                                },
                                data:[{
                                    value:data.cityIn,
                                    name:'www',
                                    label:{
                                        normal:{
                                            show:true
                                        }
                                    }
                                },{
                                    value:(data.cityTotal-data.cityIn),
                                    name:'eee',
                                    label:{
                                        normal:{
                                            show:false
                                        }
                                    }
                                }]
                            }
                        ]};
                        this.chart.setOption(optionData);
                    },
                    //区域外
                    Outregion:function(id,data){
                    this.chart = echarts.init(document.getElementById(id));
                    this.chart.clear();
                    const optionData = {
                        color:['#7c9cff','#e2e4ef'],
                        series: [
                            {
                                name: '访问来源',
                                type: 'pie',
                                radius: ['60%', '70%'],
                                center: ['50%', '50%'],
                                avoidLabelOverlap: false,
                                hoverAnimation:false,
                                label: {
                                    normal: {
                                        show: true,
                                        position: 'center',
                                        textStyle: {
                                            fontSize: '18',
                                            fontWeight: 'bold'
                                        },
                                        formatter:'{d}%'
                                    },
                                },
                                labelLine: {
                                    normal: {
                                        show: false
                                    }
                                },
                                data:[{
                                    value:data.cityOut,
                                    name:'www',
                                    label:{
                                        normal:{
                                            show:true
                                        }
                                    }
                                },{
                                    value:(data.cityOutTotal-data.cityOut),
                                    name:'eee',
                                    label:{
                                        normal:{
                                            show:false
                                        }
                                    }
                                }]
                            }
                        ]};
                        this.chart.setOption(optionData);
                    },
                    //省外
                    provinceOut:function(id,data){
                    this.chart = echarts.init(document.getElementById(id));
                    this.chart.clear();
                    const optionData = {
                        color:['#ca90ff','#e2e4ef'],
                        series: [
                            {
                                name: '访问来源',
                                type: 'pie',
                                radius: ['60%', '70%'],
                                center: ['50%', '50%'],
                                avoidLabelOverlap: false,
                                hoverAnimation:false,
                                label: {
                                    normal: {
                                        show: true,
                                        position: 'center',
                                        textStyle: {
                                            fontSize: '18',
                                            fontWeight: 'bold'
                                        },
                                        formatter:'{d}%'
                                    },
                                },
                                labelLine: {
                                    normal: {
                                        show: false
                                    }
                                },
                                data:[{
                                    value:data.province,
                                    name:'www',
                                    label:{
                                        normal:{
                                            show:true
                                        }
                                    }
                                },{
                                    value:data.provinceTotal-data.province,
                                    name:'eee',
                                    label:{
                                        normal:{
                                            show:false
                                        }
                                    }
                                }]
                            }
                        ]};
                        this.chart.setOption(optionData);
                    },
                    //就业方式
                    jobmode:function(id,data){
                        this.chart = echarts.init(document.getElementById(id)); 
                        this.chart.clear();
                        console.log('方式',data)
                        const optionData ={
                            title: {
                                subtext: '（人）'
                            },
                            color: ['#6b72cf'],
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                                    type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                                }
                            },
                            grid: {
                                left: '8%',
                                right: '8%',
                                top: '10%',
                                bottom:'15%',
                                containLabel: true
                            },
                            xAxis: [
                                {
                                    type: 'category',
                                    data: data.takePays,
                                    axisLabel:{
                                        show:true,
                                        interval:0,
                                        formatter:function(val){
                                          if(val.length>10){
                                              val=val.substring(0,10)+'...'
                                          }
                                          return val
                                       },
                                    }, 
                                    axisTick: {
                                        show:false,
                                    },
                                    axisLine:{
                                       show:false, 
                                    }
                                }
                            ],
                            yAxis: [
                                {
                                    type: 'value',
                                    axisTick: {
                                        show:false,
                                    },
                                    axisLine:{
                                        show:false, 
                                     }
                                }
                            ],
                            series: [
                                {
                                    name: '统计人数',
                                    type: 'bar',
                                    barWidth: '20%',
                                    data: data.persons
                                }
                            ]};
                            this.chart.setOption(optionData);
                    },
                    //省外就业地区
                    jobprovinceOut:function(id,data){
                        this.chart = echarts.init(document.getElementById(id)); 
                        this.chart.clear();
                        console.log('省外就业地区',data);
                        var tempVal=[];
                        var num=0;
                        if(data.Persons&&data.takePays){
                            for(var i=0;i<data.Persons.length;i++){
                                num=num+data.Persons[i];
                            }
                            for(var j=0;j<data.Persons.length;j++){
                               var lendShow=data.Persons[j]*100/num;
                               tempVal.push({
                                    value:data.Persons[j],
                                    name:data.takePays[j]+' '+Math.floor(lendShow*100)/100+'%'+' '+data.Persons[j]+'人'
                                })
                            }     
                        }else{
                           return false 
                        }
                        const optionData ={
                            tooltip: {
                                trigger: 'item',
                                formatter: '{a} <br/>{b}:{d}%'
                            },
                            color:['#ca90ff','#87b9ff','#ffbc4c','#6b72cf','#68bc99'],
                            legend: {
                                orient: 'vertical',
                                left: 300,
                                bottom:'center',
                                itemWidth:10,
                                itemHeight:10,
                                textStyle:{
                                    fontSize:'15'
                                },
                            },
                            series: [
                                {
                                    name: '访问来源',
                                    type: 'pie',
                                    radius: ['55%', '65%'],
                                    center: ['30%', '50%'],
                                     avoidLabelOverlap: false,
                                     hoverAnimation:false,
                                    label: {
                                        normal: {
                                            show: false,
                                            position: 'center'
                                        },
                                        emphasis: {
                                            show: true,
                                            textStyle: {
                                                fontSize: '30',
                                                fontWeight: 'bold'
                                            }
                                        }
                                    },
                                    labelLine: {
                                        normal: {
                                            show: false
                                        }
                                    },
                                    data:tempVal
                                }
                            ] 
                        };
                        this.chart.setOption(optionData); 
                    },
                    //受欢迎的岗位
                    WelcomeJob:function(id,data){
                        this.chart = echarts.init(document.getElementById(id)); 
                        this.chart.clear();
                        console.log('欢迎',data);
                        const optionData ={
                            tooltip: {
                                trigger: 'item',
                                formatter: '{a} <br/>{b}: {c}'
                            },
                            title: {
                                subtext: '单位（人）'
                            },
                            color: ['#6b72cf','#ca90ff'],
                            legend: {
                                data: ['申请岗位', '就业岗位'],
                                itemHeight:5,
                                right:28
                            },
                            grid: {
                                left: '8%',
                                right: '8%',
                                top: '15%',
                                bottom:'5%',
                                containLabel: true
                            },
                            xAxis: [
                                {
                                    type: 'category',
                                    data: data.jobs,
                                    axisLabel:{
                                        show:true,
                                        interval:0,
                                        formatter:function(val){
                                          if(val.length>6){
                                              val=val.substring(0,6)+'...'
                                          }
                                          return val
                                       },
                                    }, 
                                    axisTick: {
                                        show:false,
                                    },
                                    axisLine:{
                                       show:false, 
                                    }
                                }
                            ],
                            yAxis: [
                                {
                                    type: 'value',
                                    axisTick: {
                                        show:false,
                                    },
                                    axisLine:{
                                        show:false, 
                                     }
                                }
                            ],
                            series: [
                                {
                                    name: '申请岗位',
                                    type: 'bar',
                                    stack: '广告',
                                    barWidth: '20%',
                                    data: data.applys
                                },
                                {
                                    name: '就业岗位',
                                    type: 'bar',
                                    stack: '广告',
                                    barWidth: '20%',
                                    data: data.takes
                                },
                            ]};
                        this.chart.setOption(optionData);  
                    },
                    //岗位分类统计
                    jobclassify:function(id,data){
                        this.chart = echarts.init(document.getElementById(id)); 
                        this.chart.clear();
                        console.log('岗位data',data);
                        var NOtemp=[];
                        var num=0;
                        data.jobNums.sort(function (a, b) {
                            return a-b;
                        }); 
                        var max = data.jobNums[data.jobNums.length - 1]; 
                        for(var i=0;i<data.jobNums.length;i++){
                            NOtemp[i]=(max+4)-data.jobNums[i];
                            num=num+data.jobNums[i]
                        }
                        console.log('max',max,NOtemp);
                        const optionData ={
                            color:['#6b72cf','#e2e4ef'],
                            tooltip: {
                                trigger: 'item',
                                formatter:function(val){
                                     if(val.seriesIndex==0){
                                         return (val.name+'：'+val.value);  
                                     }else{
                                         return null
                                     }
                                }
                            },
                            grid: {
                                left: '8%',
                                right: '18%',
                                top: '10%',
                                bottom:'2%',
                                containLabel: true
                            },
                            xAxis: {
                                type: 'value',
                                show:false,
                            },
                            yAxis: {
                                type: 'category',
                                axisTick: {
                                    show:false,
                                },
                                axisLabel:{
                                    show:true,
                                    interval:0,
                                    formatter:function(val){
                                      if(val.length>10){
                                          val=val.substring(0,10)+'...'
                                      }
                                      return val
                                   },
                                }, 
                                axisLine:{
                                   show:false, 
                                },
                                data:data.cates
                            },
                            series: [
                                {
                                    name: '岗位分类',
                                    type: 'bar',
                                    stack: '总量',
                                    barWidth: '25%',
                                    itemStyle:{
                                       barBorderRadius:[5,0,0,5],
                                    },
                                    data: data.jobNums
                                },
                                {
                                    name: '邮件营销',
                                    type: 'bar',
                                    stack: '总量',
                                    itemStyle:{
                                        barBorderRadius:[0,5,5,0],
                                    },
                                    label: {
                                        show: true,
                                        position:'right',
                                        color:'#999',
                                        formatter:function(val){
                                           var showVal=(((max+4)-val.value)*100/num).toFixed(2)+'%';
                                           return showVal
                                        }
                                    }, 
                                    data: NOtemp
                                },
                            ]
                        };
                        this.chart.setOption(optionData);   
                    },
                    //岗位推荐趋势
                    jobRecom:function(id,data){
                        this.chart = echarts.init(document.getElementById(id)); 
                        this.chart.clear();
                        console.log('推荐Data',data);
                        if(!data){
                            return false
                        }
                        const optionData ={
                            tooltip: {
                                trigger: 'item',
                                formatter: '{a} <br/>{b}: {c}'
                            },
                            title: {
                                subtext: '单位（人）'
                            },
                            legend: {
                                data: ['岗位资源', '岗位推荐', '推荐成功'],
                                itemHeight:5,
                                right:28
                            },
                            grid: {
                                left: '8%',
                                right: '8%',
                                top: '15%',
                                bottom:'5%',
                                containLabel: true
                            },
                            xAxis: [
                                {
                                    type: 'category',
                                    boundaryGap: false,
                                    axisTick: {
                                        show:false,
                                    },
                                    axisLine:{
                                       show:false, 
                                    },
                                    data: data.cates
                                }
                            ],
                            yAxis: [
                                {
                                    type: 'value',
                                    axisTick: {
                                        show:false,
                                    },
                                    axisLine:{
                                       show:false, 
                                    },
                                }
                            ],
                            series: [
                                {
                                    name: '岗位资源',
                                    type: 'line',
                                    stack: '总量',
                                    areaStyle: {
                                        color:['#eaeef8']
                                    },
                                    lineStyle:{
                                        color:['#ffbc4c']
                                    },
                                    data:data.jobApplys
                                },
                                {
                                    name: '岗位推荐',
                                    type: 'line',
                                    stack: '总量',
                                    areaStyle: {
                                        color:['#ebe9e8']
                                    },
                                    lineStyle:{
                                        color:['#6b72cf']
                                    },
                                    data:data.jobRecomNums
                                },
                                {
                                    name: '推荐成功',
                                    type: 'line',
                                    stack: '总量',
                                    areaStyle: {
                                        color:['#e8e1e8']
                                    },
                                    lineStyle:{
                                        color:['#ca90ff']
                                    },
                                    data:data.jobRecomSuccessNums
                                }
                            ]
                        };
                        this.chart.setOption(optionData);
                    },
                    //简历详情页部分
                    Resability:function(id, data){
                        this.chart = echarts.init(document.getElementById(id)); 
                        this.chart.clear();
                        console.log("dddddd", data);
                         const optionData = {
                                title: {
                                    text: '求职能力画像'
                                },
                                tooltip: {},
                                radar: {
                                    center: ['50%', '55%'],
                                    name: {
                                        textStyle: {
                                            color: '#999',
                                            // backgroundColor: '#999',
                                            borderRadius: 2,
                                            padding: [1, 2]
                                        }
                                    },
                                    indicator: data
                                },
                                series: [{
                                    name: '求职能力画像',
                                    type: 'radar',
                                    data: [
                                        {
                                            value: [data[0].value
                                            , data[1].value, data[2].value, data[3].value
                                            , data[4].value, data[5].value],
                                            name: '画像',
                                            areaStyle:{
                                                color:'#409eff' 
                                            },
                                            itemStyle:{
                                                opacity:0
                                              },
                                              lineStyle:{
                                                opacity:0
                                            }
                                        }
                                    ]
                                }]
                            };
                        this.chart.setOption(optionData);
                    },
                    //性格评估
                    // ResAssess:function(id){
                    //     this.chart = echarts.init(document.getElementById(id)); 
                    //     var colorArry=['#fff','#fff','#fff','#fff','#fff','#e1e1e1'];
                    //     this.chart.clear();
                    //      const optionData = {
                    //             title: {
                    //                 text: '五维职业性格测评'
                    //             },
                    //             tooltip: {},
                    //             radar: {
                    //                 name: {
                    //                     textStyle: {
                    //                         color: '#999',
                    //                         borderRadius: 2,
                    //                         padding: [1, 2]
                    //                     }
                    //                 },
                    //                 center:['50%','60%'],
                    //                 splitLine:{
                    //                     lineStyle: {
                    //                        // 使用深浅的间隔色
                    //                        color: colorArry
                    //                    }
                    //                },
                    //                axisLine:{
                    //                     lineStyle:{
                    //                         color:'#e1e1e1'
                    //                     }
                    //                 },
                    //                 splitArea:{
                    //                     show:false//去分割区域
                    //                 },
                    //                 indicator: [
                    //                     { name: '稳定性', max: 6500},
                    //                     { name: '执着性', max: 16000},
                    //                     { name: '顺应性', max: 30000},
                    //                     { name: '创新性', max: 38000},
                    //                     { name: '外向性', max: 52000},   
                    //                 ]
                    //             },
                    //             series: [{
                    //                 name: '五维职业性格测评',
                    //                 type: 'radar',
                    //                 data: [
                    //                     {
                    //                         value: [4300, 10000, 28000, 35000, 50000],
                    //                         name: '预算分配（Allocated Budget）',
                    //                         areaStyle:{
                    //                             color:'#409eff' 
                    //                         },
                    //                         itemStyle:{
                    //                             opacity:0
                    //                           },
                    //                           lineStyle:{
                    //                             opacity:0
                    //                         }
                    //                     }
                    //                 ]
                    //             }]
                    //         };
                    //     this.chart.setOption(optionData);
                    // }              
                    // 多维度统计
                    deliverys:function(id, data) {
                        let xArry = [];
                        let sData = [];
                        for(var i=0;i<data.length;i++){
                            if(data[i].jobname.length>4){
                                data[i].jobname = data[i].jobname.substring(0,4)+'...';
                               xArry.push(data[i].jobname);
                            } else {
                                xArry.push(data[i].jobname)
                            }
                            sData.push(data[i].applytotal)
                        }
                        this.chart = echarts.init(document.getElementById(id)); 
                        this.chart.clear();
                        const optionData = {
                            xAxis: {
                                type: 'category',
                                data: xArry
                            },
                            tooltip: {},
                            grid:{
                                top:'10%'
                            },
                            yAxis: {
                                type: 'value'
                            },
                            series: [{
                                data: sData,
                                type: 'bar',
                                showBackground: true,
                                backgroundStyle: {
                                    color: 'rgba(220, 220, 220, 0.8)'
                                }
                            }]
                        }
                        this.chart.setOption(optionData);
                    },
                    hotEnts:function(id, data) {
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();
                        let sData = [];
                        for(var i=0; i<data.length;i++){
                            sData.push({
                                value:data[i].applytotal,
                                name:data[i].enterprisename
                            })
                        }
                        const optionData = {
                            tooltip: {
                                trigger: 'item',
                                formatter: '{a} <br/>{b} : {c}'
                            },
                            series: [
                                {
                                    name: '大数据统计',
                                    type: 'pie',
                                    radius: '55%',
                                    center: ['50%', '45%'],
                                    data: sData,
                                    emphasis: {
                                        itemStyle: {
                                            shadowBlur: 10,
                                            shadowOffsetX: 0,
                                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                                        }
                                    }
                                }
                            ]
                        }
                        this.chart.setOption(optionData);
                    },
                    //最热门的职位
                    hotjobs(id, data) {
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();
                        let xArry = [];
                        let sData = [];
                        for(var i=0;i<data.length;i++){
                            if(data[i].jobname.length>4){
                                data[i].jobname = data[i].jobname.substring(0,4)+'...';
                               xArry.push(data[i].jobname);
                            } else {
                                xArry.push(data[i].jobname)
                            }
                            sData.push(data[i].fabulousNum)
                        }
                        const optionData = {
                            color: ['#3398DB'],
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                                    type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                                }
                            },
                            grid: {
                                top:'10%',
                                containLabel: true
                            },
                            xAxis: [
                                {
                                    type: 'category',
                                    data: xArry,
                                    axisTick: {
                                        alignWithLabel: true
                                    }
                                }
                            ],
                            yAxis: [
                                {
                                    type: 'value'
                                }
                            ],
                            series: [
                                {
                                    name: '直接访问',
                                    type: 'bar',
                                    barWidth: '60%',
                                    data: sData
                                }
                            ]
                        };
                        this.chart.setOption(optionData);
                    },
                    //最热门的行业
                    hotjobsInds(id,data) {
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();
                        let sData = [];
                        for(var i=0; i<data.length;i++){
                            sData.push({
                                value:data[i].applytotal,
                                name:data[i].indtypeNames
                            })
                        }
                        const optionData = {
                            tooltip: {
                                trigger: 'item',
                                formatter: '{a} <br/>{b} : {c}'
                            },
                            series: [
                                {
                                    name: '大数据统计',
                                    type: 'pie',
                                    radius: '55%',
                                    center: ['50%', '45%'],
                                    data: sData,
                                    emphasis: {
                                        itemStyle: {
                                            shadowBlur: 10,
                                            shadowOffsetX: 0,
                                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                                        }
                                    }
                                }
                            ]
                        }
                        this.chart.setOption(optionData);
                    },
                    //同期对比
                    contrast(id, data) {
                        this.chart = echarts.init(document.getElementById(id));
                        this.chart.clear();
                        var app = {};
                        app.config = {
                            rotate: 90,
                            align: 'left',
                            verticalAlign: 'middle',
                            position: 'insideBottom',
                            distance: 15,
                            onChange: function () {
                                var labelOption = {
                                    normal: {
                                        rotate: app.config.rotate,
                                        align: app.config.align,
                                        verticalAlign: app.config.verticalAlign,
                                        position: app.config.position,
                                        distance: app.config.distance
                                    }
                                };
                                myChart.setOption({
                                    series: [{
                                        label: labelOption
                                    }, {
                                        label: labelOption
                                    }, {
                                        label: labelOption
                                    }, {
                                        label: labelOption
                                    }]
                                });
                            }
                        };
                        var labelOption = {
                            show: true,
                            position: app.config.position,
                            distance: app.config.distance,
                            align: app.config.align,
                            verticalAlign: app.config.verticalAlign,
                            rotate: app.config.rotate,
                            formatter: '{c}  {name|{a}}',
                            fontSize: 16,
                            rich: {
                                name: {
                                    textBorderColor: '#fff'
                                }
                            }
                        };
                        
                        const optionData = {
                            title: {
                                text: '岗位投递量同期对比'
                            },
                            color: ['#003366', '#4cabce', '#006699', '#e5323e'],
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow'
                                }
                            },
                            legend: {
                                //data: ['本期岗位投递数', '同期岗位投递数', '本期职位数量', '同期职位数量']
                                data: ['本期岗位投递量', '同期岗位投递量']
                            },
                            toolbox: {
                                show: true,
                                orient: 'vertical',
                                left: 'right',
                                top: 'center',
                                feature: {
                                    mark: {show: true},
                                    dataView: {show: true, readOnly: false},
                                    magicType: {show: true, type: ['line', 'bar', 'stack', 'tiled']},
                                    restore: {show: true},
                                    saveAsImage: {show: true}
                                }
                            },
                            xAxis: [
                                {
                                    type: 'category',
                                    axisTick: {show: false},
                                    data: data.months
                                }
                            ],
                            yAxis: [
                                {
                                    type: 'value'
                                }
                            ],
                            series: [
                                {
                                    name: '本期岗位投递量',
                                    type: 'bar',
                                    barGap: 0,
                                    label: labelOption,
                                    data: data.nowYearData
                                },
                                {
                                    name: '同期岗位投递量',
                                    type: 'bar',
                                    label: labelOption,
                                    data: data.lastYearData
                                }
                            ]
                        }
                        this.chart.setOption(optionData);
                    }
                }
            }
        }
    })
}

export default {
    install
}
