<template>
    <div class="home">
        <div class="tilte_btn">
            <span class="lineY"></span>
            <span>
                <h2>设置展位</h2>
            </span>
        </div>
        <div style="padding:10px">
            <span class="ent_del">1.添加展区分布：（如：A区填A，B区填B）</span>
            <div class="m_top20">
               <el-button @click="changesRmlist" type="" v-for="(item,index) in tabRmlistArry" :key="index">{{item.rmArea}}区</el-button>
               <el-input v-show="showInput" @blur="changeRminput" v-model="tabRmlist" class="tag_input"></el-input>
               <el-button style="margin-left:15px" @click="tabRmlistAdd">+添 加</el-button>
            </div>
        </div>
        <div style="padding:10px">
            <span class="ent_del">2.设置展区的展位号</span>
            <div class="m_top20" v-for="(item,index) in tabRmlistArry" :key="index">
                <span>{{item.rmArea}}区：</span>
                <el-input @blur="genRmBootchNo" v-model="item.startbno" class="tag_input" placeholder="开始展位号"></el-input> 至
                <el-input @blur="genRmBootchNo" v-model="item.endbno" style="width:100px" placeholder="结束展位号"></el-input>
                <span class="m_fl15">(如填写“1-10”,系统将自动生成10个展位,并且编号由1开始、由10结束)</span>
            </div>
            <div class="m_top20" v-if="tabRmlistArry.length<=0">请先添加展区</div>
        </div>
        <div style="padding:10px">
           <span class="ent_del">3.请上传展位图</span>
           <div class="mapBox">
                <el-upload
                    class="avatar-uploader"
                    :action="uploadImgUrl"
                    :show-file-list="false"
                    :on-success="handleRmAreaPicSuccess">
                    <img v-if="rmAreaPicUrl" :src="rmAreaPicUrl" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </div>
        </div>
        <div class="tilte_btn">
            <span class="lineY"></span>
            <span>
                <h2>展位一览</h2>
            </span>
        </div>
        <div class="rmba">
            展位预定状态说明: 
            <el-button type="" class="c1"></el-button>可预订 
            <el-button type="info" class="c2" ></el-button>已预订 
            <el-button type="danger" class="c3"></el-button>暂停预定
        </div>
        <div style="padding:10px">
            <div class="m_top20">
               <el-button @click="selectRmlist(index)" :type="selectRmareaIndex==index?'warning':''" v-for="(item,index) in tabRmlistArry" :key="index">{{item.rmArea}}区</el-button>
            </div>
        </div>
        <div style="padding:10px;margin-bottom: 120px">
            <div class="m_top20 acs" v-if="tabRmBootchlistArry && tabRmBootchlistArry.length > 0">
                <span v-for="(item,index) in tabRmBootchlistArry" :key="index">
                    <el-popover
                        v-if="item"
                        placement="top"
                        trigger="hover">
                        <div class="ac_dialog">
                            <div class="acd_header" v-if="item.status==1">状态：可预订</div>
                            <div class="acd_content" v-if="item.status == 1">
                                <el-button type="danger" size="mini" @click="stopYuding(index)">暂停预定</el-button>
                                <el-button style="margin-left: 5px" @click="deleteBootch(index)" type="danger" size="mini">删除展位</el-button>
                            </div>

                            <div class="acd_header" v-if="item.status==2">状态：已预订</div>
                            <div class="acd_content" v-if="item.status == 2">
                                <span>{{item.entname}}</span>
                                <el-button @click="goEntdetail(item)" style="margin-left: 5px" type="danger" size="mini">查看详情</el-button>
                            </div>
                            <div v-if="item.status==2">
                                <p v-for="(list,key) in entjobinfo" :key="key">{{list.jobname}}
                                    <span style="color: red;" v-if="list.minSalary==-1&&list.maxSalary==-1">面议</span>
                                    <span style="color: red;" v-else-if="list.minSalary==-1">{{list.maxSalary}}元以下</span>    
                                    <span style="color: red;" v-else-if="list.maxSalary==-1">{{list.minSalary}}元以上</span>
                                    <span style="color: red;" v-else>{{list.minSalary}}-{{list.maxSalary}}元</span>

                                </p>
                            </div>
                            <div class="acd_header" v-if="item.status==3">状态：暂停预定</div>
                        </div>
                        <el-button slot="reference" type="" class="area_card" v-if="item.status==1">{{item.bno}}</el-button>
                        <el-button slot="reference" type="info" class="area_card" @mouseenter.native="getEntInfo(item.id)" v-else-if="item.status==2">{{item.bno}}</el-button> 
                        <el-button slot="reference" type="danger" class="area_card" v-else-if="item.status==3">{{item.bno}}</el-button>
                        <el-button slot="reference" type="" class="area_card" v-else>
                            {{item.bno}}
                        </el-button>
                    </el-popover>
                </span>
            </div>
        </div>
    </div>
</template>
<script>

import qs from 'qs';

export default {
    name:'Recruit_BoothEdit',
    data(){
        return{
            uploadImgUrl: this.API.file.uploadSingle,
            tabRmlist:'',//展区
            rmAreaNo: {
               start: 1,
               end: 10
            },
            rmAreaPicUrl: '',
            rmData: {}, //招聘会信息
            showInput:false,
            selectRmareaIndex: 0,
            tabRmlistArry: [], //展区
            tabRmBootchlistArry:[], //展区的展位

            entjobinfo:[]
        }
    },
    /*props: {
        comParam: Object
    },*/
    created(){
        this.comParam = this.$router.currentRoute.query;
        this.rmData = this.comParam;
        console.log('rm', this.rmData);
        this.findRmDetail();
        this.findRmba();
    },
    methods:{
        goEntdetail: function(item){
            this.$parent.pullItem("企业详情","EnterpriseDetail",{
                id: item.entid
            })
        },
        //获取以约定的职位
        getEntInfo(id){
            var _this = this;
            axios({
                url: _this.API.job_fairs.rm_bootch_Detail,
                method: 'get',
                headers:{
                    token: _this.Util.getjwtToken(),
                },
                params:{
                    id: id
                }
            }).then(function(e){
                var resp = e.data;
                if(resp && resp.success){
                    if(resp.data.jobinfo && resp.data.jobinfo.length>0){
                        _this.entjobinfo = resp.data.jobinfo
                    }
                }
            })
        },
        /**
         * 获取展位详情
         */
        findRmDetail: function(){
            var _this = this;
            axios({
                url: _this.API.job_fairs.getRecruitMeetingOne,
                method: 'get',
                headers:{
                    token: _this.Util.getjwtToken(),
                },
                params:{
                    id: _this.rmData.id
                }
            }).then(function(e){
                var resp = e.data;
                if(resp && resp.success){
                    _this.rmData = resp.data;
                    _this.rmAreaPicUrl = _this.rmData.meetingImage;
                }
            })
        },
        /**
         * 获取展区分布
         */
        findRmba: function(){
            var _this = this;
            axios({
                url: this.API.job_fairs.rmba_list,
                method: 'get',
                headers:{
                    token:this.Util.getjwtToken(),
                },
                params:{
                    rmid: this.rmData.id
                }
            }).then(function(e){
                var resp = e.data;
                if(resp && resp.success){
                    _this.tabRmlistArry = resp.data;
                    // _this.rmAreaPicUrl = _this.tab;
                    if(_this.tabRmlistArry && _this.tabRmlistArry.length > 0){
                        _this.findRmbootch(_this.tabRmlistArry[0].rmArea);
                    }
                }
            })
        },
        /**
         * 获取招聘会展区的展位
         */
        findRmbootch: function(barea){
            var _this = this;
            axios({
                url: this.API.job_fairs.rm_bootch_list,
                method: 'get',
                headers:{
                    token:this.Util.getjwtToken(),
                },
                params:{
                    rmid: this.rmData.id,
                    barea: barea
                }
            }).then(function(e){
                var resp = e.data;
                if(resp && resp.success){
                    _this.tabRmBootchlistArry = resp.data;
                }
            }).catch(function(e){

            });
        },
        /**
         *添加展区
         * @author cy
         */
        addRmBootchArea:function(barea){
            var _this = this;
            var rba={
                id: "",
                rmArea: barea,
                rmid: this.rmData.id,
                startbno: 1,
                endbno: ""
            };
            this.axios({
                url: this.API.job_fairs.addRba,
                method: 'post',
                headers:{
                    token:this.Util.getjwtToken(),
                },
                data: rba
            }).then(function(e){
                var resp = e.data;
                if(resp && resp.success){
                    _this.tabRmlistArry = resp.data;
                }
            }).catch(function(e){

            });
        },
        /**
         * 确定生成展位号
         * @author cy
         * @version 2.0
         */
        genRmBootchNo:function(){
            var _this = this;
            if(!_this.tabRmlistArry || _this.tabRmlistArry.length <= 0){
                _this.$message.error("请添加展区分布");
                return;
            }
            var areaNumJson = [];
            for(var i = 0; i < _this.tabRmlistArry.length; i ++){
                var rmAreaNoStart = parseInt(_this.tabRmlistArry[i].startbno);
                var rmAreaNoEnd = parseInt(_this.tabRmlistArry[i].endbno);
                var barea = _this.tabRmlistArry[i].rmArea;
                if(rmAreaNoStart <= 0){
                    _this.$message.error(barea + "区的展位号开始位置必须大于0");
                    return;
                }
                if(rmAreaNoStart >= rmAreaNoEnd){
                    _this.$message.error(barea + "区的展位号结束必须大于开始位置");
                    return;
                }
                areaNumJson.push({
                    name: barea,
                    startno: rmAreaNoStart,
                    endno: rmAreaNoEnd
                });
            }

            axios({
                url: _this.API.job_fairs.setup_booth,
                method: 'post',
                data: qs.stringify({
                    rmid: _this.rmData.id,
                    areaNumJson: JSON.stringify(areaNumJson),
                    meetingImage: _this.rmAreaPicUrl
                }),
                headers: {
                    token:_this.Util.getjwtToken(),
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(function(e){
                var resp = e.data;
                console.log('submitData', resp);
                if(resp && resp.success){
                    _this.$message.success(resp.msg);
                    _this.findRmba();
                }else{
                    _this.$message.error(resp.msg);
                }
            }).catch(function(e){
                console.log('submitData', e)
            })
        },
        /**
         * 确定生成展位号 1.0
         */
        okGenRmno: function(){
            var _this = this;

            if(!_this.tabRmlistArry || _this.tabRmlistArry.length <= 0){
                _this.$message.error("请添加展区分布");
                return;
            }
            var rmAreaNoStart = parseInt(_this.rmAreaNo.start);
            var rmAreaNoEnd = parseInt(_this.rmAreaNo.end);

            if(rmAreaNoStart <= 0){
                _this.$message.error("展位号开始位置必须大于0");
                return;
            }
            if(rmAreaNoStart >= rmAreaNoEnd){
                _this.$message.error("展位号结束必须大于开始位置");
                return;
            }
            var minNo = rmAreaNoStart, maxNo = rmAreaNoEnd;
            for(var i = 0; i < _this.tabRmlistArry.length; i ++){
                if(_this.tabRmlistArry[i]['bnos']){
                    for(var j = 0; j < _this.tabRmlistArry[i]['bnos'].length; j ++){
                        if(_this.tabRmlistArry[i]['bnos'][j]){
                            if(minNo >= _this.tabRmlistArry[i]['bnos'][j].nonum){
                                minNo = _this.tabRmlistArry[i]['bnos'][j].nonum;
                            }
                            if(maxNo <= _this.tabRmlistArry[i]['bnos'][j].nonum){
                                maxNo = _this.tabRmlistArry[i]['bnos'][j].nonum;
                            }
                        }
                    }
                }
            }

            if(rmAreaNoStart > minNo){
                _this.$message.error("展位号开始位置不能比现有的大");
                return;
            }
            if(rmAreaNoEnd < maxNo){
                _this.$message.error("展位号结束位置不能比现有的小");
                return;
            }
            
            if(!_this.tabRmlistArry || _this.tabRmlistArry.length <= 0){
                _this.$message.error("请添加展区分布");
                return;
            }

            var areaNumJson = [];
            for(var i = 0; i < _this.tabRmlistArry.length; i ++){
                areaNumJson.push({
                    name: _this.tabRmlistArry[i].rmArea,
                    startno: rmAreaNoStart,
                    endno: rmAreaNoEnd
                });
            }

            axios({
                url: _this.API.job_fairs.setup_booth,
                method: 'post',
                data: qs.stringify({
                    rmid: _this.rmData.id,
                    areaNumJson: JSON.stringify(areaNumJson),
                    meetingImage: _this.rmAreaPicUrl
                }),
                headers: {
                    token:_this.Util.getjwtToken(),
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(function(e){
                var resp = e.data;
                if(resp && resp.success){
                    _this.$message(resp.msg);
                    _this.findRmba();
                }else{
                    _this.$message.error(resp.msg);
                }
            }).catch(function(e){
                console.log('submitData', e)
            })
        },
        /**
         * 上传展位图
         */
        handleRmAreaPicSuccess(res, file) {
            if(res.success){
                this.rmAreaPicUrl = res.data;
                this.genRmBootchNo();
            }else{
                this.$message.error(res.msg);
            }
        },
        changeRminput:function(){
            var barea = this.tabRmlist;
            /*if(this.tabRmlist){
                this.tabRmlistArry.push({
                    rmArea: this.tabRmlist
                });

            }*/
            this.showInput=false;
            this.tabRmlist=''
            this.addRmBootchArea(barea);
           //this.okGenRmno();
        },
        selectRmlist: function(index){
            this.selectRmareaIndex = index;
            this.findRmbootch(this.tabRmlistArry[index].rmArea);
        },
        //选择展位区
        changesRmlist:function(index){
           
        },
        tabRmlistAdd:function(){
            this.showInput=true
        },
        stopYuding: function(index){
            if(index < 0 || index >= this.tabRmBootchlistArry.length){
                return;
            }
            var _this = this;
            axios({
                url: _this.API.job_fairs.rm_bootch_status,
                method: 'post',
                data: qs.stringify({
                    id: _this.tabRmBootchlistArry[index].id,
                    status: 3
                }),
                headers: {
                    token:_this.Util.getjwtToken(),
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(function(e){
                var resp = e.data;
                if(resp && resp.success){
                    _this.$message(resp.msg);
                    var item = _this.tabRmBootchlistArry[index];
                    item.status = 3;
                    _this.$set(_this.tabRmBootchlistArry, index, item);
                }else{
                    _this.$message.error(resp.msg);
                }
            }).catch(function(e){
                console.log('stopYuding', e)
            })
        }, 
        deleteBootch: function(index){
            if(index < 0 || index >= this.tabRmBootchlistArry.length){
                return;
            }
            var _this = this;
            axios({
                url: _this.API.job_fairs.rm_bootch_delete,
                method: 'delete',
                params: {
                    id:  _this.tabRmBootchlistArry[index].id
                },
                headers: {
                    token:_this.Util.getjwtToken(),
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(function(e){
                var resp = e.data;
                console.log('stopYuding', resp);
                if(resp && resp.success){
                    _this.$message(resp.msg);
                    _this.tabRmBootchlistArry.splice(index, 1);
                }else{
                    _this.$message.error(resp.msg);
                }
            }).catch(function(e){
                console.log('stopYuding', e)
            })
        }
    }
}
</script>
<style scoped lang="scss">
.tilte_btn{
    background-color: #ffffff;
}
.tag_input{
    width: 100px;
    margin-left:15px ;
}
.mapBox .avatar-uploader{
    width: 100%;
    height: 100%;
}
.el-upload{
    width: 100%;
    height: 100%;
}
.rmba {
    display: flex;
    padding: 10px;
    align-items: center;
}

.rmba .c1{
    margin: 0 5px;
}
.rmba .c2{
    margin: 0 5px;
}
.rmba .c3{
    margin: 0 5px;
}
.acs {
    display: flex;
    flex-flow: wrap row;
    justify-content: flex-start;
}
.area_card {
    margin: 10px;
}
.ac_dialog {
    
}
.ac_dialog .acd_header{
    background: #fc4c4e;
    padding: 0 5px;
    height: 38px;
    display: flex;
    align-items: center;
    color: #fff;
}
.ac_dialog .acd_content{
    height: 54px;
    padding: 0 5px;
    display: flex;
    align-items: center;
}
</style>