<template>
  <div class="home">
    <nav class="navTab">
      <div
        v-for="(item, index) in newTitleType"
        :key="index"
        :class="tabIndex == index ? 'isDivcom' : ''"
        @click="tabNavtilte(item, index)"
      >
        <h2>{{ item.title }}</h2>
      </div>
    </nav>
    <div class="inte"></div>
    <nav class="nav_sech" style="justify-content: space-between;">
      <div>
        <el-button
          @click="getWebNewadd"
          size="medium"
          type="primary"
          class="el_btn"
          >添加</el-button
        >
        <el-select
          size="medium"
          class="el_input"
          v-model="queryform.status"
          placeholder="文章状态"
          clearable
        >
          <el-option label="有效" value="0"></el-option>
          <el-option label="关闭" value="1"></el-option>
        </el-select>
        <el-date-picker
          class="el_input"
          v-model="ShowitemList.item"
          @change="changePublishTime"
          value-format="yyyy-MM-dd"
          size="medium"
          type="date"
          clearable
          placeholder="发布日期"
        >
        </el-date-picker>
        <!-- <el-date-picker
                    v-model="ShowitemList.item" size="medium" clearable class="el_input"
                    @change="changePublishTime"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker> -->
        <el-button
          @click="searchNewList"
          size="medium"
          type="warning"
          class="el_btn"
          >查询</el-button
        >
      </div>
      <el-input
        style="width:300px;margin-right:15px"
        size="medium"
        placeholder="请输入关键字"
        v-model="queryform.keywords"
      >
        <i
          slot="suffix"
          @click="searchName"
          class="el-input__icon el-icon-search"
        ></i>
      </el-input>
    </nav>
    <main class="el_table" style="margin: 15px 15px 0">
      <el-table
        :data="tableData"
        style="width: 100%;margin-bottom: 20px"
        border
      >
        <el-table-column prop="title" label="文章标题" align="center" min-width="300">
        </el-table-column>
        <el-table-column label="文章状态" align="center" width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">有效</span>
            <span v-if="scope.row.status == 1">关闭</span>
          </template>
        </el-table-column>
        <el-table-column prop="asort" label="重要性" align="center" width="100">
        </el-table-column>
        <el-table-column
          prop="clickNum"
          label="文章点击量"
          align="center"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="publishTime"
          label="发布日期"
          align="center"
          width="200"
        >
        </el-table-column>
        <el-table-column label="操作" align="center" width="400">
          <template slot-scope="scope">
            <el-button
              :type="scope.row.status == 0 ? 'info' : 'success'"
              size="medium"
              @click="handleStatusTab(scope.$index, scope.row)"
            >
              {{ scope.row.status == 0 ? '关闭' : '开启' }}
            </el-button>
            <el-button
              type="danger"
              size="medium"
              @click="handleDetail(scope.$index, scope.row)"
              >查看详情</el-button
            >
            <el-button
              type="info"
              size="medium"
              @click="handleDel(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: center; height:60px;">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="10"
          :current-page="queryform.page"
          layout="total, sizes, prev, pager, next, jumper"
          :total="connt"
        >
        </el-pagination>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'Web_newZX',
  data() {
    return {
      tabIndex: 0,
      ShowitemList: {
        item: '',
      },
      queryform: {
        menid: '',
        status: '',
        publicTimeStart: '',
        publicTimeEnd: '',
        keywords: '',
        page: 1,
        limit: 10,
      },
      newTitleType: [],
      tableData: [],
      connt: null,
    };
  },
  created() {
    this.getNewType(2);
  },
  methods: {
    tabNavtilte: function(item, index) {
      this.tabIndex = index;
      this.queryform.menid = item.id;
      this.getNewzwData();
    },
    searchName: function() {
      if (this.Util.isBlank(this.queryform.keywords)) {
        this.$message.error('请输入关键字！');
        return;
      }
      this.queryform.page = 1;
      this.getNewzwData();
    },
    //查询
    searchNewList: function() {
      this.queryform.page = 1;
      this.getNewzwData();
    },
    //时间选择
    changePublishTime: function(eArry) {
      if (eArry) {
        this.queryform.publicTimeStart = eArry;
        this.queryform.publicTimeEnd = eArry;
      } else {
        this.queryform.publicTimeStart = '';
        this.queryform.publicTimeEnd = '';
      }
    },
    getNewType: function(type) {
      var _this = this;
      axios
        .get(this.API.news.menus, {
          headers: {
            token: this.Util.getjwtToken(),
          },
          params: {
            iclass: type,
          },
        })
        .then(function(res) {
          if (res.data.success && res.data.data.length > 0) {
            _this.newTitleType = res.data.data;
            if (_this.newTitleType.length > 0) {
              _this.queryform.menid = _this.newTitleType[0].id;
              _this.getNewzwData();
            }
            console.log('分类', res);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getNewzwData: function() {
      var _this = this;
      axios
        .get(this.API.news.listPager, {
          headers: {
            token: this.Util.getjwtToken(),
          },
          params: _this.queryform,
        })
        .then(function(res) {
          console.log('列表', res);
          if (res.data.success) {
            _this.tableData = res.data.data.list;
            _this.connt = res.data.data.pager.recordCount;
          } else {
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getWebNewadd: function() {
      this.$parent.pullItem('添加新闻信息', 'Web_newAdd', { type: 2 });
    },
    //改变状态
    handleStatusTab: function(index, row) {
      var _this = this;
      if (row.status == 0) {
        row.status = 1;
      } else {
        row.status = 0;
      }
      axios
        .get(this.API.news.status, {
          headers: {
            token: _this.Util.getjwtToken(),
          },
          params: {
            id: row.id,
            status: row.status,
          },
        })
        .then(function(res) {
          if (res.data.success) {
            _this.$message.success('修改成功');
          } else {
            if (row.status == 0) {
              row.status == 1;
            } else {
              row.status == 0;
            }
          }
        });
    },
    //去详情
    handleDetail: function(index, row) {
      row.type = 2;
      this.$parent.pullItem('新闻详情', 'Web_newDetail', row);
    },
    handleDel: function(index, row) {
      var _this = this;
      this.$confirm('此操作将永久删除该条新闻, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          _this.gethandleRowNewDel(index, row);
        })
        .catch(() => {
          this.$message.info('已取消删除');
        });
    },
    gethandleRowNewDel: function(index, row) {
      var _this = this;
      axios({
        url: _this.API.news.delete,
        method: 'delete',
        headers: {
          token: _this.Util.getjwtToken(),
        },
        params: {
          id: row.id,
        },
      }).then(function(res) {
        if (res.data.success) {
          _this.$message.success(res.data.msg);
          _this.tableData.splice(index, 1);
        } else {
          _this.$message.error(res.data.msg);
        }
      });
    },
    //分页操作
    handleSizeChange: function(e) {
      this.queryform.limit = e;
      this.getNewzwData();
    },
    handleCurrentChange: function(e) {
      this.queryform.page = e;
      this.getNewzwData();
    },
  },
};
</script>

<style scoped lang="scss">
.isDivcom {
  color: #000;
  border-bottom: 2px solid #000000;
}
.inte {
  height: 10px;
}
</style>
