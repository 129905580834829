<template>
    <div class="home">
        <div class="tilte_btn">
           <span class="lineY"></span>
           <span><h1>企业信息库</h1></span>
           <span>
               <el-button @click="getNews" type="primary" class="el_btn" size="medium">新增企业</el-button>
           </span>
        </div>
        <nav class="nav_sech" style="justify-content: space-between;">
            <div>
                <el-date-picker class="el_input" size="medium"
                    @change="changeCreatetime"
                    v-model="queryform.time"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="注册时间">
                </el-date-picker>
                <el-select
                    class="el_input"
                    v-model="naturesValue"
                    placeholder="企业性质"
                    @change="naturesChange"
                    size="medium" clearable>
                    <el-option
                        v-for="(item, index) in enterprseNatures"
                        :key="index"
                        :label="item.name"
                        :value="index"
                    ></el-option>
                </el-select>
                <el-select
                    class="el_input"
                    v-model="scaleValue"
                    placeholder="企业规模"
                    @change="scaleEntChange"
                    size="medium" clearable>
                    <el-option
                        v-for="(item, index) in enterprseScaleEnts"
                        :key="index"
                        :label="item.name"
                        :value="index"
                    ></el-option>
                </el-select>
            </div>
            <div style="margin-right: 15px;">
                <el-input
                        size="medium"
                        placeholder="请输入企业名称"
                        v-model="queryform.keywords">
                        <el-button class="searchBtn" slot="append" @click="searchName">搜索</el-button>
               </el-input>
            </div>
        </nav>
        <!-- <div class="inte"></div> -->
        <main class="el_table">
             <div class="org_box">
                  <el-row>
                      <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6" class="org_list" v-for="(item,index) in tableDatas" :key="index">
                            <div class="org_main" >
                                <div class="col" @click="getorgDetail(item)">
                                    <div class="">
                                        <img :src="item.logoPurl" v-if="item.logoPurl"/>
                                        <img v-else src="../../assets/img/no.png"/>
                                    </div>
                                    <div class="textBox"> 
                                        <span class="entName">{{item.name}}</span>
                                        <p>{{item.natures}}</p>
                                    </div>
                                </div>
                                <div class="line_s m_top20"></div>
                                <div style="font-size:16px;color:#999999;padding:15px 0">
                                  <div>
                                    <span style="color:#409eff">{{item.jobnum}}个热招岗位</span>
                                    <div style="float: right;">
                                      <el-button  icon="el-icon-delete"  @click="deleteEnt(item)" circle></el-button>
                                    </div>
                                  </div>

                                </div>
                            </div>
                      </el-col>
                  </el-row>
             </div>
             <div class="noList" v-if="tableDatas&&tableDatas.length==0">
                    暂无数据
             </div>  
              <div style="height:60px;text-align: center; margin: 20px auto;">
                <el-pagination
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-sizes="[12,16, 20, 24, 28]"
                        :page-size="12"
                        :current-page="pages"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="recordCount">
                </el-pagination>
              </div>
        </main>
    </div>
</template>

<script>
    export default {
        name: "Organs",
        data(){
            return{
              queryform:{
                createtime: '',
                nature: '', 
                scaleEnt: '',
                auditState: "-1",
                keywords: '',
              },
              naturesValue: '',
              scaleValue: '',
              auditValue: '',
              enterprseNatures: [],
              enterprseScaleEnts: [],
              enterpriseAudits: [
                  {label: "不限", value: "-1"},
                  {label: "审核通过", value: "0"},
                  {label: "审核未通过", value: "1"},
              ],
              tableDatas:[],
            //   分页操作
              pages: 1,
              limits: 12,
              recordCount:null 
            }
        },
        created(){
            this.getEnterprNatures();
            this.getEnterprBaseEcs();
            this.getEnterpriseList();
        },
        methods:{
            //关键字
            searchName:function(){
               this.pages=1;
               this.getEnterpriseList();
            },
            naturesChange (e){
                this.pages=1;
                if(e){
                  this.queryform.nature = this.enterprseNatures[e].name;
                }else{
                  this.queryform.nature=''  
                }
                this.getEnterpriseList();
            },
            scaleEntChange(e){
                this.pages=1;
                if(e){
                   this.queryform.scaleEnt= this.enterprseScaleEnts[e].name;
                }else{
                   this.queryform.scaleEnt='' 
                }
                this.getEnterpriseList();
            },
            //注册时间
            changeCreatetime:function(e){
                this.pages=1;
                if(e){
                   this.queryform.createtime=e+''
                }else{
                   this.queryform.createtime=''
                }
                 this.getEnterpriseList();
            },
            //企业认证
            // auditChange(e){
            //     this.queryform.auditState = this.enterpriseAudits[e].value;
            //     this.getEnterpriseList();
            // },
            //企业性质
            getEnterprNatures: function() {
                var _self = this;
                axios.get(_self.API.baseEn.list, {
                        headers: {
                            token: _self.Util.getjwtToken()
                        }
                    }).then(function(pores) {
                        if (pores.data.success) {
                            if (pores.data.data && pores.data.data.length > 0) {
                                pores.data.data.unshift({
                                    name: "全部"
                                });
                                _self.enterprseNatures = pores.data.data;
                            }
                        }
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            },
            //企业规模
            getEnterprBaseEcs: function() {
                var _self = this;
                axios
                    .get(_self.API.baseEcs.list, {
                        headers: {
                            token: _self.Util.getjwtToken()
                        }
                    })
                    .then(function(pores) {
                        if (pores.data.success) {
                            // console.log("企业规模", pores);
                            if (pores.data.data && pores.data.data.length > 0) {
                                pores.data.data.unshift({
                                    name: "全部"
                                });
                                _self.enterprseScaleEnts = pores.data.data;
                            }
                        }
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            },
            getEnterpriseList:function(){
                const loading = this.$loading({
                    lock: true,
                    text: 'loading...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(255, 255, 255, 0.7)'
                });
                var _this=this;
                axios.get(this.API.aent.entDblist,{
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                    params:{
                        createtime:this.queryform.createtime,
                        nature: this.queryform.nature != '全部'?this.queryform.nature:'',
                        auditState:this.queryform.auditState,
                        scaleEnt:this.queryform.scaleEnt != '全部'?this.queryform.scaleEnt:'',
                        keywords:this.queryform.keywords,
                        page:this.pages,
                        limit:this.limits,
                    }
                }).then(function (res) {
                    // console.log('res', res);
                    if(res.data.success){
                        var temp=res.data.data.list;
                        for(var i = 0; i < temp.length; i ++){
                            if(temp[i].natures&&temp[i].natures.length>15){
                                temp[i].natures = temp[i].natures.replace( /-/g, "/");
                                temp[i].natures= temp[i].natures.substring(0,15)+'...';
                            }else{
                                temp[i].natures= temp[i].natures;
                            };
                            if(temp[i].name&&temp[i].name.length>13){
                                temp[i].name=temp[i].name.substring(0,13)+'...' ;
                            }else{
                                temp[i].name=temp[i].name;
                            }
                        }
                       _this.recordCount=res.data.data.pager.recordCount;
                       _this.tableDatas=temp;
                    }
                    loading.close();
                }).catch(function (error) {
                    console.log(error);
                    loading.close();
                });
           },
           //新增
           getNews:function(){
            //  this.$router.push({path:'/Enterpriseadd'})
                this.$parent.pullItem("添加企业", "Enterpriseadd");
           },
           //跳详情
           getorgDetail:function(item){
             this.$parent.pullItem("企业详情","EnterpriseDetail",item)
           },
           handleSizeChange:function(e){
                this.limits=e;
                this.getEnterpriseList();
           },
           handleCurrentChange:function(e){
                this.pages=e;
                this.getEnterpriseList();
           },
          deleteEnt:function(item){
            let _this = this;
            this.$confirm('确认删除该企业?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              axios.delete(_this.API.aent.delete,{
                headers:{
                  token:_this.Util.getjwtToken(),
                },
                params:{
                  id:item.id
                }
              }).then(function (res) {
                if(res.data.success){
                  _this.$message({
                    type: "success",
                    message: "删除成功！"
                  });
                  _this.getEnterpriseList();
                }

              }).catch(function (error) {
                console.log(error);
              });
            });
          }
        }
    
    }
</script>

<style scoped lang="scss">
.tilte_btn{
    background:#fff;
}
.el_table{
    background:#f6f7fb;
    margin: 20px auto;
}
.col{
    height: 80px;
    display: flex;
    justify-content: space-between;
    .entName{
        font-size:18px !important; 
        font-weight: 600 !important;
        overflow:hidden; 
        text-overflow:ellipsis; 
        white-space:nowrap;
    } 
}
.textBox{
    width: 100%;
    text-align: right;
    font-size:16px;
}
</style>