<template>
    <div class="home">
         <div class="tilte_btn">
           <span class="lineY"></span>
           <span><h2>发布招聘会</h2></span>
        </div>
        <div style="padding:10px;">
            <span class="ent_del">招聘会标题：</span>
            <el-input v-model="RecruitInfo.title" style="width:80%" placeholder="请输入标题..."></el-input>
         </div>
         <div style="padding:10px;">
             <el-row>
                <el-col :span="12">
                     <span class="ent_del">举办日期：</span>
                     <el-date-picker style="margin-left:15px;width:65%;"
                        v-model="showRecruitInfo.item"
                        @change="changesItem"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                        </el-date-picker>
                        <span style="padding-left:10px" v-if="showRecruitInfo.day">共{{showRecruitInfo.day}}天</span>
                </el-col>
                <el-col :span="12">
                       <span class="ent_del">规定状态：</span>
                       <el-radio v-model="RecruitInfo.status" label="1">开放预订</el-radio>
                       <el-radio v-model="RecruitInfo.status" label="2">停止预订</el-radio>
                </el-col>
             </el-row>
         </div>
         <div style="padding:10px;">
             <el-row>
                <el-col :span="12">
                    <span class="ent_del">联系人：</span>
                   <el-input v-model="RecruitInfo.contactsPerson" style="width:65%;margin-left:30px" placeholder="请输入联系人"></el-input>
                </el-col>
                <el-col :span="12">
                    <span class="ent_del">联系电话：</span>
                   <el-input v-model="RecruitInfo.contactsNumber" style="width:58%;margin-left:30px" placeholder="请输入联系电话"></el-input>
                </el-col>
             </el-row>
         </div>
         <div style="padding:10px;">
             <el-row>
                <el-col :span="12">
                     <span class="ent_del">乘车路线：</span>
                     <el-input v-model="RecruitInfo.rideCarLine" style="width:65%;margin-left:15px" placeholder="请输入乘车路线"></el-input> 
                        
                </el-col>
             </el-row>
         </div>
         <div style="padding:10px;">
                    <span class="ent_del">招聘地址：</span>
                    <button style="margin-left:15px;padding:10px 20px">
                        {{RecruitInfo.provinceName?RecruitInfo.provinceName:'选择省'}}
                    </button>
                    <button style="margin-left:15px;padding:10px 20px">
                        {{RecruitInfo.cityName?RecruitInfo.cityName:'选择市'}}
                    </button>
                    <button style="margin-left:15px;padding:10px 20px">
                        {{RecruitInfo.countyName?RecruitInfo.countyName:'选择区'}}
                    </button>
                    <el-input v-model="RecruitInfo.addrDetail" style="width:200px;margin-left:15px;" placeholder="请输入详细地址"></el-input>
            </div>
            <el-row>
                <el-col :span="12">
                    <div class="mapBox" id="mapBox">
                    <!-- 地图 -->
                        <div class="map_info">
                            <input id='tipinput' v-model="mapkeyword" placeholder="请输入关键字"/>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12">
                     <div class="m_top10">
                         <span class="ent_del">招聘会标题图片：</span>
                     </div>
                     <el-upload
                        class="avatar-uploader m_top20"
                        :action="uploadImgUrl"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess">
                        <img v-if="RecruitInfo.titleImgUrl" :src="RecruitInfo.titleImgUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                     </el-upload>
                </el-col>
            </el-row>
        <div class="tilte_btn">
           <span class="lineY"></span>
           <span><h2>招聘会简介</h2></span>
        </div>
        <div style="padding:10px;">
            <el-input
            v-model="RecruitInfo.descript"
                type="textarea"
                placeholder="请输入简介"
                style="width:90%"
                :autosize="{ minRows: 7, maxRows: 10}">
            </el-input>
        </div>
        <div style="padding:20px 10px">
            <el-button @click="Recruitadd" style="width:150px" type="danger">
                提交
            </el-button>
        </div>
    </div>
</template>

<script>
import { lazyAMapApiLoaderInstance } from 'vue-amap';
    export default {
        name: "Recruit_add",
        data(){
            return{
               mapkeyword:'',
               uploadImgUrl:this.API.file.uploadSingle,
               showRecruitInfo:{},//用于显示的
               RecruitInfo:{
                   status:'1'
               },
            }
        },
        created(){
            var _self=this;
            lazyAMapApiLoaderInstance.load().then(() => {
            var point = new AMap.LngLat(116.445294,39.891345);
            _self.map = new AMap.Map('mapBox', {
                center: point,
                zoom: 13
            });
            var marker = new AMap.Marker({
                position:point ,  // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            });
            _self.map.add(marker);
            _self.map.plugin('AMap.Autocomplete', function(){
                // 实例化Autocomplete
                var autoOptions = {
                    //city 限定城市，默认全国
                    city: '全国',
                    input: 'tipinput'
                }
                var auto= new AMap.Autocomplete(autoOptions);
                var placeSearch = new AMap.PlaceSearch({
                    map:_self.map
                });  //构造地点查询类
                AMap.event.addListener(auto, "select", select);//注册监听，当选中某条记录时会触发
                function select(e) {
                    placeSearch.setCity(e.poi.adcode);
                    placeSearch.search(e.poi.name);  //关键字查询查询
                }
            });
            _self.map.on('click',function (ev) {
                _self.map.remove(marker);
                marker = new AMap.Marker({
                    position:new AMap.LngLat(ev.lnglat.lng,ev.lnglat.lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                });
                _self.finareacode(ev.lnglat.lng,ev.lnglat.lat);
                _self.map.add(marker);
            });
        });
        },
        methods:{
            //图片上传部分
            handleAvatarSuccess(res, file) {
                    if(res.success){
                        this.$set(this.RecruitInfo, 'titleImgUrl', res.data);
                    }else{
                     this.$message.error(res.msg);
                }
            },
            //举办日期
           changesItem:function(e){
               if(e&&e!=null){
                  this.RecruitInfo.holdtime=e[0];
                  this.RecruitInfo.holdendtime=e[1];
                  this.GetNumberOfDays(e[0],e[1])
               }else{
                  this.RecruitInfo.holdtime='';
                  this.RecruitInfo.holdendtime='';
                  this.GetNumberOfDays(null,null)
               }
               
            },
            finareacode:function(lng,lat){
                var _self=this;
                axios.get(_self.API.areacode.getAddrByLnglat, {
					headers: {
						token: _self.Util.getjwtToken()
					},
					params: {
                        lng:lng,
                        lat:lat
					}
				}).then(function(pores) {
					if (pores.data.success) {
                        _self.$set(_self.RecruitInfo,'provinceName',pores.data.data.provinceName)
                        _self.RecruitInfo.provinceCode=pores.data.data.provinceCode;

                        _self.$set(_self.RecruitInfo,'cityName',pores.data.data.cityName);
                        _self.RecruitInfo.cityCode=pores.data.data.cityCode;

                        _self.$set(_self.RecruitInfo,'countyName',pores.data.data.countyName);
                        _self.RecruitInfo.countyCode=pores.data.data.countyCode;
                        
                        _self.RecruitInfo.addrDetail=pores.data.data.addrDetail;  
                        //经纬度
                        _self.RecruitInfo.addrlng=lng;
                        _self.RecruitInfo.addrlat=lat;
					}
				}).catch(function(error) {
					console.log(error);
				});
            },
           GetNumberOfDays:function(s , e){
               var s = Date.parse(new Date(s));
               var e = Date.parse(new Date(e));
               var day = parseInt((e-s)/ (1000 * 60 * 60 * 24));//核心：时间戳相减，然后除以天数
               if(day){
                   this.$set(this.showRecruitInfo,'day',day)
               }else{
                   this.$set(this.showRecruitInfo,'day','')
               }
           },
           Recruitadd:function(){
               var _this=this;
               if(this.Util.isBlank(this.RecruitInfo.title)){
                   this.$message.error('招聘会标题不能为空！');
                   return;
               }
               if(this.Util.isBlank(this.RecruitInfo.holdtime)){
                  this.$message.error('举办日期不能为空！');
                  return; 
               }
               if(this.Util.isBlank(this.RecruitInfo.contactsPerson)){
                   this.$message.error('联系人不能为空！');
                  return; 
               }
               if(this.Util.isBlank(this.RecruitInfo.contactsNumber)){
                   this.$message.error('联系电话不能为空！');
                   return; 
               }
               if(!this.Util.phoneVail(this.RecruitInfo.contactsNumber)){
                   this.$message.error('联系电话不合理！');
                   return; 
               }
               if(this.Util.isBlank(this.RecruitInfo.rideCarLine)){
                   this.$message.error('乘车路线不能为空！');
                   return;  
               }
               if(this.Util.isBlank(this.RecruitInfo.provinceName)){
                   this.$message.error('请点击地图选择省市区！');
                   return; 
               }
               if(this.Util.isBlank(this.RecruitInfo.addrDetail)){
                   this.$message.error('请输入详情地址');
                   return;
               }
               if(this.Util.isBlank(this.RecruitInfo.titleImgUrl)){
                   this.$message.error('请上传招聘会标题图片！');
                   return;
               }
               if(this.Util.isBlank(this.RecruitInfo.descript)){
                   this.$message.error('公司简介不能为空！');
                   return;
               }
                axios({
                    url: _this.API.job_fairs.editor,
                    method: 'post',
                    headers:{
                        token: _this.Util.getjwtToken(),
                    },
                    data:_this.RecruitInfo
                }).then(function(e){
                    if(e.data.success){
                        _this.showRecruitInfo={};//用于显示的
                        _this.RecruitInfo={
                            status:'1'
                        };
                       _this.$message.success(e.data.msg);
                    }else{
                        _this.$message.error(e.data.msg);
                    }
                })
           }
        }
    }
</script>

<style scoped lang="scss">
.tilte_btn,.home{
    background: #ffffff;
}
</style>