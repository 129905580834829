<template>
  <div class="tinymce-editor">
    <Editor :id="editorId" v-model="editorValue" :init="editorInit" :disabled="disabled" @onClick="handleClick" />
  </div>
</template>

<script>
// 引入组件
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
// 引入富文本编辑器主题的js和css
import 'tinymce/themes/silver/theme.min.js'
import 'tinymce/icons/default/icons.min.js'
import 'tinymce/skins/ui/oxide/skin.min.css'
// 扩展插件
import 'tinymce/plugins/image'
import 'tinymce/plugins/link'
import 'tinymce/plugins/code'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/wordcount' // 字数统计插件

import API from '@/plugins/config.js'

export default {
  name: 'Tinymce',
  components: { Editor },
  props: {
    id: {
      type: String,
      default: 'tinymce'
    },
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    plugins: {
      type: [String, Array],
      default: 'link lists image code table wordcount'
    },
    toolbar: {
      type: [String, Array],
      default: 'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo | link unlink image code | removeformat'
    }
  },
  data () {
    return {
      editorInit: {
        language_url: './tinymce/langs/zh_CN.js',
        language: 'zh_CN',
        skin_url: './tinymce/skins/ui/oxide',
        height: 650,
        plugins: this.plugins,
        toolbar: this.toolbar,
        statusbar: true, // 底部的状态栏
        menubar: true, // 最上方的菜单
        branding: false, // 水印“Powered by TinyMCE”
        images_upload_handler: (blobInfo, success, failure) => {
          this.$emit('handleImgUpload', blobInfo, success, failure)
        },
        file_picker_callback: function (callback, value, meta) {
          var filetype = '.pdf, .txt, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp3, .mp4';
          //后端接收上传文件的地址
          var upurl = API.file.uploadSingle;
          //为不同插件指定文件类型及后端地址
          switch (meta.filetype) {
            case 'image':
              filetype = '.jpg, .jpeg, .png, .gif';
              break;
            case 'media':
              filetype = '.mp3, .mp4';
              break;
            case 'file':
            default:
          }
          //模拟出一个input用于添加本地文件
          var input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', filetype);
          input.click();
          input.onchange = function () {
            var file = this.files[0];
            var xhr, formData;
            xhr = new XMLHttpRequest();
            xhr.withCredentials = false;
            xhr.open('POST', upurl);
            xhr.onload = function () {
              var json;
              if (xhr.status != 200) {
                console.log('HTTP Error: ' + xhr.status);
                return;
              }
              json = JSON.parse(xhr.responseText);
              if (!json.success) {
                console.log('Invalid JSON: ' + json.msg);
                return;
              }
              callback(json.data, { text: file.name });
            };
            formData = new FormData();
            formData.append('file', file, file.name);
            xhr.send(formData);
          };
        }
      },
      editorId: this.id,
      editorValue: this.value
    }
  },
  watch: {
    editorValue (newValue) {
      this.$emit('input', newValue)
    }
  },
  mounted () {
    tinymce.init({})
  },
  methods: {
    // https://github.com/tinymce/tinymce-vue => All available events
    handleClick (e) {
      this.$emit('onClick', e, tinymce)
    },
    clear () {
      this.editorValue = ''
    }
  }
}
</script>
