<template>
  <div class="home">
    <nav class="nav_sech">
      <el-input
        class="el_input"
        v-model="navSech.navName"
        placeholder="请输入菜单名称"
      ></el-input>
      <el-select
        class="el_input"
        v-model="navSech.navIsHide"
        placeholder="请选择显示状态"
        clearable
      >
        <el-option label="显示" value="0"></el-option>
        <el-option label="隐藏" value="1"></el-option>
      </el-select>
      <el-select
        class="el_input"
        v-model="navSech.navMenuType"
        placeholder="请选择类型"
        clearable
      >
        <el-option label="页面栏目" value="0"></el-option>
        <el-option label="文章栏目" value="1"></el-option>
      </el-select>
      <el-button class="el_btn" @click="navSechlist" type="warning"
        >查询</el-button
      >
      <el-button class="el_btn" @click="tableDataAdd" type="primary"
        >新增</el-button
      >
    </nav>
    <div class="inte"></div>
    <main class="el_table" style="margin:15px">
      <el-table
        :data="tableData"
        style="width:100%;margin-bottom: 20px"
        row-key="id"
        border
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column
          prop="navName"
          label="菜单名称"
          align="left"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="navUrl"
          label="菜单地址"
          align="center"
          width="200"
        >
        </el-table-column>
        <el-table-column label="菜单类型" align="center" width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.navMenuType == 0">页面栏目</span>
            <span v-if="scope.row.navMenuType == 1">文章栏目</span>
          </template>
        </el-table-column>
        <el-table-column prop="navsort" label="排序" align="center" width="150">
        </el-table-column>
        <el-table-column
          prop="navCode"
          label="栏目代码(唯一)"
          align="center"
          width="250"
        >
        </el-table-column>
        <el-table-column label="状态" align="center" width="170">
          <template slot-scope="scope">
            <span style="color: blue;" v-if="scope.row.navIsHide == 0"
              >显示</span
            >
            <span style="color: red;" v-if="scope.row.navIsHide == 1"
              >隐藏</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              size="medium "
              :type="scope.row.navIsHide == 0 ? 'primary' : 'warning'"
              @click="handleProhibit(scope.$index, scope.row)"
            >
              {{ scope.row.navIsHide == 0 ? '隐藏' : '显示' }}
            </el-button>
            <el-button
              size="medium "
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </main>
    <el-dialog :title="diaTitle" :visible.sync="dialogVisible" width="38%">
      <template>
        <el-form
          ref="addEditinfo"
          size="medium"
          :model="addEditinfo"
          label-width="100px"
        >
          <el-form-item label="上级菜单">
            <el-cascader
              class="el_input"
              v-model="addEditinfo.navpid"
              placeholder="如果为第一级的则不用选"
              @change="changeProps"
              :options="addUpPermissions"
              :props="props"
              :show-all-levels="false"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="菜单名称">
            <el-input
              class="el_input"
              v-model="addEditinfo.navName"
              placeholder="请输入菜单名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="栏目类型">
            <el-select
              class="el_input"
              v-model="addEditinfo.navMenuType"
              placeholder="请选择类型"
              clearable
            >
              <el-option label="页面栏目" value="0"></el-option>
              <el-option label="文章栏目" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="菜单地址">
            <el-input
              class="el_input"
              v-model="addEditinfo.navUrl"
              placeholder="请输入菜单地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="栏目代码">
            <el-input
              class="el_input"
              v-model="addEditinfo.navCode"
              placeholder="请输入栏目代码"
            ></el-input>
          </el-form-item>
          <el-form-item label="显示排序">
            <el-input
              class="el_input"
              v-model="addEditinfo.navsort"
              type="number"
              min="0"
              placeholder="请输入排序"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="postAddData()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Web_topNav',
  data() {
    return {
      navSech: {
        navMenuType: '',
        navIsHide: '',
        navIsTab: -1,
        navName: '',
        navUrl: '',
        navCode: '',
      },
      tableData: [],

      dialogVisible: false,
      diaTitle: '',
      addEditinfo: {
        navpid: '0',
        navIsHide: 0,
      },

      addUpPermissions: [],
      props: {
        lazy: true,
        value: 'id',
        checkStrictly: true,
        label: 'navName',
        lazyLoad: this.menusfather,
      },
    };
  },
  created() {
    this.getNavlist();
  },
  methods: {
    getNavlist: function() {
      var _self = this;
      axios
        .get(this.API.navmenu.listChildren, {
          headers: {
            token: this.Util.getjwtToken(),
          },
          params: this.navSech,
        })
        .then(function(res) {
          console.log('res', res);
          if (res.data.success) {
            _self.tableData = res.data.data;
          } else {
            _self.tableData = [];
          }
        });
    },
    //查询
    navSechlist: function() {
      this.getNavlist();
    },
    //添加
    tableDataAdd: function() {
      this.addEditinfo = {
        navpid: '0',
        navIsHide: 0,
      };
      this.diaTitle = '新增导航栏';
      this.dialogVisible = true;
    },
    changeProps: function(arrid) {
      this.addEditinfo.navpid = arrid[arrid.length - 1];
    },
    //根据父id获取类型菜单
    menusfather: function(node, resolve) {
      var _self = this;
      var pid = '0';
      if (node.data) {
        pid = node.data.id;
      }
      axios
        .get(_self.API.navmenu.list, {
          headers: {
            token: _self.Util.getjwtToken(),
          },
          params: {
            type: -1,
            pid: pid,
          },
        })
        .then(function(pores) {
          if (pores.data.success) {
            if (pores.data.data && pores.data.data.length > 0) {
              resolve(pores.data.data);
            } else {
              resolve([]);
            }
          } else {
            resolve([]);
          }
        });
    },
    //编辑
    handleEdit: function(index, row) {
      this.diaTitle = '编辑导航栏';
      row.navMenuType = row.navMenuType + '';
      this.addEditinfo = JSON.parse(JSON.stringify(row));
      this.dialogVisible = true;
    },
    //隐藏
    handleProhibit: function(index, row) {
      if (row.navIsHide == 0) {
        row.navIsHide = 1;
      } else {
        row.navIsHide = 0;
      }
      this.isSuccessgetData(row);
    },
    //提交
    postAddData: function() {
      if (this.Util.isBlank(this.addEditinfo.navName)) {
        this.$message.warning('请输入菜单名称！');
        return;
      }
      if (this.Util.isBlank(this.addEditinfo.navMenuType)) {
        this.$message.warning('请选择栏目类型！');
        return;
      }
      if (this.Util.isBlank(this.addEditinfo.navUrl)) {
        this.$message.warning('请输入菜单地址！');
        return;
      }
      if (this.Util.isBlank(this.addEditinfo.navCode)) {
        this.$message.warning('请输入栏目代码！');
        return;
      }
      // if(this.Util.isBlank(this.addEditinfo.navsort)){
      //     this.$message.warning('请输入显示排序！');
      //     return
      // }
      this.isSuccessgetData(this.addEditinfo);
    },
    isSuccessgetData: function(DataInfo) {
      var _this = this;
      axios({
        url: this.API.navmenu.editor,
        method: 'post',
        data: DataInfo,
        headers: {
          token: this.Util.getjwtToken(),
        },
      }).then(function(res) {
        console.log(res);
        if (res.data.success) {
          _this.dialogVisible = false;
          _this.addEditinfo = {
            navpid: '0',
            navIsHide: 0,
          };
          _this.getNavlist();
        } else {
        }
      });
    },
    //删除
    handleDelete: function(index, row) {
      var _self = this;
      this.$confirm('此操作将永久删除该菜单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        _self.handleDeleteData(index, row);
      });
    },
    handleDeleteData: function(index, row) {
      var _this = this;
      axios({
        url: this.API.navmenu.delete,
        method: 'delete',
        params: {
          id: row.id,
        },
        headers: {
          token: this.Util.getjwtToken(),
        },
      }).then((res) => {
        if (res.data.success) {
          _this.$message.success('删除成功!');
          _this.getNavlist();
        } else {
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
