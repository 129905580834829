<template>
    <div class="home">
        <div class="tilte_btn">
           <span class="lineY"></span>
           <span><h1>企业亮点管理</h1></span>
        </div>
        <nav class="nav_sech">
            <div>
                <!-- <el-input v-model="name" placeholder="请输入企业亮点名称" class="el_input" size="medium">
                </el-input>
                <el-button type="warning" class="el_btn" size="medium">查询</el-button> -->
                <el-button @click="EntgoodsAdds" type="primary" class="el_btn" size="medium">新增</el-button>
            </div>
        </nav> 
        <div class="inte"></div>
        <main class="el_table" style="margin: 15px 15px 0">
            <el-table
                    :data="tableData"
                    style="width: 100%;margin-bottom: 20px"
                    border>
                <el-table-column
                        prop="name"
                        label="亮点名称"
                        align="center">
                </el-table-column>
                <el-table-column 
                        prop="jlsort" 
                        label="显示排序"
                        align="center" 
                        width="300">
                </el-table-column>
                <el-table-column 
                        prop="createtime" 
                        label="添加时间" 
                        align="center" 
                        width="300">
                </el-table-column>
                <el-table-column label="操作"  align="center" width="500">
                    <template slot-scope="scope">
                        <el-button
                            type="primary"
                            size="medium"
                            @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        <el-button
                            type="danger"
                            size="medium"
                            @click="handleDel(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height:60px;text-align: center;">
                <!-- <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-sizes="[10,20, 30, 40, 50]"
                        :page-size="10"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="connt">
                </el-pagination> -->
            </div>
        </main>
        <!-- 添加 -->
          <el-dialog
            title="添加"
            :visible.sync="dialogAddVisible"
            width="30%">
             <div style="padding:10px"> 
                 <span class="ent_del">亮点名称：</span>
                 <el-input v-model="EntgoodsAdd.name" size="medium" style="width:82%" placeholder="请输入亮点名称"></el-input>
             </div>
             <div style="padding:10px"> 
                 <span class="ent_del">亮点排序：</span>
                 <el-input v-model="EntgoodsAdd.jlsort" size="medium" style="width:82%" placeholder="请输入亮点名称"></el-input>
             </div>
             <div>
                 <p>数字越大排序越靠后</p>
             </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogAddVisible = false">取 消</el-button>
                <el-button type="primary" @click="EntgoodsAddOK">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 编辑 -->
          <el-dialog
            title="编辑"
            :visible.sync="dialogEditVisible"
            width="30%">
             <div style="padding:10px"> 
                 <span class="ent_del">亮点名称：</span>
                 <el-input v-model="Entgoods.name" size="medium" style="width:82%" placeholder="请输入亮点名称"></el-input>
             </div>
             <div style="padding:10px"> 
                 <span class="ent_del">亮点排序：</span>
                 <el-input v-model="Entgoods.jlsort" size="medium" style="width:82%" placeholder="请输入亮点名称"></el-input>
             </div>
             <div>
                 <p>数字越大排序越靠后</p>
             </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogEditVisible = false">取 消</el-button>
                <el-button type="primary" @click="EntgoodsOK">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name:'Ent_goodspot',
    data(){
        return{
           name:'',
           tableData:[],
           connt:null,

           EntgoodsAdd:{},
           dialogAddVisible:false,

           dialogEditVisible:false,
           Entgoods:{}
        }
    },
    created(){
        this.getBasicsData()
    },
    methods:{
        getBasicsData:function(){
            var _this=this;
            axios({
                url: _this.API.baseJoblight.list,
                method: 'get',
                headers:{
                    token:_this.Util.getjwtToken(),
                },
                params:{
                    name:'',
                },
            }).then(function(res){
                if(res.data.success){
                    // console.log('=====',res)
                    _this.tableData=res.data.data;
                }else{
                    
                }
            })
        },
        //添加
         EntgoodsAdds:function(){
            this.dialogAddVisible=true
         },
         EntgoodsAddOK:function(){
            var _this=this;
            if(this.Util.isBlank(this.EntgoodsAdd.name)){
                _this.$message.error("请输入企业亮点名称！");
                return
            }
            axios({
                    url: _this.API.baseJoblight.add,
                    method: 'post',
                    headers:{
                        token:_this.Util.getjwtToken(),
                    },
                    data:this.EntgoodsAdd
                }).then(function(res){
                    // console.log('添加',res)
                    if(res.data.success){
                        _this.getBasicsData();
                        _this.EntgoodsAdd={};
                        _this.dialogAddVisible=false;
                        _this.$message.success("添加成功");
                    }else{
                       _this.$message.error("添加失败");
                    }
                })
         },
        //编辑
         handleEdit:function(index,row){
            this.dialogEditVisible=true;
            this.Entgoods=JSON.parse(JSON.stringify(row)); 
         },
         EntgoodsOK:function(){
            var _this=this;
            if(this.Util.isBlank(this.Entgoods.name)){
                _this.$message.error("请输入企业亮点名称！");
                return
            }
            axios({
                    url: _this.API.baseJoblight.update,
                    method: 'post',
                    headers:{
                        token:_this.Util.getjwtToken(),
                    },
                    data:this.Entgoods
                }).then(function(res){
                    // console.log('编辑',res)
                    if(res.data.success){
                        _this.getBasicsData();
                        _this.dialogEditVisible=false;
                        _this.$message.success("编辑成功");
                    }else{
                       _this.$message.error("编辑失败");
                    }
                })
         },
         //删除
         handleDel:function(index,row){
             var _this=this;
             this.$confirm('此操作将永久删除该亮点, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        _this.gethandleDelData(row.id)
                    }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
              });
         },
         gethandleDelData:function(id){
            var _this=this;
            axios({
                    url: _this.API.baseJoblight.delete,
                    method: 'DELETE',
                    headers:{
                        token:_this.Util.getjwtToken(),
                    },
                    params:{
                        id:id
                    }
                }).then(function(res){
                    // console.log('删除',res)
                    if(res.data.success){
                        _this.getBasicsData();
                        _this.$message.success("删除成功");
                    }else{
                       _this.$message.error("编辑失败");
                    }
                })
         },
         handleSizeChange:function(e){

         },
         handleCurrentChange:function(e){

         }

    }
}
</script>
<style lang="scss" scoped>

</style>