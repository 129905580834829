//封装接口地址
const API = {
  baseUrl: window.baseUrl
};

API.memberImportTempUrl = API.baseUrl + "/file/人员导入.xlsx";

API.login = {
  accountPwdLogin: API.baseUrl + '/mem/logrei/admin/accountPwdLogin'
};
//首页统计接口
API.statis = {
  statisticalRegistration: API.baseUrl + '/ent/ling/statis/statisticalRegistration',
  statisticalResume: API.baseUrl + '/ent/ling/statis/statisticalResume',
  statisticalEnterprise: API.baseUrl + '/ent/ling/statis/statisticalEnterprise',
  statisticalJobinfo: API.baseUrl + '/ent/ling/statis/statisticalJobinfo',
  statisticalInterviewInvitation: API.baseUrl + '/ent/ling/statis/statisticalInterviewInvitation',
  statisticalEmploymentArea: API.baseUrl + '/ent/ling/statis/statisticalEmploymentArea',
  statisticalResumedownload: API.baseUrl + '/ent/ling/statis/statisticalResumedownload',
  statisticalResumeDelivery: API.baseUrl + '/ent/ling/statis/statisticalResumeDelivery',
  statisticalCollectResume: API.baseUrl + '/ent/ling/statis/statisticalCollectResume',
  statisticalEmploymentMode: API.baseUrl + '/ent/ling/statis/statisticalEmploymentMode',
  statisticalEmployOutsideProvince: API.baseUrl + '/ent/ling/statis/statisticalEmployOutsideProvince',
  statisticalJobClassification: API.baseUrl + '/ent/ling/statis/statisticalJobClassification',
  statisticalPopularPositions: API.baseUrl + '/ent/ling/statis/statisticalPopularPositions',
  statisticalRecommendationTrend: API.baseUrl + '/ent/ling/statis/statisticalRecommendationTrend',
  statisticalViews: API.baseUrl + '/ent/ling/statis/statisticalViews',
}
API.permission = {
  loadLeftMenu: API.baseUrl + '/mem/permission/loadLeftMenu',
  list: API.baseUrl + '/mem/permission/list',
  menusByPid: API.baseUrl + '/mem/permission/menusByPid',
  addPermissionSelect: API.baseUrl + '/mem/permission/addPermissionSelect',
  add: API.baseUrl + '/mem/permission/add',
  update: API.baseUrl + '/mem/permission/update',
  delete: API.baseUrl + '/mem/permission/delete'
};
API.role = {
  list: API.baseUrl + '/mem/role/list',
  add: API.baseUrl + '/mem/role/add',
  authPerm: API.baseUrl + '/mem/role/authPerm',
  delete: API.baseUrl + '/mem/role/delete',
  update: API.baseUrl + '/mem/role/update',
  rolePerms: API.baseUrl + '/mem/role/rolePerms',
};
API.admin = {
  add: API.baseUrl + '/mem/admin/add',
  list: API.baseUrl + '/mem/admin/list',
  on: API.baseUrl + '/mem/admin/on',
  setPwd: API.baseUrl + '/mem/admin/setPwd',
  update: API.baseUrl + '/mem/admin/update',
  roleList: API.baseUrl + '/mem/ling/admin/roleList',
  roleSet: API.baseUrl + '/mem/ling/admin/roleSet',
  delete: API.baseUrl + '/mem/admin/delete'
};
API.person = {
  list: API.baseUrl + '/mem/person/list',
  setPwd: API.baseUrl + '/mem/person/setPwd'
}
API.Enterprise = {
  list: API.baseUrl + '/mem/enterprise/list',
  setPwd: API.baseUrl + '/mem/enterprise/setPwd'
}
//求职人才库，求职简历库
API.mem = {
  queryMemberInfo: API.baseUrl + '/mem/mem/queryMemberInfo',
  queryMemberInfoDetails: API.baseUrl + '/mem/mem/queryMemberInfoDetails',
  queryMemberSort: API.baseUrl + '/mem/mem/queryMemberSort',
  updMemberInfoSort: API.baseUrl + '/mem/mem/updMemberInfoSort',

  jobResumes: API.baseUrl + '/mem/member_manager/jobResumes',
  insert: API.baseUrl + '/mem/resume/insert',
  baseinfo: API.baseUrl + "/mem/resume/baseinfo",
  jobMems: API.baseUrl + '/mem/ling/mem/jobMems',
  talentList: API.baseUrl + '/mem/member_manager/talentList',
  talentExport: API.baseUrl + '/mem/member_manager/talentExport',
  exportExcel: API.baseUrl + '/mem/member_manager/exportExcel',
  deleteJl: API.baseUrl + '/mem/member_manager/resume/delete',
  deleteMember: API.baseUrl + '/mem/member_manager/member/delete'
}
//简历详情部分1
API.resume = {
  baseinfo: API.baseUrl + '/mem/resume/baseinfo/',
  work_wexp: API.baseUrl + "/mem/resume/work_wexp",
  work_exp: API.baseUrl + "/mem/resume/work_exp",
  edu_exp: API.baseUrl + "/mem/resume/edu_exp",
  train_exp: API.baseUrl + "/mem/resume/train_exp",
  project_exp: API.baseUrl + "/mem/resume/project_exp",
  major_skill: API.baseUrl + "/mem/resume/major_skill",
  lang_skill: API.baseUrl + "/mem/resume/lang_skill",
  //cert_exp: API.baseUrl + "/mem/resume/cert_exp",
  //下载简历
  download: API.baseUrl + '/file/resume/download/',
  getAbilityDraw: API.baseUrl + "/mem/resume/getAbilityDraw",
  //work_wexp:API.baseUrl+'/mem/resume/work_wexp/',
  //project_exp:API.baseUrl+'/mem/resume/project_exp/',
  //edu_exp:API.baseUrl+'/mem/resume/edu_exp/',
  //train_exp:API.baseUrl+'/mem/resume/train_exp/',
  //major_skill:API.baseUrl+'/mem/resume/major_skill/',
  //lang_skill:API.baseUrl+'/mem/resume/lang_skill/',
  cert_exp: API.baseUrl + '/mem/resume/cert_exp/'

}
//简历详情部分2
API.personal_mark = {
  job_apply: API.baseUrl + '/mem/personal_mark/job_apply',
  interview: API.baseUrl + '/mem/personal_mark/interview',
  inappropriate: API.baseUrl + '/mem/personal_mark/inappropriate',
  job_recommend: API.baseUrl + '/mem/personal_mark/job_recommend',
  job_take: API.baseUrl + '/mem/personal_mark/job_take',
  mark_count: API.baseUrl + '/mem/personal_mark/mark_count'
}
//职位数据库
API.ajob = {
  list: API.baseUrl + '/ent/ajob/list',
  detail: API.baseUrl + '/ent/ajob/detail',
  batchJobUrl: API.baseUrl + "/ent/ling/enterprise/exportJobinfos",
  batchAudit: API.baseUrl + '/ent/ajob/batchAudit',
  deleteJob: API.baseUrl + '/ent/ajob/delete',

}
//注册企业审核
API.aent = {
  entDblist: API.baseUrl + '/ent/aent/entDblist',
  regEntAuditList: API.baseUrl + '/ent/aent/regEntAuditList',
  detail: API.baseUrl + '/ent/aent/detail',
  audit: API.baseUrl + '/ent/aent/audit',
  album: API.baseUrl + '/ent/aent/album',
  add: API.baseUrl + '/ent/aent/add',
  delete: API.baseUrl + '/ent/aent/delete',
}
//企业福利
API.baseEw = {
  list: API.baseUrl + '/ent/baseEw/list',
  add: API.baseUrl + '/ent/baseEw/add',
  delete: API.baseUrl + '/ent/baseEw/delete',
  update: API.baseUrl + '/ent/baseEw/update'
}
//企业特色
API.baseEc = {
  list: API.baseUrl + '/ent/baseEc/list',
  add: API.baseUrl + '/ent/baseEc/add',
  delete: API.baseUrl + '/ent/baseEc/delete',
  update: API.baseUrl + '/ent/baseEc/update'
}
//职位亮点
API.baseJoblight = {
  list: API.baseUrl + '/ent/baseJoblight/list',
  add: API.baseUrl + '/ent/baseJoblight/add',
  delete: API.baseUrl + '/ent/baseJoblight/delete',
  update: API.baseUrl + '/ent/baseJoblight/update'
}
API.file = {
  uploadSingle: API.baseUrl + "/file/upload/single",
  export: API.baseUrl + "/file/statistics/export"      //自定义导出字段
}
//公司性质
API.baseEn = {
  list: API.baseUrl + '/ent/baseEn/list',
  add: API.baseUrl + '/ent/baseEn/add',
  delete: API.baseUrl + '/ent/baseEn/delete',
  update: API.baseUrl + '/ent/baseEn/update'
}
//企业规模
API.baseEcs = {
  list: API.baseUrl + '/ent/baseEcs/list',
  add: API.baseUrl + '/ent/baseEcs/add',
  delete: API.baseUrl + '/ent/baseEcs/delete',
  update: API.baseUrl + '/ent/baseEcs/update'
}
//员工规模
API.baseEss = {
  list: API.baseUrl + '/ent/baseEss/list',
  add: API.baseUrl + '/ent/baseEss/add',
  delete: API.baseUrl + '/ent/baseEss/delete',
  update: API.baseUrl + '/ent/baseEss/update',
}
//获取职位类别
API.jtAll = {
  findByPid: API.baseUrl + '/ent/jtAll/findByPid',
  add: API.baseUrl + "/ent/jtAll/add",
  update: API.baseUrl + "/ent/jtAll/update",
  delete: API.baseUrl + "/ent/jtAll/delete"
}
API.industry = {
  findByPid: API.baseUrl + "/ent/industry/findByPid",
  add: API.baseUrl + "/ent/industry/add",
  update: API.baseUrl + "/ent/industry/update",
  delete: API.baseUrl + "/ent/industry/delete"
}
//人力资源意向
API.hrIntention = {
  list: API.baseUrl + '/cms/hrIntention/list'
}
API.hrib_user = {
  list: API.baseUrl + '/cms/hrib_user/list',
  editor: API.baseUrl + '/cms/hrib_user/editor',
  delete: API.baseUrl + '/cms/hrib_user/delete'
}
//全局banner图片管理
API.banner_imgs = {
  list: API.baseUrl + '/cms/banner_imgs/list',
  editor: API.baseUrl + '/cms/banner_imgs/editor',
  delete: API.baseUrl + '/cms/banner_imgs/delete'
}
API.friends_links = {
  editor: API.baseUrl + '/cms/friends_links/editor',
  list: API.baseUrl + '/cms/friends_links/list',
  delete: API.baseUrl + '/cms/friends_links/delete'
}
//导航栏菜单
API.navmenu = {
  list: API.baseUrl + '/cms/navmenu/list',
  listChildren: API.baseUrl + '/cms/navmenu/listChildren',
  chk_navcode: API.baseUrl + '/cms/navmenu/chk_navcode',
  delete: API.baseUrl + '/cms/navmenu/delete',
  editor: API.baseUrl + '/cms/navmenu/editor',
  general_list: API.baseUrl + '/cms/navmenu/general_list'
}
API.copyright = {
  editor: API.baseUrl + '/cms/copyright/editor',
  info: API.baseUrl + '/cms/copyright/info'
}
//网站内容管理
API.banner_type = {
  select: API.baseUrl + '/cms/banner_type/select',
  list: API.baseUrl + '/cms/banner_type/list',
  listAll: API.baseUrl + '/cms/banner_type/listAll',
}
//首页职位推荐排序
API.jobinfo = {
  jobinfoSort: API.baseUrl + '/ent/ling/jobinfo/jobinfoSort',
  insert: API.baseUrl + '/ent/ling/jobinfo/insert',
  queryJobinfo: API.baseUrl + '/ent/ling/jobinfo/queryJobinfo',
  updJobinfoSort: API.baseUrl + '/ent/ling/jobinfo/updJobinfoSort',
  delJobinfoSort: API.baseUrl + '/ent/ling/jobinfo/delJobinfoSort',
  queryJoblist: API.baseUrl + '/ent/ling/jobinfo/queryJoblist'
}
//首页企业推荐排序
API.enterprise = {
  enterpriseSort: API.baseUrl + '/ent/ling/enterprise/enterpriseSort',
  queryEnterpriseInfo: API.baseUrl + '/ent/ling/enterprise/queryEnterpriseInfo',
  updEnterpriseSort: API.baseUrl + '/ent/ling/enterprise/updEnterpriseSort',
  //求职人
  getEnterpriseApplyJobPeople: API.baseUrl + '/ent/ling/enterprise/getEnterpriseApplyJobPeople',
  recommandJob: API.baseUrl + '/ent/enterprise/recommandJob',
  delEnterpriseSort: API.baseUrl + '/ent/ling/enterprise/delEnterpriseSort',
  getEnterPrisePhotoById: API.baseUrl + '/ent/enterprise/getEnterPrisePhotoByEntId',
  deleteJobInfo: API.baseUrl + '/ent/enterprise/deleteJobInfo'
}
//首页推荐人员排序
API.lingMem = {
  queryMemberSort: API.baseUrl + '/mem/ling/mem/queryMemberSort',
  queryMemberInfoSort: API.baseUrl + '/mem/ling/mem/queryMemberInfoSort',
  updMemberInfoSort: API.baseUrl + '/mem/ling/mem/updMemberInfoSort',
  delMemberSort: API.baseUrl + '/mem/ling/mem/delMemberSort'
}
//新闻模块
API.news = {
  menus: API.baseUrl + '/cms/news/menus',
  editor: API.baseUrl + '/cms/news/editor',
  // list:API.baseUrl+'/cms/news/list',
  detail: API.baseUrl + '/cms/news/detail',
  listPager: API.baseUrl + '/cms/news/listPager',
  status: API.baseUrl + '/cms/news/status',
  delete: API.baseUrl + '/cms/news/delete',
  menuDetail: API.baseUrl + '/cms/news/menuDetail',
  singleMenuArtList: API.baseUrl + '/cms/news/single_menu_art_list',
  smaEditor: API.baseUrl + '/cms/news/single_menu_art_editor',
  smaDetail: API.baseUrl + '/cms/news/sma_detail',
  sma_detail: API.baseUrl + '/cms/news/sma_detail',
  menus_editor: API.baseUrl + '/cms/news/menus_editor',
  menus_delete: API.baseUrl + '/cms/news/menus_delete'
}
//招聘会
API.job_fairs = {
  getRecruitMeetingList: API.baseUrl + '/cms/job_fairs/getRecruitMeetingList',
  getRecruitMeetingOne: API.baseUrl + '/cms/job_fairs/getRecruitMeetingOne',
  rmba_list: API.baseUrl + '/cms/job_fairs/rmba_list',
  rm_bootch_list: API.baseUrl + '/cms/job_fairs/rm_bootch_list',
  rm_bootch_status: API.baseUrl + "/cms/job_fairs/rm_bootch_status",
  setup_booth: API.baseUrl + "/cms/job_fairs/setup_booth",
  editor: API.baseUrl + '/cms/job_fairs/editor',
  rm_bootch_delete: API.baseUrl + "/cms/job_fairs/rm_bootch_delete",
  addRba: API.baseUrl + '/cms/job_fairs/addRba',
  rm_bootch_Detail: API.baseUrl + "/cms/job_fairs/rm_bootch_Detail",
  getRecruitMeetingReserve: API.baseUrl + '/cms/job_fairs/getRecruitMeetingReserve',//获取招聘会预定详情数据
  getBoothJob: API.baseUrl + '/cms/job_fairs/rm_bootch_Detail',//获取预定展位的企业和岗位
  boothReservation: API.baseUrl + '/cms/job_fairs/rm_bootch_reserve',//展位预定
}
//地址模块
API.areacode = {
  getAddrByLnglat: API.baseUrl + '/cms/areacode/getAddrByLnglat',
  getAreacode: API.baseUrl + "/ent/areacode/getAreacode"
}
//简历统计模块
API.statistics = {
  jobStatis: API.baseUrl + '/ent/statistics/jobStatis'
}
// 按照企业职位统计
API.general_statistics = {
  jobStatis: API.baseUrl + '/ent/general_statistics/jobStatis',
  hotTopMoreJobs: API.baseUrl + '/ent/general_statistics/hotTopMoreJobs',
  hotTopMoreCompany: API.baseUrl + '/ent/general_statistics/hotTopMoreCompany',
  applyTopMoreJobs: API.baseUrl + '/ent/general_statistics/applyTopMoreJobs',
  hotTopMoreIndustry: API.baseUrl + '/ent/general_statistics/hotTopMoreIndustry',
  contrast: API.baseUrl + '/ent/general_statistics/contrast'
}
export default API
