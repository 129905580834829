<template>
    <div class="height:100%">
        <el-row >
            <el-col :span="18">
               <nav class="navTab">
                <div :class="tabIndex==0?'isDivcom':''" @click="tabNavtilte(0)"><h2>已申请({{ResConnt.applyCount?ResConnt.applyCount:'0'}})</h2></div>
                <div :class="tabIndex==1?'isDivcom':''" @click="tabNavtilte(1)"><h2>邀请面试({{ResConnt.interviewCount?ResConnt.interviewCount:'0'}})</h2></div>
                <div :class="tabIndex==2?'isDivcom':''" @click="tabNavtilte(2)"><h2>不合适({{ResConnt.inappropriateCount?ResConnt.inappropriateCount:'0'}})</h2></div>
                <div :class="tabIndex==3?'isDivcom':''" @click="tabNavtilte(3)"><h2>推荐职位({{ResConnt.recommendCount?ResConnt.recommendCount:'0'}})</h2></div>
                <div :class="tabIndex==4?'isDivcom':''" @click="tabNavtilte(4)"><h2>已就业({{ResConnt.takeCount?ResConnt.takeCount:'0'}})</h2></div>
              </nav>
              <div style="width:100%;height: 100px;line-height:80px;text-align:center;font-size: 20px"
                v-if="!jobApply || jobApply.length <= 0">
                <div class="inte"></div>
                  <span>没有数据喽</span>
              </div>
              <div v-for="(item,index) in jobApply" :key="index">
                 <div class="inte"></div> 
                 <div class="Res_List">
                    <div>
                        <p class="ent_detail" style="font-size: 18px;">{{item.jobname}}
                           <span class="ent_del m_fl15" v-if="tabIndex<=1">申请时间：{{item.createtime}}</span>
                           <span class="ent_del m_fl15" v-else>申请时间：{{item.jccreatetime}}</span>
                       </p>
                       <p class="m_top20 ent_del">
                           <span style="color:#409eff;font-weight: bold;" v-if="item.min_salary==-1&&item.max_salary==-1">
                           面议</span>
                           <span style="color:#409eff;font-weight: bold;" v-else-if="item.min_salary==-1">
                           {{item.max_salary}}元以下</span>
                           <span style="color:#409eff;font-weight: bold;" v-else-if="item.max_salary==-1">
                           {{item.min_salary}}元以上</span>
                           <span style="color:#409eff;font-weight: bold;" v-else>
                           {{item.min_salary}}-{{item.max_salary}}元</span>
                         <span class="m_fl15">{{item.wprovince_name?item.wprovince_name:'无地址'}}{{item.wcity_name?'-'+item.wcity_name:''}}</span><span class="m_fl15">I</span>
                         <span class="m_fl15">{{item.showeducation?item.showeducation:'无学历'}}</span><span class="m_fl15">I</span>
                         
                        <span class="m_fl15" v-if="item.work_experience_min==-1&&item.work_experience_max==-1">
                           不限
                        </span>
                        <span class="m_fl15" v-else-if="item.work_experience_min!=-1&&item.work_experience_max!=-1">
                            {{item.work_experience_min}}-{{item.work_experience_max}}年
                        </span>
                        <span class="m_fl15" v-else-if="item.work_experience_min==-1">
                            {{item.work_experience_max}}年以下
                        </span>
                        <span class="m_fl15" v-else-if="item.work_experience_max==-1">
                            {{item.work_experience_min}}年以上
                        </span>
                       </p>
                    </div>
                    <div>
                        <p class="ent_detail" style="font-size: 15px;">{{item.entname}}</p>
                        <p class="ent_del m_top20" style="font-size: 15px;">
                            <span :title="item.indtype_names.length>5?item.indtype_names:''">{{item.Showindtype_names?item.Showindtype_names:'无'}}</span><span class="m_fl15">I</span>
                            <span class="m_fl15">招聘人数</span><span class="m_fl15">I</span>
                            <span class="m_fl15" v-if="item.recruit_num_max==-1">{{item.recruit_num_min}}人以上</span>
                            <span class="m_fl15" v-else>{{item.recruit_num_min}}-{{item.recruit_num_max}}人</span>
                        </p>
                    </div>
                 </div>
              </div>
              <!-- 分页 -->
                 <div class="bom_connt">
                    <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :page-sizes="[10,20,30,40,50]"
                            :page-size="10"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="connt">
                    </el-pagination>
                </div>
            </el-col>
            <el-col :span="6">
               <div class="Res_fr">
                 <div></div>
                 <div>
                     <div id="Resability">
                      
                     </div>
                     <!-- <div class="inte"></div>  -->
                     <!-- <div id="ResiPhone">
                      
                     </div> -->
                 </div>
               </div>

            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: "Res_Deliver",
        /*props: {
         comParam: Object
        },*/
        data(){
            return{
                 tabIndex:0,
                 jobApply:[],
                 page:1,
                 limit:10,
                 ResConnt:{},
                 connt:null,
                 getUrl:[
                     {url:this.API.personal_mark.job_apply},
                     {url:this.API.personal_mark.interview},
                     {url:this.API.personal_mark.inappropriate},
                     {url:this.API.personal_mark.job_recommend},
                     {url:this.API.personal_mark.job_take}
                 ],    
            }
        },
        created(){
            this.comParam = this.$router.currentRoute.query;
            this.memid=this.comParam.id;
            console.log(this.comParam);
            this.Url=this.getUrl[0].url;
            this.getJobResinfo(this.memid,this.Url);
            this.getResCont(this.memid);
        },
        mounted(){
           this.findRResability();
        //    this.$chart.ResAssess('ResiPhone') 
       },
        methods:{

           tabNavtilte:function(index){
               this.tabIndex=index;
               this.Url=this.getUrl[index].url;
               this.getJobResinfo(this.memid,this.Url);
           },
           findRResability: function(){
               var _this=this;
              axios({
                  url:this.API.resume.getAbilityDraw,
                  method:'get',
                  headers:{
                      token:_this.Util.getjwtToken(),
                  },
                  params:{
                      memid: this.memid
                  }
              }).then(function(res){
                   if(res.data.success){
                        var d = res.data.data;
                        var iindicator = [
                            { name: '社会能力', value: d.socialValue, max: 15},
                            { name: '语言能力', value: d.languageValue, max: 15},
                            { name: '领导力', value: d.leaderValue, max: 15},
                            { name: '工作能力', value: d.workValue, max: 15},
                            { name: '教育背景', value: d.educationValue, max: 15},
                            { name: '所获荣誉', value: d.rongyuValue, max: 15}
                        ];
                        _this.$chart.Resability('Resability',iindicator);
                   }else{

                   }
              })
           },
           //获取数量总数
           getResCont:function(Mid){
               var _this=this;
              axios({
                  url:this.API.personal_mark.mark_count,
                  method:'get',
                  headers:{
                      token:_this.Util.getjwtToken(),
                  },
                  params:{
                      memid:Mid
                  }
              }).then(function(res){
                   console.log('数量',res);
                   if(res.data.success){
                      _this.ResConnt=res.data.data;  
                   }else{

                   }
              })
           },
           getJobResinfo:function(Mid,idUrl){
               var _this=this;
              axios({
                    url: idUrl,
                    method: 'get',
                    headers:{
                        token:_this.Util.getjwtToken(),
                    },
                    params:{
                        memid:Mid,
                        page:this.page,
                        limit:this.limit
                   }
                }).then(function(res){
                    var resp=res.data;
                     console.log('申请',resp)
                    if(resp.success){
                        var temp=_this.Util.recordAsk;
                        for(var i=0;i<resp.data.list.length;i++){
                            if(resp.data.list[i].indtype_names&&resp.data.list[i].indtype_names.length>5){
                                resp.data.list[i].Showindtype_names=resp.data.list[i].indtype_names.substring(0,5)+'...' ; 
                            }else{
                               resp.data.list[i].Showindtype_names= resp.data.list[i].indtype_names;
                            }
                            for(var j=0;j<temp.length;j++){
                                if(temp[j].value==resp.data.list[i].education){
                                    resp.data.list[i].showeducation=temp[j].label
                                };  
                            }
                            
                        }
                       _this.jobApply=resp.data.list;
                       _this.connt=resp.data.pager.recordCount;
                    }else{
                       _this.jobApply=[];
                       _this.connt=null
                    }
                })
           },
           //分页
           handleSizeChange:function(e){
               this.limit=e;
               this.getJobResinfo(this.memid,this.Url);
           },
           handleCurrentChange:function(e){
               this.page=e;
               this.getJobResinfo(this.memid,this.Url);
           }

           
        }
        
    }
</script>

<style scoped lang="scss">
   .home{
      background: #fff
   }
   .bom_connt{
        background: #f6f7f6
   }
   #Resability,#ResiPhone{
     height:400px;
     padding: 10px;
   }
   .isDivcom{
      color:#000;
     border-bottom: 2px solid #000000; 
   }
   .inte{
       height:15px;
   }
</style>