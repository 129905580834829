<template>
    <div class="home">
        <div class="tilte_btn">
            <span class="lineY"></span>
            <span><h1>基础信息添加</h1></span>
            <el-button size="small" type="warning" class="el_btn" @click="updateMemberBaseinfo">保存</el-button>
        </div>
        <div style="padding:10px">
            <el-row>
                <el-col :span="8">
                    <div class="Numder_top_b" style="width: 100%;">
                       <el-upload
                            class="Numder_top" 
                            :action="uploadImgUrl"
                            :show-file-list="false"
                            :on-success="handlePortraitSuccess">
                            <img v-if="memberBaseinfo.portraitUrl" :src="memberBaseinfo.portraitUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon el-icon-user"></i>
                        </el-upload>
                    <div>
                        <div> 
                            <span class="ent_del" style="margin-left:15px">姓名：</span>
                            <el-input size="medium" @blur="baseInfoUsernameEdit" style="width:70%" placeholder="请输入姓名" v-model="memberBaseinfo.username"></el-input>
                        </div>
                        <div class="m_top10">
                            <span class="ent_del" style="margin-left:15px">学历：</span>
                            <el-select
                                placeholder="请选择学历"
                                v-model="memberBaseinfo.educationIndex"
                                @change="baseInfoEducationEdit"
                                clearable
                                style="width:70%"
                                size="medium">
                                <el-option
                                    v-for="(item, index) in recordAskArry"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="index"
                                ></el-option>
                            </el-select>  
                        </div>
                        <div class="m_top10"> 
                            <span class="ent_del" style="margin-left:15px">电话：</span>
                            <el-input size="medium" maxlength="11" style="width:70%" @blur="baseInfoPhoneEdit" placeholder="请输入联系方式" v-model="memberBaseinfo.phone"></el-input>
                        </div>
                        <div class="m_top10">
                            <span class="ent_del" style="margin-left:15px">民族：</span>
                            <el-input size="medium" style="width:70%" @blur="baseInfoNationEdit" placeholder="请输入民族" v-model="memberBaseinfo.nation"></el-input>
                        </div>
                        <div class="m_top10">
                           <span class="ent_del" style="margin-left:15px">邮箱：</span>
                           <el-input size="medium" @blur="baseInfoEmailEdit" style="width:70%" placeholder="请输入电子邮箱" v-model="memberBaseinfo.email"></el-input>
                        </div>
                    </div>
                </div>
                <div class="Numder_top_b">
                    <el-upload
                        class="avatar-updicon"
                        :action="uploadImgUrl"
                        :show-file-list="false"
                        :on-success="handleIdcardpSuccess">
                        <img v-if="memberBaseinfo.idcardp" :src="memberBaseinfo.idcardp">
                        <i v-else class="el-icon-circle-plus"></i>
                    </el-upload>
                    <el-upload
                        class="avatar-updicon"
                        :action="uploadImgUrl"
                        :show-file-list="false"
                        :on-success="handleIdcardnSuccess">
                        <img v-if="memberBaseinfo.idcardn" :src="memberBaseinfo.idcardn">
                        <i v-else class="el-icon-remove"></i>
                    </el-upload>
                </div>
            </el-col>
            <el-col :span="8">
            <div class="m_top5">
                <span class="ent_del">身份证：</span>
                <el-input size="medium" @blur="baseInfoIdcardEdit" v-model="memberBaseinfo.idcard" style="width:60%" placeholder="输入身份证后自动计算年龄和性别"></el-input>
            </div>
            <div class="m_top10">
                <span class="ent_del" style="margin-left:15px">年龄：</span>
                <el-input size="medium" @blur="baseInfoAgeEdit" style="width:60%" placeholder="请输入年龄" v-model="memberBaseinfo.age"></el-input>
            </div>
            <div class="m_top10">
                <span class="ent_del" style="margin-left:15px">生日：</span>
                <el-input size="medium" @blur="baseInfoBrithdayEdit" style="width:60%" placeholder="请输入生日" v-model="memberBaseinfo.brithday"></el-input>
            </div>
            <div class="m_top10">
                <span class="ent_del" style="margin-left:15px">性别：</span>
                <el-radio-group v-model="memberBaseinfo.sex" @change="baseInfoSexEdit">
                    <el-radio class="m_fl15" label="1">男</el-radio>
                    <el-radio label="2">女</el-radio>
                </el-radio-group>
                <div class="m_top10">
                    <span class="ent_del" style="margin-left:15px">婚姻：</span>
                    <el-radio-group v-model="memberBaseinfo.maritalStatus" @change="baseInfoMaritalStatusEdit">
                        <el-radio class="m_fl15" label="1">已婚</el-radio>
                        <el-radio label="0">未婚</el-radio>
                    </el-radio-group>
                </div>
                <div class="m_top10">
                    <span class="ent_del" style="margin-left:15px">毕业：</span>
                    <el-radio-group v-model="memberBaseinfo.isgraduation" @change="baseInfoIsgraduationEdit">
                        <el-radio class="m_fl15" label="1">是</el-radio>
                        <el-radio label="0">否</el-radio>
                    </el-radio-group>
                </div>
            </div>
        </el-col>
        <el-col :span="8">
             <div class="m_top10"> 
                <span class="ent_del">参加工作时间：</span>
                <el-date-picker @change="baseInfoWordtimeEdit" style="width:74%"
                    v-model="memberBaseinfo.worktime"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请输入参加工作时间">
                </el-date-picker>
             </div>
            <div :class="{'m_top10':true, 'ph':(memberBaseinfo.liveprovinceCode)}">
                <span class="ent_del">居住地址：</span>
                <el-cascader
                    @change="baseInfoLiveaddrEdit"
                    :props="addrProps"
                    clearable
                    :placeholder="memberBaseinfo.liveph"
                    size="medium"
                    style="width:55%"
                ></el-cascader>
                <el-input size="medium" style="width:26%" placeholder="详细地址" @blur="baseInfoLivedetailEdit" v-model="memberBaseinfo.livedetail"></el-input>
            </div>
            <div :class="{'m_top10':true, 'ph':(memberBaseinfo.hkprovinceCode)}">
                <span class="ent_del">户口地址：</span>
                <el-cascader
                    @change="baseInfoHkaddrEdit"
                    :props="addrProps"
                    clearable
                    :placeholder="memberBaseinfo.hkph"
                    size="medium"
                    style="width:55%"
                ></el-cascader>
                <el-input size="medium" style="width:26%" placeholder="详细地址" @blur="baseInfoHkdetailEdit" v-model="memberBaseinfo.hkdetail"></el-input>
            </div>
            <div class="m_top10">
                <span class="ent_del">登录账号：</span>
                <el-input placeholder="请输入登录账号" size="medium" style="width: 50%;" v-model="memberBaseinfo.account"></el-input>
            </div>
            <div class="m_top10">
                <span class="ent_del">登录密码：</span>
                <el-input placeholder="请输入登录密码" size="medium" style="width: 50%;" v-model="memberBaseinfo.pwd"></el-input>
            </div>
        </el-col>
    </el-row>
</div>
<main v-if="showId || memberBaseinfo.id">
     <div class="tilte_btn">
           <span class="lineY"></span>
           <span><h1>求职意向添加</h1></span>
           <el-button size="small" type="warning" class="el_btn" @click="updateMemberBaseinfo">保存</el-button>
     </div>
     <div style="padding:20px 10px">
          <el-row>
              <el-col :span="8">
                    <div :class="{'ph':(memberBaseinfo.ittprovinceCode)}">
                        <span class="ent_del" style="margin-left:15px">意向地点：</span>
                        <el-cascader
                            @change="baseInfoIttaddrEdit"
                            :props="addrProps"
                            clearable
                            :placeholder="memberBaseinfo.ittph"
                            size="medium"
                            style="width:60%"
                        ></el-cascader>
                    </div>
                    <div class="m_top10">
                        <span class="ent_del" style="margin-left:15px">期望薪资：</span>
                        <el-select
                            placeholder="请选择期望薪资"
                            v-model="memberBaseinfo.ittSalaryIndex"
                            @change="baseInfoIttsalaryEdit"
                            clearable
                            style="width:60%"
                            size="medium">
                            <el-option
                                v-for="(item, index) in salaryArr"
                                :key="'ittsalary' + index"
                                :label="item.label"
                                :value="index"
                            ></el-option>
                        </el-select>  
                    </div>
              </el-col>
              <el-col :span="8" :class="{'ph':(memberBaseinfo.ittcartypeNames)}">
                    <span class="ent_del" style="margin-left:15px">意向行业：</span>
                    <el-cascader
                        v-model="memberBaseinfo.industryInfoShowNames"
                        @change="baseInfoIttindustrysEdit"
                        :options="industryInfoSelectIds"
                        :props="industryInfoProps"
                        :show-all-levels="false"
                        clearable
                        :placeholder="memberBaseinfo.industryInfoPh"
                        size="medium"
                        style="width:60%"
                    ></el-cascader>
                    <div class="m_top15">
                      <span class="ent_del" style="margin-left:15px">意向类型：</span>
                      <el-radio-group v-model="memberBaseinfo.ittType" @change="baseInfoIttTypeEdit">
                            <el-radio class="m_fl15" label="1">全职</el-radio>
                            <el-radio label="2">兼职</el-radio>
                            <el-radio label="3">实习</el-radio>
                       </el-radio-group>
                  </div>
              </el-col>
              <el-col :span="8" :class="{'ph':(memberBaseinfo.ittcartypeNames)}">
                  <span class="ent_del" style="margin-left:15px">意向职位：</span>
                  <el-cascader
                        v-model="memberBaseinfo.careeInfoShowNames"
                        @change="baseInfoIttcareeInfoEdit"
                        :options="careeInfoSelectIds"
                        :props="careeInfoProps"
                        :show-all-levels="false"
                        clearable
                        :placeholder="memberBaseinfo.careeInfoPh"
                        size="medium"
                        style="width:60%"
                ></el-cascader>
              </el-col>
          </el-row>
     </div>
     <div class="tilte_btn">
           <span class="lineY"></span>
           <span><h1>工作经历添加</h1></span>
           <el-button size="small" type="primary" class="el_btn" @click="addWorkExperiencesEvt">添加新一条</el-button>
     </div>
     <div v-for="(item, index) in workExperiences" :key="'workex' + index">
            <span class="el-icon-error" title="点击可删除" @click="removeWorkExperience(index)"></span>
            <el-button type="text" title="点击保存成功" @click="saveWorkExperience(index)">保存</el-button>
            <el-row style="margin-bottom:15px">
                <el-col :span="8">
                    <span class="ent_del" style="margin-left:15px">公司名称：</span>
                    <el-input size="medium" v-model="item.entname" style="width:60%" placeholder="请输入所在公司"></el-input>
                    <div class="m_top10">
                        <span class="ent_del" style="margin-left:15px">职位名称：</span>
                        <el-input size="medium" v-model="item.jobname" style="width:60%" placeholder="请输入职位名称"></el-input>
                    </div>
                    <div :class="{'m_top10':true, 'ph':(item.indnames)}">
                        <span class="ent_del" style="margin-left:15px">所属行业：</span>
                        <el-cascader
                            v-model="item.indValue"
                            :options="item.indSelectIds"
                            :props="industryInfoProps"
                            :show-all-levels="false"
                            clearable
                            :placeholder="item.indInfoPh"
                            size="medium"
                            style="width:60%"
                        ></el-cascader>
                    </div>
                    <div :class="{'m_top10':true, 'ph':(item.carnames)}">
                       <span class="ent_del" style="margin-left:15px">所属职业：</span>
                       <el-cascader
                            v-model="item.carValue"
                            :options="item.carSelectIds"
                            :props="careeInfoProps"
                            :show-all-levels="false"
                            clearable
                            :placeholder="item.carInfoPh"
                            size="medium"
                            style="width:60%"
                        ></el-cascader>
                    </div>
                    <div class="m_top10">
                        <span class="ent_del" style="margin-left:15px">税前薪资：</span>
                        <el-select
                            placeholder="请选择税前薪资"
                            v-model="item.getSalaryIndex"
                            clearable
                            style="width:60%"
                            size="medium">
                            <el-option
                                v-for="(item, index) in salaryArr"
                                :key="'getSalary' + index"
                                :label="item.label"
                                :value="index"
                            ></el-option>
                        </el-select>
                    </div>
                </el-col>
                <el-col :span="8">
                    <span class="ent_del">入职时间：</span>
                    <el-date-picker
                        size="medium"
                        style="width:60%"
                        v-model="item.starttime"
                        type="date"
                        placeholder="选择入职时间"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                    <div class="m_top10">
                        <span class="ent_del">工作描述：</span>
                        <div style="width:96%;margin-top:5px">
                            <el-input
                                v-model="item.workDesc"
                                type="textarea"
                                :rows="6"
                                placeholder="请输入内容">
                            </el-input>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <span class="ent_del">离职时间：</span>
                    <el-date-picker
                        size="medium"
                        style="width:60%"
                        v-model="item.endtime"
                        type="date"
                        placeholder="选择离职时间"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                     <div class="m_top10">
                        <span class="ent_del">工作业绩：</span>
                        <div style="width:96%;margin-top:5px">
                            <el-input
                                v-model="item.workArch"
                                type="textarea"
                                :rows="6"
                                placeholder="请输入内容">
                            </el-input>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <div class="inte"></div>
     </div>
     <div class="tilte_btn">
           <span class="lineY"></span>
           <span><h1>项目经验添加</h1></span>
           <el-button size="small" type="primary" class="el_btn" @click="addProjectExperiencesEvt">添加新一条</el-button>
     </div>
     <div v-for="(item, index) in projectExperiences" :key="'project' + index">
            <span class="el-icon-error" title="点击可删除" @click="removeProjectExperience(index)"></span>
            <el-button type="text" title="点击保存成功" @click="saveProjectExperience(index)">保存</el-button>
            <el-row style="margin-bottom:15px">
                <el-col :span="8">
                    <span class="ent_del" style="margin-left:15px">项目名称：</span>
                    <el-input size="medium" v-model="item.projectName" style="width:60%" placeholder="请输入所在公司"></el-input>
                    <div class="m_top10">
                        <span class="ent_del" style="margin-left:15px">个人职责：</span>
                        <el-input size="medium" v-model="item.personalDuties" style="width:60%" placeholder="请输入职位名称"></el-input>
                    </div>
                    <div class="m_top10">
                        <span class="ent_del" style="margin-left:15px">所属企业：</span>
                        <el-select
                            placeholder="选择企业"
                            v-model="item.weIndex"
                            clearable
                            style="width:60%"
                            size="medium">
                            <el-option
                                v-for="(item, index) in workExperiences"
                                :key="'getSalary' + index"
                                :disabled="!item.id"
                                :label="item.entname"
                                :value="index"
                            ></el-option>
                        </el-select>
                    </div>
                    <div class="m_top10">
                        <span class="ent_del" style="margin-left:15px">开始时间：</span>
                        <el-date-picker
                            size="medium"
                            style="width:60%"
                            v-model="item.starttime"
                            type="date"
                            placeholder="开始时间"
                            value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </div>
                    <div class="m_top10">
                        <span class="ent_del" style="margin-left:15px">结束时间：</span>
                        <el-date-picker
                            size="medium"
                            style="width:60%"
                            v-model="item.endtime"
                            type="date"
                            placeholder="结束时间"
                            value-format="yyyy-MM-dd">
                        </el-date-picker>
                        <el-checkbox v-if="item.endtime==-1" v-model="checkbox" style="margin-left: 10px;">至今</el-checkbox>
                    </div>
                </el-col>
                <el-col :span="8">
                    <span class="ent_del">项目描述：</span>
                    <div style="width:96%;margin-top:5px">
                        <el-input
                            v-model="item.projectDesc"
                            type="textarea"
                            :rows="8"
                            placeholder="请输入内容">
                        </el-input>
                    </div>
                </el-col>
                <el-col :span="8">
                    <span class="ent_del">项目业绩：</span>
                    <div style="width:96%;margin-top:5px">
                        <el-input
                            v-model="item.projectArch"
                            type="textarea"
                            :rows="8"
                            placeholder="请输入内容">
                        </el-input>
                    </div>
                </el-col>
            </el-row>
            <div class="inte"></div>
     </div>
     <!-- 教育背景 -->
     <div class="tilte_btn">
           <span class="lineY"></span>
           <span><h1>教育背景添加</h1></span>
           <el-button size="small" type="primary" class="el_btn" @click="addEduExperiencesEvt">添加新一条</el-button>
     </div>

     <div v-for="(item,index) in eduExperiences" :key="'ffftt' + index">
            <span class="el-icon-error" title="点击可删除" @click="removeEduExperience(index)"></span>
            <el-button type="text" title="点击保存成功" @click="saveEduExperience(index)">保存</el-button>
            <el-row>
                <el-col :span="8">
                    <span class="ent_del" style="margin-left:15px">学校名称：</span>
                    <el-input size="medium" v-model="item.schoolName" style="width:60%" placeholder="请输入所在学校"></el-input>
                </el-col>
                <el-col :span="8">
                    <span class="ent_del" style="margin-left:15px">所属专业：</span>
                    <el-input size="medium" v-model="item.majorName" style="width:60%" placeholder="请填写专业"></el-input>
                </el-col>
                <el-col :span="8">
                    <span class="ent_del" style="margin-left:15px">学历：</span>
                    <el-select
                        placeholder="请选择学历"
                        v-model="item.educationIndex"
                        clearable
                        style="width:70%"
                        size="medium">
                        <el-option
                            v-for="(item, index) in recordAskArry"
                            :key="'education' + index"
                            :label="item.label"
                            :value="index"
                        ></el-option>
                    </el-select>
                </el-col>
            </el-row>
            <el-row class="m_top10" style="margin-bottom:20px">
                <el-col :span="8">
                    <span class="ent_del" style="margin-left:15px">入学时间：</span>
                    <el-date-picker
                        style="width:60%"
                        size="medium"
                        v-model="item.starttime"
                        type="date"
                        placeholder="请选择入学时间"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-col>
                <el-col :span="8">
                    <span class="ent_del" style="margin-left:15px">毕业时间：</span>
                    <el-date-picker
                        style="width:60%"
                        size="medium"
                        v-model="item.endtime"
                        type="date"
                        placeholder="请选择毕业时间"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-col>
            </el-row>
            <div class="inte"></div>
     </div>
     <!-- 培训经验 -->
     <div class="tilte_btn">
           <span class="lineY"></span>
           <span><h1>培训经验添加</h1></span>
           <el-button size="small" type="primary" class="el_btn" @click="addTrainExperiencesEvt">添加新一条</el-button>
     </div>
     <div v-for="(item,index) in trainExperiences" :key="'dddfd' + index">
            <span class="el-icon-error" title="点击可删除" @click="removeTrainExperience(index)"></span>
            <el-button type="text" title="点击保存成功" @click="saveTrainExperience(index)">保存</el-button>
            <el-row style="margin-bottom:20px">
                <el-col :span="6">
                    <span class="ent_del" style="margin-left:15px">培训机构：</span>
                    <el-input size="medium" v-model="item.institutionsName" style="width:60%" placeholder="请输入所在学校"></el-input>
                </el-col>
                <el-col :span="6">
                    <span class="ent_del" style="margin-left:15px">培训课程：</span>
                    <el-input size="medium" v-model="item.courseName" style="width:60%" placeholder="请填写专业"></el-input>
                </el-col>
                <el-col :span="6">
                    <span class="ent_del" style="margin-left:15px">开始时间：</span>
                    <el-date-picker
                        style="width:60%"
                        size="medium"
                        v-model="item.starttime"
                        type="date"
                        placeholder="开始时间"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-col>
                <el-col :span="6">
                    <span class="ent_del" style="margin-left:15px">结束时间：</span>
                    <el-date-picker
                        style="width:60%"
                        size="medium"
                        v-model="item.endtime"
                        type="date"
                        placeholder="结束时间"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-col>
            </el-row>
            <div class="inte"></div>
     </div>
     <!-- 专业技能 -->
     <div class="tilte_btn">
           <span class="lineY"></span>
           <span><h1>专业技能添加</h1></span>
           <el-button size="small" type="primary" class="el_btn" @click="addMajorSkillEvt">添加新一条</el-button>
     </div>
     <div v-for="(item,index) in majorSkills" :key="'majorSkills' + index">
            <span class="el-icon-error" title="点击可删除" @click="removeMajorSkill(index)"></span>
            <el-button type="text" title="点击保存成功" @click="saveMajorSkill(index)">保存</el-button>
            <el-row style="margin-bottom:20px">
                <el-col :span="8">
                    <span class="ent_del" style="margin-left:15px">技能名称：</span>
                    <el-input size="medium" v-model="item.skillName" style="width:60%" placeholder="请输入技能名称"></el-input>
                </el-col>
                <el-col :span="8">
                    <span class="ent_del" style="margin-left:15px">技能时长：</span>
                    <el-input type="number" size="medium" v-model="item.useTimeLength" style="width:60%" placeholder="请填写技能时长"></el-input>
                </el-col>
                <el-col :span="8">
                    <span class="ent_del" style="margin-left:15px">掌握程度：</span>
                    <el-select
                        placeholder="请选择掌握程度"
                        v-model="item.masterLevelIndex"
                        clearable
                        style="width:70%"
                        size="medium">
                        <el-option
                            v-for="(item, index) in masterLevels"
                            :key="'education' + index"
                            :label="item.label"
                            :value="index"
                        ></el-option>
                    </el-select>
                </el-col>
            </el-row>
            <div class="inte"></div>
     </div>
     <!-- 证书 -->
     <div class="tilte_btn">
           <span class="lineY"></span>
           <span><h1>证书添加</h1></span>
           <el-button size="small" type="primary" class="el_btn" @click="addCertificateEvt">添加新一条</el-button>
     </div>
     <div v-for="(item,index) in certificate" :key="'ff' + index">
            <span class="el-icon-error" title="点击可删除" @click="removeCertificate(index)"></span>
            <el-button type="text" title="点击保存成功" @click="saveCertificate(index)">保存</el-button>
            <el-row style="margin-bottom:20px">
                <el-col :span="8">
                    <span class="ent_del" style="margin-left:15px">证书名称：</span>
                    <el-input size="medium" v-model="item.name" style="width:60%" placeholder="请输入所在学校"></el-input>
                </el-col>
                <el-col :span="8">
                    <span class="ent_del" style="margin-left:15px">获得时间：</span>
                    <el-date-picker
                        style="width:60%"
                        size="medium"
                        v-model="item.gettime"
                        type="date"
                        placeholder="获得日期"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-col>
            </el-row>
            <div class="inte"></div>
     </div>
     <!-- 语言能力 -->
     <div class="tilte_btn">
           <span class="lineY"></span>
           <span><h1>语言能力添加</h1></span>
           <el-button size="small" type="primary" class="el_btn" @click="addLanguageEvt">添加新一条</el-button>
     </div>
     <div v-for="(item,index) in language" :key="'fff' + index">
            <span class="el-icon-error" title="点击可删除" @click="removeLanguage(index)"></span>
            <el-button type="text" title="点击保存成功" @click="saveLanguage(index)">保存</el-button>
            <el-row style="margin-bottom:20px">
                <el-col :span="8">
                    <span class="ent_del" style="margin-left:15px">语言名称：</span>
                    <el-input size="medium" v-model="item.language" style="width:60%" placeholder="请输入所在学校"></el-input>
                </el-col>
                <el-col :span="8">
                    <span class="ent_del" style="margin-left:15px">听说能力：</span>
                    <el-select
                        placeholder="请选择听说能力"
                        v-model="item.hearSayLevelIndex"
                        clearable
                        style="width:70%"
                        size="medium">
                        <el-option
                            v-for="(item, index) in masterLevels"
                            :key="'hearSayLevel' + index"
                            :label="item.label"
                            :value="index"
                        ></el-option>
                    </el-select>
                </el-col>
                <el-col :span="8">
                    <span class="ent_del" style="margin-left:15px">读写能力：</span>
                    <el-select
                        placeholder="请选择读写能力"
                        v-model="item.readWriteLevelIndex"
                        clearable
                        style="width:70%"
                        size="medium">
                        <el-option
                            v-for="(item, index) in masterLevels"
                            :key="'readWriteLevel' + index"
                            :label="item.label"
                            :value="index"
                        ></el-option>
                    </el-select>
                </el-col>
            </el-row>
            <div class="inte"></div>
        </div>
    </main>
 </div>
</template>
<script>
    export default {
        name:'Numder_add',
        data(){
            return{
                showId:'',//是否显示简历
                checkbox:true,
                memberBaseinfo: {},
                workExperiences: [],
                projectExperiences: [],
                eduExperiences: [],
                trainExperiences: [],
                majorSkills: [],
                certificate: [],
                language: [],
                uploadImgUrl: this.API.file.uploadSingle,//类型列表地址

                imageUrl:'',

                recordAskArry:[],//学历
                experWorkAskArry:[],//工作经验
                salaryArr: [], //薪资
                masterLevels: this.Util.masterLevels,
                NumderjbInfo:{},
                addrProps:{
                    lazy: true,
                    value: "areaCode",
                    checkStrictly: true,
                    label: "areaTitle",
                    lazyLoad: this.addrLazyLoad
                },
                industryInfoSelectIds:[],//行业分类
                industryInfoProps: {//行业分类多级联动
                    lazy: true,
                    value: "id",
                    checkStrictly: true,
                    label: "name",
                    multiple: true,
                    lazyLoad: this.industryInfoLazyLoad
                },
                careeInfoSelectIds:[],//职位分类
                careeInfoProps:{
                    lazy: true,
                    value: "id",
                    checkStrictly: true,
                    label: "name",
                    multiple: true,
                    lazyLoad: this.careeInfoLazyLoad
                },
            }
        },
        created(){
            this.comParam = this.$router.currentRoute.query;
            if(this.comParam){
                this.memberBaseinfo.id = this.comParam.id;
                this.findDetail();
                this.findWorkExperience();
                this.findProjectExperience();
                this.findEduExperience();
                this.findTrainExperience();
                this.findMajorSkill();
                this.findCertificate();
                this.findLanguage();
            }
            var recordAskArry=JSON.parse(JSON.stringify(this.Util.recordAsk));
            recordAskArry.splice(0,1);
            this.recordAskArry=recordAskArry;

            var experWorkAskArry=JSON.parse(JSON.stringify(this.Util.experWorkAsk));
            experWorkAskArry.splice(0,1);
            this.experWorkAskArry=experWorkAskArry;

            var salaryArr = JSON.parse(JSON.stringify(this.Util.salaryArr));
            salaryArr.splice(0,1);
            this.salaryArr = salaryArr;
        },
        methods:{
            findDetail: function(){
                var _this = this;
                axios({
                    url: _this.API.mem.baseinfo + "/" + _this.memberBaseinfo.id,
                    method: 'get',
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                }).then(function(e){
                    let resp = e.data;
                    if(resp.success){
                        //单选框
                        resp.data.sex = "" + resp.data.sex;
                        resp.data.maritalStatus = "" + resp.data.maritalStatus;
                        resp.data.isgraduation = "" + resp.data.isgraduation;
                        resp.data.baseInfoIttTypeEdit =  "" + resp.data.baseInfoIttTypeEdit;
                        //学历
                        var educationIndex = 0;
                        for(var i = 0 ; i < _this.recordAskArry.length; i ++){
                            if(resp.data.education == _this.recordAskArry[i].value){
                                educationIndex = i;
                            }
                        }
                        resp.data.educationIndex = educationIndex;
                        //生日
                        if(resp.data.brithday && resp.data.brithday.length>10){
                            resp.data.brithday=resp.data.brithday.substring(0,10)
                        }
                        //居住地址
                        if(resp.data.liveprovinceCode){
                            var liveph = "";
                            if(resp.data.liveprovinceName){
                                liveph += resp.data.liveprovinceName;
                            }
                            if(resp.data.livecityName){
                                liveph += resp.data.livecityName;
                            }
                            if(resp.data.livecountyName){
                                liveph += resp.data.livecountyName;
                            }
                             resp.data.liveph = liveph;
                        }else{
                            resp.data.liveph = "请选择居住地址";
                        }
                        //户口地址
                        if(resp.data.hkprovinceCode){
                            var hkph = "";
                            if(resp.data.hkprovinceName){
                                hkph += resp.data.hkprovinceName;
                            }
                            if(resp.data.hkcityName){
                                hkph += resp.data.hkcityName;
                            }
                            if(resp.data.hkcountyName){
                                hkph += resp.data.hkcountyName;
                            }
                             resp.data.hkph = hkph;
                        }else{
                            resp.data.hkph = "请选择户口地址";
                        }
                        //期望地址
                        if(resp.data.ittprovinceCode){
                            var ittph = "";
                            if(resp.data.ittprovinceName){
                                ittph += resp.data.ittprovinceName;
                            }
                            if(resp.data.ittcityName){
                                ittph += resp.data.ittcityName;
                            }
                            resp.data.ittph = ittph;
                        }else{
                            resp.data.ittph = "请选择期望地址";
                        }
                        //期望薪资
                        var ittSalaryIndex = 0;
                        for(var i = 0 ; i < _this.salaryArr.length; i ++){
                            if(resp.data.ittminSalary == _this.salaryArr[i].min
                                && resp.data.ittmaxSalary == _this.salaryArr[i].max){
                                ittSalaryIndex = i;
                            }
                        }
                        resp.data.ittSalaryIndex = ittSalaryIndex;
                        if(resp.data.ittindtypeNames){
                            resp.data.industryInfoPh = resp.data.ittindtypeNames.replace(/-/g," ");
                        }else{
                            resp.data.industryInfoPh =  "请选择意向行业分类";
                        }
                        if(resp.data.ittcartypeNames){
                            resp.data.careeInfoPh = resp.data.ittcartypeNames.replace(/-/g, " ");
                        }else{
                            resp.data.careeInfoPh =  "请选择意向职位类型";
                        }
                        _this.memberBaseinfo = resp.data;
                        // console.log('_this.memberBaseinfo',_this.memberBaseinfo)
                    }
                })
            },
            baseInfoUsernameEdit: function(e){
                if(e.target.value){
                    this.memberBaseinfo.username = e.target.value;
                }
            },
            baseInfoEducationEdit: function(e){
                if(e){
                    this.memberBaseinfo.education = this.recordAskArry[e].value;
                }else{
                    this.memberBaseinfo.education = ''  
                }
            },
            baseInfoWordtimeEdit: function(tiem){
                if(tiem){
                    this.memberBaseinfo.worktime = tiem;
                    
                }else{
                   this.memberBaseinfo.worktime = ''; 
                }
            },
            baseInfoPhoneEdit: function(e){
                if(e.target.value){
                    this.memberBaseinfo.phone = e.target.value;
                }
            }, 
            baseInfoNationEdit: function(e){
                if(e.target.value){
                    this.memberBaseinfo.nation = e.target.value;
                }
            },
            baseInfoIdcardEdit: function(e){
                if(e.target.value){
                    this.memberBaseinfo.idcard = e.target.value;
                }
            }, 
            baseInfoEmailEdit: function(e){
                if(e.target.value){
                    this.memberBaseinfo.email = e.target.value;
                }
            },
            baseInfoSexEdit: function(e){
                this.memberBaseinfo.sex = e;
            },
            baseInfoBrithdayEdit: function(e){
                if(e.target.value){
                    this.memberBaseinfo.brithday = e.target.value;
                }
            },
            baseInfoAgeEdit: function(e){
                if(e.target.value){
                    this.memberBaseinfo.age = e.target.value;
                }
            },
            baseInfoLiveaddrEdit: function(e){
                if(e && e.length > 0){
                    this.memberBaseinfo.liveprovinceCode = e[0];
                }
                if(e && e.length > 1){
                    this.memberBaseinfo.livecityCode = e[1];
                }
                if(e && e.length > 2){
                    this.memberBaseinfo.livecountyCode = e[2];
                }
            },
            baseInfoLivedetailEdit: function(e){
                if(e.target.value){
                    this.memberBaseinfo.livedetail = e.target.value;
                }
            },
            baseInfoHkaddrEdit: function(e){
                if(e && e.length > 0){
                    this.memberBaseinfo.hkprovinceCode = e[0];
                }
                if(e && e.length > 1){
                    this.memberBaseinfo.hkcityCode = e[1];
                }
                if(e && e.length > 2){
                    this.memberBaseinfo.hkcountyCode = e[2];
                }
            },
            baseInfoHkdetailEdit: function(e){
                if(e.target.value){
                    this.memberBaseinfo.hkdetail = e.target.value;
                }
            },
            baseInfoIsgraduationEdit: function(e){
                this.memberBaseinfo.isgraduation = e;
            }, 
            baseInfoMaritalStatusEdit: function(e){
                this.memberBaseinfo.maritalStatus = e;
            },
            baseInfoIttaddrEdit: function(e){
                if(e && e.length > 0){
                    this.memberBaseinfo.ittprovinceCode = e[0];
                }
                if(e && e.length > 1){
                    this.memberBaseinfo.ittcityCode = e[1];
                }
            },
            baseInfoIttsalaryEdit: function(e){
                this.memberBaseinfo.ittminSalary = this.salaryArr[e].min;
                this.memberBaseinfo.ittmaxSalary = this.salaryArr[e].max;
            },
            baseInfoIttindustrysEdit: function(arrid) {
                console.log(arrid);
                var indtypeIds = "";
                if(arrid && arrid.length > 0){
                    indtypeIds = arrid[0][arrid[0].length - 1]
                    for(var i = 1; i < arrid.length; i ++){
                        indtypeIds += "-" + arrid[i][arrid[i].length - 1]
                    }
                    this.memberBaseinfo.ittindtypeIds = indtypeIds;
                }
            }, 
            baseInfoIttcareeInfoEdit:function(arrid){
                var cartypeIds = "";
                if(arrid && arrid.length > 0){
                    cartypeIds = arrid[0][arrid[0].length - 1]
                    for(var i = 1; i < arrid.length; i ++){
                        cartypeIds += "-" + arrid[i][arrid[i].length - 1]
                    }
                    this.memberBaseinfo.ittcartypeIds = cartypeIds;
                }
            },
            baseInfoIttTypeEdit: function(e){
                this.memberBaseinfo.ittType = e;
            },
            updateMemberBaseinfo: function(){
                var _this = this;
                if(!this.memberBaseinfo.portraitUrl){
                    _this.$message.error('请上传头像'); 
                    return
                }
                if(!this.memberBaseinfo.idcardp||!this.memberBaseinfo.idcardn){
                    _this.$message.error('请上传身份证正反照'); 
                    return
                }
                if(!this.memberBaseinfo.username){
                    _this.$message.error('请输入姓名'); 
                    return
                }
                if(this.memberBaseinfo.educationIndex === ''){
                    _this.$message.error('请选择学历'); 
                    return
                }
                if(!this.memberBaseinfo.phone){
                    _this.$message.error('请输入电话号码'); 
                    return
                }
                if(!this.memberBaseinfo.nation){
                    _this.$message.error('请输入名族'); 
                    return
                }
                if(!this.memberBaseinfo.email){
                    _this.$message.error('请输入邮箱'); 
                    return
                }
                if(!this.memberBaseinfo.idcard){
                    _this.$message.error('请输入身份证'); 
                    return
                }
                if(!this.memberBaseinfo.age){
                    _this.$message.error('请输入年龄'); 
                    return
                }
                if(!this.memberBaseinfo.brithday){
                    _this.$message.error('请输入生日'); 
                    return
                }
                if(!this.memberBaseinfo.sex){
                    _this.$message.error('请输入性别'); 
                    return
                }
                if(!this.memberBaseinfo.account){
                    _this.$message.error('请输入登录账号');
                    return
                }
                if(this.memberBaseinfo.pwd){
                    this.memberBaseinfo.password = this.$md5(this.memberBaseinfo.pwd)
                }
                // if(!this.memberBaseinfo.password){
                //     _this.$message.error('请输入登录密码');
                //     return
                // }
                axios({
                    url: _this.API.mem.baseinfo,
                    method: 'post',
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                    data: _this.memberBaseinfo
                }).then(function(e){
                    let resp = e.data;
                    console.log("添加编辑基本信息", resp);
                    if(resp.success){
                        _this.showId = resp.data;
                        _this.memberBaseinfo.id = resp.data;
                        _this.$message.success(resp.msg);
                    }else{
                        _this.$message.error(resp.msg);
                    }
                })
            },
            /**
             * 头像上传成功
             */
            handlePortraitSuccess:function(res,file){
                if(res.success){
                    this.$set(this.memberBaseinfo,'portraitUrl',res.data)
                }else{
                    this.$message.error(res.msg);
                }
            },
            handleIdcardnSuccess:function(res,file){
                if(res.success){
                    this.$set(this.memberBaseinfo,'idcardn',res.data)
                }else{
                    this.$message.error(res.msg);
                }
            },
            handleIdcardpSuccess:function(res,file){
                if(res.success){
                    this.$set(this.memberBaseinfo,'idcardp',res.data)
                }else{
                    this.$message.error(res.msg);
                }
            },
            //工作经验 -- start
            findWorkExperience: function(){
                var _this = this;
                axios({
                    url: _this.API.resume.work_wexp +"/" + _this.memberBaseinfo.id,
                    method: 'get',
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                }).then(function(e){
                    var resp = e.data;
                    if(resp.success){
                        for(var i = 0; i < resp.data.length; i ++){
                            var getSalaryIndex = 0;
                            for(var j = 0; j < _this.salaryArr.length; j ++){
                                if(resp.data[i].getSalaryMin == _this.salaryArr[j].min
                                    && resp.data[i].getSalaryMax == _this.salaryArr[j].max){
                                    getSalaryIndex = j;
                                }
                            }
                            resp.data[i].getSalaryIndex = getSalaryIndex;
                            if(resp.data[i].indids){
                                var indValue = [];
                                var indids = resp.data[i].indids.split('-');
                                for(var k = 0; k < indids.length; k ++){
                                    indValue[k] = [indids[k]];
                                }
                                resp.data[i].indValue = indValue;
                            }
                            if(resp.data[i].indnames){
                                resp.data[i].indInfoPh = resp.data[i].indnames.replace(/-/g, " ");
                            }
                            if(resp.data[i].carids){
                                var carValue = [];
                                var carids = resp.data[i].carids.split('-');
                                for(var k = 0; k < carids.length; k ++){
                                    carValue[k] = [carids[k]];
                                }
                                resp.data[i].carValue = carValue;
                            }
                            if(resp.data[i].carnames){
                                resp.data[i].carInfoPh = resp.data[i].carnames.replace(/-/g, " ");
                            }
                        }
                        _this.workExperiences = resp.data;
                    }
                })
            },
            addWorkExperiencesEvt: function(){
                this.workExperiences.push({
                    memid: this.memberBaseinfo.id,
                    indValue: '',
                    getSalaryIndex: 0,
                    indSelectIds: [],
                    carSelectIds: []
                });
            },
            removeWorkExperience: function(index){
                var _this = this;
                _this.$confirm('先想好是不是要删除？', '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '点错了',
                    type: 'warning'
                }).then(() => {
                    var item = _this.workExperiences[index];
                    if(item.id){
                        axios({
                            url: _this.API.resume.work_wexp,
                            method: 'delete',
                            headers:{
                                token:this.Util.getjwtToken(),
                            },
                            params: {
                                id: item.id,
                                memid: item.memid
                            }
                        }).then(function(e){
                            var resp = e.data;
                            if(resp.success){
                                _this.workExperiences.splice(index, 1);
                                _this.$message(resp.msg);
                            }else{
                                _this.$message.error(resp.msg);
                            }
                        }).catch(function(e){
                            console.log('saveWorkExperience', e);
                        })
                    }else{
                        _this.workExperiences.splice(index, 1);
                    }
                })
            }, 
            saveWorkExperience: function(index){
                var _this = this;
                if(_this.saveWorkExperienceing){
                    return;
                }

                var item = this.workExperiences[index];
                if(!item.entname){
                    _this.$message.error('公司名称不能为空');
                    return;
                }
                if(!item.jobname){
                    _this.$message.error('职位名称不能为空');
                    return;
                }
                if(!item.starttime){
                    _this.$message.error('入职时间不能为空');
                    return;
                }
                if(!item.endtime){
                    _this.$message.error('离职时间不能为空');
                    return;
                }
                if(item.starttime>item.endtime){
                   _this.$message.error('入职时间不能大于离职时间');
                    return;
                }
                if(!item.workDesc){
                    _this.$message.error('工作描述不能为空');
                    return;
                }
                if(!item.workArch){
                    _this.$message.error('工作业绩不能为空');
                    return;
                }
                if(item.indValue && item.indValue.length > 0){
                    var indids = "";
                    for(var i = 0; i < item.indValue.length; i ++){
                        if(i > 0){
                            indids += "-";
                        }
                        indids += item.indValue[i][item.indValue[i].length - 1];
                    }
                    item.indids = indids;
                }else{
                    _this.$message.error('请选择所属行业');
                    return;
                }
                if(item.carValue && item.carValue.length > 0){
                    var carids = "";
                    for(var i = 0; i < item.carValue.length; i ++){
                        if(i > 0){
                            carids += "-";
                        }
                        carids += item.carValue[i][item.carValue[i].length - 1];
                    }
                    item.carids = carids;
                }else{
                    _this.$message.error('请选择所属行业');
                    return;
                }
                item.getSalaryMin = this.salaryArr[item.getSalaryIndex].min;
                item.getSalaryMax = this.salaryArr[item.getSalaryIndex].max;
                
                _this.saveWorkExperienceing = true;
                axios({
                    url: _this.API.resume.work_exp,
                    method: 'post',
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                    data: item
                }).then(function(e){
                    _this.saveWorkExperienceing = false;
                    var resp = e.data;
                    if(resp.success){
                        _this.workExperiences[index].id = resp.data.id;
                        _this.$message(resp.msg);
                    }else{
                        _this.$message.error(resp.msg);
                    }
                }).catch(function(e){
                    _this.saveWorkExperienceing = false;
                })
            },
            //工作经验 -- end

            //工作经验 -- start
            findProjectExperience: function(){
                var _this = this;
                axios({
                    url: _this.API.resume.project_exp +"/" + _this.memberBaseinfo.id,
                    method: 'get',
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                }).then(function(e){
                    var resp = e.data;
                    if(resp.success){
                        _this.projectExperiences = resp.data;
                    }else{

                    }
                }).catch(function(e){
                    console.log("eee", e);
                })
            },
            addProjectExperiencesEvt: function(){
                this.projectExperiences.push({
                    memid: this.memberBaseinfo.id,
                    weIndex: 0
                });
            },
            removeProjectExperience: function(index){
                var _this = this;
                _this.$confirm('先想好是不是要删除？', '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '点错了',
                    type: 'warning'
                }).then(() => {
                    var item = _this.projectExperiences[index];
                    if(item.id){
                        axios({
                            url: _this.API.resume.project_exp,
                            method: 'delete',
                            headers:{
                                token:this.Util.getjwtToken(),
                            },
                            params: {
                                id: item.id,
                                memid: item.memid
                            }
                        }).then(function(e){
                            var resp = e.data;
                            if(resp.success){
                                _this.projectExperiences.splice(index, 1);
                                _this.$message(resp.msg);
                            }else{
                                _this.$message.error(resp.msg);
                            }
                        })
                    }else{
                        _this.projectExperiences.splice(index, 1);
                    }
                    
                }).catch(() => {
                    
                });
            }, 
            saveProjectExperience: function(index){
                var _this = this;
                if(_this.saveProjectExperienceing){
                    return;
                }
                var item = this.projectExperiences[index];
                if(!item.projectName){
                    _this.$message.error('项目名称不能为空');
                    return;
                }
                if(!item.personalDuties){
                    _this.$message.error('个人职责不能为空');
                    return;
                }
                if(!item.starttime){
                    _this.$message.error('结束时间不能为空');
                    return;
                }
                if(this.checkbox){
                    item.endtime='-1'
                }else{
                    if(!item.endtime){
                        _this.$message.error('结束时间不能为空');
                        return;
                    }    
                    if(item.starttime>item.endtime){
                    _this.$message.error('开始时间不能大于结束时间');
                    return;
                    }    
                }
                if(!item.projectDesc){
                    _this.$message.error('项目描述不能为空');
                    return;
                }
                if(!item.projectArch){
                    _this.$message.error('项目业绩不能为空');
                    return;
                }
                item.mweId = _this.workExperiences[item.weIndex].id;
                item.mweEntname = _this.workExperiences[item.weIndex].entname;
                _this.saveProjectExperienceing = true;
                axios({
                    url: _this.API.resume.project_exp,
                    method: 'post',
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                    data: item
                }).then(function(e){
                    _this.saveProjectExperienceing = false;
                    var resp = e.data;
                    if(resp.success){
                        _this.projectExperiences[index].id = resp.data.id;
                        _this.$message(resp.msg);
                    }else{
                        _this.$message.error(resp.msg);
                    }
                }).catch(function(e){
                    _this.saveProjectExperienceing = false;
                })
            },
            //项目经验 -- end

            //教育经验 -- start
            findEduExperience: function(){
                var _this = this;
                axios({
                    url: _this.API.resume.edu_exp +"/" + _this.memberBaseinfo.id,
                    method: 'get',
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                }).then(function(e){
                    var resp = e.data;
                    if(resp.success){
                        for(var i = 0; i < resp.data.length; i ++){
                            var educationIndex = 0;
                            for(var j = 0; j < _this.recordAskArry.length; j ++){
                                if(resp.data[i].education == _this.recordAskArry[j].value){
                                    educationIndex = j;
                                }
                            }
                            resp.data[i].educationIndex = educationIndex;
                        }
                        _this.eduExperiences = resp.data;
                    }else{

                    }
                })
            },
            addEduExperiencesEvt: function(){
                this.eduExperiences.push({
                    memid: this.memberBaseinfo.id,
                    educationIndex: 0,
                });
            },
            removeEduExperience: function(index){
                var _this = this;
                _this.$confirm('先想好是不是要删除？', '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '点错了',
                    type: 'warning'
                }).then(() => {
                    var item = _this.eduExperiences[index];
                    if(item.id){
                        axios({
                            url: _this.API.resume.edu_exp,
                            method: 'delete',
                            headers:{
                                token:this.Util.getjwtToken(),
                            },
                            params: {
                                id: item.id,
                                memid: item.memid
                            }
                        }).then(function(e){
                            var resp = e.data;
                            if(resp.success){
                                _this.eduExperiences.splice(index, 1);
                                _this.$message(resp.msg);
                            }else{
                                _this.$message.error(resp.msg);
                            }
                        }).catch(function(e){
                            console.log('saveEduExperience', e);
                        })
                    }else{
                        _this.eduExperiences.splice(index, 1);
                    }
                }).catch(() => {
                    
                });
            }, 
            saveEduExperience: function(index){
                var _this = this;
                if(_this.saveEduExperienceing){
                    return;
                }
                var item = this.eduExperiences[index];
                if(!item.schoolName){
                    _this.$message.error('学校名称不能为空');
                    return;
                }
                if(!item.majorName){
                    _this.$message.error('专业名称不能为空');
                    return;
                }
                if(!item.starttime){
                    _this.$message.error('入学时间不能为空');
                    return;
                }
                if(!item.endtime){
                    _this.$message.error('毕业时间不能为空');
                    return;
                }
                if(item.starttime>item.endtime){
                    _this.$message.error('入学时间不能大于毕业时间');
                    return;
                }
                item.education = _this.recordAskArry[item.educationIndex].value;
                _this.saveEduExperienceing = true;
                axios({
                    url: _this.API.resume.edu_exp,
                    method: 'post',
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                    data: item
                }).then(function(e){
                    _this.saveEduExperienceing = false;
                    var resp = e.data;
                    if(resp.success){
                        _this.eduExperiences[index].id = resp.data.id;
                        _this.$message(resp.msg);
                    }else{
                        _this.$message.error(resp.msg);
                    }
                }).catch(function(e){
                    _this.saveEduExperienceing = false;
                })
            },
            //教育经验 -- end
            //培训经验 -- start
            findTrainExperience: function(){
                var _this = this;
                axios({
                    url: _this.API.resume.train_exp +"/" + _this.memberBaseinfo.id,
                    method: 'get',
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                }).then(function(e){
                    var resp = e.data;
                    if(resp.success){
                        _this.trainExperiences = resp.data;
                    }else{

                    }
                }).catch(function(e){
                    
                })
            },
            addTrainExperiencesEvt: function(){
                this.trainExperiences.push({
                    memid: this.memberBaseinfo.id,
                });
            },
            removeTrainExperience: function(index){
                var _this = this;
                _this.$confirm('先想好是不是要删除？', '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '点错了',
                    type: 'warning'
                }).then(() => {
                    var item = _this.trainExperiences[index];
                    if(item.id){
                        axios({
                            url: _this.API.resume.train_exp,
                            method: 'delete',
                            headers:{
                                token:this.Util.getjwtToken(),
                            },
                            params: {
                                id: item.id,
                                memid: item.memid
                            }
                        }).then(function(e){
                            var resp = e.data;
                            if(resp.success){
                                _this.trainExperiences.splice(index, 1);
                                _this.$message(resp.msg);
                            }else{
                                _this.$message.error(resp.msg);
                            }
                        })
                    }else{
                        _this.trainExperiences.splice(index, 1);
                    }
                })
            }, 
            saveTrainExperience: function(index){
                var _this = this;
                if(_this.saveTrainExperienceing){
                    return;
                }
                var item = this.trainExperiences[index];
                if(!item.institutionsName){
                    _this.$message.error('培训机构不能为空');
                    return;
                }
                if(!item.courseName){
                    _this.$message.error('培训课程不能为空');
                    return;
                }
                if(!item.starttime){
                    _this.$message.error('开始时间不能为空');
                    return;
                }
                if(!item.endtime){
                    _this.$message.error('结束时间不能为空');
                    return;
                }
                if(item.starttime>item.endtime){
                   _this.$message.error('开始时间大于结束时间');
                    return; 
                }
                _this.saveTrainExperienceing = true;
                axios({
                    url: _this.API.resume.train_exp,
                    method: 'post',
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                    data: item
                }).then(function(e){
                    _this.saveTrainExperienceing = false;
                    var resp = e.data;
                    if(resp.success){
                        _this.trainExperiences[index].id = resp.data.id;
                        _this.$message(resp.msg);
                    }else{
                        _this.$message.error(resp.msg);
                    }
                }).catch(function(e){
                    _this.saveTrainExperienceing = false;
                })
            },
            //培训经验 -- end
            //专业技能 -- start
            findMajorSkill: function(){
                var _this = this;
                axios({
                    url: _this.API.resume.major_skill +"/" + _this.memberBaseinfo.id,
                    method: 'get',
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                }).then(function(e){
                    var resp = e.data;
                    if(resp.success){
                        for(var i = 0; i < resp.data.length; i ++){
                            var masterLevelIndex = 0;
                            for(var j = 0; j < _this.masterLevels.length; j ++){
                                if(resp.data[i].masterLevel == _this.masterLevels[j].value){
                                    masterLevelIndex = j;
                                }
                            }
                            resp.data[i].masterLevelIndex = masterLevelIndex;
                        }
                        _this.majorSkills = resp.data;
                    }else{

                    }
                })
            },
            addMajorSkillEvt: function(){
                this.majorSkills.push({
                    memid: this.memberBaseinfo.id,
                });
            },
            removeMajorSkill: function(index){
                var _this = this;
                _this.$confirm('先想好是不是要删除？', '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '点错了',
                    type: 'warning'
                }).then(() => {
                    var item = _this.majorSkills[index];
                    if(item.id){
                        axios({
                            url: _this.API.resume.major_skill,
                            method: 'delete',
                            headers:{
                                token:this.Util.getjwtToken(),
                            },
                            params: {
                                id: item.id,
                                memid: item.memid
                            }
                        }).then(function(e){
                            var resp = e.data;
                            if(resp.success){
                                _this.majorSkills.splice(index, 1);
                                _this.$message(resp.msg);
                            }else{
                                _this.$message.error(resp.msg);
                            }
                        })
                    }else{
                        _this.majorSkills.splice(index, 1);
                    }
                })
            }, 
            saveMajorSkill: function(index){
                var _this = this;
                if(_this.saveMajorSkilling){
                    return;
                }
                var item = this.majorSkills[index];
                if(!item.skillName){
                    _this.$message.error('技能名称不能为空');
                    return;
                }
                if(!item.useTimeLength){
                    item.useTimeLength = 0;
                }
                item.masterLevel = _this.masterLevels[item.masterLevelIndex].value;
                _this.saveMajorSkilling = true;
                axios({
                    url: _this.API.resume.major_skill,
                    method: 'post',
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                    data: item
                }).then(function(e){
                    _this.saveMajorSkilling = false;
                    var resp = e.data;
                    if(resp.success){
                        _this.majorSkills[index].id = resp.data.id;
                        _this.$message(resp.msg);
                    }else{
                        _this.$message.error(resp.msg);
                    }
                }).catch(function(e){
                    _this.saveMajorSkilling = false;
                })
            },
            //专业技能 -- end
            //培训经验 -- start
            findCertificate: function(){
                var _this = this;
                axios({
                    url: _this.API.resume.cert_exp +"/" + _this.memberBaseinfo.id,
                    method: 'get',
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                }).then(function(e){
                    var resp = e.data;
                    if(resp.success){
                        _this.certificate = resp.data;
                    }else{

                    }
                }).catch(function(e){
                    
                })
            },
            addCertificateEvt: function(){
                this.certificate.push({
                    memid: this.memberBaseinfo.id,
                });
            },
            removeCertificate: function(index){
                var _this = this;
                _this.$confirm('先想好是不是要删除？', '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '点错了',
                    type: 'warning'
                }).then(() => {
                    var item = _this.certificate[index];
                    if(item.id){
                        axios({
                            url: _this.API.resume.cert_exp,
                            method: 'delete',
                            headers:{
                                token:this.Util.getjwtToken(),
                            },
                            params: {
                                id: item.id,
                                memid: item.memid
                            }
                        }).then(function(e){
                            var resp = e.data;
                            if(resp.success){
                                _this.certificate.splice(index, 1);
                                _this.$message(resp.msg);
                            }else{
                                _this.$message.error(resp.msg);
                            }
                        }).catch(function(e){
                            console.log('saveCertificate', e);
                        })
                    }else{
                        _this.certificate.splice(index, 1);
                    }
                }).catch(() => {
                    
                });
            }, 
            saveCertificate: function(index){
                var _this = this;
                if(_this.saveCertificateing){
                    return;
                }
                var item = this.certificate[index];
                if(!item.name){
                    _this.$message.error('证书名称不能为空');
                    return;
                }
                if(!item.gettime){
                    _this.$message.error('获得时间不能为空');
                    return;
                }
                _this.saveCertificateing = true;
                axios({
                    url: _this.API.resume.cert_exp,
                    method: 'post',
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                    data: item
                }).then(function(e){
                    _this.saveCertificateing = false;
                    var resp = e.data;
                    if(resp.success){
                        _this.certificate[index].id = resp.data.id;
                        _this.$message(resp.msg);
                    }else{
                        _this.$message.error(resp.msg);
                    }
                }).catch(function(e){
                    _this.saveCertificateing = false;
                    console.log('saveCertificate', e);
                })
            },
            //证书 -- end
            //语言能力 -- start
            findLanguage: function(){
                var _this = this;
                axios({
                    url: _this.API.resume.lang_skill +"/" + _this.memberBaseinfo.id,
                    method: 'get',
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                }).then(function(e){
                    var resp = e.data;
                    if(resp.success){
                        for(var i = 0; i < resp.data.length; i ++){
                            var hearSayLevelIndex = 0;
                            for(var j = 0; j < _this.masterLevels.length; j ++){
                                if(resp.data[i].hearSayLevel == _this.masterLevels[j].value){
                                    hearSayLevelIndex = j;
                                }
                            }
                            resp.data[i].hearSayLevelIndex = hearSayLevelIndex;

                            var readWriteLevelIndex = 0;
                            for(var j = 0; j < _this.masterLevels.length; j ++){
                                if(resp.data[i].readWriteLevel == _this.masterLevels[j].value){
                                    readWriteLevelIndex = j;
                                }
                            }
                            resp.data[i].readWriteLevelIndex = readWriteLevelIndex;
                        }
                        _this.language = resp.data;
                    }
                })
            },
            addLanguageEvt: function(){
                this.language.push({
                    memid: this.memberBaseinfo.id,
                });
            },
            removeLanguage: function(index){
                var _this = this;
                _this.$confirm('先想好是不是要删除？', '提示', {
                    confirmButtonText: '是',
                    cancelButtonText: '点错了',
                    type: 'warning'
                }).then(() => {
                    var item = _this.language[index];
                    if(item.id){
                        axios({
                            url: _this.API.resume.lang_skill,
                            method: 'delete',
                            headers:{
                                token:this.Util.getjwtToken(),
                            },
                            params: {
                                id: item.id,
                                memid: item.memid
                            }
                        }).then(function(e){
                            var resp = e.data;
                            if(resp.success){
                                _this.language.splice(index, 1);
                                _this.$message(resp.msg);
                            }else{
                                _this.$message.error(resp.msg);
                            }
                        }).catch(function(e){
                            console.log('removeLanguage', e);
                        })
                    }else{
                        _this.language.splice(index, 1);
                    }
                }).catch(() => {
                    
                });
            }, 
            saveLanguage: function(index){
                var _this = this;
                if(_this.saveLanguageing){
                    return;
                }
                var item = this.language[index];
                if(!item.language){
                    _this.$message.error('语言名称不能为空');
                    return;
                }
                item.hearSayLevel = _this.masterLevels[item.hearSayLevelIndex].value;
                item.readWriteLevel = _this.masterLevels[item.readWriteLevelIndex].value;
                _this.saveLanguageing = true;
                axios({
                    url: _this.API.resume.lang_skill,
                    method: 'post',
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                    data: item
                }).then(function(e){
                    _this.saveLanguageing = false;
                    var resp = e.data;
                    if(resp.success){
                        _this.language[index].id = resp.data.id;
                        _this.$message(resp.msg);
                    }else{
                        _this.$message.error(resp.msg);
                    }
                }).catch(function(e){
                    _this.saveLanguageing = false;
                    console.log('saveLanguage', e);
                })
            },
            //语言能力 -- end

            //居住/户口地址懒加载
            addrLazyLoad: function(node, resolve) {
                var eid = "1";
                var _self = this;
            
                if (node.data) {
                    eid = node.data.areaCode;
                }
                axios.get(_self.API.areacode.getAreacode, {
					headers: {
						token: _self.Util.getjwtToken()
                    },
					params: {
						parent: eid
					}
				}).then(function(pores) {
					if (pores.data.success) {
						if (pores.data.data && pores.data.data.length > 0) {
							resolve(pores.data.data);
						} else {
							resolve([]);
						}
					}
				})
				.catch(function(error) {
					console.log(error);
				});
		   },
           //行业分类懒加载
		    industryInfoLazyLoad: function(node, resolve) {
                var eid = "0";
                var _self = this;
                if (node.data) {
                    eid = node.data.id;
                }
                axios.get(_self.API.industry.findByPid, {
                    headers: {
                        token: _self.Util.getjwtToken()
                    },
                    params: {
                        id: eid
                    }
                }).then(function(pores) {
                    if (pores.data.success) {
                        console.log("respo", pores);
                        if (pores.data.data && pores.data.data.length > 0) {
                            resolve(pores.data.data);
                        } else {
                            resolve([]);
                        }
                    }
                })
            },
            //职位分类懒加载
            careeInfoLazyLoad: function(node, resolve) {
                var eid = "0";
                var _self = this;
                if (node.data) {
                    eid = node.data.id;
                }
                axios.get(_self.API.jtAll.findByPid, {
                    headers: {
                        token: _self.Util.getjwtToken()
                    },
                    params: {
                        id: eid
                    }
                }).then(function(pores) {
                    console.log("respo", pores);
                    if (pores.data.success) {	
                        if (pores.data.data && pores.data.data.length > 0) {
                            resolve(pores.data.data);
                        } else {
                            resolve([]);
                        }
                    }
                })
            }
      }
  }
</script>
<style lang="scss" scoped>
.el-icon-error{
    font-size: 20px;
    color:red;
    cursor: pointer;
    margin: 15px;
}
.Numder_top{
    width: 224px;
    height: 220px;
    line-height: 220px;
    text-align: center;
    border: 1px dotted #999999;
}
.Numder_top_b{
    display: flex;
    justify-content: space-between;
    width:209px;
    margin-top: 5px;
}
.avatar-updicon{
    height: 60px;
    line-height: 60px;
    text-align: center;
    width: 100px;
    border: 1px dotted #999999;
}
.avatar-updicon img{
    width: 100% !important;
    height: 100% !important;
}
</style>