<template>
  <div class="home">
    <!-- <div class="ent_tilte"><h2>新增企业</h2></div> -->
    <main class="ent_main clearfix">
      <div class="fl ent_fl">
        <div class="tilte_btn">
          <span class="lineY"></span>
          <span>
            <h2>企业基础数据</h2>
          </span>
        </div>
        <div style="padding:10px">
          <span class="ent_del"><span style="color: red;">*</span>企业名称：</span>
          <el-input v-model="EnterprBasic.name" size="medium" style="width:30%" placeholder="请输入企业名称"></el-input>
        </div>
        <div style="padding:10px">
          <span class="ent_del"><span style="color: red;">*</span>行业分类：</span>
          <el-cascader :class="EnterprBasic.indtypeNames ? 'ph' : ''" v-model="EnterprBasic.indtypes" @change="changeIndtypes" :options="enterprseIndtypes" :props="indtypeProps" :show-all-levels="false" clearable :placeholder="
              EnterprBasic.indtypeNames
                ? EnterprBasic.indtypeNames
                : '请选择行业分类'
            " size="medium" style="width:30%"></el-cascader>
          <span class="ent_del ent_delFr"><span style="color: red;">*</span>企业性质：</span>
          <!-- multiple修改为多选 -->
          <el-select clearable v-model="EnterprBasic.natures" placeholder="请输入企业性质" style="width:30%" size="medium">
            <el-option v-for="(item, index) in enterprseNatures" :key="item.id" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </div>
        <div style="padding:10px">
          <span class="ent_del"><span style="color: red;">*</span>企业规模：</span>
          <el-select placeholder="请输入企业规模" v-model="EnterprBasic.scaleEnt" clearable style="width:30%" size="medium">
            <el-option v-for="item in enterprseScaleEnts" :key="item.id" :label="item.name" :value="item.name">
            </el-option>
          </el-select>
          <span class="ent_del ent_delFr"><span style="color: red;">*</span>员工规模：</span>
          <el-select placeholder="请输入员工规模" v-model="EnterprBasic.scalePerson" clearable style="width:30%" size="medium" @change="scalePersonChange">
            <el-option v-for="(item, index) in enterprseScalePersons" :key="item.id" :label="item.scaleEnt" :value="index"></el-option>
          </el-select>
        </div>
        <div style="padding:10px">
          <span class="ent_del">企业福利：</span>
          <!-- <span style="color: red;">*</span> -->
          <el-tag :key="index" v-for="(tag, index) in enterprseWelfareTags" closable :disable-transitions="false" @close="enterprseWelfareTagsHandleClose(index)">{{ tag.name }}</el-tag>
          <el-button class="input-new-tag" size="medium" @click="openWelfareDialog">+添加</el-button>
        </div>
        <div style="padding:10px">
          <span class="ent_del">企业特色：</span>
          <!-- <span style="color: red;">*</span> -->
          <el-tag :key="index" v-for="(tag, index) in enterprseCharacteristicTags" closable :disable-transitions="false" @close="enterprseCharacteristicTagsHandleClose(index)">{{ tag.name }}</el-tag>
          <el-button class="input-new-tag" size="medium" @click="openCharacteristicDialog">+添加</el-button>
        </div>
        <div style="padding:10px" v-if="updatepwd">
          <span class="ent_del">登陆密码：</span>
          <el-input v-model="EnterprBasic.password" size="medium" style="width:30%" placeholder="不修改密码请留空"></el-input>
        </div>
        <div>
          <span class="ent_del"><span style="color: red;">*</span> 工作地点：</span>
          <el-input v-model="EnterprBasic.eaddrDetail" size="medium" style="width:30%" placeholder="请输入工作地点"></el-input>
        </div>
        <div style="padding:10px;width:100%;box-sizing:border-box;">
          <!-- <span class="ent_del"><span style="color: red;">*</span>企业地址：</span>
					<el-cascader v-model="EnterprBasic.addrs" @change="changeAddrs" :options="enterprseAddrs" :props="addrProps"
					 clearable placeholder="请选择企业地址" size="medium" style="width:30%"></el-cascader>
					<span class="ent_del ent_delFr"><span style="color: red;">*</span>详细地址：</span>
					<el-input v-model="EnterprBasic.eaddrDetail" size="medium" style="width:30%" placeholder="街道、门牌号"></el-input> -->
          <div class="mapBox" id="mapBox" style="width:80%;">
            <!-- 地图 -->
            <div class="map_info">
              <input id="tipinput" v-model="mapkeyword" placeholder="请输入工作地址" />
            </div>
          </div>
        </div>

        <div class="addrs">
          <span class="iconfont icondizhi"></span>
          <span v-if="
              EnterprBasic.eprovinceName ||
                EnterprBasic.ecityName ||
                EnterprBasic.ecountyName ||
                EnterprBasic.eaddrDetail
            ">
            {{ EnterprBasic.eprovinceName ? EnterprBasic.eprovinceName : '' }}
            {{ EnterprBasic.ecityName ? '/' + EnterprBasic.ecityName : '' }}
            {{ EnterprBasic.ecountyName ? '/' + EnterprBasic.ecountyName : '' }}
            {{ EnterprBasic.eaddrDetail ? '/' + EnterprBasic.eaddrDetail : '' }}
          </span>
          <span style="color: #999;" v-else>请在地图上点击详细工作地点...</span>
        </div>

        <div class="tilte_btn">
          <span class="lineY"></span>
          <span>
            <h2>联系方式</h2>
          </span>
        </div>
        <div style="padding:10px">
          <span class="ent_del"><span style="color: red;">*</span>联系人：</span>
          <el-input size="medium" v-model="EnterprBasic.contactsPerson" style="width:20%" placeholder="请输入联系人"></el-input>
          <span class="ent_del ent_delFr"><span style="color: red;">*</span>联系方式：</span>
          <el-input size="medium" v-model="EnterprBasic.contactsNumber" style="width:20%" placeholder="请输入号码"></el-input>
          <span class="ent_del ent_delFr">邮箱：</span>
          <el-input size="medium" v-model="EnterprBasic.contactsEmail" style="width:20%" placeholder="请输入邮箱"></el-input>
        </div>
        <div class="tilte_btn">
          <span class="lineY"></span>
          <span>
            <h2>企业简介</h2>
          </span>
        </div>
        <div style="padding:10px">
          <el-input type="textarea" style="width:90%" :autosize="{ minRows: 7, maxRows: 10 }" placeholder="请输入内容" v-model="EnterprBasic.descript"></el-input>
        </div>
        <div class="tilte_btn">
          <span class="lineY"></span>
          <span>
            <h2>企业logo</h2>
          </span>
        </div>
        <div style="padding:10px">
          <el-upload class="avatar-uploader" :action="uploadImgUrl" :show-file-list="false" :on-success="handleLogoPurlSuccess">
            <img v-if="EnterprBasic.logoPurl" :src="EnterprBasic.logoPurl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        <div class="tilte_btn">
          <span class="lineY"></span>
          <span>
            <h2>企业照片</h2>
          </span>
        </div>
        <div class="entperise-entimg">
          <img width="222px" height="150px" :key="i" v-for="(item, i) in enterprsePhotos" :src="item" title="点击图片删除" @click="removePhoto(item, i)" />
          <el-upload class="avatar-uploader ent_delFr" :action="uploadImgUrl" :show-file-list="false" :on-success="handleAvatarSuccess">
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        <div style="padding:10px">
          <el-button type="primary" @click="saveData">保存</el-button>
        </div>
      </div>
      <div class="fr ent_fr">
        <div class="tilte_btn">
          <span class="lineY"></span>
          <span>
            <h2>企业资质审核</h2>
          </span>
        </div>
        <div style="padding:10px">
          <span class="ent_del m_b10" style="padding-bottom: 5px;display:inline-block">注册号：</span>
          <br />
          <el-input v-model="EnterprBasic.entRegno" size="medium" style="width:50%" placeholder="请输入企业注册号"></el-input>
        </div>
        <div style="padding:10px">
          <span class="ent_del"><span style="color: red;padding-bottom: 5px;display:inline-block">*</span>统一社会信用代码：</span>
          <br />
          <el-input v-model="EnterprBasic.creditCode" size="medium" style="width:50%" placeholder="请输入企业注册号"></el-input>
        </div>
        <div style="padding:10px">
          <span class="ent_del"><span style="color: red;padding-bottom: 5px;display:inline-block">*</span>法人代表：</span>
          <br />
          <el-input size="medium" v-model="EnterprBasic.legalPerson" style="width:50%" placeholder="请输入代表人"></el-input>
        </div>
        <div style="padding:10px">
          <span class="ent_del"><span style="color: red;padding-bottom: 5px;display:inline-block">*</span>营业期限：</span>
          <br />
          <el-input size="medium" v-model="EnterprBasic.dbtime" style="width:50%" placeholder="请输入期限"></el-input>
        </div>
        <div style="padding:10px">
          <span class="ent_del"><span style="color: red;">*</span>上传营业执照：</span>
          <div style="padding:10px">
            <el-upload class="avatar-uploader" :action="uploadImgUrl" :show-file-list="false" :on-success="handleLicensePhotoSuccess">
              <img v-if="EnterprBasic.licensePhotoUrl" :src="EnterprBasic.licensePhotoUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>
        <div style="padding:10px">
          <span class="ent_del"><span style="color: red;">*</span> 法人实名制认证：</span>
          <div style="padding:10px">
            <el-upload class="avatar-uploader" :action="uploadImgUrl" :show-file-list="false" :on-success="handleLegalPersonCardNurlSuccess">
              <img v-if="EnterprBasic.legalPersonCardNurl" :src="EnterprBasic.legalPersonCardNurl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <div class="el-upload__tip" style="text-align:center;color:#aaa" slot="tip">身份证正面</div>
            </el-upload>

            <el-upload class="avatar-uploader" style="margin-left: 20px" :action="uploadImgUrl" :show-file-list="false" :on-success="handleLegalPersonCardPurlSuccess">
              <img v-if="EnterprBasic.legalPersonCardPurl" :src="EnterprBasic.legalPersonCardPurl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <div class="el-upload__tip" style="text-align:center;color:#aaa" slot="tip">身份证反面</div>
            </el-upload>
          </div>
          <div style="padding:10px">
            <span class="ent_del">人脸识别认证：</span>
            <div style="padding:10px">
              <el-upload class="avatar-uploader" :action="uploadImgUrl" :show-file-list="false" :on-success="handleLegalPersonUrlSuccess">
                <img v-if="EnterprBasic.legalPersonUrl" :src="EnterprBasic.legalPersonUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- 企业福利弹出框 -->
    <el-dialog title="选择这家企业的福利" :visible.sync="dialogWelfare" width="40%">
      <el-tag v-for="(item, index) in tagsWelfare" :key="index" class="welfareType" :type="item.isselect ? '' : 'info'" @click="welfareDialogTagClick(index)" effect="dark">{{ item.name }}</el-tag>
      <el-input style="width: 20%;margin:10px 0;" maxlength="10" v-model="tagsWelfareVal" size="small" placeholder="自定义添加" @blur="showWelfareInput" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogWelfare = false">取 消</el-button>
        <el-button type="primary" @click="welfareDialogOk">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 企业特色弹出框 -->
    <el-dialog title="选择这家企业的特色" :visible.sync="dialogCharacteristic" width="40%">
      <el-tag v-for="(item, index) in tagsCharacteristic" :key="index" class="welfareType" :type="item.isselect ? '' : 'info'" @click="characteristicDialogTagClick(index)" effect="dark">{{ item.name }}</el-tag>
      <el-input style="width: 20%;margin: 10px 0;" maxlength="10" v-model="tagscharacterVal" size="small" placeholder="自定义添加" @blur="characteristicInput" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogCharacteristic = false">取 消</el-button>
        <el-button type="primary" @click="characteristicDialogOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { lazyAMapApiLoaderInstance } from 'vue-amap';
export default {
  name: 'Enterpriseadd',
  data () {
    return {
      uploadImgUrl: this.API.file.uploadSingle,
      EnterprBasic: {
        entLng: 116.445294, // 添加的时候设置一个默认的经纬度
        entLat: 39.891345,
      },
      enterprseIndtypes: [], //行业分类
      indtypeProps: {
        lazy: true,
        value: 'id',
        checkStrictly: true,
        label: 'name',
        multiple: true,
        lazyLoad: this.indtypeLazyLoad,
      },

      // enterprseAddrs: [], //企业地址
      // addrProps: {
      // 	lazy: true,
      // 	value: "areaCode",
      // 	checkStrictly: true,
      // 	label: "areaTitle",
      // 	lazyLoad: this.addrLazyLoad
      // },
      mapkeyword: '', // 地址修改成地图选择

      enterprseNatures: [], //企业性质
      enterprseScaleEnts: [], //企业规模
      enterprseScalePersons: [], //员工规模

      //   企业福利
      tagsWelfareVal: '',
      enterprseWelfareTags: [],
      tagsWelfare: [],
      dialogWelfare: false,

      //   企业特色
      tagscharacterVal: '',
      enterprseCharacteristicTags: [],
      tagsCharacteristic: [],
      dialogCharacteristic: false,

      enterprsePhotos: [],
      comParam: {
        id: '',
      },
      updatepwd: false,
    };
  },
  created () {
    this.comParam = this.$router.currentRoute.query;
    this.getEntDetail(this.comParam.id);
    this.getEnterprBasic();
    this.getEnterprBaseEcs();
    this.getEnterprStaff();
    if (this.comParam.id != '') {
      this.updatepwd = true;
    }
    var _self = this;
    lazyAMapApiLoaderInstance.load().then(() => {
      console.log('====', _self.EnterprBasic.entLng, _self.EnterprBasic.entLat);
      var point = new AMap.LngLat(
        _self.EnterprBasic.entLng,
        _self.EnterprBasic.entLat
      );
      _self.map = new AMap.Map('mapBox', {
        center: point,
        zoom: 13,
      });
      var marker = new AMap.Marker({
        position: new AMap.LngLat(
          _self.EnterprBasic.entLng,
          _self.EnterprBasic.entLat
        ),
      });
      _self.map.add(marker);
      _self.map.plugin('AMap.Autocomplete', function () {
        // 实例化Autocomplete
        var autoOptions = {
          city: '全国',
          input: 'tipinput',
        };
        var auto = new AMap.Autocomplete(autoOptions);
        var placeSearch = new AMap.PlaceSearch({
          map: _self.map,
        }); //构造地点查询类
        AMap.event.addListener(auto, 'select', select); //注册监听，当选中某条记录时会触发
        function select (e) {
          placeSearch.setCity(e.poi.adcode);
          placeSearch.search(e.poi.name); //关键字查询查询
        }
      });
      _self.map.on('click', function (ev) {
        _self.map.remove(marker);
        marker = new AMap.Marker({
          position: new AMap.LngLat(ev.lnglat.lng, ev.lnglat.lat),
          // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        });
        _self.finareacode(ev.lnglat.lng, ev.lnglat.lat);
        _self.map.add(marker);
      });
    });
  },
  methods: {
    getEntDetail: function (id) {
      var _self = this;
      axios
        .get(_self.API.aent.detail, {
          headers: {
            token: _self.Util.getjwtToken(),
          },
          params: {
            id: id,
          },
        })
        .then(function (pores) {
          if (pores.data.success) {
            if (pores.data.data) {
              if (pores.data.data.indtypeNames) {
                pores.data.data.indtypeNames.replace(/-/g, '/');
              }
              // if (pores.data.data.natures) { //性质改为单选了
              // 	pores.data.data.natures.replace(/-/g, '/');
              // }
              _self.EntDetail = pores.data.data;
              if (pores.data.data.characteristic) {
                _self.EntDetail.characteristicArry = pores.data.data.characteristic.split(
                  '-'
                );
              }
              if (pores.data.data.welfare) {
                _self.EntDetail.welfareArry = pores.data.data.welfare.split(
                  '-'
                );
              }
              // console.log("data,", _self.EntDetail);
              _self.entName = _self.EntDetail.name;
              _self.EntDetail.indtypeIds = _self.EntDetail.indtypeIds.replace(
                /,$/gi,
                ''
              );
              var indtypesArr = _self.EntDetail.indtypeIds.split('-');
              _self.EntDetail.indtypes = indtypesArr;
              if (
                _self.EntDetail.scalePersonMin == -1 &&
                _self.EntDetail.scalePersonMax >= 0
              ) {
                _self.EntDetail.scalePerson =
                  _self.EntDetail.scalePersonMax + '人以下';
              } else if (
                _self.EntDetail.scalePersonMin >= 0 &&
                _self.EntDetail.scalePersonMax == -1
              ) {
                _self.EntDetail.scalePerson =
                  _self.EntDetail.scalePersonMin + '人以上';
              } else {
                _self.EntDetail.scalePerson =
                  _self.EntDetail.scalePersonMin +
                  '-' +
                  _self.EntDetail.scalePersonMax +
                  '人';
              }
              //企业福利
              if (_self.EntDetail.welfare) {
                _self.EntDetail.welfare = _self.EntDetail.welfare
                  .replace('-', ',')
                  .replace(/,$/gi, '');
                var welfareArr = _self.EntDetail.welfare.split(',');
                welfareArr.forEach(function (item) {
                  _self.enterprseWelfareTags.push({
                    name: item,
                  });
                });
              }

              //企业特点
              if (_self.EntDetail.characteristic) {
                _self.EntDetail.characteristic = _self.EntDetail.characteristic
                  .replace('-', ',')
                  .replace(/,$/gi, '');
                var characteristicArr = _self.EntDetail.characteristic.split(
                  ','
                );
                characteristicArr.forEach(function (item) {
                  _self.enterprseCharacteristicTags.push({
                    name: item,
                  });
                });
              }

              //地址，已改为地图选择
              // _self.EntDetail.addrs = [];
              // if (_self.EntDetail.eprovinceCode != "") {
              // 	_self.EntDetail.addrs.push(_self.EntDetail.eprovinceCode);
              // 	_self.EntDetail.addrs.push(_self.EntDetail.ecityCode);
              // 	_self.EntDetail.addrs.push(_self.EntDetail.ecountyCode);
              // }
              // console.log('eprovinceCode', _self.EntDetail.addrs)
              _self.EnterprBasic = _self.EntDetail;
              _self.getEnterprPhotos(_self.comParam.id);
            }
          }
        });
    },
    //根据经纬度获取地址
    finareacode: function (lng, lat) {
      var _self = this;
      axios
        .get(_self.API.areacode.getAddrByLnglat, {
          headers: {
            token: _self.Util.getjwtToken(),
          },
          params: {
            lng: lng,
            lat: lat,
          },
        })
        .then(function (pores) {
          if (pores.data.success) {
            _self.$set(
              _self.EnterprBasic,
              'eprovinceName',
              pores.data.data.provinceName
            );
            _self.EnterprBasic.eprovinceCode = pores.data.data.provinceCode;

            _self.$set(
              _self.EnterprBasic,
              'ecityName',
              pores.data.data.cityName
            );
            _self.EnterprBasic.ecityCode = pores.data.data.cityCode;

            _self.$set(
              _self.EnterprBasic,
              'ecountyName',
              pores.data.data.countyName
            );
            _self.EnterprBasic.ecountyCode = pores.data.data.countyCode;

            _self.EnterprBasic.eaddrDetail = pores.data.data.addrDetail;
            //经纬度
            _self.EnterprBasic.entLng = lng;
            _self.EnterprBasic.entLat = lat;
          }
        });
    },
    //企业照片
    getEnterprPhotos: function (id) {
      var _self = this;
      axios
        .get(_self.API.enterprise.getEnterPrisePhotoById, {
          headers: {
            token: _self.Util.getjwtToken(),
          },
          params: {
            enterpriseId: id,
          },
        })
        .then(function (pores) {
          if (pores.data.success) {
            if (pores.data.data && pores.data.data.length > 0) {
              pores.data.data.forEach(function (item) {
                _self.enterprsePhotos.push(item.phoneUrl);
              });
            }
          }
        });
    },
    removePhoto: function (imgurl, idx) {
      var msg =
        "确定要删除这张照片吗？<img width='100' height='100' src=\"" +
        imgurl +
        '" />';
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.enterprsePhotos.splice(idx, 1);
        })
        .catch(function (error) { });
    },
    scalePersonChange: function (e) {
      this.EnterprBasic.scalePersonMin = this.enterprseScalePersons[
        e
      ].scalePersonMin;
      this.EnterprBasic.scalePersonMax = this.enterprseScalePersons[
        e
      ].scalePersonMax;
    },
    saveData: function () {
      if (!this.EnterprBasic.name) {
        this.$message.error('企业名称不能为空');
        return;
      }
      if (!this.EnterprBasic.indtypeIds) {
        this.$message.error('请选择行业分类');
        return;
      }
      if (!this.EnterprBasic.natures) {
        this.$message.error('请选择企业性质');
        return;
      }
      // if (this.Util.isnull(this.EnterprBasic.natureIndexs)) {
      // 	this.$message.error('请选择企业性质');
      // 	return;
      // } else {
      // this.EnterprBasic.natures = this.EnterprBasic.natureIndexs;
      // var natures = this.enterprseNatures[this.EnterprBasic.natureIndexs[0]].name;
      // for(var i = 1; i < this.EnterprBasic.natureIndexs.length; i ++){
      //   natures += "-" + this.enterprseNatures[this.EnterprBasic.natureIndexs[i]].name;
      // }
      // this.EnterprBasic.natures = natures;
      // }
      if (
        !this.EnterprBasic.scalePerson &&
        this.EnterprBasic.scalePerson != 0
      ) {
        this.$message.error('请选择企业规模');
        return;
      }
      /*else{
        this.EnterprBasic.scalePersonMin = this.enterprseScalePersons[this.EnterprBasic.scalePerson].scalePersonMin;
        this.EnterprBasic.scalePersonMax = this.enterprseScalePersons[this.EnterprBasic.scalePerson].scalePersonMax;
      }*/

      // if (!this.enterprseWelfareTags || this.enterprseWelfareTags.length <= 0) {
      //   this.$message.error('请选择企业福利');
      //   return;
      // } else {
      //   var welfare = this.enterprseWelfareTags[0].name;
      //   for (var i = 1; i < this.enterprseWelfareTags.length; i++) {
      //     welfare += "-" + this.enterprseWelfareTags[i].name;
      //   }
      //   this.EnterprBasic.welfare = welfare;
      // }
      if (this.enterprseWelfareTags.length > 0) {
        var welfare = this.enterprseWelfareTags[0].name;
        for (var i = 1; i < this.enterprseWelfareTags.length; i++) {
          welfare += '-' + this.enterprseWelfareTags[i].name;
        }
        this.EnterprBasic.welfare = welfare;
      }

      // if (!this.enterprseCharacteristicTags || this.enterprseCharacteristicTags.length <= 0) {
      //   this.$message.error('请选择企业特色');
      //   return;
      // } else {
      //   var characteristic = this.enterprseCharacteristicTags[0].name;
      //   for (var i = 1; i < this.enterprseCharacteristicTags.length; i++) {
      //     characteristic += "-" + this.enterprseCharacteristicTags[i].name;
      //   }
      //   this.EnterprBasic.characteristic = characteristic;
      // }

      if (this.enterprseCharacteristicTags.length > 0) {
        var characteristic = this.enterprseCharacteristicTags[0].name;
        for (var i = 1; i < this.enterprseCharacteristicTags.length; i++) {
          characteristic += '-' + this.enterprseCharacteristicTags[i].name;
        }
        this.EnterprBasic.characteristic = characteristic;
      }

      // if (!this.EnterprBasic.eprovinceName) {
      //   this.$message.error('企业地址不能为空');
      //   return;
      // }

      if (!this.EnterprBasic.eaddrDetail) {
        this.$message.error('企业详细地址不能为空');
        return;
      }
      if (!this.EnterprBasic.contactsPerson) {
        this.$message.error('联系人不能为空');
        return;
      }
      if (!this.EnterprBasic.contactsNumber) {
        this.$message.error('联系方式不能为空');
        return;
      }
      if (!this.Util.phoneVail(this.EnterprBasic.contactsNumber)) {
        this.$message.error('联系方式输入不合法');
        return;
      }
      if (this.EnterprBasic.contactsEmail) {
        if (!this.Util.emailVail(this.EnterprBasic.contactsEmail)) {
          this.$message.error('邮箱输入不合法');
          return;
        }
      }
      /*if(!this.EnterprBasic.entRegno){
        this.$message.error('注册号不能为空');
        return ;
      }*/
      if (!this.EnterprBasic.creditCode) {
        this.$message.error('统一信用代码不能为空');
        return;
      }
      if (!this.EnterprBasic.legalPerson) {
        this.$message.error('法人代表不能为空');
        return;
      }
      if (!this.EnterprBasic.dbtime) {
        this.$message.error('营业期限不能为空');
        return;
      }
      if (!this.EnterprBasic.licensePhotoUrl) {
        this.$message.error('请上传营业执照');
        return;
      }
      if (!this.EnterprBasic.legalPersonCardNurl) {
        this.$message.error('请上传法人实名制-正面身份证照片');
        return;
      }
      if (!this.EnterprBasic.legalPersonCardPurl) {
        this.$message.error('请上传法人实名制-反面身份证照片');
        return;
      }

      /*if(!this.EnterprBasic.legalPersonUrl){
        this.$message.error('请上传人脸识别认证照片');
        return ;
      }*/
      if (this.enterprsePhotos.length > 0) {
        var entPhotoUrls = this.enterprsePhotos[0];
        for (var i = 1; i < this.enterprsePhotos.length; i++) {
          entPhotoUrls += '-' + this.enterprsePhotos[i];
        }
        this.EnterprBasic.entPhotoUrls = entPhotoUrls;
      }
      //this.EnterprBasic.auditState = 2;
      //this.EnterprBasic.auditmsg = '后台管理员添加默认为通过';
      this.EnterprBasic.adduid = this.Util.getUid();

      var _self = this;
      axios({
        url: this.API.aent.add,
        method: 'post',
        data: this.EnterprBasic,
        headers: {
          token: this.Util.getjwtToken(),
        },
      })
        .then((res) => {
          if (res.data.success) {
            //关闭页面
            _self.$message({
              message: res.data.msg,
              type: 'success',
              duration: 2000,
              onClose: function () {
                _self.$parent.removeTab(_self.$parent.editableTabsValue);
              },
            });
            //_self.EnterprBasic = {};
            //_self.enterprsePhotos = [];
            //_self.enterprseWelfareTags = [];
            //_self.enterprseCharacteristicTags = [];
          } else {
            _self.$message.error(res.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //行业分类懒加载
    indtypeLazyLoad: function (node, resolve) {
      var eid = '0';
      var _self = this;
      if (node.data) {
        eid = node.data.id;
      }
      axios
        .get(_self.API.industry.findByPid, {
          headers: {
            token: _self.Util.getjwtToken(),
          },
          params: {
            id: eid,
          },
        })
        .then(function (pores) {
          if (pores.data.success) {
            if (pores.data.data && pores.data.data.length > 0) {
              resolve(pores.data.data);
            } else {
              resolve([]);
            }
          }
        });
    },
    //企业地址懒加载
    addrLazyLoad: function (node, resolve) {
      var eid = '1';
      var _self = this;
      if (node.data) {
        if (node.data.areaTag >= 3) {
          console.log('地址32324', node.data.areaTag);
          resolve([]);
          return;
        } else {
          eid = node.data.areaCode;
        }
      }
      axios
        .get(_self.API.areacode.getAreacode, {
          headers: {
            token: _self.Util.getjwtToken(),
          },
          params: {
            parent: eid,
          },
        })
        .then(function (pores) {
          if (pores.data.success) {
            if (pores.data.data && pores.data.data.length > 0) {
              resolve(pores.data.data);
            } else {
              resolve([]);
            }
          } else {
            resolve([]);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //选择行业分类id
    changeIndtypes: function (arrid) {
      var indtypeIds = '';
      if (arrid && arrid.length > 0) {
        indtypeIds = arrid[0][arrid[0].length - 1];
        for (var i = 1; i < arrid.length; i++) {
          indtypeIds += '-' + arrid[i][arrid[i].length - 1];
        }
      }
      if (indtypeIds != '') {
        this.EnterprBasic.indtypeIds = indtypeIds;
      }
    },
    //选择地址分类id
    changeAddrs: function (arrid) {
      // console.log(arrid)
      if (arrid && arrid.length > 0) {
        this.EnterprBasic.eprovinceCode = arrid[0];
      }
      if (arrid && arrid.length > 1) {
        this.EnterprBasic.ecityCode = arrid[1];
      }
      if (arrid && arrid.length > 2) {
        this.EnterprBasic.ecountyCode = arrid[2];
      }
    },
    //企业性质
    getEnterprBasic: function () {
      var _self = this;
      axios
        .get(_self.API.baseEn.list, {
          headers: {
            token: _self.Util.getjwtToken(),
          },
        })
        .then(function (pores) {
          if (pores.data.success) {
            if (pores.data.data && pores.data.data.length > 0) {
              _self.enterprseNatures = pores.data.data;
            }
          }
        });
    },
    //企业规模
    getEnterprBaseEcs: function () {
      var _self = this;
      axios
        .get(_self.API.baseEcs.list, {
          headers: {
            token: _self.Util.getjwtToken(),
          },
        })
        .then(function (pores) {
          if (pores.data.success) {
            if (pores.data.data && pores.data.data.length > 0) {
              _self.enterprseScaleEnts = pores.data.data;
            }
          }
        });
    },
    //员工规模
    getEnterprStaff: function () {
      var _self = this;
      axios
        .get(_self.API.baseEss.list, {
          headers: {
            token: _self.Util.getjwtToken(),
          },
        })
        .then(function (pores) {
          if (pores.data.success) {
            if (pores.data.data && pores.data.data.length > 0) {
              _self.enterprseScalePersons = pores.data.data;
            }
          }
        });
    },
    //企业福利部分
    enterprseWelfareTagsHandleClose (index) {
      this.enterprseWelfareTags.splice(index, 1);
    },
    //企业福利,获取常用福利并弹出来
    openWelfareDialog: function () {
      var _this = this;
      axios
        .get(this.API.baseEw.list, {
          headers: {
            token: this.Util.getjwtToken(),
          },
        })
        .then(function (res) {
          if (res.data.success) {
            _this.tagsWelfare = res.data.data;
            for (var i = 0; i < _this.tagsWelfare.length; i++) {
              _this.tagsWelfare[i].typeClass = true;
            }
            _this.dialogWelfare = true;
          } else {
            _this.$message.error('获取失败');
          }
        });
    },
    //弹框中点击企业福利标签
    welfareDialogTagClick: function (index) {
      var item = this.tagsWelfare[index];
      item.isselect = !item.isselect;
      this.$set(this.tagsWelfare, index, item);
    },
    //点击添加自定义福利
    showWelfareInput: function () {
      if (this.tagsWelfareVal) {
        this.tagsWelfare.push({
          name: this.tagsWelfareVal,
          isselect: false,
        });
      }
    },
    //企业福利，选择确定
    welfareDialogOk: function () {
      for (var i = 0; i < this.tagsWelfare.length; i++) {
        if (this.tagsWelfare[i].isselect) {
          this.enterprseWelfareTags.push(this.tagsWelfare[i]);
          this.dialogWelfare = false;
        } else {
          this.dialogWelfare = false;
        }
      }
    },
    //企业福利部分
    enterprseCharacteristicTagsHandleClose (index) {
      this.enterprseCharacteristicTags.splice(index, 1);
    },
    //企业福利,获取常用福利并弹出来
    openCharacteristicDialog: function () {
      var _this = this;
      axios
        .get(this.API.baseEc.list, {
          headers: {
            token: this.Util.getjwtToken(),
          },
        })
        .then(function (res) {
          if (res.data.success) {
            _this.tagsCharacteristic = res.data.data;
            for (var i = 0; i < _this.tagsCharacteristic.length; i++) {
              _this.tagsCharacteristic[i].typeClass = true;
            }
            _this.dialogCharacteristic = true;
          } else {
            _this.$message.error('获取失败');
          }
        });
    },
    //弹框中点击企业特色标签
    characteristicDialogTagClick: function (index) {
      var item = this.tagsCharacteristic[index];
      item.isselect = !item.isselect;
      this.$set(this.tagsCharacteristic, index, item);
    },
    //点击添加自定义特色
    characteristicInput: function () {
      if (this.tagscharacterVal) {
        this.tagsCharacteristic.push({
          name: this.tagscharacterVal,
          isselect: false,
        });
      }
    },
    //企业特色，选择确定
    characteristicDialogOk: function () {
      for (var i = 0; i < this.tagsCharacteristic.length; i++) {
        if (this.tagsCharacteristic[i].isselect) {
          this.enterprseCharacteristicTags.push(this.tagsCharacteristic[i]);
          this.dialogCharacteristic = false;
        } else {
          this.dialogCharacteristic = false;
        }
      }
    },

    //照片上传
    handleAvatarSuccess (res, file) {
      if (res.success) {
        this.enterprsePhotos.push(res.data);
      } else {
        this.$message.error(res.msg);
      }
    },
    //营业执照上传
    handleLicensePhotoSuccess (res, file) {
      if (res.success) {
        this.$set(this.EnterprBasic, 'licensePhotoUrl', res.data);
      } else {
        this.$message.error(res.msg);
      }
    },

    //企业logo
    handleLogoPurlSuccess (res, file) {
      if (res.success) {
        this.$set(this.EnterprBasic, 'logoPurl', res.data);
      } else {
        this.$message.error(res.msg);
      }
    },
    //身份证正面
    handleLegalPersonCardNurlSuccess (res, file) {
      if (res.success) {
        this.$set(this.EnterprBasic, 'legalPersonCardNurl', res.data);
      } else {
        this.$message.error(res.msg);
      }
    },
    //身份证反面
    handleLegalPersonCardPurlSuccess (res, file) {
      if (res.success) {
        this.$set(this.EnterprBasic, 'legalPersonCardPurl', res.data);
      } else {
        this.$message.error(res.msg);
      }
    },

    //手持身份证
    handleLegalPersonUrlSuccess (res, file) {
      if (res.success) {
        this.$set(this.EnterprBasic, 'legalPersonUrl', res.data);
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.tilte_btn {
  background-color: #ffffff;
}

.m_fl15 {
  margin-left: 15px;
}
.addrs {
  padding: 5px 20px;
  font-size: 17px;
  .iconfont {
    margin-right: 10px;
  }
}
.welfareType {
  cursor: pointer;
}

.entperise-entimg {
  display: flex;
  flex-flow: row wrap;
}

.entperise-entimg img {
  margin-left: 15px;
  margin-top: 15px;
  cursor: pointer;
}
</style>
