<template>
    <div>
        <div class="web_navSort">
            <span :class="navIndex==index?'IsTab':''" 
            @click="WebNavtab(index)" v-for="(item,index) in navIndexarry" :key="index">
            {{item.title}}
            </span>
        </div>
        <el-card>
            <el-radio v-model="radio" color:="#4184fd" label="1" style="font-size:14px">使用系统自动排序</el-radio>
                 <draggable class="home_card" v-model="Sortinfo" @update="datadragEnd" :options = "{animation:500}">
                    <transition-group>
                        <el-col :span="6" v-for="(item, index) in Sortinfo" :key="index">
                            <el-card>
                                <el-row class="home_card_item">
                                    <el-col :span="6">
                                        <el-image class="home_card_image" :src="item.portraitUrl" fill></el-image>
                                        <el-image class="home_card_image" src="../../../assets/img/noheadimg.png" fill></el-image>
                                    </el-col>
                                    <el-col :span="12">
                                        <div class="home_card_item1">{{item.username}}</div>
                                        <div class="home_card_item2">{{item.sex==1?'男':'女'}} /{{item.age?item.age+'岁':'无年龄'}} / {{item.showEdu}}</div>
                                    </el-col>
                                    <el-col :span="6" style="text-align:right" class="m_top15">
                                        <el-button  type="danger" size="mini" @click="delMemSortinfo(item)">删除</el-button>
                                    </el-col>
                                </el-row>        
                                 <div class="home_card_item3">
                                        <div>
                                        <p class="people_ft_s">工作经验</p>
                                        <p class="people_ft_e">{{item.workExperience?item.workExperience:'无经验'}}</p>
                                        </div>
                                        <div>
                                            <p class="people_ft_s">求职意向</p>
                                            <p class="people_ft_e">{{item.ittcartypeNames?item.ittcartypeNames:'无'}}</p>
                                        </div>
                                        <div>
                                            <p class="people_ft_s">期望薪资</p>
                                            <p class="people_ft_e" v-if="item.ittminSalary==-1&&item.ittmaxSalary==-1">
                                                面议</p>
                                            <p class="people_ft_e" v-else-if="item.ittminSalary==-1&&item.ittmaxSalary!=-1">
                                                {{item.ittmaxSalary}}元以下</p>
                                            <p class="people_ft_e" v-else-if="item.ittminSalary!=-1&&item.ittmaxSalary==-1">
                                                {{item.ittminSalary}}元以上</p>
                                            <p class="people_ft_e" v-else>{{item.ittminSalary}}-{{item.ittmaxSalary}}元</p>
                                        </div>
                                 </div>
                            </el-card>
                        </el-col>
                     </transition-group> 
            </draggable>
            <div class="home_card" >
                <el-col :span="6" >
                     <el-card class="card_button">
                        <span @click="openAddMemdialog">新增人员</span>
                    </el-card>
                </el-col>  
            </div>
        </el-card>
        <!-- dialog 对话框-->
         <el-dialog :visible.sync="dialogVisible" :show-close="false" class="dialogstyle">
            <div>
                <div class="dialogstyle_top">请选择人员</div>
                <div class="m_top15">
                   <el-input v-model="addMem.username" placeholder="人员名称" style="width:25%" size="medium"></el-input>
                   <el-input class="ent_delFr" v-model="addMem.phone" placeholder="手机号码" style="width:25%" size="medium"></el-input>
                   <el-button @click="seachAddMenlist" type="danger" size="medium" style="margin-left:15px">筛选</el-button>
                </div>
                 <el-table :data="tableData" border ref="addTable" 
                    style="width: 100%"
                    class="m_top15" @selection-change="addTableChange">
                       <el-table-column type="selection" align="center">
                       </el-table-column>
                        <el-table-column prop="username" label="人员姓名" align="center">
                        </el-table-column>
                        <el-table-column prop="phone" label="手机号码" width="300" align="center">
                        </el-table-column>
                 </el-table> 
                 <div style="margin-top:10px;text-align: center;">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :page-sizes="[10,20, 30, 40, 50]"
                            :page-size="10"
                            :current-page="addMem.pageIndex" 
                            layout="total, sizes, prev, pager, next, jumper" 
                            :total="currenttotal">
                      </el-pagination>
                </div>
                <el-button @click="getAddMenInfo" type="primary">提交</el-button>
            </div>
        </el-dialog>
    </div>
    
</template>
<script>
import  Qs from 'qs';
import draggable from 'vuedraggable';
export default {
    name:'People_sort',
    components:{
        draggable
    },
    data(){
        return{
            navIndex:0,
            radio:'',

            Sortinfo:[],
            /*{
            id:2,
            title:'高端人才排序'
        },{
            id:3,
            title:'毕业生排序'
        }*/
            navIndexarry:[{
                id:1,
                title:'推荐简历'
            }],

            addMem:{ //新增
               phone:'',
               username:'',
               queryType:1,
               pageIndex:1,
               pageSize:10
            },
            tableData:[],
            dialogVisible:false,
            Type:1,
            currenttotal:null
            
        }
    },
    created(){
        this.memSortinfo(this.Type)
    },
    mounted(){
        //为了防止火狐浏览器拖拽的时候以新标签打开，此代码真实有效
        document.body.ondrop = function (event) {
            event.preventDefault();
            event.stopPropagation();
        } 
    },
    methods:{
        datadragEnd (evt) {
            var _self = this;
            var temp=[];
            for(var i=0;i< _self.Sortinfo.length;i++){
                temp.push({
                    memid:_self.Sortinfo[i].id,
                    sortType:_self.Type,
                    sortNo: i
                })
            }
            axios({
                url: _self.API.lingMem.updMemberInfoSort,
                method: 'post',
                headers: {
                    token: _self.Util.getjwtToken()
                },
                data:Qs.stringify({
                    mSortparamVos:JSON.stringify(temp)
                })
            }).then(function(e) {
                var resp = e.data;
                if (resp.success) {
                     _self.$message.success(resp.msg);
                }else{
                       
                }
            })
         },
        WebNavtab:function(index){
           this.navIndex=index;
           this.Type=this.navIndexarry[index].id;
           this.addMem.queryType=this.navIndexarry[index].id;
           this.memSortinfo(this.Type)
        },
        memSortinfo:function(Type){
           var _this=this;
           axios({
                url: _this.API.lingMem.queryMemberSort,
                method: 'get',
                headers: {
                    token: _this.Util.getjwtToken()
                },  
               params:{
                    queryType:Type
                }
            }).then(function(res) {
                var resp=res.data;
                if(resp.success){
                    var temp=_this.Util.recordAsk;
                    for(var i=0;i<resp.data.length;i++){
                        for(var j=0;j<temp.length;j++){
                           if(temp[j].value==resp.data[i].education){
                               resp.data[i].showEdu=temp[j].label
                           }
                        }
                    }
                   _this.Sortinfo= resp.data;
                }else{
                   _this.Sortinfo=[]; 
                }
            })
        },
        //新增筛选
        seachAddMenlist:function(){
            this.addMem.pageIndex=1;
            this.getAddMemList();
        },
        openAddMemdialog:function(){
           this.dialogVisible=true;
           this.getAddMemList();
        },
        getAddMemList:function(){
            var _this=this;
            axios({
                url: _this.API.lingMem.queryMemberInfoSort,
                method: 'POST',
                headers: {
                    token: _this.Util.getjwtToken()
                },  
                data:this.addMem
            }).then(function(res) {
                var resp=res.data;
                if(resp.success){
                    _this.tableData=resp.data.list;
                    _this.currenttotal=resp.data.pager.recordCount;
                }else{
                    _this.tableData=[];
                    _this.currenttotal=0;
                }
                
            })
        },
        //新增选择
        addTableChange:function(val){
           this.jsonData=val;
        },
        /**
         * 删除
         */
        delMemSortinfo:function(e){
            var _self = this;
            var dd = {
                "memid": e.id,
                "sortNo": 0,
                "sortType": _self.Type
            }
            axios({
                url: _self.API.lingMem.delMemberSort,
                method: 'POST',
                headers: {
                    token: _self.Util.getjwtToken()
                },
                data:dd
            }).then(function(e) {
                var resp = e.data;
                if (resp.success) {
                    _self.$message.success('删除成功！');
                    _self.memSortinfo(_self.Type);
                }else{
                    _self.$message.error('删除失败！');
                }
            }).catch(function(e){

            })
        },
        //提交
        getAddMenInfo:function(){
           var _self=this;
            if(_self.jsonData&&_self.jsonData.length>0){
                var temp=[];
                for(var i=0;i<_self.jsonData.length;i++){
                    temp.push({
                        memid:_self.jsonData[i].id,
                        sortType:_self.Type,
                        sortNo:0
                    })
                }
                axios({
                    url: _self.API.lingMem.updMemberInfoSort,
                    method: 'post',
                    headers: {
                        token: _self.Util.getjwtToken()
                    },
                    data:Qs.stringify({
                        mSortparamVos:JSON.stringify(temp)
                    })
                }).then(function(e) {
                    var resp = e.data;
                    if (resp.success) {
                            _self.dialogVisible=false;
                            _self.memSortinfo(_self.Type);
                    }else{
                            _self.$message.error('新增失败');
                    }
                })
            }else{
                _self.$message.warning('请勾选要新增的人员！');
            }
        },
        //分页操作
        handleSizeChange:function(e){
            this.addMem.pageSize=e;
            this.getAddMemList();
        },
        handleCurrentChange:function(e){
            this.addMem.pageIndex=e;
            this.getAddMemList();
        }


    }
}
</script>
<style scoped lang="scss">
.IsTab{
    color: #000000;
}
.dialogstyle_top {
	   margin-top: -20px;
	   font-family: '微软雅黑';
	   font-style: normal;
	   font-size: 15px;
	   color: #000000;
}
.home_card_item{
    height: 70px;
}
.home_card_image{
    width: 60px;
    height: 60px;
    border-radius: 150px;
}
.home_card_item1,.home_card_item2{
    text-align: left;
    margin-left:10px;
}
.home_card_item3{
    justify-content: space-between;
    text-align: center;
    .people_ft_s{
        font-size: 15px;
        color: #999999;
    }
    .people_ft_e{
       font-size: 15px;   
       font-weight: 600;
       margin-top:10px;
    }
}
.card_button{
    // height: 160px;
    line-height: 130px;
}
</style>