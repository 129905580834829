<template>
  <div class="home">
    <div class="tilte_btn">
      <span class="lineY"></span>
      <span>
        <h1>求职人才库</h1>
      </span>
      <span>
        <el-button @click="numderAdd" type="primary" class="el_btn" size="medium">新增人才信息</el-button>
      </span>
      <!--<span>
               <el-button type="primary" class="el_btn" size="medium" @click="onImport">批量录入人才信息</el-button>
           </span>-->
    </div>
    <nav class="nav_sech">
      <el-cascader :props="props" placeholder="职位类型" class="el_input" clearable v-model="memderinfo.carArryid" size="medium">
      </el-cascader>
      <el-input size="medium" v-model="memderinfo.keywords" class="el_input el_input_winth_300" placeholder="关键字：姓名、期望职业、期望行业、民族">
      </el-input>
      <el-cascader v-model="memderinfo.addrs" @change="changeAddrs" :options="enterprseAddrs" :props="addrProps" clearable placeholder="请选择所在地址" size="medium" class="el_input"></el-cascader>
    </nav>

    <!-- 第二排 -->
    <nav class="nav_sech" style="padding-top:0;margin-top:0">
      <div style="width:112px;padding-left:15px">
        <el-input v-model="memderinfo.age" style="width:100%" placeholder="年龄" size="medium" type="number" min="1">
        </el-input>
      </div>
      <div style="width:112px;padding-left:15px">
        <el-select size="medium" style="width:100%" v-model="memderinfo.sex" placeholder="性别" clearable>
          <el-option label="男" value="1"></el-option>
          <el-option label="女" value="2"></el-option>
        </el-select>
      </div>
      <div style="width:112px;margin-left:15px;">
        <el-select @change="changeSalary" size="medium" style="width:100%" v-model="memderinfo.Salary" placeholder="期望薪资" clearable>
          <el-option v-for="(item,index) in salaryArry" :key="index" :label="item.label" :value="index">
          </el-option>
        </el-select>
      </div>
      <div style="width:224px;padding-left:15px">
        <el-col :span="12">
          <el-select size="medium" style="width:100%" v-model="memderinfo.education" placeholder="学历">
            <el-option :label="item.label" :value="item.value" v-for="item in recordAskarry" :key="item.value">
            </el-option>
          </el-select>
        </el-col>
        <!-- <el-col :span="12">
                    <div style="line-height:32px;padding-left:1px">
                       <el-checkbox v-model="memderinfo.Isrecord">含以上</el-checkbox>
                    </div>
                </el-col>-->
      </div>
      <div style="width:152px;padding-left:15px">
        <el-select size="medium" style="width:100%" v-model="memderinfo.resumeComp" placeholder="是否有简历" clearable>
          <el-option label="查询全部" value="-1"></el-option>
          <el-option label="仅仅筛选有简历" value="10"></el-option>
        </el-select>
      </div>
      <!--<div style="width:224px;padding-left:15px">
                <el-col :span="13">
                    <el-input v-model="memderinfo.workExperience" style="width:100%" 
                      placeholder="工作经验" size="medium" type="number" min="1">
                </el-input>
                </el-col>
                <el-col :span="11">
                    <div style="line-height:32px;padding-left:1px">
                       <el-checkbox v-model="memderinfo.IsworkExper">含以上</el-checkbox>
                    </div>
                </el-col>
            </div>-->
      <div>
        <el-button @click="searchNumderlist" size="medium" type="warning" class="el_btn">查询</el-button>
        <el-button @click="exportExcel" size="medium" type="success" :disabled="isDisabled" class="el_btn">导出数据</el-button>
      </div>
    </nav>
    <nav class="navTab">
      <div :class="tabIndex==0?'isDivcom':''" @click="tabNavtilte(0)">
        <h2>全部/最新</h2>
      </div>
      <!-- <div :class="tabIndex==1?'isDivcom':''" @click="tabNavtilte(1)"><h2>智能推荐</h2></div> -->
    </nav>
    <main v-if="mainShow" style="height:100%">
      <div style="width:100%;height: 100px;line-height:100px;text-align:center;font-size: 20px" v-if="!tableDatas || tableDatas.length <= 0">没有数据喽</div>
      <div :class="index>0?'m_top10':''" class="people_list" v-for="(item,index) in tableDatas" :key="index" v-else>
        <div style="text-align: right;">
          <el-col :span="9">
            <img :src="item.portraitUrl" v-if="item.portraitUrl">
            <img src="../../assets/img/noheadimg.png" alt="" v-else>
          </el-col>
          <el-col :span="15">
            <p class="po_title m_top10" v-if="item.username">{{item.username}}</p>
            <p class="po_title m_top10" v-else style="color: red;">无名称</p>
            <p style="color:#999" class="m_top10">
              <span v-if="item.sex">{{item.sex==1?'男':'女'}}</span>
              <span v-else>无性别</span>
              / {{item.age?item.age:'0'}}岁 / {{item.showEducation}}
            </p>
          </el-col>
        </div>
        <div>
          <p><span style="color:#999">工作经验：</span>{{item.workExperience?item.workExperience:'无'}}</p>
          <p class="m_top10"><span style="color:#999">曾任职岗位：</span>{{item.jobTitles?item.jobTitles:'无'}}</p>
        </div>
        <div>
          <p><span style="color:#999">期望职业：</span>{{item.ittcartypeNames?item.ittcartypeNames:'无'}}</p>
          <p v-if="item.ittminSalary && item.ittmaxSalary" class="m_top10">
            <span v-if="item.ittminSalary==-1&&item.ittmaxSalary==-1" style="color: #999;">
              期望薪资：面议
            </span>
            <span v-else-if="item.ittminSalary==-1" style="color: #999;">
              期望薪资：{{item.ittmaxSalary}}元以下
            </span>
            <span v-else-if="item.ittmaxSalary==-1" style="color: #999;">
              期望薪资：{{item.ittminSalary}}元以上
            </span>
            <span v-else style="color:#999">
              期望薪资：{{item.ittminSalary}}-{{item.ittmaxSalary}}元
            </span>
          </p>
          <p v-else class="m_top10">
            <span style="color: #999;">期望薪资：无</span>
          </p>
        </div>
        <div>
          <p style="color:#999">期望职位类型：</p>
          <p class="m_top10">
            {{item.ittindtypeNames?item.ittindtypeNames:'无'}}
          </p>
        </div>
        <!-- <div>
                   <p style="color: #999;">所在地址：</p>
                   <p style="font-size: 15px;" class="m_top10">
                    {{item.liveprovinceName}}
                   </p>
                   <p style="color:#999">匹配岗位：</p>
                   <p style="font-size: 15px;" class="m_top10">暂无匹配岗位</p> 
               </div> -->
        <!-- <div>
                   <p style="color:#999">匹配度：</p>
                   <p style="font-size: 15px;" class="m_top10">00.0%</p>
               </div> -->
        <div>
          <div style="float: left;width: 300px;margin-left: -100px;">
            <el-button @click="getDetails(item)" type="danger" class="el_btn" size="small">查看详情</el-button>
            <el-button @click="downloadjl(item)" type="info" class="el_btn" size="small">下载简历</el-button>
            <el-button @click="deleteMember(item)" type="danger" class="el_btn" size="small">删除人员</el-button>
          </div>
        </div>
      </div>
      <div class="bom_connt">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[10,20, 30, 40, 50]" :page-size="10" :current-page="memderinfo.page"
          layout="total, sizes, prev, pager, next, jumper" :total="pageCount">
        </el-pagination>
      </div>
    </main>
    <!--新增人才信息弹出框-->
    <!-- <el-dialog
            title="新增人才信息" width="38%"
            :visible.sync="dialogAdd">
            <template>
                <el-form ref="permisEdit" size="medium " :model="memberAdd" label-width="70px">
                    <el-form-item label="用户名">
                        <el-input v-model="memberAdd.username" placeholder="请输入用户名"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号">
                        <el-input v-model="memberAdd.phone" placeholder="请输入手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="登录账号">
                        <el-input v-model="memberAdd.account" placeholder="请输入登录账号，为空则后台自动生成"></el-input>
                    </el-form-item>
                    <el-form-item label="登录密码">
                        <el-input v-model="memberAdd.password" placeholder="登录密码"></el-input>
                    </el-form-item>
                </el-form>
            </template>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogAdd = false">取 消</el-button>
                <el-button type="primary" @click="submitMemberEvt">提 交</el-button>
            </span>
        </el-dialog> -->
    <el-dialog title="批量人员数据" :visible.sync="batchMemberDataDialogVisible">
      <div>人员数据导入模板 <a :href="memberImportTempUrl" style="color:#409EFF;text-decoration: none">下载模板</a></div>
      <div class="import-data">
        <el-upload v-if="!importRes.importing" class="upload-demo" drag action="${ctxPath}/member/memdataupload.api" :show-file-list="false" :on-success="memberDataUploadSuccess"
          :on-error="memberDataUploadError">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将人员数据文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">请先下载模版，填写后上传。文件大小不超过500kb</div>
          <div class="el-upload__tip" slot="tip" v-if="importRes.errorFile">
            <a :href="importRes.errorFile">上传错误文件下载</a>
          </div>
        </el-upload>
        <div class="el-upload__tip" style="width: 8%" slot="tip" v-if="importRes.importing">正在导入</div>
        <div class="el-upload__tip import-logs" slot="tip" v-if="importRes.importing">
          <div v-for="(item, index) in importRes.infos" :key="index"><span v-html="item"></span><br></div>
        </div>

      </div>
      <div style="text-align:center">
        <el-button type="primary" @click="batchMemberImport">导 入</el-button>
        <el-button @click="batchMemberDataDialogVisible = false">取 消</el-button>
      </div>
      <div style="margin-top: 20px" v-html="importMsg"></div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "JobMember",
  data () {
    return {
      memberImportTempUrl: this.API.memberImportTempUrl,
      tableDatas: [],
      props: {//职位类型
        lazy: true,
        value: 'id',
        label: "name",
        checkStrictly: true,
        lazyLoad: this.LoadjobTypeDatas
      },
      memderinfo: {
        page: 1,
        limit: 10,
      },
      enterprseAddrs: [], //企业地址
      addrProps: {
        lazy: true,
        value: "areaCode",
        checkStrictly: true,
        label: "areaTitle",
        lazyLoad: this.addrLazyLoad
      },
      recordAskarry: [],//学历
      salaryArry: [],//薪资

      // dialogAdd: false,//新增人才
      // memberAdd: {
      //     account: "",
      //     password: "",
      //     phone: "",
      //     username: ""
      // },

      tabIndex: 0,
      mainShow: true,

      pageCount: null,//分页操作
      importRes: {},
      batchMemberDataDialogVisible: false,
      importMsg: "",
      jdcloadding: false,
      educationUp: false,
      isDisabled: false
    }
  },
  created () {
    this.recordAskarry = this.Util.recordAsk;
    this.salaryArry = this.Util.salaryArr;
    this.loadmenDataList();
  },
  methods: {
    tabNavtilte: function (index) {
      this.tabIndex = index;
    },
    //职位类型懒加载
    LoadjobTypeDatas: function (node, resolve) {
      var eid = "0";
      var _this = this;
      if (node.data) {
        eid = node.data.id;
      }
      axios.get(_this.API.jtAll.findByPid, {
        headers: {
          token: _this.Util.getjwtToken()
        },
        params: {
          id: eid
        }
      }).then(function (pores) {
        if (pores.data.success) {
          if (pores.data.data && pores.data.data.length > 0) {
            resolve(pores.data.data);
          } else {
            resolve([]);
          }
        }
      })
    },
    //地址懒加载
    addrLazyLoad: function (node, resolve) {
      var eid = "1";
      var _self = this;
      if (node.data) {
        eid = node.data.areaCode;
      }
      axios.get(_self.API.areacode.getAreacode, {
        headers: {
          token: _self.Util.getjwtToken()
        },
        params: {
          parent: eid
        }
      }).then(function (pores) {
        if (pores.data.success) {
          if (pores.data.data && pores.data.data.length > 0) {
            resolve(pores.data.data);
          } else {
            resolve([]);
          }
        }
      })
    },
    changeAddrs: function (arrid) {
      console.log(arrid);
      if (arrid && arrid.length > 0) {
        this.memderinfo.liveprovinceCode = arrid[0];
        this.memderinfo.livecityCode = arrid[1];
        this.memderinfo.livecountyCode = arrid[2];
      } else {
        this.memderinfo.liveprovinceCode = '';
        this.memderinfo.livecityCode = '';
        this.memderinfo.livecountyCode = '';
      }
    },
    //选择薪资
    changeSalary: function (val) {
      if (val) {
        this.memderinfo.ittminSalary = this.salaryArry[val].min;
        this.memderinfo.ittmaxSalary = this.salaryArry[val].max;
      } else {
        this.memderinfo.ittminSalary = '';
        this.memderinfo.ittmaxSalary = '';
      }
    },
    //查询
    searchNumderlist: function () {
      this.memderinfo.page = 1;
      this.jdcloadding = true;
      if (this.memderinfo.Isrecord) {
        this.memderinfo.education = this.memderinfo.education + 2;
        this.memderinfo.educationUp = 1;
        this.educationUp = true;
      } else {
        this.memderinfo.educationUp = 0;
      }
      /*if(this.memderinfo.IsworkExper){
         this.memderinfo.workExperienceUp=1;
      }else{
         this.memderinfo.workExperienceUp=0; 
      }*/
      if (this.memderinfo.carArryid && this.memderinfo.carArryid.length > 0) {
        var temp = [];
        temp = this.memderinfo.carArryid;
        this.memderinfo.ittcartypeIds = temp[temp.length - 1];
        //  this.memderinfo.carArryid;
      } else {
        this.memderinfo.ittcartypeIds = ''
      }
      console.log(this.memderinfo.ittcartypeIds);
      this.loadmenDataList()
    },
    exportExcel: function () {
      this.isDisabled=true;
      const _this = this
      axios.get(_this.API.mem.talentExport, { responseType: 'blob' }).then(res => {
        let data = res.data;
        let fileReader = new FileReader();
        fileReader.onload = (event) => {
          try {
            console.log(event, 'event')
            if (event.target.result.indexOf('success') >= 0) {
              let jsonData = JSON.parse(event.target.result);
              if (jsonData.code) {
                // 说明是普通对象数据，后台转换失败
                console.log(jsonData)
                _this.$message({
                  type: "error",
                  message: jsonData.msg
                });
              }
            } else {
              _this.popDownLoadBox(res.data, `人员数据.xls`)
            }
          } catch (err) {
            console.log(err, "流处理")
            _this.$message({
              type: "error",
              message: "导出人才数据失败！"
            });
          }
        };
        fileReader.readAsText(data);
        this.isDisabled=false;
      }).catch(err => {
        console.log(err, 'errerrerr ---------------')
      })
    },
    //获取列表数据
    loadmenDataList: function () {
      var _this = this;
      const loading = this.$loading({
        lock: true,
        text: 'loading...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      });
      var queryParam = JSON.parse(JSON.stringify(_this.memderinfo))
      queryParam.carArryid = "";
      axios({
        url: _this.API.mem.talentList,
        method: 'get',
        params: queryParam,
        headers: {
          token: _this.Util.getjwtToken(),
        },
      }).then(function (e) {
        let resp = e.data;
        if (_this.educationUp == true) {
          _this.memderinfo.education = _this.memderinfo.education - 2;
        }
        if (resp.success) {
          var temp = _this.Util.recordAsk;
          // console.log('人员列表',resp);
          for (var i = 0; i < resp.data.list.length; i++) {
            for (var j = 0; j < temp.length; j++) {
              if (temp[j].value == resp.data.list[i].education) {
                resp.data.list[i].showEducation = temp[j].label;
              }
            }
          }
          _this.tableDatas = resp.data.list;
          _this.pageCount = resp.data.pager.recordCount;
          if (_this.jdcloadding) {
            _this.$message.success("查询成功^_^");
            _this.jdcloadding = false;
          }
        } else {
          _this.tableDatas = []
        }
        loading.close();
      }).catch(function (e) {
        loading.close();
      })
    },
    // submitMemberEvt: function(){
    // var _this = this;
    // var data = JSON.parse(JSON.stringify(_this.memberAdd));
    // if(!data.username){
    //    _this.$message.error("用户名不能为空");
    //    return;
    // }
    // if(!_this.Util.phoneVail(data.phone)){
    //    _this.$message.error("手机号格式不对");
    //    return;
    // }
    // if(!data.password){
    //    _this.$message.error("登录密码不能为空");
    //    return;
    // }else{
    //     data.password = _this.$md5(data.password);
    // }
    //     axios({
    //         url: _this.API.mem.insert,
    //         method: 'post',
    //         data: data,
    //         headers:{
    //             token:this.Util.getjwtToken(),
    //         },
    //     }).then(function(e){
    //         let resp = e.data;
    //         if(resp.success){
    //             var data = resp.data;
    //             _this.tableDatas.unshift(data);
    //             _this.memberAdd = {
    //                 account: "",
    //                 password: "",
    //                 phone: "",
    //                 username: ""
    //             };
    //             _this.dialogAdd =  false;
    //             _this.$confirm('添加成功，是否继续完善改成员信息?', '提示', {
    //                 confirmButtonText: '去完善',
    //                 cancelButtonText: '不去了',
    //                 type: 'warning'
    //             }).then(() => {
    //                 _this.$parent.pullItem("添加人才信息", "Numder_add", data);
    //             })
    //         }else{
    //             _this.$message.error(resp.msg);
    //         }
    //     })
    // },
    //添加
    numderAdd: function () {
      this.$parent.pullItem("添加人才信息", "Numder_add");
      // this.dialogAdd = true;
    },
    //查看详情
    getDetails: function (row) {
      this.$parent.pullItem("简历详情", "ResumeDetail", row)
    },
    downloadjl(item) {
      const _this = this
      axios.get(_this.API.resume.download + item.id, {responseType: 'blob'}).then(res => {
        let data = res.data;
        let fileReader = new FileReader();
        fileReader.onload = (event) => {
          try {
            console.log(event, 'event')
            if (event.target.result.indexOf('success') >= 0) {
              let jsonData = JSON.parse(event.target.result);
              if (jsonData.code) {
                // 说明是普通对象数据，后台转换失败
                console.log(jsonData)
                _this.$message({
                  type: "error",
                  message: jsonData.msg
                });
              }
            } else {
              _this.popDownLoadBox(res.data, `${item.username}个人简历.PDF`)
            }
          } catch (err) {
            console.log(err, "流处理")
            _this.$message({
              type: "error",
              message: "简历下载失败！"
            });
          }
        };
        fileReader.readAsText(data);
      }).catch(err => {
        console.log(err, 'errerrerr ---------------')
      })
    },
    deleteMember: function (item) {
      let _this = this;
      _this.$confirm('确认删除该人员?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios.delete(_this.API.mem.deleteMember,{
          headers:{
            token:_this.Util.getjwtToken(),
          },
          params:{
            id:item.id
          }
        }).then(function (res) {
          if(res.data.success){
            _this.$message({
              type: "success",
              message: "删除成功！"
            });
            _this.searchNumderlist();
          }

        }).catch(function (error) {
          console.log(error);
        });
      });
    },
    // downloadjl: function (Eid) {
    //   var _this = this;
    //   this.$confirm('是否下载该人员的简历?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     window.location.href = _this.API.resume.download + Eid;
    //   }).catch(() => {
    //     this.$message({
    //       type: 'info',
    //       message: '已取消下载'
    //     });
    //   });
    // },
    popDownLoadBox(data, filename) {
      if (data) {
        let blob = new Blob([data])
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, filename)
        } else {
          let a = document.createElement('a')
          a.download = filename
          a.href = window.URL.createObjectURL(blob)
          a.click()
        }
      }
    },
    //全部的分页操作
    handleSizeChange: function (e) {
      this.memderinfo.limit = e;
      this.loadmenDataList();
    },
    handleCurrentChange: function (e) {
      this.memderinfo.page = e;
      this.loadmenDataList();
    },
    getImportInfo: function () {
      var _self = this;
      //   axios.get("${ctxPath}/member/getImportInfo.api", {
      // 	  params: {
      // 	  }
      //   }).then(function(resp){
      // 	  if(resp.data){
      // 		  var rdata = resp.data;
      // 		  console.log(rdata);
      // 		 _self.importRes = rdata.data;
      // 	  }else{

      // 	  }
      //   }).catch(function(error){

      //   });
    },
    onImport: function () {
      this.$message.error('没做');
      //this.batchMemberDataDialogVisible= true;
    },
    memberDataUploadSuccess: function (response, file, fileList) {
      if (response.success) {
        this.importFilename = response.data;
        this.importMsg = '<span style="color:#409EFF">' + response.msg + '</span>';
      } else {
        this.importMsg = '<span style="color:red">' + response.msg + '</span>';
      }
    },
    memberDataUploadError: function (err, file, fileList) {
      // console.log('error', err)
      // console.log('file', file)
      // console.log('fileList', fileList)
    },
    batchMemberImport: function () {
      if (!this.importFilename) {
        this.importMsg = '<span style="color:red">请重新上传数据文件</span>';
        return;
      }
      var _self = this;
      axios.get("${ctxPath}/member/memimport.api", {
        params: {
          filename: this.importFilename
        }
      }).then(function (resp) {
        if (resp.data) {
          var rdata = resp.data;
          _self.$message(rdata.msg);
        } else {
          _self.$message.error('导入异常，请重试');
        }
      }).catch(function (error) {
        _self.$message.error('导入异常，请重试');
      });
    },
  }
}
</script>

<style scoped lang="scss">
.home {
  background: none;
}
.tilte_btn {
  background-color: #ffffff;
}
.navTab {
  margin: 10px 0;
}
.isDivcom {
  color: #000;
  border-bottom: 2px solid #000000;
}
.nav_sechList {
  display: flex;
  background: #fff;
  padding-top: 15px;
}
.nav_sech {
  height: 60px !important;
}
.el_input_winth_300 {
  width: 300px !important;
}
</style>