<template>
    <div>
       <div class="tilte_btn">
            <span class="lineY"></span>
            <span>
                <h2>招聘会信息</h2>
            </span>
            <span>
               <el-button @click="getRecruInfoEdit" type="warning" class="el_btn" size="medium">编辑</el-button>
           </span>
		</div>
        <div style="padding:10px">
            <el-row>
                <el-col :span="8">
                   <span class="ent_del">标题：</span>
                   <span class="ent_detail">{{RecruitInfo.title}}</span>
                </el-col>
                <el-col :span="8">
                   <span class="ent_del">举办日期：</span>
                   <span class="ent_detail">{{RecruitInfo.holdtime}} 至 {{RecruitInfo.holdendtime}}</span>
                </el-col>
                <el-col :span="8">
                   <span class="ent_del">乘车路线：</span>
                   <span class="ent_detail">{{RecruitInfo.rideCarLine?RecruitInfo.rideCarLine:'暂无路线'}}</span>
                </el-col>
            </el-row>
            <el-row style="margin-top:10px">
                <el-col :span="8">
                   <span class="ent_del">联系人：</span>
                   <span class="ent_detail">{{RecruitInfo.contactsPerson}}</span>
                </el-col>
                <el-col :span="8">
                   <span class="ent_del">联系电话：</span>
                   <span class="ent_detail">{{RecruitInfo.contactsNumber}}</span>
                </el-col>
            </el-row>
            <el-row style="margin-top:30px">
                <el-col :span="12">
                   <span class="ent_del">地址：</span>
                   <span class="ent_detail">{{RecruitInfo.provinceName?RecruitInfo.provinceName:'无'}}-
                       {{RecruitInfo.cityName?RecruitInfo.cityName:'无'}}-{{RecruitInfo.countyName?RecruitInfo.countyName:'无'}}
                   </span>
                   <span class="ent_detail">
                        {{RecruitInfo.addrDetail?" / "+RecruitInfo.addrDetail:''}}
                   </span>
                </el-col>
                <el-col :span="12">
                   <span class="ent_del">现场布展图：</span>
                </el-col>
            </el-row>
            <el-row >
                <el-col :span="12">
                    <div class="mapBox" id="mapBox">
                     <!-- 地图 -->
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="mapBox">
                     <!-- 图片 -->
                     <img :src="RecruitInfo.meetingImage" alt="">
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="tilte_btn">
            <span class="lineY"></span>
            <span>
                <h2>招聘会简介</h2>
            </span>
		</div>
        <div style="padding:10px">
             <el-input
                disabled
                type="textarea"
                v-model="RecruitInfo.descript"
                placeholder="请输入简介"
                style="width:90%"
                :autosize="{ minRows: 7, maxRows: 10}">
            </el-input>
        </div>
    </div>
</template>
<script>
import { lazyAMapApiLoaderInstance } from 'vue-amap';
export default {
    name:'Recruit_info',
    data(){
        return{
          RecruitInfo:{}
        }
    },
    /*props:{
         comRecruit:Object
     },*/
    created(){
        this.comRecruit = this.$router.currentRoute.query;
         var _self=this;
         this.Eid=this.comRecruit.id;
         this.getrecruitData(this.Eid);
    },
    methods:{
        getRecruInfoEdit:function(){
           this.$parent.$parent.pullItem("招聘会信息编辑","Recruit_edit",this.comRecruit);
        },
        getrecruitData:function(Eid){
           var _this=this;
            axios({
                    url: _this.API.job_fairs.getRecruitMeetingOne,
                    method: 'get',
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                     params:{
                         id:Eid
                     }
                }).then(function(e){
                    var resp=e.data;
                    if(resp.success){
                        lazyAMapApiLoaderInstance.load().then(() => {
                            //替换经纬度
                            var point = new AMap.LngLat(resp.data.addrlng,resp.data.addrlat);
                                _this.map = new AMap.Map('mapBox', {
                                    center: point,
                                    zoom: 13
                                });
                            var marker = new AMap.Marker({
                                position:point
                            });
                            _this.map.add(marker);       
                        });
                        _this.RecruitInfo=resp.data;
                    }
                })
        }
    }
    
}
</script>
<style lang="scss" scoped>
.tilte_btn{
    background-color: #ffffff;
}
.mapBox img{
   width: 100%;
   height: 100%;
}
</style>