<template>
  <div class="Res_home">
    <el-row style="height:100%;">
      <el-col :span="18" style="background:#fff;padding-bottom:30px">
        <div class="Res_fl">
          <div id="divId01" class="Res_title">
            <div><img v-if="MemjbInfo.portraitUrl" :src="MemjbInfo.portraitUrl" />
              <img v-else src="../../assets/img/noheadimg.png" />
            </div>
            <div>
              <p class="Num_name m_top20"><span class="fontWidth">{{MemjbInfo.username?MemjbInfo.username:'无名称'}}</span><span
                  class="m_fl15">更新时间：{{MemjbInfo.createtime?MemjbInfo.createtime:'暂未更新'}}</span></p>
              <p class="Num_name m_top20"><span class="m_fl15">{{MemjbInfo.sex==1?'男':'女'}}</span><span class="m_fl15">I</span>
                <span class="m_fl15">{{MemjbInfo.age?MemjbInfo.age+'岁':'无年龄'}}</span><span class="m_fl15">I</span>
                <span class="m_fl15">{{MemjbInfo.liveprovinceName?MemjbInfo.liveprovinceName:'无地址'}}
                  {{MemjbInfo.livecityName?'-'+MemjbInfo.livecityName:''}}
                  <!-- {{MemjbInfo.livecountyName?MemjbInfo.livecountyName:''}} /  -->
                </span><span class="m_fl15">I</span>
                <span class="m_fl15">{{MemjbInfo.workExperience?MemjbInfo.workExperience+'经验':'无经验'}}</span>
              </p>
              <p class="Num_name m_top20"><span class="m_fl15">{{MemjbInfo.phone?MemjbInfo.phone:'无电话号码'}}</span>
                <span class="m_fl15">{{MemjbInfo.email?MemjbInfo.email:'无电子邮箱'}}</span>
              </p>
            </div>
            <div>
              <el-button @click="downloadJL(MemjbInfo)" type="primary" size="small">下载简历</el-button>
              <el-button @click="jobRecom" type="warning" size="small">推荐岗位给他</el-button>
            </div>
          </div>
          <div id="divId02">
            <div class="tilte_btn">
              <span class="lineY"></span>
              <span>
                <h2>求职意向</h2>
              </span>
            </div>
            <div style="padding:10px">
              <el-row>
                <el-col :span='10'>
                  <span class="ent_del">求职状态：</span>
                  <span class="ent_detail" v-if="MemjbInfo.ittJobstate==1">
                    离职
                  </span>
                  <span class="ent_detail" v-else-if="MemjbInfo.ittJobstate==2">
                    在职,可一个月到岗
                  </span>
                  <span class="ent_detail" v-else-if="MemjbInfo.ittJobstate==3">
                    在职,考虑换工作
                  </span>
                  <span class="ent_detail" v-else>
                    在职,应届生毕业
                  </span>
                </el-col>
                <el-col :span='10'>
                  <span class="ent_del ent_delFr">期望地点：</span>
                  <span class="ent_detail">
                    {{MemjbInfo.ittprovinceName?MemjbInfo.ittprovinceName:'无地址'}}
                    {{MemjbInfo.ittcityName?'-'+MemjbInfo.ittcityName:''}}
                  </span>
                </el-col>
              </el-row>
            </div>
            <div style="padding:10px">
              <el-row>
                <el-col :span='10'>
                  <span class="ent_del">期望行业：</span>
                  <span class="ent_detail">{{MemjbInfo.ittindtypeNames?MemjbInfo.ittindtypeNames:'无行业'}}</span>
                </el-col>
                <el-col :span='10'>
                  <span class="ent_del ent_delFr">期望职业：</span>
                  <span class="ent_detail">{{MemjbInfo.ittcartypeNames?MemjbInfo.ittcartypeNames:'无职业'}}</span>
                </el-col>
              </el-row>
            </div>
            <div style="padding:10px">
              <el-row>
                <el-col :span='10'>
                  <span class="ent_del">期望薪资：</span>
                  <span class="ent_detail" v-if="MemjbInfo.ittminSalary==-1&&MemjbInfo.ittmaxSalary==-1">
                    面议
                  </span>
                  <span class="ent_detail" v-else-if="MemjbInfo.ittminSalary==-1">{{MemjbInfo.ittmaxSalary}}元以下</span>
                  <span class="ent_detail" v-else-if="MemjbInfo.ittmaxSalary==-1">{{MemjbInfo.ittminSalary}}元以上</span>
                  <span class="ent_detail" v-else>{{MemjbInfo.ittminSalary?MemjbInfo.ittminSalary:'0'}}-{{MemjbInfo.ittmaxSalary?MemjbInfo.ittmaxSalary:'0'}}元</span>
                </el-col>
                <el-col :span='10'>
                  <span class="ent_del ent_delFr">工作性质：</span>
                  <span class="ent_detail">{{MemjbInfo.ittType==1?'全职':'普工/兼职'}}</span>
                </el-col>
              </el-row>
            </div>
          </div>
          <div id="divId03">
            <div class="tilte_btn">
              <span class="lineY"></span>
              <span>
                <h2>工作经验</h2>
              </span>
            </div>
            <div class="exper_box" v-for="(item,index) in WorkInfo" :key="index" :class="index>0?'m_top5':''">
              <p class="ent_detail" style="font-size: 18px;">{{item.entname}}
                <span class="ent_del m_fl15" v-if="item.endtime&&item.endtime==-1">{{item.starttime}} - 至今</span>
                <span class="ent_del m_fl15" v-else>{{item.starttime}} - {{item.endtime}}</span>
              </p>
              <el-row style="padding:20px 0">
                <el-col :span='8'>
                  <span class="ent_del">{{item.jobname}}</span><span style="padding:0 10px;font-size:15px">l</span>
                  <span class="ent_detail" v-if="item.getSalaryMin==-1&&item.getSalaryMax==-1">面议</span>
                  <span class="ent_detail" v-else-if="item.getSalaryMin==-1">{{item.getSalaryMax}}元以下/月</span>
                  <span class="ent_detail" v-else-if="item.getSalaryMax==-1">{{item.getSalaryMin}}元以上/月</span>
                  <span class="ent_detail" v-else>{{item.getSalaryMin}}-{{item.getSalaryMax}}元/月</span>
                </el-col>
                <el-col :span='8'>
                  <span class="ent_del ent_delFr">职位类别：</span>
                  <span class="ent_detail offWidth">{{item.carnames}}</span>
                </el-col>
                <el-col :span='8'>
                  <span class="ent_del ent_delFr">所属行业：</span>
                  <span class="ent_detail offWidth">{{item.indnames}}</span>
                </el-col>
              </el-row>
              <p class="ent_del">工作内容：</p>
              <p class="ent_detail Res_job" style="padding:10px 0">{{item.workDesc}}</p>
              <p class="ent_del">工作业绩：</p>
              <p class="ent_detail Res_job" style="padding-top:10px">
                {{item.workArch}}
              </p>
            </div>
          </div>
          <div class="line m_top30"></div>
          <div id="divId04">
            <div class="tilte_btn">
              <span class="lineY"></span>
              <span>
                <h2>项目经验</h2>
              </span>
            </div>
            <!-- 项目经验 -->
            <div class="exper_box" v-for="(item,index) in ProjectInfo" :key="'project'+index" :class="index>0?'m_top5':''">
              <p class="ent_detail" style="font-size: 18px;">{{item.mweEntname?item.mweEntname:'无企业'}}
                <span class="ent_del m_fl15" v-if="item.endtime==-1"> {{item.starttime}} - 至今</span>
                <span class="ent_del m_fl15" v-else>{{item.starttime}} - {{item.endtime}}</span>
              </p>
              <p style="margin:20px 0;font-size:18px;color:#666;font-weight:600">{{item.personalDuties}}</p>
              <p class="ent_del">项目名称：</p>
              <p class="ent_detail Res_job" style="padding:10px 0">{{item.projectName}}</p>
              <p class="ent_del">项目描述：</p>
              <p class="ent_detail Res_job" style="padding:10px 0">{{item.projectDesc}}</p>
              <p class="ent_del">项目业绩：</p>
              <p class="ent_detail Res_job" style="padding-top:10px">
                {{item.projectArch}}
              </p>
            </div>
          </div>
          <div class="line m_top30"></div>
          <div id="divId05">
            <div class="tilte_btn">
              <span class="lineY"></span>
              <span>
                <h2>教育经历</h2>
              </span>
            </div>
            <!-- 教育经历 -->
            <div class="exper_box" v-for="(item,index) in EduInfo" :key="'EduInfo'+index" :class="index>0?'m_top5':''">
              <el-row>
                <el-col :span="6">
                  <p class="ent_detail" style="font-size: 18px;">{{item.schoolName}}</p>
                </el-col>
                <el-col :span="6">
                  <p class="ent_del">{{item.majorName}}</p>
                </el-col>
                <el-col :span="6">
                  <p class="ent_del">{{item.Showeducation}}</p>
                </el-col>
                <el-col :span="6">
                  <p class="ent_del" v-if="item.endtime==-1">{{item.starttime}} - 至今</p>
                  <p class="ent_del" v-else>{{item.starttime}} - {{item.endtime}}</p>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="line m_top30"></div>
          <div id="divId06">
            <div class="tilte_btn">
              <span class="lineY"></span>
              <span>
                <h2>培训经历</h2>
              </span>
            </div>
            <!-- 培训经历 -->
            <div class="exper_box" v-for="(item,index) in TrainInfo" :key="'index'+index" :class="index>0?'m_top5':''">
              <el-row>
                <el-col :span="8">
                  <p class="ent_detail" style="font-size: 18px;">{{item.institutionsName}}</p>
                </el-col>
                <el-col :span="8">
                  <p class="ent_del">{{item.courseName}} </p>
                </el-col>
                <el-col :span="8">
                  <p class="ent_del" v-if="item.endtime==-1">{{item.starttime}} - 至今</p>
                  <p class="ent_del" v-else>{{item.starttime}} - {{item.endtime}}</p>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="line m_top30"></div>
          <div id="divId07">
            <div class="tilte_btn">
              <span class="lineY"></span>
              <span>
                <h2>专业技能</h2>
              </span>
            </div>
            <!-- 专业技能 -->
            <div class="exper_box" v-for="(item,index) in SkillInfo" :key="'SkillInfo'+index" :class="index>0?'m_top5':''">
              <el-row>
                <el-col :span="8">
                  <p class="ent_detail" style="font-size: 18px;">{{item.skillName}}</p>
                </el-col>
                <el-col :span="8">
                  <p class="ent_del">{{item.showValue}}</p>
                </el-col>
                <el-col :span="8">
                  <p class="ent_del">{{item.useTimeLength}}个月</p>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="line m_top30"></div>
          <div id="divId08">
            <div class="tilte_btn">
              <span class="lineY"></span>
              <span>
                <h2>语言能力</h2>
              </span>
            </div>
            <!-- 语言能力 -->
            <div class="exper_box" v-for="(item,index) in Langskill" :key="'Langskill'+index" :class="index>0?'m_top5':''">
              <el-row>
                <el-col :span="8">
                  <p class="ent_detail" style="font-size: 18px;">{{item.language}}</p>
                </el-col>
                <el-col :span="8">
                  <span class="ent_del ent_delFr">听说能力：</span>
                  <span class="ent_detail">{{item.hearSayValue}}</span>
                </el-col>
                <el-col :span="8">
                  <span class="ent_del ent_delFr">读写能力：</span>
                  <span class="ent_detail">{{item.readWriteValue}}</span>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="line m_top30"></div>
          <div id="divId09">
            <div class="tilte_btn">
              <span class="lineY"></span>
              <span>
                <h2>证书</h2>
              </span>
            </div>
            <!-- 证书 -->
            <div class="exper_box" v-for="(item,index) in certBook" :key="'certBook'+index" :class="index>0?'m_top5':''">
              <el-row>
                <el-col :span="12">
                  <p class="ent_detail" style="font-size: 18px;">{{item.name}}</p>
                </el-col>
                <el-col :span="12">
                  <p class="ent_del">{{item.gettime}}</p>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6" :class="istopHe>60?'Topflxed':''">
        <div class="Res_fr">
          <div></div>
          <div>
            <div style="padding:10px">
              <p class="ent_del">简历名称：</p>
              <p class="ent_detail m_top10">{{MemjbInfo.resumeName?MemjbInfo.resumeName:'暂无名称'}}</p>
              <el-button @click="getEdit" style="width:100%;margin-top:10px" type="success">编辑简历</el-button>
            </div>
            <div class="inte"></div>
            <div style="padding:10px">
              <p style="padding:10px 0" class="ent_del">当前简历完成度</p>
              <el-progress :percentage="MemjbInfo.resumeComp"></el-progress>
              <div class="line m_top20"></div>
            </div>
            <ul class="Resinfo">
              <li @click="tabFrlist(index)" :class="tabFRIndex==index?'IsStyle':''" v-for="(list,index) in frListArry" :key="index">
                {{list}}
              </li>
            </ul>
          </div>
        </div>
      </el-col>
    </el-row>
    <!--岗位推荐-->
    <el-dialog title="推荐岗位给他" top="30" :visible.sync="dialogopenJob" width="30%">
      <div class="OutText">
        <span class="ent_del">推荐岗位：</span>
        <el-autocomplete style="width:50%" size="medium" value-key="jobname" :fetch-suggestions="jobInputSearch" placeholder="请输入岗位名称" v-model="jobRecominfo.jobname" @select="jobNamehandleSelect">
        </el-autocomplete>
        <div class="m_top15">
          <span class="ent_del">推荐理由：</span>
          <el-input class="m_top10" type="textarea" :autosize="{ minRows: 3, maxRows: 5}" maxlength="100" v-model="jobRecominfo.reason" placeholder="请输入理由/100字内">
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogopenJob = false">取 消</el-button>
        <el-button type="primary" @click="jobrecomData">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
var scrDiv = document.getElementsByClassName("tab_page");
export default {
  name: "Res_Detail",
  /*props: {
      comParam: Object
  },*/
  data () {
    return {
      istopHe: 0,
      jobRecominfo: {},
      dialogopenJob: false,//推荐岗位
      comParam: {},
      tabFRIndex: 0,
      MemjbInfo: {},//基本信息
      WorkInfo: [],//工作经历
      ProjectInfo: [],//项目经验
      EduInfo: [],//教育经历
      TrainInfo: [],//培训经历
      SkillInfo: [],//专业技能
      Langskill: [],//语言能力
      certBook: [],//证书
      frListArry: ['基本信息', '求职意向', '工作经验', '项目经验',
        '教育经历', '培训经历', '语言能力', '专业技能', '获得证书',//'附件','自我评价','其他'
      ]
    }
  },
  created () {
    this.comParam = this.$router.currentRoute.query;
    this.memid = this.comParam.id;
    this.ResumeBasicInfo(this.memid);
    this.ResumeWorkInfo(this.memid);
    this.ResumeProjectInfo(this.memid);
    this.EducationInfo(this.memid);
    this.ResumeTrainInfo(this.memid);
    this.ResumeSkillInfo(this.memid);
    this.ResumeLangInfo(this.memid);
    this.ResumeCertbookInfo(this.memid);
    this.jobRecominfo.memid = this.memid;
  },
  mounted () {
    this.arryId = [];
    scrDiv[0].addEventListener('scroll', this.Scroll, true)
  },
  destroyed () {
    scrDiv[0].removeEventListener('scroll', this.Scroll, true)
  },
  methods: {
    calcScrollHeight: function () {
      for (var i = 1; i < 10; i++) {
        var h = document.getElementById("divId0" + i).clientHeight;
        this.arryId[i - 1] = h;
      }
    },
    //右边切换
    tabFrlist: function (index) {
      this.calcScrollHeight();
      this.isscroll = true;
      if (index == 0) {
        scrDiv[0].scrollTop = 0;
      } else {
        scrDiv[0].scrollTop = this.addArr(this.arryId, 0, index - 1);
      }
      this.tabFRIndex = index;
      this.istopHe = scrDiv[0].scrollTop;
      var _self = this;
      setTimeout(function () {
        _self.isscroll = false;
      }, 2000)
    },
    Scroll: function (e) {
      if (this.isscroll) {
        return;
      }
      this.calcScrollHeight();
      let scrTop = scrDiv[0].scrollTop;
      this.istopHe = scrTop;
      if (scrTop <= this.arryId[0]) {
        this.tabFRIndex = 0;
      } else if (scrTop > this.arryId[0] && scrTop <= this.addArr(this.arryId, 0, 1)) {
        this.tabFRIndex = 1;
      } else if (scrTop > this.addArr(this.arryId, 0, 1) && scrTop <= this.addArr(this.arryId, 0, 2)) {
        this.tabFRIndex = 2;
      } else if (scrTop > this.addArr(this.arryId, 0, 2) && scrTop <= this.addArr(this.arryId, 0, 3)) {
        this.tabFRIndex = 3;
      } else if (scrTop > this.addArr(this.arryId, 0, 3) && scrTop <= this.addArr(this.arryId, 0, 4)) {
        this.tabFRIndex = 4;
      } else if (scrTop > this.addArr(this.arryId, 0, 4) && scrTop <= this.addArr(this.arryId, 0, 5)) {
        this.tabFRIndex = 5;
      } else if (scrTop > this.addArr(this.arryId, 0, 5) && scrTop <= this.addArr(this.arryId, 0, 6)) {
        this.tabFRIndex = 6;
      } else if (scrTop > this.addArr(this.arryId, 0, 7) && scrTop <= this.addArr(this.arryId, 0, 8)) {
        this.tabFRIndex = 7;
      } else {
        this.tabFRIndex = 8;
      }
    },
    //计算数组的和
    addArr (arr, s, e) {
      var sum = 0;
      for (var i = s; i <= e; i++) {
        sum += arr[i];
      }
      return sum;
    },
    ResumeBasicInfo: function (memid) {
      var _this = this;
      axios({
        url: _this.API.resume.baseinfo + memid,
        method: 'get',
        headers: {
          token: _this.Util.getjwtToken(),
        },
      }).then(function (res) {
        console.log('基本信息', res);
        if (res.data.success) {
          _this.MemjbInfo = res.data.data
        } else {

        }
      })
    },
    //岗位推荐
    jobRecom: function () {
      this.dialogopenJob = true;
    },
    jobrecomData: function () {
      var _this = this;
      if (this.Util.isBlank(_this.jobRecominfo.jobname)) {
        this.$message.warning('请输入推荐岗位');
        return
      }
      if (this.Util.isBlank(_this.jobRecominfo.reason)) {
        this.$message.warning('请输入推荐理由');
        return
      }
      axios({
        url: _this.API.enterprise.recommandJob,
        method: 'post',
        headers: {
          token: _this.Util.getjwtToken(),
        },
        data: _this.jobRecominfo
      }).then(function (res) {
        if (res.data.success) {
          _this.dialogopenJob = false;
          _this.$message.success('推荐成功');
        } else {
          _this.dialogopenJob = false;
          _this.$message.error('推荐失败，请稍后重试！')
        }
      })
    },
    //推荐岗位输入建议
    jobInputSearch: function (queryString, cb) {
      var _self = this;
      if (!queryString) {
        queryString = "";
      }
      axios({
        url: _self.API.ajob.list,
        method: 'get',
        headers: {
          token: _self.Util.getjwtToken()
        },
        params: {
          publishTimeStart: '',
          publishTimeEnd: '',
          jobtype: '-1',
          recruitType: '1',
          keywords: queryString,
          page: 1,
          limit: 10
        }
      }).then(function (e) {
        var resp = e.data;
        if (resp.success) {
          if (resp.data.list) {
            cb(resp.data.list)
          } else {
            cb([]);
          }
        } else {
          cb([]);
        }
      }).catch(function (e) {
        cb([]);
      })
    },
    jobNamehandleSelect: function (item) {
      this.jobRecominfo.enterPriseId = item.entid;
      this.jobRecominfo.jobid = item.id;
    },
    //工作经历
    ResumeWorkInfo: function (memid) {
      var _this = this;
      axios({
        url: _this.API.resume.work_wexp + "/" + memid,
        method: 'get',
        headers: {
          token: _this.Util.getjwtToken(),
        },
      }).then(function (res) {
        if (res.data.success) {
          _this.WorkInfo = res.data.data;
          console.log('经历', res)
        } else {
          _this.WorkInfo = []
        }
      })
    },
    //项目经验
    ResumeProjectInfo: function (memid) {
      var _this = this;
      axios({
        url: _this.API.resume.project_exp + "/" + memid,
        method: 'get',
        headers: {
          token: _this.Util.getjwtToken(),
        },
      }).then(function (res) {
        if (res.data.success) {
          _this.ProjectInfo = res.data.data
        } else {
          _this.ProjectInfo = []
        }
      })
    },
    //教育背景
    EducationInfo: function (memid) {
      var _this = this;
      axios({
        url: _this.API.resume.edu_exp + "/" + memid,
        method: 'get',
        headers: {
          token: _this.Util.getjwtToken(),
        },
      }).then(function (res) {
        if (res.data.success) {
          var resp = res.data.data;
          var temp = _this.Util.recordAsk;
          for (var i = 0; i < resp.length; i++) {
            for (var j = 0; j < temp.length; j++) {
              if (resp[i].education == temp[j].value) {
                resp[i].Showeducation = temp[j].label;
              }
            }
          }
          _this.EduInfo = resp;
        } else {
          _this.EduInfo = []
        }
      })
    },
    //培训经历
    ResumeTrainInfo: function (memid) {
      var _this = this;
      axios({
        url: _this.API.resume.train_exp + "/" + memid,
        method: 'get',
        headers: {
          token: _this.Util.getjwtToken(),
        },
      }).then(function (res) {
        if (res.data.success) {
          _this.TrainInfo = res.data.data
        } else {
          _this.TrainInfo = []
        }
      })
    },
    //专业技能
    ResumeSkillInfo: function (memid) {
      var _this = this;
      axios({
        url: _this.API.resume.major_skill + "/" + memid,
        method: 'get',
        headers: {
          token: _this.Util.getjwtToken(),
        },
      }).then(function (res) {
        var resp = res.data;
        if (resp.success && resp.data.length > 0) {
          var temp = _this.Util.masterLevels;
          for (var i = 0; i < resp.data.length; i++) {
            for (var j = 0; j < temp.length; j++) {
              if (temp[j].value == resp.data[i].masterLevel) {
                resp.data[i].showValue = temp[j].label
              }
            }
          }
          _this.SkillInfo = resp.data;
        } else {
          _this.SkillInfo = []
        }
      })
    },
    //语言能力
    ResumeLangInfo: function (memid) {
      var _this = this;
      axios({
        url: _this.API.resume.lang_skill + "/" + memid,
        method: 'get',
        headers: {
          token: _this.Util.getjwtToken(),
        },
      }).then(function (res) {
        var resp = res.data;
        if (resp.success && resp.data.length > 0) {
          var temp = _this.Util.masterLevels;
          for (var i = 0; i < resp.data.length; i++) {
            for (var j = 0; j < temp.length; j++) {
              if (temp[j].value == resp.data[i].hearSayLevel) {
                resp.data[i].hearSayValue = temp[j].label;//听说能力
              }
              if (temp[j].value == resp.data[i].readWriteLevel) {
                resp.data[i].readWriteValue = temp[j].label;//读写能力
              }
            }
          }
          _this.Langskill = resp.data;
        } else {
          _this.Langskill = []
        }
      })
    },
    //证书
    ResumeCertbookInfo: function (memid) {
      var _this = this;
      axios({
        url: _this.API.resume.cert_exp + "/" + memid,
        method: 'get',
        headers: {
          token: _this.Util.getjwtToken(),
        },
      }).then(function (res) {
        if (res.data.success) {
          _this.certBook = res.data.data;
        } else {
          _this.certBook = [];
        }
      })
    },
    //编辑
    getEdit: function () {
      this.$parent.$parent.pullItem("编辑人才信息", "Numder_add", this.comParam);
      // console.log('窗口大小', this.$parent.$parent.screenWidth)
      // console.log('窗口大小', this.$parent.$parent.screenHeight)
    },
    //下载简历
    downloadJL (item) {
      const _this = this
      axios.get(_this.API.resume.download + item.id, { responseType: 'blob' }).then(res => {
        let data = res.data;
        let fileReader = new FileReader();
        fileReader.onload = (event) => {
          try {
            console.log(event, 'event')
            if (event.target.result.indexOf('success') >= 0) {
              let jsonData = JSON.parse(event.target.result);
              if (jsonData.code) {
                // 说明是普通对象数据，后台转换失败
                console.log(jsonData)
                _this.$message({
                  type: "error",
                  message: jsonData.msg
                });
              }
            } else {
              _this.popDownLoadBox(res.data, `${item.username}个人简历.PDF`)
            }
          } catch (err) {
            console.log(err, "流处理")
            _this.$message({
              type: "error",
              message: "简历下载失败！"
            });
          }
        };
        fileReader.readAsText(data);
      }).catch(err => {
        console.log(err, 'errerrerr ---------------')
      })
    },
    popDownLoadBox (data, filename) {
      if (data) {
        let blob = new Blob([data])
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, filename)
        } else {
          let a = document.createElement('a')
          a.download = filename
          a.href = window.URL.createObjectURL(blob)
          a.click()
        }
      }
    },
    // downloadJL: function () {
    //   var _this = this;
    //   this.$confirm('是否下载该人员的简历?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     window.location.href = _this.API.resume.download + _this.comParam.id;
    //   }).catch(() => {
    //     this.$message({
    //       type: 'info',
    //       message: '已取消下载'
    //     });
    //   });
    // },
  }
}
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
}
.Res_home {
  background-color: #f6f7f6;
}
.tilte_btn {
  background: #fff;
}
.Res_job {
  line-height: 30px;
}
.IsStyle {
  a {
    color: #409eff;
  }
  color: #409eff;
  border-left: 4px solid #409eff;
}
.offWidth {
  width: 190px;
  vertical-align: top;
  display: inline-block;
}
</style>