<template>
    <div class="home">
        <div class="tilte_btn">
            <span class="m_fl15">
                <h2>全局banner管理</h2>
            </span>
        </div>
        <div class="web_box">
            <span class="ent_del">首页顶部广告位布局管理 (尺寸520 * 244)</span>
            <el-row :gutter="30" class="m_top30">
                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6"  v-for="(item, index) in bannerTypeList" :key="index">
                    <div v-if="item.layout=='TLR'">
                        <div class="Web_bander_up" @click="bannerClick(index, 'T', item.bannerImgMaps.T)">
                            <el-image
                                class="el_imgage"
                                :src="item.bannerImgMaps.T.imgurl"
                                :fit="contain">
                                <div slot="error" class="error">
                                    Banner
                                </div>
                            </el-image>
                        </div>
                        <div class="web_flxe m_top5">
                            <div class="Web_bander_uplist" @click="bannerClick(index, 'RB', item.bannerImgMaps.RB)">
                                    <el-image
                                    class="el_imgage"
                                    :src="item.bannerImgMaps.RB.imgurl"
                                    :fit="contain">
                                    <div slot="error" class="error">
                                        Banner
                                    </div>
                                </el-image>
                            </div>
                            <div class="Web_bander_uplist" @click="bannerClick(index, 'BL', item.bannerImgMaps.BL)">
                                <el-image
                                    class="el_imgage"
                                    :src="item.bannerImgMaps.BL.imgurl"
                                    :fit="contain">
                                    <div slot="error" class="error">
                                        Banner
                                    </div>
                                </el-image>
                            </div>
                        </div>
                        <div style="text-align: center;margin:20px 0">
                            <el-radio-group v-model="selectBannerType" @change="changeTabType">
                                <el-radio :label="item.id">选择</el-radio>
                            </el-radio-group>   
                        </div> 
                    </div>
                    <!-- 第二种类型开始 -->
                    <div v-if="item.layout=='LR'">
                        <div class="web_flxe">
                            <div class="Web_bander_uplist" style="height:54px" @click="bannerClick(index, 'LT', item.bannerImgMaps.LT)">
                                <el-image
                                    class="el_imgage"
                                    :src="item.bannerImgMaps.LT.imgurl"
                                    :fit="contain">
                                    <div slot="error" class="error" style="line-height: 54px">
                                        Banner
                                    </div>
                                </el-image>
                            </div>
                            <div class="Web_bander_uplist" style="height:54px" @click="bannerClick(index, 'RT', item.bannerImgMaps.RT)">
                                <el-image
                                    class="el_imgage"
                                    :src="item.bannerImgMaps.RT.imgurl"
                                    :fit="contain">
                                    <div slot="error" class="error" style="line-height: 54px">
                                        Banner
                                    </div>
                                </el-image>
                            </div>
                        </div>
                        <div class="web_flxe m_top5">
                            <div class="Web_bander_uplist" style="height:54px" @click="bannerClick(index, 'LC', item.bannerImgMaps.LC)">
                                <el-image
                                    class="el_imgage"
                                    :src="item.bannerImgMaps.LC.imgurl"
                                    :fit="contain">
                                    <div slot="error" class="error" style="line-height: 54px">
                                        Banner
                                    </div>
                                </el-image>
                            </div>
                            <div class="Web_bander_uplist" style="height:54px" @click="bannerClick(index, 'RC', item.bannerImgMaps.RC)">
                                <el-image
                                    class="el_imgage"
                                    :src="item.bannerImgMaps.RC.imgurl"
                                    :fit="contain">
                                    <div slot="error" class="error" style="line-height: 54px">
                                        Banner
                                    </div>
                                </el-image>
                            </div>
                        </div>
                        <div class="web_flxe m_top5">
                            <div class="Web_bander_uplist" style="height:54px" @click="bannerClick(index, 'LB', item.bannerImgMaps.LB)">
                                <el-image
                                    class="el_imgage"
                                    :src="item.bannerImgMaps.LB.imgurl"
                                    :fit="contain">
                                    <div slot="error" class="error" style="line-height: 54px">
                                        Banner
                                    </div>
                                </el-image>
                            </div>
                            <div class="Web_bander_uplist" style="height:54px" @click="bannerClick(index, 'RB', item.bannerImgMaps.RB)">
                                <el-image
                                    class="el_imgage"
                                    :src="item.bannerImgMaps.RB.imgurl"
                                    :fit="contain">
                                    <div slot="error" class="error" style="line-height: 54px">
                                        Banner
                                    </div>
                                </el-image>
                            </div>
                        </div>
                        <div style="text-align: center;margin:20px 0">
                            <el-radio-group v-model="selectBannerType" @change="changeTabType">
                                <el-radio :label="item.id">选择</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <!-- 第三种开始 -->
                    <div v-if="item.layout=='C'">
                        <!--以后换成轮播图，先不做-->
                        <el-carousel class="fw_box" :autoplay="false">
                            <el-carousel-item v-for="(list, ji) in item.bannerImgs" 
                                :key="'carouse' + ji" class="fw_boxList">
                                <el-image
                                    @click="bannerClick(index, 'C', list)"
                                    class="el_carouse"
                                    :src="list.imgurl"
                                    :fit="contain">
                                    <div slot="error" class="error" style="line-height: 176px">
                                        Banner
                                    </div>
                                </el-image>
                                <el-button @click="delBander(list.id)" type="text">删除</el-button>
                            </el-carousel-item>
                        </el-carousel>
                        <div style="text-align: center;margin:20px 0">
                            <el-radio-group v-model="selectBannerType" @change="changeTabType">
                                <el-radio :label="item.id">选择</el-radio>
                            </el-radio-group>
                            <span class="m_fl15">
                               <el-button size="mini" @click="carouselAdd(index, 'C')" type="text">添加图片</el-button>
                            </span>
                        </div>
                    </div>
                    <!-- 第四种 -->
                    <div v-if="item.layout=='LTR'">
                        <div class="web_flxe">
                            <div style="width:48%">
                               <div class="Web_bander_up" style="height:176px;width:100%" @click="bannerClick(index, 'L', item.bannerImgMaps.L)">
                                    <el-image
                                        class="el_imgage"
                                        :src="item.bannerImgMaps.L.imgurl"
                                        :fit="contain">
                                        <div slot="error" class="error" style="line-height: 176px">
                                            Banner
                                        </div>
                                    </el-image>
                               </div>
                            </div>
                            <div style="width:48%">
                                <div class="Web_bander_uplist" style="width:100%" @click="bannerClick(index, 'RT', item.bannerImgMaps.RT)">
                                    <el-image
                                        class="el_imgage"
                                        :src="item.bannerImgMaps.RT.imgurl"
                                        :fit="contain">
                                        <div slot="error" class="error">
                                            Banner
                                        </div>
                                    </el-image>
                                </div>
                                <div class="Web_bander_uplist m_top5" style="width:100%" @click="bannerClick(index, 'RB', item.bannerImgMaps.RB)">
                                    <el-image
                                        class="el_imgage"
                                        :src="item.bannerImgMaps.RB.imgurl"
                                        :fit="contain">
                                        <div slot="error" class="error">
                                            Banner
                                        </div>
                                    </el-image>
                                </div>
                            </div>
                        </div>
                        <div style="text-align: center;margin:20px 0">
                            <el-radio-group v-model="selectBannerType" @change="changeTabType">
                                <el-radio :label="item.id">选择</el-radio>
                            </el-radio-group>
                        </div>
                    </div>        
                </el-col>
            </el-row>
            <!-- 首页顶部广告结束 -->
        </div>
        <!-- <div class="inte"></div> -->
        <div v-for="(item,index) in homeOtherList" :key="index">
            <div class="web_box m_top10">
                <span class="ent_del">{{item.name}}</span>
                <p class="ent_detail m_top20">布局：{{item.layout}} 所属菜单栏目: {{item.menuName}} （尺寸：358 * 150）</p>
                <div class="web_swper m_top10">
                    <div class="web_list m_top5" v-for="(list,key) in item.bannerImgs" :key="key">
                        <li>
                            <img :src="list.imgurl" @click="homeAddClick(index,'HT',list)" alt="">
                            <p @click="delBander(list.id)">删除</p>
                        </li>
                    </div>
                    <div class="web_list m_top5">
                        <li class="web_banner_add" @click="homeAddClick(index, 'HT', '')">
                            <span style="color:#666">+新增banner</span>
                        </li>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog :visible.sync="dialogVisible" width="40%">
            <div class="dialogstyle_top">| 编辑banner</div>
            <div class="dialogstyle_select">
                <span>请点击图片更换:</span>
            </div>
                <el-upload
                    class="dialogstyle_image"
                    :action="uploadImgUrl"
                    :show-file-list="false"
                    :on-success="handleLicensePhotoSuccess">
                    <el-image style="height:200px" :fit="contain" :src="selectBannerTypeListItem.imgurl">
                       <div slot="error">
                            请上传图片
                        </div>
                    </el-image>
                </el-upload>
            <div class="dialogstyle_input">
                <span>请输入跳转链接</span>
                <el-input placeholder="请输入" v-model="selectBannerTypeListItem.targetUrl">请输入</el-input>
            </div>
            <div class="dialogstyle_input">
                <span>排序</span>
                <el-input placeholder="请输入" v-model="selectBannerTypeListItem.sort">请输入排序值</el-input>
            </div>
            <div class="dialogstyle_footer">
                <el-button @click="editDialogOk">确定</el-button>
                <el-button type="info" @click="dialogVisible = false">取消</el-button>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="dialogVisible1" width="40%">
            <div class="dialogstyle_top">| 添加/编辑banner</div>
            <div class="dialogstyle_select">
                <span>请点击图片更换:</span>
            </div>
            <el-upload
                    class="dialogstyle_image"
                    :action="uploadImgUrl"
                    :show-file-list="false"
                    :on-success="handleLicensePhotoSuccess1">
                    <el-image style="height:200px" :fit="contain" :src="selectBannerTypeListItem1.imgurl">
                        <div slot="error">
                            请上传图片
                        </div>
                    </el-image>
            </el-upload>
            <div class="dialogstyle_input">
                <span>请输入跳转链接</span>
                <el-input placeholder="请输入" v-model="selectBannerTypeListItem1.targetUrl">请输入</el-input>
            </div>
            <div class="dialogstyle_input">
                <span>排序</span>
                <el-input placeholder="请输入" v-model="selectBannerTypeListItem1.sort">请输入排序值</el-input>
            </div>
            <div class="dialogstyle_footer">
                <el-button @click="editDialogOk1">确定</el-button>
                <el-button type="info" @click="dialogVisible1 = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import  Qs from 'qs';
export default {
    name: "Web_bander",
    data () {
        return {
            uploadImgUrl: this.API.file.uploadSingle,//类型列表地址
            bannerTypeList:[],
            selectBannerType:'',
 
            homeOtherList:[],//首页其他的bannder

            // 默认不显示对话框
            dialogVisible: false,
            selectBannerTypeListItem: {},
            dialogVisible1: false,
            selectBannerTypeListItem1: {},
            contain: 'cover'
        }
    },
    created () {
        this.LoadHomeTabType();
        this.LoadHomeZFbannerType();
    },
    methods: {
        //轮播图添加
        carouselAdd:function(index,key){
            this.selectBannerTypeListIndex = index;
            this.selectBannerTypeListItem = {
                loc: key,
                menuName: this.bannerTypeList[index].menuName,
                menuid: this.bannerTypeList[index].menuid,
                sort: 0,
                typeid: this.bannerTypeList[index].id
            };
            console.log(this.selectBannerTypeListItem);
            this.dialogVisible = true;
        },
        //首页新增bander新闻
        homeAddClick:function(index,key,item){
            console.log(index, key, item);
            var selectType = this.homeOtherList[index];
            if(item){
                this.selectBannerTypeListItem1 = JSON.parse(JSON.stringify(item));
            }else{
                this.selectBannerTypeListItem1 = {
                    loc: key,
                    menuName: this.homeOtherList[index].menuName,
                    menuid: this.homeOtherList[index].menuid,
                    sort: 0,
                    typeid: this.homeOtherList[index].id
                };
            }
            this.dialogVisible1 = true;
        },
        //上面四个类型点击
        bannerClick: function(index, key, item, ){
            console.log(index, key, item);
            this.selectBannerTypeListIndex = index;
            this.selectBannerTypeListItem = JSON.parse(JSON.stringify(item));
            console.log(this.selectBannerTypeListItem);
            this.dialogVisible = true;
        },
        //上传图片
        handleLicensePhotoSuccess(res, file) {
            console.log('上传', res);
            var _this=this;
            if(!res.success){
                _this.$message.error("上传出错了,请重试");
                return;
            }
            _this.$set(_this.selectBannerTypeListItem, 'imgurl', res.data);
        },
        //上传图片
        handleLicensePhotoSuccess1(res, file) {
            console.log('上传', res);
            var _this=this;
            if(!res.success){
                _this.$message.error("上传出错了,请重试");
                return;
            }
            _this.$set(_this.selectBannerTypeListItem1, 'imgurl', res.data);
        },
        //弹框确定
        editDialogOk: function(){
            var _this = this;
            axios({
                url: _this.API.banner_imgs.editor,
                method: 'post',
                headers: {
                    token: _this.Util.getjwtToken()
                },  
                data:_this.selectBannerTypeListItem
            }).then(function(e) {
                var resp = e.data;
                console.log("编辑图片", resp);
                if (resp.success) {
                    _this.$message(resp.msg);
                    _this.LoadHomeTabType();
                    var item = _this.bannerTypeList[_this.selectBannerTypeListIndex];
                    item.bannerImgMaps[_this.selectBannerTypeListKey] = _this.selectBannerTypeListItem;
                    _this.$set(_this.bannerTypeList, _this.selectBannerTypeListIndex, item);

                    _this.dialogVisible = false;
                }else{
                    _this.$message.error(resp.msg);
                }
            })
        },
        //弹框确定
        editDialogOk1: function(){
            var _this = this;
            axios({
                url: _this.API.banner_imgs.editor,
                method: 'post',
                headers: {
                    token: _this.Util.getjwtToken()
                },  
                data:_this.selectBannerTypeListItem1
            }).then(function(e) {
                var resp = e.data;
                console.log("编辑图片", resp);
                if (resp.success) {
                    _this.$message(resp.msg);
                    _this.LoadHomeZFbannerType();
                    //这里往对应的列表里面放数据就行了
                    _this.dialogVisible1 = false;
                }else{
                    _this.$message.error(resp.msg);
                }
            })
        },
        //获取首页广告排布类型列表
        LoadHomeTabType:function(){
           var _this=this;
           axios({
                url: _this.API.banner_type.listAll,
                method: 'get',
                headers: {
                    token: _this.Util.getjwtToken()
                },  
                params:{
                    isfocus:0,
                }
            }).then(function(res) {
                var temp=res.data;
                console.log('类型',temp);
                if (temp.success&&temp.data.length>0) {
                   _this.bannerTypeList=temp.data;
                   for(var i=0;i<temp.data.length;i++){
                       if(temp.data[i].focusSelect==1){
                         _this.selectBannerType=temp.data[i].id
                       }
                   }
                }else{
                    _this.bannerTypeList=[]
                }
            })
        },
        //首页其他bander类型
        LoadHomeZFbannerType:function(){
            var _this=this;
           axios({
                url: _this.API.banner_type.listAll,
                method: 'get',
                headers: {
                    token: _this.Util.getjwtToken()
                },  
                params:{
                    isfocus:1,
                }
            }).then(function(res) {
                var temp=res.data;
                console.log('首页新闻和职位',temp);
                if (temp.success) {
                    _this.homeOtherList=temp.data
                }else{
                    _this.homeOtherList=[]
                }
            })
        },
        changeTabType:function(eid){
             console.log(eid);
             var _this=this;
             axios({
                url: _this.API.banner_type.select,
                method: 'get',
                headers: {
                    token: _this.Util.getjwtToken()
                },
                params:{
                    id:eid
                }
            }).then(function(res) {
                if (res.data.success) {
                   _this.$message.success(res.data.msg);
                }else{
                   _this.$message.success(res.data.msg);
                }
            })
        },
        delBander: function (id) {
          var _this=this;
          this.$confirm('是否删除该bander图？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
            }).then(() => {
             _this.delBanderOk(id)
            // this.$message({
            //     type: 'success',
            //     message: '删除成功!'
            // });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        delBanderOk:function(id){
             var _this=this;
             axios({
                url: _this.API.banner_imgs.delete,
                method:'delete',
                headers: {
                    token: _this.Util.getjwtToken()
                },
                params:{
                    id:id
                }
            }).then(function(res) {
                if (res.data.success){
                    _this.LoadHomeZFbannerType();
                   _this.$message.success(res.data.msg);
                }else{
                   _this.$message.success(res.data.msg);
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.home{
    background-color: #f6f7f6;
}
.web_flxe {
    display: flex;
    justify-content: space-between;
}
.ent_detail {
    color: #333;
}
// 提示框样式
.dialogstyle {
    border-width: 0px;
    position: absolute;
    left: 400px;
    top: 221px;
    width: 634px;
    height: 675px;
}
.dialogstyle_top {
    font-family: '微软雅黑';
    font-weight: 400;
    font-style: normal;
    font-size: 30px;
    color: #000000;
}
.dialogstyle_select {
    display: flex;
    margin-top: 20px;
    align-items: center;
}
.dialogstyle_select span {
    font-size: 20px;
    color: #949494;
    flex: 0.3;
}
.dialogstyle_select .el-button {
    width: 188px;
    height: 47px;
    flex: 0.5;
}
// 图片样式
.dialogstyle_image {
    margin-top: 30px;
    width: 100%;
    height: 218px;
    line-height: 218px;
}
.dialogstyle_image .el-image {
    width: 100%;
    height: 100%;
}
// 输入框样式
.dialogstyle_input {
    margin-top: 20px;
}
.dialogstyle_input span {
    font-size: 20px;
    color: #949494;
}
.dialogstyle_input .el-input {
    margin-top: 10px;
    text-align: center;
}
// 按钮样式
.dialogstyle_footer {
    margin-top: 20px;
}
.dialogstyle_footer .el-button:first-child {
    width: 300px;
    height: 60px;
    color: #fff;
    font-size: 20px;
    background-color: rgba(255, 112, 79, 1);
}
.dialogstyle_footer .el-button:last-child {
    width: 150px;
    height: 60px;
    font-size: 20px;
}
.avatar-uploader,.el-upload{
     width: 100% !important;
 }
</style>