<template>
    <div>
        <div class="web_navSort">
                <span :class="navIndex==index?'IsTab':''" 
                   @click="WebNavtab(index)" v-for="(list,index) in navIndexarry" :key="'list'+index">
                   {{list.title}}
                </span>
        </div>
        <el-card>
            <el-radio v-model="radio" color:="#4184fd" label="1" style="font-size:14px">使用系统自动排序</el-radio>
               <draggable class="home_card" v-model="sortInfo" @update="datadragEnd" :options = "{animation:500}">
                    <transition-group>
                        <el-col :span="6" v-for="(item, index) in sortInfo" :key="index">
                           <el-card>
                                <el-row class="home_card_item">
                                        <el-col :span="5">
                                            <el-image class="home_card_image" :src="item.logopurl" fill></el-image>
                                        </el-col>
                                        <el-col :span="19">
                                            <div class="home_card_item1">{{item.name}}</div>
                                            <div class="home_card_item2">{{item.indtypenames}}</div>
                                        </el-col>
                                    </el-row>
                                <div class="home_card_item3">
                                    <div class="home_card_item4">
                                        <span>{{item.count}}</span>
                                        个热招职位
                                        <i class="el-icon-star-on"></i>
                                    </div>
                                    <el-button type="danger" size="mini" @click="editjobinfo(item)">删除</el-button>
                                </div>
                            </el-card>
                        </el-col>
                     </transition-group> 
            </draggable>
            <div class="home_card">
                <el-col :span="6">
                     <el-card class="card_button">
                        <span @click="openAddEntdialog">新增企业</span>
                    </el-card>
                </el-col>  
            </div>
            
        </el-card>
        <!-- dialog 对话框-->
         <el-dialog :visible.sync="dialogVisible" :show-close="false" class="dialogstyle">
            <div>
                <div class="dialogstyle_top">请选择企业</div>
                <div class="m_top15">
                   <el-input v-model="addEnt.name" placeholder="企业名称" style="width:25%" size="medium"></el-input>
                   <el-date-picker class="ent_delFr"
                            v-model="addEnt.createtime"
                            @change="changeCreatetime"
                            type="date"
                            size="medium"
                            clearable
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="注册时间">
                        </el-date-picker>
                   <el-button @click="seachAddEntlist" type="danger" size="medium" style="margin-left:15px">筛选</el-button>
                </div>
                 <el-table :data="tableData" border ref="addTable" 
                    style="width: 100%"
                    class="m_top15" @selection-change="addTableChange">
                       <el-table-column type="selection" align="center">
                       </el-table-column>
                        <el-table-column prop="name" label="公司名称" align="center">
                        </el-table-column>
                        <el-table-column prop="createtime" label="注册时间" width="300" align="center">
                        </el-table-column>
                 </el-table> 
                 <div style="margin-top:10px;text-align: center;">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :page-sizes="[10,20, 30, 40, 50]"
                            :page-size="10" 
                            :current-page="addEnt.pageIndex"
                            layout="total, sizes, prev, pager, next, jumper" 
                            :total="currenttotal">
                      </el-pagination>
                </div>
                <el-button @click="getAddentInfo" type="primary">提交</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import  Qs from 'qs';
import draggable from 'vuedraggable';
export default {
    name:'Ent_sort',
    components:{
        draggable
    },
    data(){
        return{
            navIndex:0,
            radio:'',

            sortInfo:[],
            navIndexarry:[{
                id:1,
                title:'最新企业排序'
            },{
                id:2,
                title:'重点企业排序' 
            },{
                id:3,
                title:'热门企业排序'
            }],
            Type:1,
            
            tableData:[],
            addEnt:{
                createtime:'',
                name:'',
                pageIndex:1,
                pageSize:10,
                queryType:1,
            },//新增
            currenttotal:null,
            dialogVisible:false
        }
    },
    created(){
         this.entSortinfo(this.Type)
    },
    mounted(){
        //为了防止火狐浏览器拖拽的时候以新标签打开，此代码真实有效
        document.body.ondrop = function (event) {
            event.preventDefault();
            event.stopPropagation();
        } 
    },
    methods:{
         datadragEnd (evt) {
            var _self = this;
            var temp=[];
            for(var i=0;i< _self.sortInfo.length;i++){
                temp.push({
                    entid:_self.sortInfo[i].id,
                    sortType:_self.Type,
                    sortNo:i
                })
            }
            axios({
                url: _self.API.enterprise.updEnterpriseSort,
                method: 'post',
                headers: {
                    token: _self.Util.getjwtToken()
                },
                data:Qs.stringify({
                    eSortparamVos:JSON.stringify(temp)
                })
            }).then(function(e) {
                var resp = e.data;
                if (resp.success) {
                       _self.$message.success(resp.msg);
                    }else{
                }
            })
      },
        WebNavtab:function(index){
           this.navIndex=index;
           this.Type=this.navIndexarry[index].id;
           this.addEnt.queryType=this.navIndexarry[index].id;
           this.entSortinfo(this.Type);
        },
        //列表数据
        entSortinfo:function(Type){
            var _this=this;
           axios({
                url: _this.API.enterprise.enterpriseSort,
                method: 'get',
                headers: {
                    token: _this.Util.getjwtToken()
                },  
                params:{
                    queryType:Type
                }
            }).then(function(res) {
                var resp=res.data;
                if(resp.success){
                     var temp=resp.data;
                     for(var i=0;i<temp.length;i++){
                         if(temp[i].name&&temp[i].name.length>10){
                            temp[i].name=temp[i].name.substring(0,10)+'...'
                         }else{
                            temp[i].name=temp[i].name
                         }
                     }
                    _this.sortInfo=temp
                }else{
                    _this.sortInfo=[]
                }
                
            })
        },
        openAddEntdialog:function(){
           this.dialogVisible=true;
           this.getAddEntList()
        },
        changeCreatetime:function(e){
            if(e){
               this.addEnt.createtime=e;
            }else{
                this.addEnt.createtime='';
            }
            
        },
        getAddEntList:function(){
            var _this=this;
            axios({
                url: _this.API.enterprise.queryEnterpriseInfo,
                method: 'POST',
                headers: {
                    token: _this.Util.getjwtToken()
                },  
                data:this.addEnt
            }).then(function(res) {
                var resp=res.data;
                if(resp.success){
                    _this.tableData=resp.data.list;
                    _this.currenttotal=resp.data.pager.recordCount;
                }else{
                    _this.tableData=[];
                    _this.currenttotal=0;
                }
                
            })
        },
        //新增筛选
        seachAddEntlist:function(){
            this.addEnt.pageIndex=1;
            this.getAddEntList();
        },
        //选择提交
        addTableChange:function(val){
           this.jsonData=val;
        },
        //提交
        getAddentInfo:function(){
           var _self=this;
            if(_self.jsonData&&_self.jsonData.length>0){
               var temp=[];
                for(var i=0;i<_self.jsonData.length;i++){
                    temp.push({
                        entid:_self.jsonData[i].id,
                        sortType:_self.Type,
                        sortNo:0
                    })
                }
                axios({
                        url: _self.API.enterprise.updEnterpriseSort,
                        method: 'post',
                        headers: {
                            token: _self.Util.getjwtToken()
                        },
                        data:Qs.stringify({
                            eSortparamVos:JSON.stringify(temp)
                        })
                    }).then(function(e) {
                        var resp = e.data;
                        if (resp.success) {
                             _self.dialogVisible=false;
                             _self.entSortinfo(_self.Type);
                        }else{
                             _self.$message.error('新增失败');
                        }
                    })
            }else{
                _self.$message.warning('请勾选要新增的企业！');
            }
        },
        
        //编辑
        editjobinfo:function(e){
            var _self = this;
            var dd = {
                "entid": e.id,
                "sortNo": 0,
                "sortType": _self.Type
            }
            axios({
                url: _self.API.enterprise.delEnterpriseSort,
                method: 'POST',
                headers: {
                    token: _self.Util.getjwtToken()
                },
                data:dd
            }).then(function(e) {
                var resp = e.data;
                if (resp.success) {
                    _self.$message.success('删除成功！');
                    _self.entSortinfo(_self.Type);
                }else{
                    _self.$message.error('删除失败！');
                }
            }).catch(function(e){

            })
        },
        //分页操作
        handleSizeChange:function(e){
            this.addEnt.pageSize=e;
            this.getAddEntList();
        },
        handleCurrentChange:function(e){
            this.addEnt.pageIndex=e;
            this.getAddEntList();
        }

    }
}
</script>
<style scoped lang="scss">
.IsTab{
    color: #000000;
}
.card_button{
    /* // height: 160px; */
    line-height: 138px;
}
.dialogstyle_top {
    margin-top: -20px;
    font-family: '微软雅黑';
    font-style: normal;
    font-size: 15px;
    color: #000000;
}
</style>