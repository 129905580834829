import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../components/Index'
import Login from "../pages/login/Login";

import Ent_goodspot from "../pages/BasicData/Ent_goodspot";
import Ent_nature from "../pages/BasicData/Ent_nature";
import Ent_especial from "../pages/BasicData/Ent_especial";
import Ent_scale from "../pages/BasicData/Ent_scale";
import Ent_size from "../pages/BasicData/Ent_size";
import Ent_welfare from "../pages/BasicData/Ent_welfare";
import Ent_lndustry from "../pages/BasicData/Ent_lndustry";
import Ent_major from "../pages/BasicData/Ent_major";
import Ent_meminfo from "../pages/BasicData/Ent_meminfo";

import Recruit from "../pages/recruits/Recruit";
import Recruit_add from "../pages/recruits/Recruit_add";
import Recruit_edit from "../pages/recruits/Recruit_edit";
import Recruit_BoothEdit from "../pages/recruits/Recruit_BoothEdit";
import Recruit_detail from "../pages/recruits/Recruit_detail";

import EnterpriseList from "../pages/organs/EnterpriseList";
import EnterpriseReg from "../pages/organs/EnterpriseReg";
import Enterpriseadd from "../pages/organs/Enterpriseadd";
import EnterpriseDetail from "../pages/organs/EnterpriseDetail";
import EnterpResume from "../pages/organs/EnterpResume";

import Numder from "../pages/numder/Numder";
import Desire from "../pages/numder/Desire";
import Numder_add from "../pages/numder/Numder_add";

import Resume from "../pages/resume/Resume";
import ResumeDetail from "../pages/resume/ResumeDetail";

import Web_bander from "../pages/webruns/Web_bander";
import Web_recom from "../pages/webruns/Web_recom";
import Web_newZW from "../pages/webruns/Web_newZW";
import Web_newZX from "../pages/webruns/Web_newZX";
import Web_newAdd from "../pages/webruns/Web_newAdd";
import Web_newDetail from "../pages/webruns/Web_newDetail";
import Web_newEdit from "../pages/webruns/Web_newEdit";
import Web_info from "../pages/webruns/Web_info";
import Web_topNav from "../pages/webruns/Web_topNav";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/index',
    name: 'Index',
    component: Index,
    children: [
      {
        path: "/Home",
        name: "Home",
        component: () => import('@/pages/home/Home')
      },
      {
        path: "/Jobs",
        name: "Jobs",
        component: () => import('@/pages/jobs/Jobs')
      },
      {
        path: "/JobDetail",
        name: "JobDetail",
        component: () => import("@/pages/jobs/JobDetail")
      },
      {
        path: "/JobAdd",
        name: "JobAdd",
        component: () => import('@/pages/jobs/JobAdd')
      },
      {
        path: "/Powers",
        name: "Powers",
        component: () => import('@/pages/systems/Powers')
      },
      {
        path: "/Role",
        name: "Role",
        component: () => import('@/pages/systems/Role')
      },
      {
        path: "/Admin",
        name: "Admin",
        component: () => import('@/pages/systems/Admin')
      },
      {
        path: "/Person",
        name: "Person",
        component: () => import('@/pages/systems/Person')
      },
      {
      path: "/Enterprise",
      name: "Enterprise",
      component: () => import('@/pages/systems/Enterprise')
      },

      {
        path: "/Recruit",
        name: "Recruit",
        component: Recruit
      },
      {
        path: "/Recruit_add",
        name: "Recruit_add",
        component: Recruit_add
      },
      {
        path: "/Recruit_detail",
        name: "Recruit_detail",
        component: Recruit_detail
      },
      {
        path: "/Recruit_edit",
        name: "Recruit_edit",
        component: Recruit_edit
      },
      {
        path: "/Recruit_BoothEdit",
        name: "Recruit_BoothEdit",
        component: Recruit_BoothEdit
      },
      {
        path: "/EnterpriseList",
        name: "EnterpriseList",
        component: EnterpriseList
      },
      {
        path: "/EnterpriseReg",
        name: "EnterpriseReg",
        component: EnterpriseReg
      },
      {
        path: "/Enterpriseadd",
        name: "Enterpriseadd",
        component: Enterpriseadd
      },
      {
        path: "/EnterpriseDetail",
        name: "EnterpriseDetail",
        component: EnterpriseDetail
      },
      {
        path: "/EnterpResume",
        name: "EnterpResume",
        component: EnterpResume
      },
      {
        path: "/Ent_goodspot",
        name: "Ent_goodspot",
        component: Ent_goodspot
      },
      {
        path: "/Ent_nature",
        name: "Ent_nature",
        component: Ent_nature
      },
      {
        path: "/Ent_especial",
        name: "Ent_especial",
        component: Ent_especial
      },
      {
        path: "/Ent_scale",
        name: "Ent_scale",
        component: Ent_scale
      },
      {
        path: "/Ent_size",
        name: "Ent_size",
        component: Ent_size
      },
      {
        path: "/Ent_welfare",
        name: "Ent_welfare",
        component: Ent_welfare
      },
      {
        path: "/Ent_lndustry",
        name: "Ent_lndustry",
        component: Ent_lndustry
      },
      {
        path: "/Ent_major",
        name: "Ent_major",
        component: Ent_major
      },
      {
        path: "/Ent_meminfo",
        name: "Ent_meminfo",
        component: Ent_meminfo
      },
      {
        path: "/Numder",
        name: "Numder",
        component: Numder
      },
      {
        path: "/Desire",
        name: "Desire",
        component: Desire
      },
      {
        path: "/Numder_add",
        name: "Numder_add",
        component: Numder_add
      },
      {
        path: "/Resume",
        name: "Resume",
        component: Resume
      },
      {
        path: "/ResumeDetail",
        name: "ResumeDetail",
        component: ResumeDetail
      },
      {
        path: "/Web_bander",
        name: "Web_bander",
        component: Web_bander
      },
      {
        path: "/Web_newZW",
        name: "Web_newZW",
        component: Web_newZW
      },
      {
        path: "/Web_newZX",
        name: "Web_newZX",
        component: Web_newZX
      },
      {
        path: "/Web_newAdd",
        name: "Web_newAdd",
        component: Web_newAdd
      },
      {
        path: "/Web_newDetail",
        name: "Web_newDetail",
        component: Web_newDetail
      },
      {
        path: "/Web_recom",
        name: "Web_recom",
        component: Web_recom
      },
      {
        path: "/Web_newEdit",
        name: "Web_newEdit",
        component: Web_newEdit
      },
      {
        path: "/Web_info",
        name: "Web_info",
        component: Web_info
      },
      {
        path: "/Web_topNav",
        name: "Web_topNav",
        component: Web_topNav
      },
      {
        path: "/Web_SingleMenuArt",
        name: "Web_SingleMenuArt",
        component: () => import('@/pages/webruns/Web_SingleMenuArt')
      },
      {
        path: "/Web_smaEditor",
        name: "Web_smaEditor",
        component: () => import('@/pages/webruns/Web_smaEditor')
      },
      {
        path: "/WebNewsNav",
        name: "WebNewsNav",
        component: () => import('@/pages/webruns/WebNewsNav')
      },
      {
        path: "/RecruitPreordain",
        name: "RecruitPreordain",
        component: () => import('@/pages/recruits/RecruitPreordain')
      },
      //新增的劳动力转移模块
      {
        path: "/wroks",
        name: "Wroks",
        component: () => import('@/pages/wroks/Wroks')
      },
      {
        path: "/Notice",
        name: "Notice",
        component: () => import('@/pages/webruns/Web_Notice')
      },
      {
        path: "/Web_NoticeAdd",
        name: "Web_NoticeAdd",
        component: () => import('@/pages/webruns/Web_NoticeAdd')
      },
      {
        path: "/Web_noticeDetail",
        name: "Web_noticeDetail",
        component: () => import('@/pages/webruns/Web_noticeDetail')
      },
      {
        path: "/Web_noticeEdit",
        name: "Web_noticeEdit",
        component: () => import('@/pages/webruns/Web_noticeEdit')
      }
      
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
