<template>
    <div class="home">
        <div class="tilte_btn">
           <span class="lineY"></span>
           <span><h2>招聘会管理</h2></span>
           <span>
               <el-button @click="getRecruitAdd" type="danger" class="el_btn" size="medium">新增招聘会</el-button>
           </span>
        </div>
        <nav class="nav_sech" style="justify-content: space-between;">
               <div>
               <el-select @change="changeStatus" class="el_input" v-model="ShowList.statusName" placeholder="规定状态" clearable>
                    <el-option label="开放预定" value="1"></el-option>
                    <el-option label="停止预定" value="2"></el-option>
                </el-select>
                <el-date-picker
                    v-model="ShowList.item" class="el_input" clearable
                    @change="chengesholdtime"
                    type="daterange"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </div>
            <div>
                <el-input
                    style="width:250px;margin-right:15px"
                    placeholder="请输入姓名/关键字"
                    v-model="searchParam.keyWords">
                    <el-button class="searchBtn" slot="append" @click="searchList">搜索</el-button>
                </el-input>
            </div>
        </nav>
         <div class="inte"></div>
         <main class="el_table" style="margin: 15px 15px 0">
            <el-table
                    :data="tableData"
                    style="width: 100%;margin-bottom: 20px"
                    border>
                <el-table-column
                        prop="title"
                        label="招聘会标题"
                        align="center">
                </el-table-column>
                <el-table-column label="举办时间" align="center" width="300">
					<template slot-scope="scope">
						<span>{{scope.row.holdtime}}</span>
						<span>{{scope.row.holdendtime?'至'+scope.row.holdendtime:''}}</span>
					</template>
                </el-table-column>
                <el-table-column
                        label="预定状态"
                        align="center"
                        width="300">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status==1">开放预订</span>
                        <span v-if="scope.row.status==2">停止预订</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="createtime"
                        label="新增日期"
                        align="center"
                        width="300">
                </el-table-column>
                <el-table-column label="操作"  align="center" width="300">
                    <template slot-scope="scope">
                        <el-button
                            type="danger"
                            size="medium"
                            @click="handleDetail(scope.$index, scope.row)">查看详情</el-button>
                        <!-- <el-button
                            type="warning"
                            size="medium"
                            @click="SetuppBooth(scope.$index, scope.row)">设置展位</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <div style="height:60px;text-align: center;">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10, 20, 30, 40, 50]"
                    :page-size="10"
                    :current-page="searchParam.page"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="connt">
                </el-pagination>
            </div>
        </main>
    </div>
</template>

<script>
    export default {
        name: "Recruit",
        data(){
            return{
                ShowList:{
                    statusName:'',//用来显示的
                    item:''
                },
                searchParam:{
                   status:-1,
                   holdtime:'',
                   holdendtime:'',
                   keyWords:'',
                   page:1,
                   limit:10
                },
                connt:null,
                tableData:[]
            }
        },
        created(){
            this.getRecruitList()
        },
        methods:{
            searchList:function(){
               if(this.Util.isBlank(this.searchParam.keyWords)){
                   this.$message.error('请输入关键字！');
                   return
               }
               this.searchParam.page=1;
               this.getRecruitList();
            },
            changeStatus:function(e){
                this.searchParam.page=1;
                if(e){
                   this.searchParam.status=e;
                }else{
                   this.searchParam.status=-1;
                }
                this.getRecruitList();
            },
            chengesholdtime:function(itemData){
                if(itemData&&itemData.length>0){
                    this.searchParam.holdtime=itemData[0];
                    this.searchParam.holdendtime=itemData[1];
                }else{
                    this.searchParam.holdtime='';
                    this.searchParam.holdendtime='';
                }
                this.searchParam.page=1;
                this.getRecruitList();
            },
            getRecruitList:function(){
                var _this=this;
                const loading = this.$loading({
                    lock: true,
                    text: 'loading...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(255, 255, 255, 0.7)'
                });
                axios({
                    url: _this.API.job_fairs.getRecruitMeetingList,
                    method: 'get',
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                     params:_this.searchParam
                }).then(function(e){
                    var resp = e.data;
                    if(resp.success){
                       _this.tableData=resp.data.list;
                       _this.connt=resp.data.pager.recordCount;
                    }
                    loading.close();
                }).catch(function(error){
                    loading.close();
                });
            },
            getRecruitAdd:function(){
                this.$parent.pullItem("添加招聘会", "Recruit_add");
            },
            //去详情
            handleDetail:function(index,row){
               this.$parent.pullItem("招聘会详情", "Recruit_detail",row);
            },
            //设置展位
            SetuppBooth:function(index,row){
               this.$parent.pullItem("招聘会展位设置","Recruit_BoothEdit",row);
            },
            //分页操作
            handleSizeChange:function(e){
                this.searchParam.limit=e;
                this.getRecruitList();
            },
            handleCurrentChange:function(e){
                this.searchParam.page=e;
                this.getRecruitList()
            }

        }
    }
</script>

<style scoped>

</style>
